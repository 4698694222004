import React, { useState, useEffect } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from 'react-router-dom';
import AccountHeader from './AccountHeader';
import FormLabel from '../../common/FormLabel';
import ErrorLabel from '../../common/ErrorLabel';
import { passwordStrengthValidator } from '../../../utils/form.utils';
import { guardianSignUpErrors } from '../../../constants/error.constants';
import { useAuth } from '../../auth/Auth';
import { accountViewModel } from './account.view.model';
import {
  FORGOT_PASSWORD_ROUTE,
  GUARDIAN_LOGIN_ROUTE,
  LOGIN_ROUTE,
  SCHOOL_LOGIN,
  TREEHOUSE_LOGIN,
} from '../../../constants/routes.constants';
import FormPasswordInput from '../../common/FormPasswordInput';
import useTokenVerification from '../../common/useTokenVerification';
import { TokenStatus } from '../../../constants/tokenStatus';
import LoadButton from '../../common/LoadButton';
import { displayFormErrorsFromApi } from '../../api/api.display.error';
import OnboardingWrapper from '../onboarding/OnboardingWrapper';
import { bg } from '../../../constants/component.constants';
import { changeBodyClassName } from '../../../utils/utils';
import './scss/reset.password.scss';
import './scss/login.scss';
import '../../views/home/css/home.css';

const ResetPassword = () => {
  const { register, handleSubmit, setError, errors, watch } = useForm({
    mode: 'onBlur',
  });
  const { fetchAPI } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { id, token } = useParams();
  const history = useHistory();
  const emailStatus = useTokenVerification(
    accountViewModel.validateResetPasswordToken,
    id,
    token
  );

  useEffect(() => {
    changeBodyClassName([bg.GRADIENT]);
  }, []);

  const getLoginURL = (user) => {
    switch (user?.user_type) {
      case 'guardian':
        return GUARDIAN_LOGIN_ROUTE
      case 'school':
        return SCHOOL_LOGIN
      case 'school_teacher':
        return SCHOOL_LOGIN
      case 'partner':
        return TREEHOUSE_LOGIN
      case 'camp master':
        return TREEHOUSE_LOGIN
      default:
        return LOGIN_ROUTE
    }
  }

  const onSubmit = (data) => {
    setIsLoading(true);
    fetchAPI(accountViewModel.resetPasswordWithToken, id, token, data)
      .then((res) => {
        history.replace({
          pathname: getLoginURL(res),
          message: 'Your password was successfully updated.',
        });
      })
      .catch((error) => {
        displayFormErrorsFromApi(error, setError);
        setIsLoading(false);
      });
  };

  const getBody = () => {
    if (emailStatus.tokenStatus === TokenStatus.VERIFYING) {
      return <div className="text-center" style={{ color: '#fff' }}>Verifying...</div>;
    }
    if (emailStatus.tokenStatus === TokenStatus.VERIFIED) {
      return showResetPasswordFields();
    } else {
      return (
        <div className="text-danger text-center">
          Your password reset link has expired or is invalid. <br></br>
          Please{' '}
          <Link
            className="text-secondary"
            to={FORGOT_PASSWORD_ROUTE}
          >
            click here
          </Link>{' '}
          to reset your password.
        </div>
      );
    }
  };

  function showResetPasswordFields() {
    return (
      <div className="login-form-wrapper reset-password">
        <div className="title">Password Reset</div>
        <Row className="input-wrapper">
          <Form onSubmit={handleSubmit(onSubmit)} className="form-wrapper">
            <div className="wrapper-wrapper">
              <Form.Row>
                <Form.Group className="container-fluid">
                  <FormLabel>
                    Password<span>*</span>
                  </FormLabel>
                  <FormPasswordInput
                    name="password"
                    placeholder="at least 8 characters"
                    data-testid="password"
                    ref={register({
                      validate: (value) =>
                        passwordStrengthValidator(value) ||
                        guardianSignUpErrors.password.strength,
                    })}
                  />
                  <ErrorLabel
                    errors={errors}
                    name="password"
                    className="small text-danger"
                    as="label"
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group className="container-fluid mb-0">
                  <FormLabel className="signup-label">
                    Confirm Password<span>*</span>
                  </FormLabel>
                  <FormPasswordInput
                    name="confirm_password"
                    placeholder="Confirm Password"
                    data-testid="confirm_password"
                    ref={register({
                      validate: (value) =>
                        value === watch('password') ||
                        guardianSignUpErrors.password.mismatch,
                    })}
                  />
                  <ErrorLabel
                    errors={errors}
                    name="confirm_password"
                    className="small text-danger"
                    as="label"
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row className="login-submit mt-5">
                <Form.Group className="container-fluid mb-0">
                  <LoadButton
                    text="Set New Password"
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    className="login-btn text-black p-2 gred-btn"
                    testid="submit"
                  />
                </Form.Group>
              </Form.Row>
            </div>
          </Form>
        </Row>
      </div>
    );
  }

  return (
    <OnboardingWrapper className="reset-password-container login-container reset-password-background login-container-wrapper">
      {getBody()}
      {emailStatus.tokenStatus === TokenStatus.VERIFYING ? '' :
        <Row className="justify-content-center go-back-link-container">
          <Link to={LOGIN_ROUTE}>Back to Login</Link>
        </Row>
      }
    </OnboardingWrapper>
  );
};

export default ResetPassword;
