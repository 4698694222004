import React, { useState, useEffect } from 'react';
import { Button, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useAuth } from '../../auth/Auth';
import OnboardingModal from '../onboarding/OnboardingModal';
import RoundButton from '../../common/RoundButton';
import { GUARDIAN_SETTINGS_ROUTE } from '../../../constants/routes.constants';

import './scss/confirm-attendance.scss';

const ConfirmAttendance = () => {
  const history = useHistory();
  const { guardian, refreshUserState } = useAuth();

  const handleClick = (e) => {
    refreshUserState();
    history.push({
      pathname: GUARDIAN_SETTINGS_ROUTE,
    });
  };
  return (
    <OnboardingModal
      show={true}
      showCloseButton={false}
      className="confirm-attendance"
      onHide={handleClick}
    >
      <div className="upper">
        <h1>Thank you for registering our Special Camp</h1>
        <div className="description">
          <p>
            A confirmation email has been sent to <b>{guardian.email}</b> with a
            Zoom link. Looking forward to having your kid at the Special Camp.  
          </p>
          <p>
            Please reach us at{' '}
            <a href="mailto:contact@taletree.app">contact@taletree.app</a> for
            any questions.
          </p>
        </div>
      </div>
      <div className="bottom">
        <Row className="btn-wrapper">
          <Button
            type="submit"
            className="common-round-btn mt-3 mb-2 gred-btn font-weight-bold chat-btn"
            style={{border: '2px solid'}}
            onClick={handleClick}
          >Go to Guardian Dashboard</Button>
        </Row>
      </div>
    </OnboardingModal>
  );
};

export default ConfirmAttendance;
