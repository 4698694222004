///
/// deprecated
///
import React from 'react';
import SettingsNavHeader from './SettingsNavHeader';
import CreateChildrenForm from '../../common/form/CreateChildForm';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import {
  PAYMENT_ROUTE,
  SIGNUP_COMPLETE_ROUTE,
} from '../../../constants/routes.constants';
import DashboardHeader from '../../header/DashboardHeader';
import { isStrNullOrEmpty } from '../../../utils/utils';

function AddChildren() {
  const history = useHistory();
  const onChildCreate = (dependents) => {
    const campMembers = dependents.filter(
      (dependent) => !isStrNullOrEmpty(dependent.profile.camp_status)
    );
    let pathname = '';
    let state = '';
    if (campMembers.length != 0) {
      pathname = PAYMENT_ROUTE;
      state = { dependents: campMembers };
    } else {
      pathname = SIGNUP_COMPLETE_ROUTE;
      state = { isCamp: false };
    }
    history.replace({
      pathname,
      state,
    });
  };
  return (
    <div>
      <DashboardHeader />
      <SettingsNavHeader title={`Add a Kid`} />
      <CreateChildrenForm onChildCreate={onChildCreate} />
    </div>
  );
}

export default AddChildren;
