import React from 'react';
// import { useDropzone } from 'react-dropzone';
import { BsPlusCircleFill } from '@react-icons/all-files/bs/BsPlusCircleFill';

import './scss/upload.area.scss';

const UploadArea = ({ className = '', showText = true, open }) => {
  return (
    <div className={`image-dnd-container ${className}`} onClick={open}>
      <div className="upload-icon">
        <BsPlusCircleFill className="plus-icon" />
      </div>
      <div className={`instruction`}>
        <h4>Upload your creation</h4>
        <div className={`${showText ? 'show' : 'hide'}`}>
          Drag and drop
          <br />
          or browse to choose
          <br />an image file
        </div>
      </div>
    </div>
  );
};

export default UploadArea;
