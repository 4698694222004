import React, { useState } from "react";
import Cartoon from "../../../../assets/images/cartoon.svg";
import { Form, Image } from 'react-bootstrap';
import { changeTextToVoice, getSoundWaveJson, getUpcomingTime } from "../../../../utils/camp.utils";
import Lottie from "react-lottie-player";
import LoadButton from "../../../common/LoadButton";
import { useForm } from "react-hook-form";
import SignupModal from "./SignupModal";
import { PAYMENT_ROUTE } from "../../../../constants/routes.constants";
import { useResponseError } from "../../../api/response.error";
import { useHistory } from "react-router-dom";
import Countdown, { zeroPad } from "react-countdown";
import ZoomButtonSpecialCamp from "../../camp_schedule/ZoomButtonSpecialCamp";
import ZoomButton from "../../camp_schedule/ZoomButton";
import greenTick from '../../../../assets/icon/green_tick.svg';
import Avatar from '../../../../assets/images/my_room_active.svg';
import { useAuth } from "../../../auth/Auth";

export default function CampMemberModal({ showModal, childData, newChild, campDetails }) {
    const history = useHistory();
    const DEFAULT_TEXT = `We love it when kids join a camp. Your kid will be assigned to a tribe that can chat together and meet with their camp's captain weekly.`
    const [text, setText] = useState(DEFAULT_TEXT);
    const [isPlay, setIsPlay] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const { refreshUserState } = useAuth();

    const handleVoiceClick = () => {
        setIsPlay(!isPlay);
        changeTextToVoice(text, isPlay, setIsPlay, isPaused, setIsPaused);
    }

    const handleClick = () => {
        refreshUserState();
        history.push({
            pathname: `${PAYMENT_ROUTE}`,
            state: {
                dependent: JSON.stringify(newChild),
                type: newChild?.profile?.is_activated ? 'edit' : 'activate',
            },
        })
    }

    const getSchedule = () => {
        const convertedTime = getUpcomingTime(
            campDetails?.day,
            campDetails?.time,
            campDetails?.timezone
        );
        return `${convertedTime}`;
    };

    const countdownRendererSchool = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return (
                <div className="join-button px-3">
                    {campDetails?.meet_link ?
                        <ZoomButtonSpecialCamp title="Join Camp" link={campDetails?.meet_link} /> :
                        campDetails?.zoom_link ?
                            <ZoomButtonSpecialCamp title="Join Camp" link={campDetails?.zoom_link} /> :
                            <ZoomButton title="Join Camp" creator={dependent} />}
                </div>
            )
        } else {
            return (
                <h5 className='f-600'>
                    <div className="countdown">
                        <div className="counts-days">
                            <span id="days">{days}</span><br />
                            <span>Days</span>
                        </div>
                        <div className="counts-days">
                            <span id="hrs">{zeroPad(hours)}</span><br />
                            <span>hrs</span>
                        </div>
                        <div className="counts-days">
                            <span id="mins">{zeroPad(minutes)}</span><br />
                            <span>mins</span>
                        </div>
                        <div className="counts-days">
                            <span id="secs">{zeroPad(seconds)}</span><br />
                            <span>secs</span>
                        </div>
                    </div>
                </h5>
            );
        }
    };

    return (
        <>
            <SignupModal className="gardian auth-popup right-curved-div" showModal={showModal}>
                <div className='row bg-blue border-40'>
                    <div className="col-md-6 col-lg-5 p-4 bg-blue border-left overflow-hidden">
                        <div className="voice-buble buuble-bounce" id="SpeechBubble" style={{ width: '230px' }}>
                            <p id='voice-text' >
                                {text}
                            </p>
                        </div>
                        <div className="text-left">
                            {/* <p className="text-white voice-btn" onClick={handleVoiceClick}><BsVolumeUpFill /> Voice on</p> */}
                            <div className="" style={{marginTop: '9rem'}}>
                                {/* <Lottie
                                    loop
                                    animationData={getSoundWaveJson()}
                                    play={isPlay}
                                    className='animate-svg'
                                /> */}
                            </div>
                        </div>
                        <div className="cartoon-img">
                            <img className="w-100" src={Cartoon} />
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-7 signup-7 bg-white border-right right-curve auth-section white-right-arrow toparrow topinmius right-bottom-curve">
                        <h1 className="congratulations-h1 congratulations-heading">{childData?.first_name} is now a member of:</h1>
                        <div className="camp-schedule-container creator-camp-box justify-content-center text-center camp-schedule-main-containers">
                            <div className="camp-schedule-title p-3">{campDetails?.camp_details?.school_name}</div>
                            <div className='inner-camp-box-my-room width-box campmember-1'>
                                <div className='width-box camp-captain-profile-box'>
                                    <ul className='d-flex kid-up-box '>
                                        <li className='kid-up-name'>
                                            <div className="camp-captain-profile-box-img">
                                                <Image src={campDetails?.camp_details?.picture ? campDetails?.camp_details?.picture : Avatar} alt="" className='camp_img' width="50" />
                                                <Image src={greenTick} alt="" className='green-tick' />
                                            </div>
                                            Tribe: {campDetails?.name}
                                        </li>
                                    </ul>
                                    <p>Your camp captain:&nbsp;
                                        {campDetails?.captain_details ? campDetails?.captain_details?.first_name + ' ' + campDetails?.captain_details?.last_name : campDetails?.name}</p>
                                    <h5 className="next-camp-h5">Next Camp:</h5>
                                    <Countdown
                                        date={Date.now() + Number(getSchedule())}
                                        renderer={countdownRendererSchool}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="gain-access-div">

                            <h3 className="camp_captain_h3">Share your creativity and dream big!</h3>

                            <div className="bottom bottom-created">
                                <div className="button-wrapper bottom-created1">
                                    <Form.Group className="container-fluid w-100 account-created no-yes-buttons">
                                        <LoadButton
                                            className="gred-btn signup-btn rounded-pill mb-4 font-weight-bold access-btn"
                                            text="Gain Access"
                                            onClick={handleClick}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SignupModal>
        </>
    )
}