import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import FormInput from '../../common/FormInput';
import LoadButton from '../../common/LoadButton';
import { HiCheckCircle } from '@react-icons/all-files/hi/HiCheckCircle';
import { BsXCircleFill } from '@react-icons/all-files/bs/BsXCircleFill';
import { settingsViewModel } from './settings.view.model';
import ErrorLabel from '../../common/ErrorLabel';
import { schoolCodeError } from '../../../constants/error.constants';
import { useResponseError } from '../../api/response.error';
import ValidHttpStatusError from '../../api/api.error.valid';
import { useAuth } from '../../auth/Auth';
import { displayFormErrorsFromApi } from '../../api/api.display.error';

const AddSchoolModal = ({ show, setShow, dependent }) => {
    const [uniqueCode, setUniqueCode] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const { addError, removeError } = useResponseError();
    const [isCodeVerified, setIsCodeVerified] = useState();
    const {refreshUserState} = useAuth();
    const { register, handleSubmit, watch, setError, errors, getValues } = useForm({
        mode: 'onBlur',
    });

    const onSubmit = handleSubmit((e) => {
        setIsLoading(true);
        setIsDisabled(true);
        let params = {
            dependent_id: dependent.id,
            group_code: uniqueCode
        }
        settingsViewModel.connectTreehouse(params).then((res) => {
            //   console.log(res)
            setShow(false)
            refreshUserState();
        }).catch((err) => {
            console.log(err)
            setIsDisabled(false)
            setIsLoading(false)
            if (err instanceof ValidHttpStatusError) {
                displayFormErrorsFromApi(err, setError);
              } else {
                addError(err.message);
              }
        })
    })

    const checkTreehouse = (code) => {
        let body = {
            group_code: code
            // camp_code: code
        }
        settingsViewModel.checkTreehouse(code).then((res) => {
            setIsCodeVerified(true);
            setIsDisabled(false);
            setUniqueCode(code);
        }).catch((err) => {
            setIsDisabled(true);
            setIsCodeVerified(false);
        })
    }

    const handleCode = (e) => {
        checkTreehouse(e.target.value)
        // setUniqueCode(e.target.value)
    }

    return (
        <Modal show={show} onHide={() => setShow(false)} className='theme-modal class-mode'>
            <Modal.Header closeButton>
            {/* <ResponseErrorNotification /> */}
                <Modal.Title className='d-flex justify-content-center w-100 mt-4'>
                    <div className='d-flex text-center font-weight-bold'>Do you have a camp code from<br /> your child's camp captain?</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='col-md-8 justify-content-center mx-auto'>
                <Form onSubmit={onSubmit}>
                    <Form.Row className='mb-4 mt-40 w-100'>
                        <Form.Group className="d-flex justify-content-center w-100">
                            <FormInput
                                className="signup-input w-100"
                                name="classCode"
                                placeholder="Enter your unique class code"
                                onChange={handleCode}
                                data-testid="classCode"
                                maxLength={40}
                                ref={register({
                                    required: schoolCodeError.code.required,
                                    invalid: schoolCodeError.code.invalid
                                })}
                            />
                            {isCodeVerified ?
                                <span className='signup-input-check-ic'><HiCheckCircle /></span>
                            : isCodeVerified === false ?
                                <span className='signup-input-cross-ic'><BsXCircleFill /></span>
                            : "" }
                        </Form.Group>
                        <ErrorLabel errors={errors} name="classCode" as="label" />
                    </Form.Row>

                    <div className="button-wrapper">
                        <Form.Group className="container-fluid w-100">
                            <Button
                                className="load-button-container float-left rounded-pill mb-4 font-weight-bold"
                                variant="light"
                                onClick={() => setShow(false)}
                            >
                                <span>Cancel</span>
                            </Button>
                            <LoadButton
                                className="gred-btn signup-btn rounded-pill float-right mb-4 font-weight-bold"
                                text="Enter"
                                isDisabled={isDisabled}
                                isLoading={isLoading}
                                testid="submit"
                            // onClick={() => onSubmit(getValues(), uniqueCode)}
                            />
                        </Form.Group>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default AddSchoolModal;