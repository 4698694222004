import React, { useState, useRef, useEffect } from 'react';
import UploadArea from './UploadArea';

import './scss/image.drag.drop.scss';

function ImageDragDrop({ list, setList, open, handleImageEditClick }) {
  const [dragging, setDragging] = useState(false);
  const [cancelImageShow, setShow] = useState(false);
  const dragContainer = useRef();
  const dragItem = useRef();
  const dragItemNode = useRef();

  const handletDragStart = (e, item) => {
    dragItemNode.current = e.target;
    dragItemNode.current.addEventListener('dragend', handleDragEnd);
    dragItem.current = item;

    setTimeout(() => {
      setDragging(true);
    }, 0);
  };

  const handleDragEnter = (e, targetItem) => {
    const newList = [...list];
    [newList[targetItem], newList[dragItem.current]] = [
      newList[dragItem.current],
      newList[targetItem],
    ];
    dragItem.current = targetItem;
    setList(newList);
  };
  const handleDragEnd = (e) => {
    dragItemNode.current.removeEventListener('dragend', handleDragEnd);
    dragItemNode.current = null;
    dragItem.current = null;
    setDragging(false);
  };
  const getStyles = (item) => {
    if (dragItem.current === item) {
      return 'image-dnd-item image-dnd-current';
    }
    return 'image-dnd-item';
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const scrollToBottom = () => {
    dragContainer.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleImageClick = (index) => {
    // console.log(index);
    const newList = [...list];
    setList(newList.filter((item, i) => i !== index));
  };

  useEffect(scrollToBottom, [list.length]);

  return (
    <div className="d-flex preview-image-dnd-container" ref={dragContainer}>
      {list.map((file, index) => (
        <div
          draggable
          key={file.preview}
          onDragStart={(e) => handletDragStart(e, index)}
          onDragEnter={
            dragging
              ? (e) => {
                handleDragEnter(e, index);
              }
              : null
          }
          onDragOver={(e) => handleDragOver(e)}
          className={dragging ? getStyles(index) : 'image-dnd-item'}
        >
          <div className="image-dnd-image-container">
            <div className="image-wrapper">
              <img className="image-dnd-image" src={file.preview} />
              {file.from !== 'backend' &&
                <div
                  className="edit-button"
                  onClick={() => handleImageEditClick(index)}
                >
                  <span draggable="false">🖊️</span>
                </div>
              }
              <div
                className="cancel-button"
                onClick={() => handleImageClick(index)}
              >
                <span draggable="false">&#10006;</span>
              </div>
            </div>
          </div>
        </div>
      ))}
      <UploadArea className="preview" showText={false} open={open} />
    </div>
  );
}

export default ImageDragDrop;
