import React from 'react';
import Payment from './PaymentSubscription';
import { getStripeApiKey } from '../../../utils/config.utils';
import { useAuth } from '../../auth/Auth';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import NewPaymentPlan from '../dashboard/NewPaymentPlan';

const stripePromise = loadStripe(getStripeApiKey());

const PaymentWrapper = ({ location }) => {
  const { guardian } = useAuth();

  // const dependent = guardian.dependents.filter(
  //   (dependent) => dependent.id === location.state.id
  // );
  const props = { type: location?.state?.type, dependent: [JSON.parse(location?.state?.dependent)] };

  return (
    <Elements stripe={stripePromise}>
      {/* <Payment {...props} /> */}
      <NewPaymentPlan {...props} />
    </Elements>
  );
};

export default PaymentWrapper;
