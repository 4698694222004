import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Button,
  Form,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-bootstrap';
import { useParams } from 'react-router';
import { useAuth } from '../../auth/Auth';
import { usePayment } from './Payment';
import { Container } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import FormInput from '../../common/FormInput';
import Select from 'react-select';
import { stripeViewModel } from '../payment/stripe.view.model';
import { specialCampViewModel } from './special.camp.viewmodel';
import LoadButton from '../../common/LoadButton';
import DashboardHeader from '../../header/DashboardHeader';
import SettingsNavHeader from '../dashboard/SettingsNavHeader';
import ConfirmAttendance from './ConfirmAttendance';
import { priceWithDollerSign } from '../../../utils/utils';
import { ERROR_REQUIRE_MSG } from '../../../constants/error.constants';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';

import './scss/purchase-special-camp.scss';
import ChallengeModal from '../../common/ChallengeModal';
import { GUARDIAN_SETTINGS_ROUTE } from '../../../constants/routes.constants';
import { useHistory } from 'react-router-dom';
import ApplePayButton from '../payment/ApplePayButton';

const PurchaseSpecialCamp = (params) => {
  // const { purchased } = useParams();
  const { guardian, refreshUserState, getGuardianDependents } = useAuth();
  const { location } = params;
  const purchased = location.state.purchased;
  const {
    stripe,
    elements,
    dependent,
    price,
    specialCamp,
    totalAmount,
    defaultPromotionInfo,
    promotionInfo,
    promotionSummaryShow,
    setPromotionSummaryShow,
    isLoading,
    setIsLoading,
    setDependent,
    getPrices,
    setTotalAmount,
    setSpecialCamp,
    setPromotionInfo,
    setPaymentMethodInfo,
    createPaymentMethod,
    handlePurchase,
  } = usePayment();
  const [targetDependent, setTargetDependent] = useState(
    location.state.dependent[0]
  );
  // location.state.dependent[0]
  // getGuardianDependents([location.state.dependent[0].id])[0]
  const [newPaymentMethod, setNewPaymentMethod] = useState(false);
  const [paymentOptions, setPaymentOptions] = useState([
    { value: 'new', label: 'New Payment Method' },
  ]);
  const [selectedOption, setSelectedOption] = useState();
  const [unitAmount, setUnitAmount] = useState(0);
  const { register, handleSubmit } = useForm();
  const promotionCodeInput = useRef(null);
  const [disablePromotion, setDisablePromotion] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [promoErrorMessage, setPromoErrorMessage] = useState('');
  const [attend, setAttend] = useState('PENDING');
  const [isModalShow, setIsModalShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmText, setConfirmText] = useState('');
  const [chooseType, setChooseType] = useState('');
  const history = useHistory();
  const options = {
    style: {
      base: {
        fontSize: '1.4rem',
        color: '#424770',
        letterSpacing: '0.025em',
        height: '51px',
        marginTop: '7px',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

  const onPaymentMethodChange = (selection) => {
    if (selection.value == 'new') {
      setNewPaymentMethod(true);
    } else {
      setNewPaymentMethod(false);
    }
    setSelectedOption(selection);
  };

  const updatePaymentMethod = (stripePayments) => {
    const newPaymentOptions = [...stripePayments, ...paymentOptions];
    setPaymentOptions(newPaymentOptions);
    onPaymentMethodChange(newPaymentOptions[0]);
  };

  useEffect(() => {
    // console.log(location.state.dependent[0]);
    // setDependent(getGuardianDependents([location.state.dependent[0].id]));
    setDependent(location.state.dependent[0]);
    setTargetDependent(location.state.dependent[0]);
    getPrices();
    setSpecialCamp(location.state.specialCamp);
    stripeViewModel.getCards().then((res) => {
      updatePaymentMethod(res);
    });
  }, []);

  useEffect(() => {
    // console.log('changed');
  }, [isModalShow]);

  useEffect(() => {
    const amount = price.length !== 0 ? price[price.findIndex(obj => obj.nickname === "Special Camp")].unitAmount : '';
    setUnitAmount(amount);
    setTotalAmount(price.length !== 0 ? price[price.findIndex(obj => obj.nickname === "Special Camp")].amount : 0);
  }, [price]);

  const handleContinue = async () => {
    // e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    if (selectedOption.value == 'new') {
      createPaymentMethod();
    } else {
      setPaymentMethodInfo({ id: selectedOption.value });
      handlePurchase({ id: selectedOption.value });
    }
    setIsLoading(false);
  };

  const newPaymentMethodForm = () => {
    return (
      <div className="form-wrapper">
        <div className="item name-on-card">
          <FormInput
            name="name_on_card"
            className="form-input"
            ref={register({
              required: ERROR_REQUIRE_MSG,
            })}
            placeholder="Name on Card"
            defaultValue={`${guardian.first_name} ${guardian.last_name}`}
            onChange={(e) => {
              setErrorMessage('');
              setCardHolderName(e.target.value);
            }}
            data-testid="name_on_card"
          />
        </div>
        <div className="item card-number">
          <CardNumberElement
            options={options}
            className="form-input form-control"
            placeholder="Card Number"
            showIcon={true}
            onChange={(e) => {
              setErrorMessage('');
            }}
          />
        </div>
        <div className="extra-card-info-wrapper">
          <div className="extra-card-info">
            <div className="item expiry-date">
              <CardExpiryElement
                options={options}
                className="form-input form-control"
                placeholder="EXP"
                onChange={(e) => {
                  setErrorMessage('');
                }}
              />
            </div>
          </div>
          <div className="extra-card-info">
            <div className="item cvc">
              <CardCvcElement
                options={options}
                className="form-input form-control"
                placeholder="CVC"
                onChange={(e) => {
                  setErrorMessage('');
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 48,
      color: '#321b6d',
      fontWeight: 'bold',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#321b6d',
    }),
  };

  const handlePromotionCode = () => {
    if (promotionInfo.code === '') {
      promotionCodeInput.current.focus();
      return;
    }
    stripeViewModel
      .paymentIntentsApplyPromoCode({
        total_amount: price[0].amount,
        dependent_id: targetDependent.id,
        promo_code: promotionInfo.code,
      })
      .then((res) => {
        setPromotionInfo((promotionInfo) => ({
          ...res,
          code: promotionInfo.code,
        }));
        setTotalAmount(res.remaining_amount);
        setPromotionSummaryShow('show');
        setDisablePromotion(true);
      })
      .catch((err) => {
        console.log(err);
        // setShowErrorMessage('show')
        const msg = err.serverResponse.error_info;
        if (err.statusCode === 404) setPromoErrorMessage(msg);
        else if (
          err.statusCode === 400 &&
          err.serverResponse.result_code === 405005
        )
          setPromoErrorMessage(msg);
        else setPromoErrorMessage('');
      });
  };

  const handleRemovePromotionCode = () => {
    setPromotionInfo(defaultPromotionInfo);
    resetTotalAmount();
    setPromotionSummaryShow('hide');
    setDisablePromotion(false);
  };

  const resetTotalAmount = () => {
    setTotalAmount(price[0].amount);
  };

  const handleConfirm = (e, filter) => {
    if (filter.target.value === "YES") {
      setConfirmText('Please confirm to attend this special camp.')
      setChooseType('YES')
    } else {
      setConfirmText(`Are you sure you don't want to attend this special camp?`)
      setChooseType('NO')
    }
    // setAttend(filter.target.value);
    setShowConfirm(true)
  }

  const handleConfirmClose = () => {
    setShowConfirm(false)
  }

  const handleAttend = () => {
    // console.log(filter.target.value);
    setAttend(chooseType)
    const dependent_id = location.state.dependent[0].id;
    const special_camp_id = location.state.specialCamp.id;
    const params = {
      attend: chooseType === 'YES',
      dependent_id,
      special_camp_id,
    };
    specialCampViewModel
      .attend(params)
      .then((res) => {
        // console.log(res);
        setShowConfirm(false)
        setIsModalShow(res.response === 'YES');
        setAttend();
        setTargetDependent({
          ...targetDependent,
          special_camp: {
            ...targetDependent.special_camp,
            attend: chooseType,
          },
        });
        if (res.response === 'NO') {
          refreshUserState();
          history.push({
            pathname: GUARDIAN_SETTINGS_ROUTE,
          });
        }
      })
      .catch((err) => {
        setErrorMessage('');
      });
  };

  return (
    <div className="purchase-special-camp-wrapper">
      <DashboardHeader />
      <Row className={`upgrade ${purchased}`}>
        Upgrade to Seedlings or Villagers to access any Special Camps FREE!
      </Row>
      <Container className="setting-container">
        <SettingsNavHeader showLink={true} title="TaleTree Special Camp" />
        <Row className="special-camp-wrapper">
          <div className="special-camp-box">
            <div className="image">
              <img src={specialCamp.picture_url} />
            </div>
            <div className="contents">
              <h1 className="mt-4 mb-2">{specialCamp.name}</h1>
              <h2 className="mt-2 mb-4">{specialCamp.subtitle}</h2>
              <div className="mb-4 description">{specialCamp.description}</div>
            </div>
          </div>
        </Row>
        <Row className={`mt-5 summary-wrapper ${purchased}`}>
          <h2>Order summary</h2>
          <Row className="mt-4 order-details">
            <div className="label">{specialCamp.name}</div>
            <div className="amount">{unitAmount}</div>
          </Row>
          <Row className={`promotion-item ${promotionSummaryShow}`}>
            <Row className="item-row">
              <div className="item-name">{promotionInfo.code}</div>
              <div className="item-amount">
                {/* {priceWithDollerSign(promotionInfo.discount_amount, '-')} */}
                {promotionInfo.discount_percentage}% off
              </div>
            </Row>
            <Row className="remove-row">
              {/* <div className="product-desc">
                {promotionInfo.coupon.metadata.description}
              </div> */}
              <div className="remove" onClick={handleRemovePromotionCode}>
                Remove
              </div>
            </Row>
          </Row>
          <Row className="order-details pay-now">
            <div className="label">Pay Now</div>
            <div className="amount">{priceWithDollerSign(totalAmount)}</div>
          </Row>
        </Row>
        <div className='pay-button-wrapper'>
          <ApplePayButton
            label={specialCamp?.name}
            payAmount={totalAmount}
            handleSubscribe={handlePurchase}
          />
        </div>
        <Row className={`mt-5 method-wrapper ${purchased}`}>
          <Form onSubmit={handleSubmit(handleContinue)}>
            <div className="payment-method">&nbsp;</div>
            <div
              className={`${paymentOptions.length === 1 ? 'hide' : 'payment-method-card'
                }`}
            >
              <Select
                styles={customStyles}
                onChange={onPaymentMethodChange}
                options={paymentOptions}
                value={selectedOption}
              />
            </div>
            {newPaymentMethod && newPaymentMethodForm()}
            <div className="item promotion-wrapper">
              <div className="code">
                <FormInput
                  name="promotion_code"
                  ref={promotionCodeInput}
                  placeholder="Promo code"
                  data-testid="promotion_code"
                  onChange={(e) => {
                    setPromoErrorMessage('');
                    setPromotionInfo({
                      ...promotionInfo,
                      code: e.target.value,
                    });
                  }}
                  value={promotionInfo.code}
                  disabled={disablePromotion}
                />
              </div>
              <div className="apply">
                <Button
                  onClick={handlePromotionCode}
                  disabled={disablePromotion}
                >
                  Apply
                </Button>
              </div>
            </div>
            <div className="promo-error-message">{promoErrorMessage}</div>
            <div className="mt-4 special-camp-description">
              <div className="mb-3 main">
                You can cancel 24 hours before the camp starts.
              </div>
              <div className="sub">
                <p>
                  In the interest of the safety and security of our TaleTree
                  children, we insist that all parents save their card details
                  and billing information on file, as a form of identification.
                </p>
                <p>
                  This verifies you as the parent of your child and ensures we
                  are fully aware of each and everyone of your members. Thank
                  you for your support.
                </p>
              </div>
            </div>
            <div className={`error-message ${purchased}`}>{errorMessage}</div>
            <div className="confirm button-wrapper">
              <LoadButton
                isLoading={isLoading}
                className={`submit-btn gred-btn`}
                type="submit"
                isDisabled={isLoading || !stripe}
                text="Purchase Now"
              />
            </div>
          </Form>
        </Row>
        <Row className={`mt-4 purchased-message-wrapper ${purchased}`}>
          <div className="main">
            You purchased the special camp on{' '}
            {location.state.dependent[0].special_camp.purchase_date}.
          </div>
          <div className="sub">
            <p>
              A confirmation e-mail has been sent to{' '}
              <a href={`mailto:${guardian.email}`}>{guardian.email}</a>. Prior
              to the event, you will receive a calendar invite with a Zoom link
              so please accept the invite for a calendar reminder. Also, please
              encourage your child to work on the pre-event challenge. You can
              find it in the app under "Challenges".
            </p>
            <p>
              Reach us at{' '}
              <a href={`mailto:contact@taletree.app`}>contact@taletree.app</a>{' '}
              for any questions.
            </p>
          </div>
        </Row>
        <Row className={`mt-5 attendance-wrapper ${purchased}`}>
          <div className="main">Attend Special Camp?</div>
          <div className="mt-3 attendance-button-wrapper">
            <ToggleButtonGroup
              type="radio"
              defaultValue={targetDependent.special_camp.attend}
              name="attendance"
              onChange={handleConfirm}
            >
              <ToggleButton value={'YES'} className="togglebutton yes">
                Yes
              </ToggleButton>
              <ToggleButton value={'NO'} className="togglebutton no">
                No
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className={`error-message ${purchased}`}>{errorMessage}</div>
        </Row>
      </Container>
      {isModalShow ? <ConfirmAttendance /> : ''}
      <ChallengeModal
        handleClose={handleConfirmClose}
        handleAction={handleAttend}
        show={showConfirm}
        type="confirm"
        level="warning"
        button1Label="Confirm"
        button2Label="Cancel"
      >
        <div>
          <span>{confirmText}</span>
        </div>
      </ChallengeModal>
      <br />
      <br />
    </div>
  );
};

export default PurchaseSpecialCamp;
