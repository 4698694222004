import { groupRepository } from '../../../repository/group.repository';
import moment from 'moment-timezone';
import { capitalizeFirstLetter, getMyLocalTime } from '../../../utils/camp.utils';
function getGroupsSortedByDayAndTime(groups) {
  const dayMap = new Map([
    ['sunday', []],
    ['monday', []],
    ['tuesday', []],
    ['wednesday', []],
    ['thursday', []],
    ['friday', []],
    ['saturday', []],
  ]);
  const date = '2000-10-10'; //placeholder to normalize time
  const sortedGroups = groups.sort(
    (a, b) =>
      moment.tz(`${date} ${a.time}`, a.timezone) -
      moment.tz(`${date} ${b.time}`, b.timezone)
  );
  sortedGroups.forEach((group) => {
    const newGroup = {...group};
    const newTime = getMyLocalTime(group.time, group.timezone);
    newGroup.localTime = newTime;
    newGroup.ageGroup = group.age_group === 'A' ? '8 - 12' : '5 - 7';
    dayMap.get(group.day).push(newGroup)
  });
  
  return [...dayMap]
    .filter(([day, groupList]) => groupList.length !== 0)
    .map(([day, groupList]) => {
      const formatDay = capitalizeFirstLetter(day);
      return { day: formatDay, groupList };
    });
}
function getGroups(params) {
  return new Promise((resolve, reject) => {
    groupRepository
      .getGroups(params)
      .then((response) => {
        const sortGroups = getGroupsSortedByDayAndTime(response.data);
        resolve(sortGroups);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const campScheduleViewModel = {
  getGroups,
};
