import React from 'react';
import { Image, Button } from 'react-bootstrap';
import { useAuth } from '../../auth/Auth';
import zoomIcon from '../../../assets/icon/icon-zoom.svg';
import { isCampMember } from '../../../utils/camp.utils';
// import './css/camp.schedule.scss';

function ZoomButtonSpecialCamp({ isCommonRoom, title, link }) {
  const { dependent } = useAuth();
  // const campStatus = dependent?.special_camp?.special_camp_id?  profile.camp_status : "";
  const isActive = link ? false : true;
  const disableClass = 'camp-schedule-disabled';
  return (
    <>
      <a
        className={`camp-schedule-zoom-anchor`}
        href={link}
        target="_blank"
        style={{width: isCommonRoom ? '100%' : ''}}
      >
        {!isCommonRoom ?
          <Button
            block
            // disabled={!isActive}
            variant="success"
            className="camp-schedule-join px-3 py-3 gred-btn"
          >
            <Image className="pr-3" src={zoomIcon} />
            {title}
          </Button>
          :
          <div className='width-box'>
            <span className={`${isCommonRoom && `counter`} join-camp`}>
              <span>
                <img src={zoomIcon} alt='' />
              </span>
              {title}
            </span>
          </div>
        }
      </a>
    </>
  );
}

export default ZoomButtonSpecialCamp;
