import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CHALLENGE_ROUTE, NEXT_CHALLENGE_ROUTE, SPECIAL_CHALLENGE_ROUTE } from '../../constants/routes.constants';
import { TiArrowRightThick } from '@react-icons/all-files/ti/TiArrowRightThick';
import './scss/challenge.link.scss';
import { useAuth } from '../auth/Auth';
import GetVerifiedNowModal from '../views/next_challenge/getVerifiedNowModal';
import OptimizeSignup from '../views/onboarding/signup_popups/OptimizeSignup';

const ChallengeLink = ({ id, title, from, isSpecial = false }) => {
  const history = useHistory();
  const {dependent, guardian, school} =  useAuth();
  const [showModal, setShowModal] = useState(false);
  const [showGetVerifiedModal, setShowGetVerifiedModal] = useState(false);
  const handleClick = (e) => {
    if (!dependent && !guardian && !school) {
      setShowModal(true);
      localStorage.setItem('kids_login_location', window.location.href)
    } else {
      if(!dependent.profile.is_activated) {
        return setShowGetVerifiedModal(true)
      }
      let path = CHALLENGE_ROUTE;
      let encodeQuery = btoa(`{"id":${e.target.id}, "slug":"challenge"}`)
      if (isSpecial) {
        path = SPECIAL_CHALLENGE_ROUTE
        encodeQuery = btoa(`{"id":${e.target.id}, "slug":"special-challenge"}`)
      }
      history.push({
        pathname: path,
        search: `?encrypt=${encodeQuery}`,
        state: {
          filter: '',
          selectedTag: '',
          from: from,
        },
      });
    }
  };
  // useEffect(() => {
  //   console.log(id !== '');
  // }, []);
  return (
    <>
      {id !== undefined
        ? id !== '' && (
            <div className="challenge-link-container">
              <div className="line"> </div>
              <div className="link-wrapper" id={id} onClick={handleClick}>
                <div className="title" id={id}>
                  {title}
                </div>
                <div className="icon" id={id}>
                  <TiArrowRightThick id={id} />
                </div>
              </div>
              {showGetVerifiedModal ? <GetVerifiedNowModal show={showGetVerifiedModal} setShow={setShowGetVerifiedModal} /> : ''}
              {/* <GuestRedirectVerification showModal={showModal} setShowModal={setShowModal} /> */}
              <OptimizeSignup showModal={showModal} setShowModal={setShowModal} />
            </div>
          )
        : ''}
    </>
  );
};

export default ChallengeLink;
