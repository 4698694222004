import React, { useState } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import ZoomModal from './ZoomModal';
import { useAuth } from '../../auth/Auth';
import {
  capitalizeFirstLetter,
  getCampStatus,
  getMyLocalTime,
  isCampMember,
} from '../../../utils/camp.utils';
import ZoomButton from './ZoomButton';

import './css/camp.schedule.scss';

const MySchedule = ({ creator }) => {
  const { dependent } = useAuth();
  const [showZoomModal, setShowZoomModal] = useState(false);
  const handleCloseZoomModal = () => setShowZoomModal(false);
  const handleShowZoomModal = () => setShowZoomModal(true);
  const group = creator ? creator.profile.group : dependent ? dependent.profile.group : "";
  const campStatus = creator ? creator.profile.camp_status : dependent ? dependent.profile.camp_status : "";
  const isActive = isCampMember(campStatus);
  const zoomClassName = isActive
    ? 'camp-schedule-zoom-details'
    : 'camp-schedule-zoom-details-disabled';
  const getSchedule = () => {
    if (isActive) {
      const convertedTime = getMyLocalTime(
        group.day,
        group.time,
        group.timezone
      );
      return `${convertedTime}`;
    }
    return getCampStatus(campStatus) || '';
  };
  return (
    <Row className="camp-schedule-container justify-content-center text-center">
      <div className="camp-schedule-title p-3">Camp Session</div>
      {/* <div className="camp-schedule-time p-2">{getSchedule()}</div> */}
      <div className="join-button px-3">
        <ZoomButton title="Join Camp" creator={creator} />
      </div>
      <Row
        className={`p-3 justify-content-center ${zoomClassName}`}
        onClick={handleShowZoomModal}
      >
        <Col className="col-auto">
          <div className="camp-schedule-details">
            Check Meeting Id & Password &gt;
          </div>
        </Col>
      </Row>
      <ZoomModal showModal={showZoomModal} handleClose={handleCloseZoomModal} />
    </Row>
  );
};

export default MySchedule;
