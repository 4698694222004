import React from 'react';
import { default as BsModal } from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import RoundButton from './RoundButton';
import closeIcon from '../../assets/icon/icon-darker-close.svg';

import './scss/challenge.modal.scss';

const ChallengeModal = (props) => {
  const modalSize = 'size-' + props.size;
  return (
    <div>
      <BsModal
        show={props.show}
        onHide={props.handleClose}
        backdropClassName="backdrop"
        dialogClassName={`challenge-modal ${props.level}`}
      >
        <ModalBody
          bsPrefix="challenge-modal-body"
          className={`${modalSize} ${props.className}`}
        >
          <div className="challenge-modal-header">
            <div className="close-button">
              <img src={closeIcon} alt="Close" onClick={props.handleClose} />
            </div>
          </div>
          <div className="challenge-modal-content">{props.children}</div>
          {props.type === 'confirm' ? (
            <div className="button-wrapper">
              <RoundButton
                className={`modal-button yes`}
                type="button"
                label={props.button1Label}
                onClick={props.handleAction}
              />
              <RoundButton
                className={`modal-button no gred-btn`}
                type="button"
                label={props.button2Label}
                onClick={props.handleClose}
              />
            </div>
          ) : (
            <div className="button-wrapper">
              <RoundButton
                className={`modal-button modal-close gred-btn`}
                type="button"
                label={props.button1Label}
                onClick={props.handleClose}
              />
            </div>
          )}
        </ModalBody>
      </BsModal>
    </div>
  );
};

ChallengeModal.defaultProps = {
  handleClose: () => {},
  handleAction: () => {},
  show: false,
  title: '',
  size: 'large',
  type: 'alert',
  button1Label: 'Yes',
  button2Label: 'No',
};

export default ChallengeModal;
