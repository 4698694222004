///
/// deprecated
///
import React, { useState, useEffect } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useForm, useFieldArray } from 'react-hook-form';
import { kidSignUpErrors } from '../../../constants/error.constants';
import { containsNumberOrLetter, isKid } from '../../../utils/form.utils';
import { priceWithDollerSign } from '../../../utils/utils';
import FormLabel from '../FormLabel';
import FormInput from '../FormInput';
import ErrorLabel from '../ErrorLabel';
import FormPasswordInput from '../../common/FormPasswordInput';
import { useAuth } from '../../auth/Auth';
import { accountViewModel } from '../../views/account/account.view.model';
import { displayNestedFormErrorsFromApi } from '../../api/api.display.error';
import EditAvatar from '../EditAvatar';
import LoadButton from '../LoadButton';
import { stripeViewModel } from '../../views/payment/stripe.view.model';
import iconCamp from '../../../assets/icon/icon-camp.svg';
import './css/create.child.form.scss';
import '../scss/form.input.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
function CreateChildrenForm({ onChildCreate }) {
  const [profilePics, setProfilePics] = useState({});
  const { fetchAPI } = useAuth();
  const data = {
    dependents: [{ profile_pic: '' }],
  };
  const {
    control,
    register,
    handleSubmit,
    setError,
    watch,
    errors,
    setValue,
  } = useForm({
    mode: 'onBlur',
    defaultValues: data,
  });
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'dependents',
  });
  const [startDate, setStartDate] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [price, setPrice] = useState(undefined);
  const handleDayChange = (value, index) => {
    const newDates = [...startDate];
    newDates[index] = value;
    setStartDate(newDates);

    const newDate = moment(value).format().split('T')[0];
    setValue(`dependents[${index}].profile.birthdate`, newDate, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };
  const onSubmit = (formData) => {
    setIsLoading(true);
    fetchAPI(accountViewModel.createChild, formData, profilePics)
      .then((dependents) => {
        onChildCreate(dependents);
      })
      .catch((error) => {
        console.log(error);
        //Create child requires two api calls. The first maybe successful to create the account
        //but not to upload profile picture.
        if (error.profileUploadFail) {
          onChildCreate(error.dependents);
        } else {
          displayNestedFormErrorsFromApi(error, setError, formData);
        }
        setIsLoading(false);
      });
  };
  const handleProfileImageSelection = (index, image) => {
    const newProfilePics = { ...profilePics };
    newProfilePics[index] = image;
    setProfilePics(newProfilePics);
  };

  const removeChild = (index) => {
    remove(index);
    let newProfilePics = {};
    Object.keys(profilePics).forEach((key) => {
      const strIndex = `${index}`;
      if (key > strIndex) {
        newProfilePics[key - 1] = profilePics[key];
      } else if (key !== strIndex) {
        newProfilePics[key] = profilePics[key];
      }
    });
    setProfilePics(newProfilePics);
  };

  const appendKid = (index) => {
    append({ name: 'dependents' });
  };

  const getPrice = (productId = 'camp') => {
    fetchAPI(stripeViewModel.getPrices, productId)
      .then((res) => setPrice(res))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getPrice();
  }, []);

  return (
    <div
      data-testid="create_child_form"
      className="create-child-form-container"
    >
      <Form onSubmit={handleSubmit(onSubmit)} className="form-container">
        {fields.map((item, index) => (
          <div key={item.id} className={`item-container item-${index}`}>
            <div className="profile-image-container">
              <EditAvatar
                index={index}
                ref={register()}
                onProfileImageChange={handleProfileImageSelection}
                name={`dependents[${index}].profile_pic`}
                errors={errors}
                setError={setError}
              />
            </div>
            <Form.Group className="form-header">Basic</Form.Group>
            <Form.Group controlId={`dependents[${index}].first_name`}>
              <FormLabel>First Name</FormLabel>
              <FormInput
                name={`dependents[${index}].first_name`}
                ref={register({
                  required: kidSignUpErrors.first_name.required,
                })}
                placeholder="First Name"
                data-testid={`dependents[${index}].first_name`}
                autoFocus
              />
              <ErrorLabel
                errors={errors}
                name={`dependents[${index}].first_name`}
                as="label"
              />
            </Form.Group>
            <Form.Group controlId={`dependents[${index}].last_name`}>
              <FormLabel>Last Name</FormLabel>
              <FormInput
                name={`dependents[${index}].last_name`}
                ref={register({
                  required: kidSignUpErrors.last_name.required,
                })}
                placeholder="Last Name"
                data-testid={`dependents[${index}].last_name`}
              />
              <ErrorLabel
                errors={errors}
                name={`dependents[${index}].last_name`}
                as="label"
              />
            </Form.Group>
            <Form.Group controlId={`dependents[${index}].profile.birthdate`}>
              <FormLabel>Birthdate</FormLabel>
              <FormInput
                name={`dependents[${index}].profile.birthdate`}
                ref={register({
                  required: kidSignUpErrors.birthdate.required,
                  validate: (input) =>
                    isKid(input) || kidSignUpErrors.birthdate.age,
                })}
                type="hidden"
                data-testid={`dependents[${index}].profile.birthdate`}
              />
              <div className="date">
                <DatePicker
                  onChange={(value) => handleDayChange(value, index)}
                  dateFormat="MM/dd/yyyy"
                  selected={startDate[index]}
                  placeholderText="MM/DD/YYYY"
                  showYearDropdown
                  dropdownMode="select"
                  maxDate={new Date()}
                />
              </div>
              <ErrorLabel
                errors={errors}
                name={`dependents[${index}].profile.birthdate`}
                as="label"
              />
            </Form.Group>
            <Form.Group controlId={`dependents[${index}].profile.gender`}>
              <FormLabel>Gender</FormLabel>
              <FormInput
                as="select"
                name={`dependents[${index}].profile.gender`}
                ref={register()}
                data-testid={`dependents[${index}].profile.gender`}
              >
                <option data-testid="gender-option" key="male">
                  male
                </option>
                <option data-testid="gender-option" key="female">
                  female
                </option>
              </FormInput>
            </Form.Group>
            <Form.Group className="form-header my-4">Account</Form.Group>
            <Form.Group controlId={`dependents[${index}].username`}>
              <FormLabel>
                Kid's TaleTree ID
                <br />
                <span className="input-header-description">
                  No real names or offensive words
                </span>
              </FormLabel>
              <FormInput
                name={`dependents[${index}].username`}
                maxLength="15"
                ref={register({
                  required: kidSignUpErrors.username.required,
                  minLength: {
                    value: 3,
                    message: kidSignUpErrors.username.minLength,
                  },
                  maxLength: {
                    value: 15,
                    message: kidSignUpErrors.username.maxLength,
                  },

                  validate: {
                    letterOrNumber: (value) =>
                      containsNumberOrLetter(value) ||
                      kidSignUpErrors.username.charType,
                    notFirstName: (value) =>
                      value !== watch(`dependents[${index}].first_name`) ||
                      kidSignUpErrors.username.sameName,
                    notLastName: (value) =>
                      value !== watch(`dependents[${index}].last_name`) ||
                      kidSignUpErrors.username.sameName,
                  },
                })}
                placeholder="Create a Unique Username"
                data-testid={`dependents[${index}].username`}
              />
              <Col className="m-0 p-0">
                <ErrorLabel
                  errors={errors}
                  name={`dependents[${index}].username`}
                  as="label"
                />
              </Col>
            </Form.Group>
            <Form.Group controlId={`dependents[${index}].password`}>
              <FormLabel>Password</FormLabel>
              <FormPasswordInput
                name={`dependents[${index}].password`}
                ref={register({
                  required: kidSignUpErrors.password.required,
                  minLength: {
                    value: 5,
                    message: kidSignUpErrors.password.minLength,
                  },
                })}
                placeholder="Create a Password"
                data-testid={`dependents[${index}].password`}
                autoComplete="new-password"
              />
              <ErrorLabel
                errors={errors}
                name={`dependents[${index}].password`}
                as="label"
              />
            </Form.Group>
            <Form.Group controlId={`dependents[${index}].confirm_password`}>
              <FormLabel>Confirm Password</FormLabel>
              <FormPasswordInput
                name={`dependents[${index}].confirm_password`}
                ref={register({
                  validate: (value) =>
                    value === watch(`dependents[${index}].password`) ||
                    kidSignUpErrors.password.mismatch,
                })}
                placeholder="Confirm Password"
                data-testid={`dependents[${index}].confirm_password`}
              />
              <ErrorLabel
                errors={errors}
                name={`dependents[${index}].confirm_password`}
                as="label"
              />
            </Form.Group>
            <Form.Group className="form-header mt-4">
              <img src={iconCamp} className="icon-image" />
              TaleTree Creative Camp
            </Form.Group>
            <Form.Group controlId={`dependents[${index}].signup`}>
              <div className="form-description">
                A weekly camp to facilitate “show &#38; tell” interactions among
                kids of close age.
              </div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    ref={register({ required: kidSignUpErrors.camp.required })}
                    name={`dependents[${index}].signup`}
                    value="camp"
                    id={`camp-${index}`}
                    data-testid={`dependents[${index}].signup`}
                  />
                  <label htmlFor={`camp-${index}`}>
                    &nbsp;Yes. I want to try a <strong>FREE</strong> session
                    <br />
                    &nbsp;
                    <span className="description">
                      (First session FREE and{' '}
                      {priceWithDollerSign(price?.unit_amount || 4000)} / 4
                      weeks)
                    </span>
                  </label>
                </label>
              </div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    ref={register({ required: kidSignUpErrors.camp.required })}
                    name={`dependents[${index}].signup`}
                    value="app"
                    id={`app-${index}`}
                  />
                  <label htmlFor={`app-${index}`}>
                    &nbsp;No. I am not interested in Creative Camp
                  </label>
                </label>
              </div>
              <ErrorLabel
                errors={errors}
                name={`dependents[${index}].signup`}
                as="label"
              />
            </Form.Group>
            {index !== 0 && (
              <Form.Group>
                <div className="remove-kid" onClick={() => removeChild(index)}>
                  &#9587;&nbsp;&nbsp;Remove kid
                </div>
              </Form.Group>
            )}
          </div>
        ))}
        <Form.Group>
          <div
            className="add-child"
            onClick={() => append({ name: 'dependents' })}
          >
            <span className="plus-sign">+</span> Add another kid
          </div>
        </Form.Group>
        <Form.Group>
          <LoadButton
            className="submit-btn"
            text="Create Account"
            isDisabled={isLoading}
            isLoading={isLoading}
            testid="submit"
          />
        </Form.Group>
      </Form>
    </div>
  );
}

export default CreateChildrenForm;
