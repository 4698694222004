import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Button, Row, Col } from 'react-bootstrap';
import { useAuth } from '../../auth/Auth';
import ConfirmMessage from '../../common/ConfirmMessage';
import SettingsTitle from './SettingsTitle';
import { stripeViewModel } from '../payment/stripe.view.model';
import { priceWithDollerSign } from '../../../utils/utils';
import RoundButton from '../../common/RoundButton';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faStripe } from '@fortawesome/free-brands-svg-icons'
// import { library } from '@fortawesome/fontawesome-svg-core'


import campIcon from '../../../assets/icon/icon-camp.svg';
import './css/payment.plan.scss';

function PaymentPlan() {
  const history = useHistory();
  const { fetchAPI, getGuardianDependents } = useAuth();
  const [showCancelConfirm, setShowCancelConfirm] = useState('hide');
  const [cancelMessage, setCancelMessage] = useState('');
  // const [subscriptions, setSubscriptions] = useState([]);
  const [price, setPrice] = useState(undefined);
  const handleCloseCancelConfirm = () => setShowCancelConfirm('hide');
  // library.add( faStripe )

  useEffect(() => {
    // getPrice();
    // getSubscriptions();
  }, []);

  // const getSubscriptions = () => {
  //   fetchAPI(stripeViewModel.getSubscriptions)
  //     .then((res) => {
  //       const ids = res.map((sub) => {
  //         const { dependent_id } = sub;
  //         return dependent_id;
  //       });
  //       setSubscriptions(getGuardianDependents(ids));
  //     })
  //     .catch();
  // };

  const getPrice = (productId = 'camp') => {
    fetchAPI(stripeViewModel.getPrices, productId)
      .then((res) => setPrice(res))
      // .then(res => console.log(res))
      .catch((err) => console.log(err));
  };

  const handleCancelConfirm = () => {
    // call cancel subscription API
  };

  const openConfirmMessage = (name) => {
    const message = (
      <div>
        Are you sure you want to delete {name}'s subscription?
        <br />
        If you delete the subscription now, {name} will no longer be able to
        attend the Creative Camp.
      </div>
    );
    setCancelMessage(message);
    setShowCancelConfirm('show');
  };

  const handleCustomerPortal = () => {
    fetchAPI(stripeViewModel.getCustomerPortalSession)
      .then((res) => {
        const session = res.url;
        console.log(session);
        window.location.assign(session);
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <Container className="payment-plan-container">
        {/* <div className='connected-school mb-5'>
          <SettingsTitle title="Connected School" />
          <Row>
              <Col className="col-md-8 col-lg-6">
                <span className="link">
                  SF elementary
                </span>
                <div className="mt-3">
                    <RoundButton
                      type="button"
                      label="View public room"
                      className="mt-3 btn-public-room fw-600"
                      />
                  </div>
              </Col>
            </Row>
          </div> */}

        <SettingsTitle title="Payment" />
        {/* <Row className="justify-content-center">
          <Col className="col-md-8 col-lg-6">
            <Row className="align-items-center flex-column">
              {subscriptions.map((member, index) => (
                <div className="subscription-box" key={member.id}>
                  <hr className={(index > 0)?'sub-line':'sub-none-line'}/>
                  <div className="content-wrapper">
                    <img src={campIcon} className="camp-icon" />
                    <div className="sub-header">{member.first_name} {member.last_name}</div>
                    <div className="sub-price">
                      <div className="subscribed">Subscribed</div>
                      <div>
                        <strong>{priceWithDollerSign(price?.unit_amount||4000)}</strong> / 4 weeks 
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Row>
          </Col>
        </Row> */}
        <Row>
          <Col className="mb-5 col-md-8 col-lg-6 fw-normal">
            To view your payment history or change the credit card information,
            please go on to{' '}
            <span className="link" onClick={handleCustomerPortal}>
              Stripe payment settings
            </span>
            .
          </Col>
          {/* <div className="customer-portal" onClick={handleCustomerPortal}>
            <div className="stripe-image"></div>
            <div className="text">Manage Your Subscription & Payment</div>
          </div> */}
        </Row>
        <SettingsTitle title="Cancellation" />
        <Row>
          <Col className="col-md-8 col-lg-6">
            To cancel the subscription, please email&nbsp;<a className="link" href="mailto:contact@taletree.app">
            contact@taletree.app
            </a>
          </Col>
        </Row>
      </Container>
      <ConfirmMessage
        handleClose={handleCloseCancelConfirm}
        handleConfirm={handleCancelConfirm}
        show={showCancelConfirm}
        headerTitle="Cancel Subscription"
        message={cancelMessage}
        small={true}
        confirmLabel="Cancel"
      />
    </div>
  );
}

export default PaymentPlan;
