import { fetchUtils } from '../utils/fetch.utils';
import { getAPIUrl } from '../utils/config.utils';

const BASE_URL = getAPIUrl();
const TAGS = 'tags';

function getTags(params) {
  const queryString = fetchUtils.buildQuery(params);
  return fetchUtils.get(`${BASE_URL}/${TAGS}?${queryString}`);
}

function getBranch(params){
  let url = `${BASE_URL}/${TAGS}`+'/current-user-tags';
     return fetchUtils.get(url);
}

function addBranch(params){
  let url = `${BASE_URL}/${TAGS}`+'/add-dependent-tags';
     return fetchUtils.post(url, params);
}

export const tagRepository = {
  getTags,
  getBranch,
  addBranch
};
