import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Form, Image, Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../auth/Auth';
import ErrorLabel from '../../common/ErrorLabel';
import FormInput from '../../common/FormInput';
import FormLabel from '../../common/FormLabel';
import LoadButton from '../../common/LoadButton';
import { settingsViewModel } from '../dashboard/settings.view.model';
import { FAVORITES_CHAR_LIMIT } from '../../../constants/limit.constants';
import { displayErrorFavorites } from '../../api/api.display.error';
import OnboardingModal from '../onboarding/OnboardingModal';
import { ADD_MORE_BRANCHES } from '../../../constants/routes.constants';
import './css/favorites.scss';
import { changeTextToVoice, getSoundWaveJson, getTaletreeFriendJson, getUserNotificationChar } from '../../../utils/camp.utils';
import { BsArrowRight, BsCheckLg, BsVolumeUpFill, BsX } from 'react-icons/bs';
import Lottie from 'react-lottie-player';

const Favorites = (props) => {
  const history = useHistory();
  const { showFavoritesModal, taletreeFriend, handleFavoritesClose } = props;
  const [showColor, setShowColor] = useState(true);
  const [showSport, setShowSport] = useState(false);
  const [showAnimal, setShowAnimal] = useState(false);
  const { dependent, fetchAPI, refreshUserState, setUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [favouriteColor, setFavouriteColor] = useState(null);
  const [favouriteSport, setFavouriteSport] = useState(null);
  const [favouriteAnimal, setFavouriteAnimal] = useState(null);
  const [isPlay, setIsPlay] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const favorites = {
    color: dependent.profile.favorites[0],
    sport: dependent.profile.favorites[1],
    animal: dependent.profile.favorites[2],
  };
  const { register, handleSubmit, setError, errors, reset, watch } = useForm({
    mode: 'onChange',
    defualtValues: { favorites },
  });

  const userFriend = {
    profile: {
      taletree_friend: taletreeFriend
    }
  }

  const onSubmit = (formData) => {
    const favorites = {
      color: favouriteColor,
      sport: favouriteSport,
      animal: formData.favorites.animal
    }
    const params = {
      favorites,
      taletree_friend: taletreeFriend,
    };
    // console.log(params);
    setIsLoading(true);
    fetchAPI(
      settingsViewModel.updateTaletreeFriendAndFavorites,
      dependent.id,
      params
    )
      .then((res) => {
        reset(formData);
        setUser(res.data);
        // refreshUserState();
        handleFavoritesClose();
        // history.push({
        //   pathname: ADD_MORE_BRANCHES,
        //   state: {
        //     from: 'favorites',
        //     dependent: dependent,
        //     branches: []
        //   },
        // });
      })
      .catch((error) => {
        displayErrorFavorites(error, setError, formData);
      })
      .finally(() => setIsLoading(false));
  };

  const handleColorClick = () => {
    setShowColor(false);
    setShowSport(true);
    setShowAnimal(false);
  }

  const handleSportClick = () => {
    setShowColor(false);
    setShowSport(false);
    setShowAnimal(true);
  }

  const handleVoiceClick = () => {
    setIsPlay(!isPlay);
    const element = document.getElementById('voice-text')
    changeTextToVoice(element.textContent, isPlay, setIsPlay, isPaused, setIsPaused);
  }

  return (
    <Modal className="signupPopup gardian mobile-v-popup auth-popup right-curved-div" show={showFavoritesModal} centered>
      <Modal.Body >
        <div className='row bg-blue border-40'>
          <div className="col-md-6 col-lg-5 p-4 bg-blue border-left buble-relative overflow-hidden">
            <div className="voice-buble buuble-bounce" id="SpeechBubble" >
              <p id='voice-text' >Hey {dependent.first_name}! Thanks for choosing me! I'm excited.</p>
            </div>
            <div className="text-left">
              {/* <p className="text-white voice-btn" onClick={handleVoiceClick}><BsVolumeUpFill /> Voice on</p> */}
              <div className="" style={{marginTop: '9rem'}}>
                {/* <Lottie
                  loop
                  animationData={getSoundWaveJson()}
                  play={isPlay}
                  className='animate-svg'
                /> */}
              </div>
            </div>
            <div className="cartoon-img">
              <Lottie
                loop={false}
                animationData={getTaletreeFriendJson(taletreeFriend)}
                play
                className="w-100 cartoon-top"
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-7 bg-white border-right right-curve auth-section white-right-arrow toparrow right-bottom-curve">
            <p className="auth-entry-text">Before we start, I have some questions for you to help others get to know you in our community.</p>
            <div className="form-question">
              <Form onSubmit={handleSubmit(onSubmit)}>
                {showColor &&
                  <>
                    <Form.Group className="container-fluid">
                      <FormLabel>
                        What is your favorite color?
                      </FormLabel>
                      <FormInput
                        className={`input-modal ${favouriteColor && 'valid-input'}`}
                        name="favorites.color"
                        ref={register()}
                        // placeholder="Favourite Color"
                        maxLength={FAVORITES_CHAR_LIMIT}
                        data-testid="favorites.color"
                        onChange={e => setFavouriteColor(e.target.value)}
                        required
                      />
                      <ErrorLabel errors={errors} name="favorites.color" />
                      {watch('favorites.color') && <BsCheckLg className="input-valid-check" />}
                    </Form.Group>
                    <div className="text-center">
                      <Button className="gred-btn w-100 mt-3" onClick={handleColorClick} disabled={!watch('favorites.color')}>Submit <BsArrowRight /></Button>
                    </div>
                  </>
                }
                {showSport &&
                  <>
                    <Form.Group className="container-fluid">
                      <FormLabel>
                        What is your favorite sport?
                      </FormLabel>
                      <FormInput
                        className={`input-modal ${favouriteSport && 'valid-input'}`}
                        name="favorites.sport"
                        ref={register()}
                        // placeholder="Favourite Sport"
                        maxLength={FAVORITES_CHAR_LIMIT}
                        data-testid="favorites.sport"
                        onChange={e => setFavouriteSport(e.target.value)}
                        required
                      />
                      <ErrorLabel errors={errors} name="favorites.sport" />
                      {watch('favorites.sport') && <BsCheckLg className="input-valid-check" />}
                    </Form.Group>
                    <div className="text-center">
                      <Button className="gred-btn w-100 mt-3" onClick={handleSportClick} disabled={!watch('favorites.sport')}>Submit <BsArrowRight /></Button>
                    </div>
                  </>
                }
                {showAnimal &&
                  <>
                    <Form.Group className="container-fluid">
                      <FormLabel>
                        What is your favorite animal?
                      </FormLabel>
                      <FormInput
                        className={`input-modal ${favouriteAnimal && 'valid-input'}`}
                        name="favorites.animal"
                        ref={register()}
                        // placeholder="Favourite Animal"
                        maxLength={FAVORITES_CHAR_LIMIT}
                        data-testid="favorites.animal"
                        onChange={e => setFavouriteAnimal(e.target.value)}
                        required
                      />
                      <ErrorLabel errors={errors} name="favorites.animal" />
                      {watch('favorites.animal') && <BsCheckLg className="input-valid-check" />}
                    </Form.Group>
                  </>
                }
                {showAnimal &&
                  <div className="text-center">
                    <Button className="gred-btn w-100" type='submit' disabled={!watch('favorites.color') && !watch('favorites.sport') && !watch('favorites.animal')} >Enter the Emerald Forest <BsArrowRight /></Button>
                  </div>
                }
              </Form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Favorites;
