import { agoraRepository } from '../../repository/agora.repository';

const getAppToken = () => {
  return new Promise((resolve, reject) => {
    agoraRepository
      .getAppToken()
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        // console.log(err);
        reject(err);
      });
  });
};

const getUserToken = (uuid) => {
  return new Promise((resolve, reject) => {
    agoraRepository
      .getUserToken(uuid)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        // console.log(err);
        reject(err);
      });
  });
};

const registerUser = (params) => {
  return new Promise((resolve, reject) => {
    agoraRepository
      .registerUser(params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        // console.log(err);
        reject(err);
      });
  });
};

const getUser = (params) => {
  return new Promise((resolve, reject) => {
    agoraRepository
      .getUser(params)
      .then((res) => {
        // console.log(res)
        resolve(res);
      })
      .catch((err) => {
        // console.log(err);
        reject(err);
      });
  });
};

const loginUser = (params) => {
  return new Promise((resolve, reject) => {
    agoraRepository
      .loginUser(params)
      // .then((res) => {
      //   // console.log(res)
      //   resolve(res);
      // })
      // .catch((err) => {
      //   console.log(err);
      //   reject(err);
      // });
  });
};

const sendGroupMessage = (params) => {
  return new Promise((resolve, reject) => {
    agoraRepository
      .sendGroupMessage(params)
      .then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}

const addUserToGroup = (params) => {
  return new Promise((resolve, reject) => {
    agoraRepository
      .addUserToGroup(params)
      .then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}

const getGroupInfo = (params) => {
  return new Promise((resolve, reject) => {
    agoraRepository
      .getGroupInfo(params)
      .then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}

const createGroup = (token, data) => {
  return new Promise((resolve, reject) => {
    agoraRepository
      .createGroup(token, data)
      .then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}

const deleteGroup = (params) => {
  return new Promise((resolve, reject) => {
    agoraRepository
      .deleteGroup(params)
      .then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
  })
}

export const agoraViewModel = {
  getAppToken,
  getUserToken,
  registerUser,
  getUser,
  loginUser,
  sendGroupMessage,
  addUserToGroup,
  getGroupInfo,
  createGroup,
  deleteGroup
};
