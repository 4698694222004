import React from 'react'
import { Link } from 'react-router-dom'
import DashboardHeader from '../../header/DashboardHeader'
import { GUARDIAN_LOGIN_ROUTE } from '../../../constants/routes.constants'

function AddChildComplete() {
  // const campMessage =
  //   (
  //     <span>
  //       camp message
  //     </span>
  //   )
  const message =
    (
      <span>
        app message
      </span>
    )
  // const message = (location.state && location.state.isCamp)
  //   ? appMessage
  //   : campMessage

  return (
    <div >
      <DashboardHeader />
      <div className="complete-container">
        <div className="description-container">
          {message}
        </div>
        <div className="card-container">
          <Link to={GUARDIAN_LOGIN_ROUTE}>Back to the Guardian Dashboard</Link>
        </div>
      </div>
    </div>
  )
}

export default AddChildComplete
