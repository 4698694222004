import { fetchUtils } from '../utils/fetch.utils';
import { getAPIUrl } from '../utils/config.utils';

const BASE_URL = getAPIUrl();

function getSearchResult(params) {
  return fetchUtils.get(`${BASE_URL}/search?text=${params}`);
}

export const searchRepository = {
    getSearchResult,
};
