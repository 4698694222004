import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SettingsTitle from './SettingsTitle';
import './css/delete.account.scss';

function DeleteAccount({text}) {
  return (
    <div>
      <Container className="delete-account-container">
        <SettingsTitle title="Account Deletion" />
        <Row>
          <Col className="col-md-8 col-lg-6">
            {text ?
            text :
            "If you delete your account, all of your kids’ creations will be lost and cannot be recovered. If you still want to delete your account, please email to" }
            &nbsp;
            <a className="link" href="mailto:contact@taletree.app">
              contact@taletree.app
            </a>.
            <br />
            <br />
            &nbsp;
            <br />
            &nbsp;
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default DeleteAccount;
