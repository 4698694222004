import React, { useState, useEffect } from "react";
import { partnerViewModel } from "../school/partner.view.model";
import { Col, Row, Spinner } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroller";
import ChallengeList from "./ChallengesList";
import { useAuth } from "../../auth/Auth";
import { agoraViewModel } from "../../common/agora.view.model";
import ChallengeModal from "../../common/ChallengeModal";

const ChallengeBottomSection = ({ selectedExpert, appToken, getBadgeCount }) => {
  const { partner } = useAuth();
  const [challengeList, setChallengeList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [combineList, setCombineList] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteChallengeId, setDeleteChallengeId] = useState(null);
  const [deleteChallengeGroup, setDeleteChallengeGroup] = useState(null);
  const [isEvent, setIsEvent] = useState(false);
  const [upcomingQuery, setUpcomingQuery] = useState({
    limit: 9,
    has_more: true,
    is_expert: true
  });
  const [eventQuery, setEventQuery] = useState({
    limit: 9,
    has_more: true,
  });
  const [loading, setLoading] = useState(false);
  const [eventLoading, setEventLoading] = useState(false);
  const [tabFlag, setTabFlag] = useState("current");

  const handleChange = () => {
    const list = []
    const query = {
      limit: 9,
      has_more: true,
      is_expert: true
    }
    setCombineList(list);
    setChallengeList(list);
    setUpcomingQuery(query);
    setEventList(list);
    setEventQuery(query);
    getChallenges(list, query);
    getEvents(list, query);
  }

  useEffect(() => {
    handleChange()
  }, [tabFlag, selectedExpert]);

  const getAPI = () => {
    switch (tabFlag) {
      case "upcoming":
        return partnerViewModel.getUpcomingChallenges;
      case "current":
        return partnerViewModel.getLiveChallenges;
      case "previous":
        return partnerViewModel.getPastChallenges;
      default:
        return null;
    }
  };

  const getEventAPI = () => {
    switch (tabFlag) {
      case "upcoming":
        return partnerViewModel.getUpcomingEvents;
      case "current":
        return partnerViewModel.getLiveEvents;
      case "previous":
        return partnerViewModel.getPastEvents;
      default:
        return null;
    }
  };

  const fetchAPI = async (API, params, upcomingChallengeList) => {
    setLoading(true);
    const type = partner?.user_type
    try {
      const res = await API(params, upcomingChallengeList, type);
      setChallengeList(res.newList);
      setUpcomingQuery(res.query);
    } catch (err) {
      console.log(err);
      if (err.statusCode === 500) {
        history.push(PARTNER_LOGIN);
      }
    }
    setLoading(false);
  };

  const eventAPI = async (API, params, upcomingChallengeList) => {
    setEventLoading(true);
    const type = partner?.user_type
    const id = partner?.id
    try {
      const res = await API(params, upcomingChallengeList, type, id);
      setEventList(res.newList);
      setEventQuery(res.query);
    } catch (err) {
      console.log(err);
    }
    setEventLoading(false);
  };

  const getChallenges = (list, query) => {
    let params = { ...query };
    if (selectedExpert && selectedExpert !== "All") {
      params.partner_id = selectedExpert[0].id;
    } else {
      if(partner.user_type === "partner") {
        params.is_expert = false
      }
    }
    params.availability = tabFlag;
    const API = getAPI();
    if (API) {
      if(!loading) fetchAPI(API, params, list);
    } else {
      setLoading(false);
    }
  };

  const getInfiniteChallenges = () => {
    const upcomingChallengeList = challengeList;
    let params = { ...upcomingQuery };
    if (selectedExpert && selectedExpert !== "All") {
      params.partner_id = selectedExpert[0].id;
    } else {
      if(partner.user_type === "partner") {
        params.is_expert = false
      }
    }
    params.availability = tabFlag;
    const API = getAPI();
    if (API) {
      if(!loading) fetchAPI(API, params, upcomingChallengeList);
      getInfiniteEvents();
    } else {
      setLoading(false);
    }
  }

  const getEvents = (list, query) => {
    let params = { ...query };
    if (selectedExpert && selectedExpert !== "All") {
      params.id = selectedExpert[0].id;
      params.user_type = 'camp master'
    } else {
      params.id = partner?.id
    }
    params.availability = tabFlag;
    const API = getEventAPI();
    if (API) {
      if(!eventLoading) eventAPI(API, params, list);
    } else {
      setEventLoading(false);
    }
  }

  const getInfiniteEvents = () => {
    if(eventList.length === 0) {
      const list = eventList;
      let params = { ...eventQuery };
      if (selectedExpert && selectedExpert !== "All") {
        params.id = selectedExpert[0].id;
        params.user_type = 'camp master'
      } else {
        params.id = partner?.id
      }
      params.availability = tabFlag;
      const API = getEventAPI();
      if (API && !eventLoading) {
        if(!eventLoading) eventAPI(API, params, list);
      } else {
        setEventLoading(false);
      }
    }
  }

  const loader = (
    <Spinner
      key={0}
      className="myroom-spinner"
      animation="border"
      variant="info"
    />
  );

  const handleCloseDeleteConfirm = () => setShowDeleteConfirm(false);

  const handleDelete = (challenge) => {
    if(challenge?.is_event) {
      setIsEvent(true)
    } else {
      setIsEvent(false)
    }
    setDeleteChallengeId(challenge?.id)
    if (challenge?.created_by?.user_type === "camp master" && challenge?.agora_group_id) {
      setDeleteChallengeGroup(challenge?.agora_group_id)
    } else {
      setDeleteChallengeGroup(null)
    }
    setShowDeleteConfirm(true);
  }

  const deleteChallenge = () => {
    setLoading(true)
    if (deleteChallengeGroup) {
      let params = {
        appToken: appToken,
        id: deleteChallengeGroup
      }
      // agoraViewModel.deleteGroup(params)
    }
    let API = ''
    if(isEvent) {
      API = partnerViewModel.deleteEvent
    } else {
      API = partnerViewModel.deleteChallenge
    }
    API(deleteChallengeId).then((res) => {
      setShowDeleteConfirm(false);
      handleChange()
      getBadgeCount()
    }).catch((err) => {
      console.log(err)
    })
  }

  return (
    <>
      <Col className="myroom-creation-title">
        <div
          className={`tab tab-left pt-4 pb-2 ${
            tabFlag === "upcoming" ? "selected" : "unselected"
          }`}
          onClick={() => setTabFlag("upcoming")}
        >
          <div>Upcoming</div>
        </div>
        <div
          className={`tab tab-left pt-4 pb-2 ${
            tabFlag === "current" ? "selected" : "unselected"
          }`}
          onClick={() => setTabFlag("current")}
        >
          <div>Live</div>
        </div>
        <div
          className={`tab tab-right pt-4 pb-2 ${
            tabFlag === "previous" ? "selected" : "unselected"
          }`}
          onClick={() => setTabFlag("previous")}
        >
          <div>Past </div>
        </div>
      </Col>
      <Row className="myroom-challenge-container">
        <InfiniteScroll
          pageStart={0}
          loadMore={getInfiniteChallenges}
          hasMore={upcomingQuery.has_more}
          initialLoad={false}
          loader={loader}
          threshold={150}
        >
          <ChallengeList
            challengeList={challengeList}
            eventList={eventList}
            setEventList={setEventList}
            tabFlag={tabFlag}
            selectedExpert={selectedExpert}
            handleDelete={handleDelete}
            loading={loading}
            combineList={combineList}
            setCombineList={setCombineList}
          />
        </InfiniteScroll>
      </Row>
      <ChallengeModal
          handleClose={handleCloseDeleteConfirm}
          handleAction={deleteChallenge}
          show={showDeleteConfirm}
          type="confirm"
          level="warning"
          button1Label="Delete"
          button2Label="Cancel"
        >
          <div>
            <span>Are you sure you want to delete this challenge?</span>
          </div>
        </ChallengeModal>
    </>
  );
};

export default ChallengeBottomSection;
