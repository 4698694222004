import React from 'react';
import { Card, Image } from 'react-bootstrap';
import YouTube from 'react-youtube';
import getVideoId from 'get-video-id';
import { ChallengeStatus } from '../../../constants/status.codes.constants';
import defaultChallengeImage from '../../../assets/images/default-challenge.svg';
import LiveImage from '../../../assets/images/live.png';

import './scss/challenge.media.scss';
import { getBaseUrl } from '../../../utils/config.utils';

const ChallengeMedia = (props) => {
  const BASE_URL = getBaseUrl();
  const challenge = props.repo;
  const atList = props.for === 'list';
  const hasVideo = challenge.youtube_url && challenge.youtube_url !== '';
  const { id } = hasVideo ? getVideoId(challenge.youtube_url) : '';
  const isEvent = challenge?.is_event ? 'event' : challenge?.special_camp?.id ? 'special' : 'challenge'
  const style = {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100% !important',
    height: 'auto',
    overflow: 'hidden',
  };
  const opts = {
    playerVars: {
      origin: BASE_URL,
    },
  };
  const badge = (
    <div
      className={`remove-pointer challenge-badge ${challenge.challenge_status !== undefined
        ? challenge.challenge_status !== 'EXPIRED' &&
        challenge.challenge_status.toLowerCase()
        : ''
        }`}
    >
      {challenge.challenge_status === 'SELECTED' ? (
        <span>{`${challenge.days_left + 1} days left`}</span>
      ) : (
        challenge.challenge_status !== 'EXPIRED' && (
          <span>{ChallengeStatus[challenge.challenge_status]}</span>
        )
      )}
    </div>
  );
  const video = (
    <div className="challenge-video-container kid-ribbon-box" id={challenge.id}>
      {challenge.special_camp?.id ?
        <div className='blue-ribbon'>
          {new Date(challenge.end_date_time) > Date.now() &&
            <Image className=" liveimage" src={LiveImage} />
          }
          <p className='ribbon-text'>Special Challenge</p>
        </div>
        :
        challenge.is_event ?
          <div className='red-ribbon'>
            <p className='ribbon-text'>Event</p>
          </div>
          :
          <div className='green-ribbon'>
            <p className='ribbon-text'>Challenge</p>
          </div>
      }
      <div className='bottom-strip'>
        {challenge.special_camp?.is_root ?
          <div className='bottom-strip-box'><a className='strip-btn sky-bg-btn'>Roots</a> <a className='strip-btn purple-bg-btn'>Branches</a></div>
          :
          // <div className='bottom-strip-box'><a className='strip-btn green-bg-btn'>Seedlings</a></div>
          ''
        }
      </div>
      <YouTube
        videoId={id}
        className={`youtube-player ${atList ? '' : ''}`}
        opts={opts}
      />
      {badge}
    </div>
  );
  const image = (
    <div className="challenge-image-container kid-ribbon-box" id={challenge.id}>
      <div className=' ribbon-box' id={challenge.id}>
        {challenge.special_camp?.id ?
          <div className='blue-ribbon' id={challenge.id}>
            {new Date(challenge.end_date_time) > Date.now() &&
              <Image className=" liveimage" src={LiveImage} />
            }
            <p className='ribbon-text'>Special Challenge</p>
          </div>
          :
          challenge.is_event ?
            <div className='red-ribbon' id={challenge.id}>
              <p className='ribbon-text'>Event</p>
            </div>
            :
            <div className='green-ribbon' id={challenge.id}>
              <p className='ribbon-text'>Challenge</p>
            </div>
        }
        {/* <div className='red-ribbon'><p className='ribbon-text'>Event</p></div> */}
      </div>

      <div className='bottom-strip'>
        {challenge.special_camp?.is_root ?
          <div className='bottom-strip-box'><a className='strip-btn sky-bg-btn'>Roots</a> <a className='strip-btn purple-bg-btn'>Branches</a></div>
          :
          // <div className='bottom-strip-box'><a className='strip-btn green-bg-btn'>Seedlings</a></div>
          ''
        }
      </div>
      <Card.Img
        variant="top"
        src={
          challenge.picture_url === ''
            ? defaultChallengeImage
            : challenge.picture_url
        }
        className={`challenge-image image-d ${atList ? '' : ''}`}
        id={challenge.id}
        onClick={(e) => props.handleClick(e, isEvent)}
      />
      {badge}
    </div>
  );
  return hasVideo ? video : image;
};

export default ChallengeMedia;
