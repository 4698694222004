import React from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { SIGN_UP_ROUTE } from '../../../constants/routes.constants';
import { getHomePage, isDev } from '../../../utils/config.utils';
import Header from '../../header/Header';
// import './css/home.minified.css';
import './css/home.css';


function Home() {
  if (!isDev()) {
    window.location.replace(getHomePage());
    return null;
  }
  return (
    <div>
      <Header />
      <Container fluid className="body">
        <Row>
          <h1>Daily Creative Bites For Kids</h1>
        </Row>
        <Row>
          <Col md={12} lg={6} className="my-auto">
            <Row>
              <h2>
                Go far with your imagination! Connect with friends though
                creative projects!
              </h2>
            </Row>
            <Row>
              <h2>
                Our mission at Tale Tree is to change the way kids experience
                and expand their creativity.
              </h2>
            </Row>
          </Col>
          <Col md={12} lg={6}>
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                title="intro video"
                className="embed-responsive-item text-center"
                src="https://www.youtube.com/embed/S3Umf3f3l9U"
                allowFullScreen
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Link to={SIGN_UP_ROUTE}>
            <Button className="rounded-pill">
              Join Tale Tree Creative Camp
            </Button>
          </Link>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-center text-center">
          <h2>
            Tail Tree provides a nurturing space for kids to become empathetic,
            authentic and open-minded human beings.
          </h2>
        </Row>
        <Row className="justify-content-center">
          <h3>Our cores values</h3>
        </Row>
        <Row className="justify-content-center">
          <Col sm={3}>
            <h2>Creativity</h2>
          </Col>
          <Col sm={3}>
            <h2>Empathy</h2>
          </Col>
          <Col sm={3}>
            <h2>Autonomy</h2>
          </Col>
          <Col sm={3}>
            <h2>Authenticity</h2>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Home;
