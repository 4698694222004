import React, { useEffect, useState } from 'react';
import ChallengeMedia from '../school/ChallengeMedia';
import { Image } from 'react-bootstrap';
import deleteIconImage from '../../../assets/icon/icon-delete.svg';
import editIconImage from '../../../assets/icon/edit_ic.svg';
import { getUserProfileUrl } from '../../../utils/camp.utils';
import selectArrow from '../../../assets/icon/blue_arrow.svg';
import ClassImg from '../../../assets/images/old-letsgo.svg';
import { CHALLENGE_ROUTE, EVENT_ROUTE, PARTNER_CHALLENGES, SPECIAL_CHALLENGE_ROUTE } from '../../../constants/routes.constants';
import { useHistory } from 'react-router';
import { agoraViewModel } from '../../common/agora.view.model';
import { agoraChatGroupName } from '../../../utils/utils';
import { schoolViewModel } from '../school/school.view.model';

export default function ChallengeList(props) {
    const {
        challengeList,
        eventList,
        setEventList,
        tabFlag,
        selectedExpert,
        handleDelete,
        loading,
        combineList,
        setCombineList
    } = props;
    const history = useHistory();

    useEffect(() => {
        setCombineList([])
    }, [tabFlag, selectedExpert])

    const mergeList = (previousList, newList) => {
        const list = previousList ? [...previousList, ...newList] : newList;
        let idSet = new Set();
        const uniqueList = list.filter((item) => {
            let isUnique = false;
            if (!idSet.has(item.created_at)) {
                idSet.add(item.created_at);
                isUnique = true;
            }
            return isUnique;
        });
        return uniqueList;
    }

    const handleCombineList = () => {
        setCombineList(mergeList(combineList, challengeList))
        let tempList = []
        if (eventList.length > 0) {
            if (challengeList.length === 0) {
                tempList = [...tempList, ...eventList]
            } else {
                eventList.forEach(element => {
                    if (
                        element.start_date_time_ts > challengeList[challengeList.length - 1].start_date_time_ts
                    ) {
                        tempList.push(element)
                        setEventList((current) =>
                            current.filter((ele) => ele.id !== element.id)
                        )
                    }
                });
            }
        }
        if (tempList.length > 0) {
            setCombineList(mergeList(combineList, tempList))
        }
    }

    useEffect(() => {
        handleCombineList()
    }, [challengeList, eventList])

    const redirect = (challenge) => {
        let path = CHALLENGE_ROUTE
        let encodeQuery = btoa(`{"id":${challenge?.id}, "slug":"challenge"}`)
        if (challenge?.special_camp?.id) {
            path = SPECIAL_CHALLENGE_ROUTE
            encodeQuery = btoa(`{"id":${challenge?.id}, "slug":"special-challenge"}`)
        }
        if (challenge?.is_event) {
            path = EVENT_ROUTE
            encodeQuery = btoa(`{"id":${challenge?.id}, "slug":"event"}`)
        }
        history.push({
            pathname: path,
            search: `?encrypt=${encodeQuery}`
        })
    }

    const createGroup = async (challenge) => {
        // const data = {
        //     groupname: agoraChatGroupName(challenge.title),
        //     desc: challenge.description,
        //     public: true,
        //     owner: String(challenge?.created_by?.id),
        // };
        // let res = await agoraViewModel.getAppToken()
        // const token = res.data.app_token;

        let challengeData = {
            title: challenge?.title,
            description: challenge?.description,
            deadline: challenge?.deadline,
            agora_chat_box: false,
            agora_group_id: challenge?.treehouse_user?.agora_group_id || ''
        }

        try {
            // await agoraViewModel.createGroup(token, data).then(async (response) => {
            //     if (response) challengeData.agora_group_id = response.data.groupid
            await schoolViewModel.editChallenge(challenge?.id, challengeData)
            redirect(challenge)
            // });
        } catch (err) {
            console.error(err);
            redirect(challenge)
        }
    }

    const handleChallengeClick = (challenge) => {
        if (challenge?.created_by?.user_type === 'camp master' && !challenge?.agora_group_id && challenge?.treehouse_user?.agora_group_id) {
            createGroup(challenge)
        } else {
            redirect(challenge)
        }
    }

    const handleEdit = (challenge) => {
        let path = ''
        if (challenge?.is_event) {
            path = 'update_event'
        } else {
            path = 'partner_challenge'
        }
        history.push(`/${path}/${challenge?.id}`)
    }

    return (
        combineList && combineList.length > 0 ?
            <div className="section-div">
                <div>
                    <div className="completed-challenges-container withbtn has-1">
                        {combineList.map((challenge) => {
                            return (
                                <div className="challenge-card challenge-myroom card-1 card" key={challenge?.created_at}>
                                    <div role="button" onClick={() => handleChallengeClick(challenge)}>
                                        <ChallengeMedia repo={challenge} for="list" />
                                    </div>
                                    <span className='postedby-img'>
                                        {challenge?.created_by?.picture_url ?
                                            <Image src={challenge?.created_by?.picture_url} />
                                            : ''}
                                    </span>
                                    <div className="custom-title-and-desc card-body">
                                        <div className="last-column justify-box">
                                            <div className='edit-delete-wrapper'>
                                                <div className="fv-edit-wrapper" onClick={() => handleEdit(challenge)}>
                                                    <img src={editIconImage} />
                                                </div>
                                                <img role='button' src={deleteIconImage} onClick={() => handleDelete(challenge)} />
                                            </div>
                                        </div>
                                        <div className="feed-title-bar" id="3">
                                            <div className="challenge-title card-title h5">{challenge?.title || challenge?.name}</div>
                                            <p className="challenge-desc card-text" >{challenge?.description}</p>
                                            <div className="additional-infos">
                                                <div className="left">
                                                    {challenge?.accepted_dependents?.length > 0 && challenge?.accepted_dependents?.map((kid, key) => {
                                                        return (
                                                            <span key={key}>
                                                                <div className="avatar-wrapper">
                                                                    <div className="smallest-type">
                                                                        <div className="avatar-image"><img src={getUserProfileUrl(kid)} className="rounded-circle overlap-item" /></div>
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        )
                                                    })}
                                                    {!challenge?.is_event &&
                                                        <>
                                                            <span className="accepted-count opa">{challenge?.submission_count} <span className="show" id="3"></span></span><span className="dot opa" id="3">·</span>
                                                            <div className="submission-count opa">{challenge?.accepted_dependent_count} Participated</div>
                                                        </>
                                                    }
                                                </div>
                                                <div role="button" onClick={() => handleChallengeClick(challenge)}><img src={selectArrow} alt="" id="3" className="" /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div></div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            :
            !loading &&
            <div className="myroom-creation-container">
                <div className='choose-class'>
                    <Image
                        className="profile-image"
                        src={ClassImg}
                    />
                    Set a challenge tagged to an interest branch and build up your subscriber base.
                </div>
            </div>
    )
}