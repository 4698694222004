import { fetchUtils } from '../utils/fetch.utils';
import { getAPIUrl } from '../utils/config.utils';
import { handleHttpStatusError, handleJsonParseError } from '../components/api/api.error.handler';
import WebIM from 'agora-chat';
import axios from 'axios';

const BASE_URL = getAPIUrl();
const appId = "a628a174b6634381a6860ad7e161652b";
const appCert = "c035f0310ec24b38b356cff52ea9f37a";
const orgName = "61748012";
const appName = "1001929";
const AGORA_CHAT_APPKEY = '61748012#1001929';
const AGORA_CHAT_URL = 'https://a61.chat.agora.io';

WebIM.conn = new WebIM.connection({
  appKey: AGORA_CHAT_APPKEY,
});

const handleResponse = (response) => {
  return response.text().then((text) => {
    let data;
    try {
      data = text && JSON.parse(text);
    } catch (error) {
      return handleJsonParseError(response);
    }
    if (!response.ok) {
      data.status = response.status;
      return handleHttpStatusError(data);
    }
    return data;
  });
};

function getAppToken() {
  return fetchUtils.get(`${BASE_URL}/agora/token?app_id=${appId}&app_certificate=${appCert}&expiration_in_seconds=6000`);
}

function getUserToken(uuid) {
  // console.log(uuid)
  return fetchUtils.get(`${BASE_URL}/agora/token?app_id=${appId}&app_certificate=${appCert}&user_id=${uuid}&expiration_in_seconds=6000`);
}

const registerUser = async (data) => {
  let body = {
    username: data.username,
    nickname: data.nickname,
    password: data.password
  }
  const requestOptions = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${data.appToken}`
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${AGORA_CHAT_URL}/${orgName}/${appName}/users`, requestOptions);
  return handleResponse(response);
}

const getUser = async (data) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${data.appToken}`
    }
  };
  const response = await fetch(`${AGORA_CHAT_URL}/${orgName}/${appName}/users/${data.username}`, requestOptions);
  return handleResponse(response);
}

const loginUser = async (data) => {
  // console.log(data)
  await WebIM.conn.open({
    user: data.username,
    agoraToken: data.userToken,
  });
}

const sendGroupMessage = async (params) => {
  let data = {
    from: params.username,
    to: [params.peerId],
    type: "txt",
    body: {
      msg: params.peerMessage,
    },
    routetype: "ROUTE_ONLINE",
    sync_device: true,
  };
  const response = await axios.post(`${AGORA_CHAT_URL}/${orgName}/${appName}/messages/chatgroups`, data, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${params.appToken}`
    },
  });
  return response;
}

const addUserToGroup = async (params) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${params.appToken}`
    },
  };
  const response = await fetch(`${AGORA_CHAT_URL}/${orgName}/${appName}/chatgroups/${params.groupId}/users/${params.username}`, requestOptions);
  return handleResponse(response);
}

const getGroupInfo = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${params.appToken}`
    }
  };
  const response = await fetch(`${AGORA_CHAT_URL}/${orgName}/${appName}/chatgroups/${params.groupId}`, requestOptions);
  return handleResponse(response);
}

const createGroup = async (token, data) => {
  const response = await axios.post(`${AGORA_CHAT_URL}/${orgName}/${appName}/chatgroups`, data, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });
  return response.data;
}

const deleteGroup = async (params) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${params.appToken}`
    }
  };
  const response = await fetch(`${AGORA_CHAT_URL}/${orgName}/${appName}/chatgroups/${params.id}`, requestOptions);
  return handleResponse(response);
}

export const agoraRepository = {
  getAppToken,
  getUserToken,
  registerUser,
  getUser,
  loginUser,
  sendGroupMessage,
  addUserToGroup,
  getGroupInfo,
  createGroup,
  deleteGroup
};
