import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { BsArrowRight, BsVolumeUpFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useAuth } from '../../auth/Auth';
import { nextChallengeViewModel } from '../next_challenge/next.challenge.viewmodel';
import { ADD_CHALLENGES_ROUTE } from '../../../constants/routes.constants';
import { changeTextToVoice, getSoundWaveJson, getTaletreeFriendJson } from '../../../utils/camp.utils';
import Lottie from 'react-lottie-player';

export default function InterestBranchesModal(props) {
    const { showInterestBranchesModal, setShowInterestBranchesModal, taletreeFriend } = props;
    const handleClose = () => {
        setShowInterestBranchesModal(false);
    }
    const history = useHistory();
    const { dependent, fetchAPI } = useAuth();
    const [selectedBranch, setSelectedBranch] = useState([]);
    const [taglist, setTaglist] = useState([]);
    const [isPlay, setIsPlay] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const userData = dependent?.profile;

    const getTagList = () => {
        fetchAPI(nextChallengeViewModel.getTags, { limit: 99 })
            .then((res) => {
                setTaglist(res);
            })
            .catch((err) => { });
    };

    const getBranchList = () => {
        fetchAPI(nextChallengeViewModel.getBranch)
            .then((res) => {
                setSelectedBranch(res.map(item => item.id));
            })
            .catch((err) => { });
    };

    const setBranchForAdd = (data) => {

        if (selectedBranch.indexOf(data) != -1) {
            setSelectedBranch(selectedBranch.filter(item => item != data));
        }
        else {
            setSelectedBranch([...selectedBranch, data]);
        }
    }
    const pageRedirect = () => {
        history.goBack(1);
    }

    const showSelected = (id) => {
        if (selectedBranch.indexOf(id) != -1) {
            return "active";
        }
        else {
            return "";
        }
    }

    const addBranch = () => {
        fetchAPI(nextChallengeViewModel.addBranch, selectedBranch)
            .then((res) => {
                history.push({
                    pathname: ADD_CHALLENGES_ROUTE,
                    state: {
                        dependent: userData
                    }
                });
                // else{
                //   history.goBack();
                // }
            })
            .catch((err) => { });
    };

    useEffect(() => {
        getBranchList();
        getTagList();
    }, []);

    const handleVoiceClick = () => {
        setIsPlay(!isPlay);
        const element = document.getElementById('voice-text');
        changeTextToVoice(element.textContent, isPlay, setIsPlay, isPaused, setIsPaused);
    }

    return (
        <Modal className="signupPopup mobile-v-popup gardian auth-popup right-curved-div" show={showInterestBranchesModal} centered>
            <Modal.Body >
                <div className='row bg-blue border-40'>
                    <div className="col-md-6 col-lg-5 p-4 bg-blue border-left buble-relative overflow-hidden">
                        <div className="voice-buble buuble-bounce" >
                            <p id='voice-text' >Embark on a magical journey and discover where your passions lie!</p>
                        </div>
                        <div className="text-left">
                            {/* <p className="text-white voice-btn" onClick={handleVoiceClick}><BsVolumeUpFill /> Voice on</p> */}
                            <div className="" style={{marginTop: '10rem'}}>
                                {/* <Lottie
                                    loop
                                    animationData={getSoundWaveJson()}
                                    play={isPlay}
                                    className='animate-svg'
                                /> */}
                            </div>
                        </div>
                        <div className="cartoon-img">
                            <Lottie
                                loop={false}
                                animationData={getTaletreeFriendJson(taletreeFriend)}
                                play
                                className="w-100 cartoon-top"
                            />
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-7 v-flex bg-white border-right right-curve auth-section white-right-arrow toparrow right-bottom-curve">
                        <p className="auth-entry-text">Explore the Interest Branches</p>
                        {/* <p>Choose the TaleTree Friend you feel most close to! This friend will always be there for you :)</p> */}
                        <p>Select three things you love, <span className="user-span">{dependent.first_name}</span>.</p>
                        <div className='v-flex h-100'>
                            <div className="py-3">
                                <ul className="list-ul-interst">
                                    {taglist.map((tag, index) => (
                                        <li key={index} id={tag.id} onClick={e => setBranchForAdd(tag.id)}>
                                            <a className={`list-interst ${showSelected(tag.id)}`} href='javascript:void(0);'>{tag.tag}</a></li>
                                    ))}
                                </ul>

                            </div>
                            <div className="text-center">
                                <Button className="gred-btn w-80" onClick={addBranch} disabled={selectedBranch.length < 3 || selectedBranch.length > 10}>Next <BsArrowRight /></Button>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    )
}