import React, { useEffect, useState } from 'react';
import { Row, Col, Image, Button, Carousel } from 'react-bootstrap';
import ZoomModal from './ZoomModal';
import { useAuth } from '../../auth/Auth';
import {
  capitalizeFirstLetter,
  getCampStatus,
  getMyLocalTime,
  getSpecialCountTime,
  getUpcomingTime,
  isCampMember,
} from '../../../utils/camp.utils';
import ZoomButton from './ZoomButton';
import Avatar from '../../../assets/images/my_room_active.svg';
import defaultProfile from '../../../assets/profile/default-profile-guardian.svg';
import verified from '../../../assets/icon/Verified-badge.svg';
import greenTick from '../../../assets/icon/green_tick.svg';
import goldTick from '../../../assets/icon/gold_tick.svg';
import silverTick from '../../../assets/icon/silver_tick.svg';
import './css/camp.schedule.scss';
import Countdown, { zeroPad, formatTimeDelta } from 'react-countdown';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { specialCampViewModel } from '../special_camp/special.camp.viewmodel';
import ZoomButtonSpecialCamp from './ZoomButtonSpecialCamp';
import { CAPTAIN_ROUTE, GUARDIAN_LOGIN_ROUTE, TREEHOUSE_ROUTE } from '../../../constants/routes.constants';

// import { treehouseViewModel } from './treehouse.view.modal';
import { treehouseViewModel } from '../../views/treehouse/treehouse.view.modal';
const MyRoomCamp = () => {
  const { dependent, logout } = useAuth();
  const history = useHistory();
  const [treehouse, setTreehouse] = useState();
  const [showZoomModal, setShowZoomModal] = useState(false);
  const handleCloseZoomModal = () => setShowZoomModal(false);
  const handleShowZoomModal = () => setShowZoomModal(true);
  const [specialCamp, setSpecialCamp] = useState();
  const [isCompleted, setIsCompleted] = useState(false);
  const [isSpecialCampShow, setIsSpecialCampShow] = useState(false);
  const group = dependent ? dependent.profile?.group : "";
  const campStatus = dependent ? dependent.profile?.camp_status : "";
  const [key, setKey] = useState(0);
  const isActive = isCampMember(campStatus);
  const [showCampJoin, setShowCampJoin] = useState(false);
  const zoomClassName = isActive
    ? 'camp-schedule-zoom-details'
    : 'camp-schedule-zoom-details-disabled';
  const getSchedule = () => {

    const convertedTime = getUpcomingTime(
      group.day,
      group.time,
      group.timezone
    );
    return `${convertedTime}`;

    // return getCampStatus(campStatus) || '';
  };

  const getSpecialCampSchedule = () => {
    const convertedTime = getSpecialCountTime(specialCamp.start_date_time, specialCamp.timezone)
    return convertedTime
  }

  const countdownRendererSchool = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // setShowCampJoin(true)
      return (
        <div className="join-button px-3">
          {group?.meet_link ?
            <ZoomButtonSpecialCamp title="Join Camp" link={group?.meet_link} /> :
            group?.zoom_link ?
              <ZoomButtonSpecialCamp title="Join Camp" link={group?.zoom_link} /> :
              <ZoomButton title="Join Camp" creator={dependent} />}
        </div>
      )
    } else {
      // setShowCampJoin(false)
      return (
        <h5 className='f-600'>
          {/* <span>
          {days} Days:{zeroPad(hours)} Hrs:{zeroPad(minutes)} Mins:{zeroPad(seconds)} Secs
          </span> */}
          <div class="countdown">
            <div class="counts-days">
              <span id="days">{days}</span><br />
              <span>Days</span>
            </div>
            <div class="counts-days">
              <span id="hrs">{zeroPad(hours)}</span><br />
              <span>hrs</span>
            </div>
            <div class="counts-days">
              <span id="mins">{zeroPad(minutes)}</span><br />
              <span>mins</span>
            </div>
            <div class="counts-days">
              <span id="secs">{zeroPad(seconds)}</span><br />
              <span>secs</span>
            </div>
          </div>
        </h5>
      );
    }
  };

  const countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // setIsCompleted(true)
      return (
        <div className="join-button px-3">
          <ZoomButtonSpecialCamp title="Join Camp" link={specialCamp?.zoom_link} />
        </div>
      );
    } else {
      return (
        <h5 className='f-600'>
          {/* <span>
          {days} Days:{zeroPad(hours)} Hrs:{zeroPad(minutes)} Mins:{zeroPad(seconds)} Secs
          </span> */}
          <div class="countdown">
            <div class="counts-days">
              <span id="days">{days}</span><br />
              <span>Days</span>
            </div>
            <div class="counts-days">
              <span id="hrs">{zeroPad(hours)}</span><br />
              <span>hrs</span>
            </div>
            <div class="counts-days">
              <span id="mins">{zeroPad(minutes)}</span><br />
              <span>mins</span>
            </div>
            <div class="counts-days">
              <span id="secs">{zeroPad(seconds)}</span><br />
              <span>secs</span>
            </div>
          </div>
        </h5>
      );
    }
  };

  const getSpecialCamps = () => {
    specialCampViewModel.getDependentSpecialCamp(dependent.special_camp?.special_camp_id).then((res) => {
      setSpecialCamp(res)
      if (res && dependent.school_details) {
        if (Date.now() + getSchedule() < Date.now() + getSpecialCountTime(res.start_date_time, res.timezone)) {
          setIsSpecialCampShow(true);
        }
      }
    }).catch((err) => { })
  }

  const handleCampClick = (e) => {
    e.preventDefault();
    if (group?.id) {
      history.push(`${CAPTAIN_ROUTE}/${group?.id}`)
    } else {
      return
    }
  }

  const handleSpecialCampClick = () => {
    if (specialCamp?.challenge?.treehouse_user?.id) {
      let id = specialCamp.challenge.treehouse_user.slug ? specialCamp.challenge.treehouse_user?.slug : specialCamp.challenge.treehouse_user?.id
      history.push({
        pathname: `${TREEHOUSE_ROUTE}/${id}`,
        state: {
          type: specialCamp.challenge.treehouse_user?.slug ? 'slug' : 'id'
        }
      })
    }
  }

  const getTreehouseDetails = (tid) => {
    treehouseViewModel.getTeachersDetails(tid).then((res) => {
      setTreehouse(res);
    }).catch((err) => {
      console.log(err)
    })
  }

  useEffect(() => {
    if (dependent?.special_camp?.attend === "YES" && dependent.special_camp?.special_camp_id) {
      getSpecialCamps()
    }
    setTimeout(() => {
      if (group.group_captain?.id) {
        getTreehouseDetails(group.group_captain?.id);
      }
    }, 500);
  }, [])

  return (
    <div className="camp-schedule-container creator-camp-box justify-content-center text-center">
      {/* <div className="camp-schedule-time p-2">{getSchedule()}</div> */}

      {!group.id && !specialCamp ?
        <>
          <div className="camp-schedule-title p-3">{"Upcoming Camp"}</div>
          <div className='inner-camp-box-my-room width-box'>
            <div className='width-box'>
              <p> <span className='no-upcoming-camp'>Upgrade your plan </span> <br />
                or add a camp code</p>
            </div>
            <div className="join-button px-3">
              {/* <ZoomButton title="Join Camp" creator={creator} /> */}
              <a
                className={`camp-schedule-zoom-anchor`}
                onClick={() => logout(GUARDIAN_LOGIN_ROUTE)}
              >
                <Button
                  block
                  // disabled={!isActive}
                  variant="success"
                  className="camp-schedule-join px-3 py-3 gred-btn"
                >
                  {/* <Image className="pr-3" src={zoomIcon} /> */}
                  Upgrade
                </Button>
              </a>
            </div>
          </div>
        </>
        :
        <Carousel className="home-feed-caousel ofday">
          {group?.id &&
            <Carousel.Item>
              <div className="camp-schedule-title p-3">{dependent.school_details?.school_name ? dependent.school_details?.school_name : "Upcoming Camp"}</div>
              <div className='inner-camp-box-my-room width-box'>
                <div className='width-box camp-captain-profile-box'>
                  <ul className='d-flex kid-up-box ' role='button' onClick={handleCampClick}>
                    <li className='kid-up-name'>
                      <div className="camp-captain-profile-box-img">
                        <Image src={dependent.school_details?.picture ? dependent.school_details?.picture : Avatar} alt="" className='camp_img' width="50" />
                        <Image src={greenTick} alt="" className='green-tick' />
                      </div>
                      {group.name}
                    </li>
                  </ul>
                  <p onClick={handleCampClick}>Your camp captain:&nbsp;
                    <NavLink to="#">{group.captain_details ? group.captain_details?.first_name + ' ' + group.captain_details?.last_name : group.name}</NavLink></p>
                  <Countdown
                    key={key}
                    date={Date.now() + Number(getSchedule())}
                    renderer={countdownRendererSchool}
                  // overtime={true}
                  // onComplete={handleComplete}
                  />
                </div>
                {showCampJoin ?
                  !group.meet_link && group.zoom_link &&
                  <>
                    <Row
                      className={`p-3 justify-content-center camp-schedule-zoom-details`}
                      onClick={handleShowZoomModal}
                    >
                      <Col className="col-auto">
                        <div className="camp-schedule-details">
                          Check Meeting Id & Password &gt;
                        </div>
                      </Col>
                    </Row>
                    <ZoomModal showModal={showZoomModal} handleClose={handleCloseZoomModal} />
                  </>
                  : ''
                }
              </div>
            </Carousel.Item>
          }
          {specialCamp &&
            <Carousel.Item>
              <div className="camp-schedule-title p-3">{"Upcoming Camp"}</div>
              <div className='inner-camp-box-my-room width-box'>
                <div className='width-box camp-captain-profile-box'>
                  <ul className='d-flex kid-up-box ' role='button' onClick={handleCampClick}>
                    <li className='kid-up-name'>
                      <div className="camp-captain-profile-box-img">
                        <Image src={
                          specialCamp?.challenge?.treehouse_user?.picture ? specialCamp.challenge.treehouse_user.picture : specialCamp?.picture_url ? specialCamp?.picture_url : specialCamp?.created_by?.picture_url ? specialCamp?.created_by?.picture_url : defaultProfile
                        }
                          alt="" className='camp_img' width="50" />
                        {specialCamp?.challenge?.treehouse_user?.type_of_organisation === "non profit" ?
                          <Image src={silverTick} alt="" className='green-tick' />
                          : specialCamp?.challenge?.treehouse_user?.type_of_organisation === "profit" ?
                            <Image src={goldTick} alt="" className='green-tick' />
                            : <Image src={verified} alt="" className='green-tick' style={{ top: '-7px' }} />
                        }
                      </div>
                      {specialCamp?.name}
                      {/* {specialCamp?.challenge ? specialCamp?.challenge?.treehouse_user?.name : specialCamp?.created_by?.first_name + ' ' + specialCamp?.created_by?.last_name} */}
                    </li>
                  </ul>
                  <p onClick={handleSpecialCampClick}>Meet the expert:&nbsp;
                    <NavLink to="#">
                      {specialCamp?.challenge ?
                        <a href="javascript:void(0);">
                          {specialCamp?.challenge ? specialCamp?.challenge?.treehouse_user?.name : specialCamp?.created_by?.first_name + specialCamp?.created_by?.last_name}</a>
                        : specialCamp?.created_by?.first_name + ' ' + specialCamp?.created_by?.last_name}
                    </NavLink>
                  </p>
                  <Countdown
                    date={Date.now() + Number(getSpecialCampSchedule())}
                    renderer={countdownRenderer}
                  // overtime={true}
                  // onComplete={handleComplete}
                  />
                </div>
              </div>
            </Carousel.Item>
          }
        </Carousel>
      }
      {/* <Row
        className={`p-3 justify-content-center ${zoomClassName}`}
        onClick={handleShowZoomModal}
      >
        <Col className="col-auto">
          <div className="camp-schedule-details">
            Check Meeting Id & Password &gt;
          </div>
        </Col>
      </Row> */}
      {/* <ZoomModal showModal={showZoomModal} handleClose={handleCloseZoomModal} /> */}
    </div>
  );
};

export default MyRoomCamp;
