import React, { useEffect, useState } from 'react';
import { Image, Navbar, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import {
  CHALLENGE_ROUTE,
  EVENT_ROUTE,
  HOME_FEED,
  MY_ROOM_ROUTE,
  NEXT_CHALLENGE_ROUTE,
  NOTIFICATION_ROUTE,
  SHARE_CREATION_ROUTE_BANNER,
  SPECIAL_CHALLENGE_ROUTE,
  TALETREE_MAP,
} from '../../constants/routes.constants';
import { useAuth } from '../auth/Auth';
import logo from '../../assets/logo/logo-53x53.svg';
import TaletreeHeader from './TaletreeHeader';
import ProfileMenu from './ProfileMenu';
import './css/camp.header.scss';
import myRoom from '../../assets/images/my_room.svg';
import myRoomDark from '../../assets/icon/room-dark.svg';
import myRoomActive from '../../assets/images/my_room_active.svg';
import myRoomActiveDark from '../../assets/icon/room-active-dark.svg';
import HomeActive from '../../assets/images/home_active.svg';
import HomeActiveDark from '../../assets/icon/home-active-dark.svg';
import HomeDark from '../../assets/icon/home-dark.svg';
import Home from '../../assets/images/home.svg';
import Challenges from '../../assets/images/challenge.svg';
import ChallengesActive from '../../assets/images/challenges_active.svg';
import ChallengesActiveDark from '../../assets/icon/challenge-active-dark.svg';
import ChallengesDark from '../../assets/icon/challenge-dark.svg';
import userAvtr from '../../assets/images/uni.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { getUserNotificationChar } from '../../utils/camp.utils';
import Search from '../common/Search';
import Switch from 'react-switch';
import { changeBodyClassName } from '../../utils/utils';
import { bg } from '../../constants/component.constants';
import ImgMoon from '../../assets/icon/moon.svg';
import ImgSun from '../../assets/icon/sun.svg';
import MapMode from '../../assets/images/map-rocket.svg';
import MapModeHover from '../../assets/images/map-rocket-hover.svg';
import EventEmitter from '../../utils/events';
import { HOWS_YOUR_DAY } from '../../constants/companionPrompt.constants';

const CampHeader = () => {
  const { logout, dependent } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const mode = localStorage.getItem('mode');

  const handleClick = () => {
    history.push(NOTIFICATION_ROUTE)
  }
  const [checked, setChecked] = useState(mode === 'light' ? false : true);

  const handleChange = val => {
    setChecked(val)
    if (val) {
      changeBodyClassName([bg.DARK])
      localStorage.setItem('mode', 'dark')
    } else {
      changeBodyClassName([bg.CHALLENGES])
      localStorage.setItem('mode', 'light')
    }
  }

  useEffect(() => {
    handleChange(mode === 'light' ? false : true)
    if (location?.state?.from === 'login') {
      // EventEmitter.dispatch('show_prompt', {
      //   showPrompt: 'block',
      //   promptType: HOWS_YOUR_DAY
      // })
    }
  }, [])

  return (
    <TaletreeHeader className="camp-header kid">
      <div className='navbar-brand-wrapper'>
        <LinkContainer to={HOME_FEED}>
          <Navbar.Brand>
            <Image src={logo} className="logo" />
          </Navbar.Brand>
        </LinkContainer>
        <div className='headerUserNotification' onClick={handleClick}>
          <Image src={getUserNotificationChar(dependent)} className="User Avtar" />
          <span className='userNotification kid'>Hey {dependent !== null && dependent.first_name}, I want to talk to you.</span>
        </div>

        <Search />

      </div>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse
        id="responsive-navbar-nav"
        className=""
      >
        <Nav className=" align-items-md-center navbar-sub navbar-custom ">
          <LinkContainer to={HOME_FEED}>
            <Nav.Link className={`px-3 camp-header-link ${history?.location?.pathname === HOME_FEED ? 'header-active' : ''}`}>
              {history?.location?.pathname === HOME_FEED ?
                <Image
                  src={mode === 'dark' ? HomeActiveDark : HomeActive}
                  alt=""
                />
                :
                <Image
                  src={mode === 'dark' ? HomeDark : Home}
                  alt=""
                />
              }
              <span className='mob-menu'>Home Feed</span>
            </Nav.Link>
          </LinkContainer>
          <LinkContainer to={NEXT_CHALLENGE_ROUTE}>
            <Nav.Link className={`px-3 camp-header-link ${history.location.pathname.slice(0, 11) === NEXT_CHALLENGE_ROUTE ||
              history.location.pathname.slice(0, 10) === CHALLENGE_ROUTE ||
              history.location.pathname.slice(0, 18) === SPECIAL_CHALLENGE_ROUTE ||
              history.location.pathname.slice(0, 6) === EVENT_ROUTE ?
              "header-active" : ''
              }`}>
              {history.location.pathname.slice(0, 11) === NEXT_CHALLENGE_ROUTE ||
                history.location.pathname.slice(0, 10) === CHALLENGE_ROUTE ||
                history.location.pathname.slice(0, 18) === SPECIAL_CHALLENGE_ROUTE ||
                history.location.pathname.slice(0, 6) === EVENT_ROUTE ?
                <Image src={mode === 'dark' ? ChallengesActiveDark : ChallengesActive} alt="" />
                : <Image src={mode === 'dark' ? ChallengesDark : Challenges} alt="" />}
              <span className='mob-menu'>Challenges</span>
            </Nav.Link>
          </LinkContainer>
          <LinkContainer to={MY_ROOM_ROUTE}>
            <Nav.Link className={`px-3 camp-header-link ${history.location.pathname !== HOME_FEED &&
              history.location.pathname !== NEXT_CHALLENGE_ROUTE &&
              history.location.pathname.slice(0, 10) !== CHALLENGE_ROUTE &&
              history.location.pathname.slice(0, 18) !== SPECIAL_CHALLENGE_ROUTE &&
              history.location.pathname.slice(0, 6) !== EVENT_ROUTE &&
              history.location.pathname.slice(0, 9) !== SHARE_CREATION_ROUTE_BANNER &&
              history.location.pathname !== NOTIFICATION_ROUTE ?
              'header-active' : ''
              }`}>
              {history.location.pathname !== HOME_FEED &&
                history.location.pathname !== NEXT_CHALLENGE_ROUTE &&
                history.location.pathname.slice(0, 10) !== CHALLENGE_ROUTE &&
                history.location.pathname.slice(0, 18) !== SPECIAL_CHALLENGE_ROUTE &&
                history.location.pathname.slice(0, 6) !== EVENT_ROUTE &&
                history.location.pathname.slice(0, 9) !== SHARE_CREATION_ROUTE_BANNER &&
                history.location.pathname !== NOTIFICATION_ROUTE ?
                <Image src={mode === 'dark' ? myRoomActiveDark : myRoomActive} alt="" />
                : <Image src={mode === 'dark' ? myRoomDark : myRoom} alt="" />}
              <span className='mob-menu'>My Room</span>
            </Nav.Link>
          </LinkContainer>
          <div>
          </div>
          <Nav.Link
            className="px-4 camp-header-link logout"
            onClick={() => logout()}
          >
            Logout
          </Nav.Link>
          <div className="profile-menu-header-container">
            <div className='map-mode' onClick={() => history.push(TALETREE_MAP)}>
              <img className='normal-state' src={MapMode} alt='' />
              <img className='hover-state' src={MapModeHover} alt='' />
            </div>
            <label htmlFor="small-radius-switch">
              {/* <span>A switch all available styling options</span> */}
              <Switch
                checked={checked}
                onChange={handleChange}
                handleDiameter={28}
                offColor="#08f"
                onColor="#101B2F"
                offborder="2px solid #CCDCF3"
                // onBorder="2px solid #CCDCF3"
                height={50}
                width={120}
                borderRadius={150}
                activeBoxShadow="0px 0px 1px 2px #fffc35"
                uncheckedIcon={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      fontSize: 9,
                      color: "#fff",
                      paddingRight: 2,
                      paddingLeft: 1,
                      fontWeight: 700,
                      textTransform: "uppercase",
                      fontFamily: "Open Sans"
                    }}
                  >
                    Day Mode
                  </div>
                }
                checkedIcon={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      fontSize: 9,
                      color: "#fff",
                      paddingRight: 2,
                      paddingLeft: 1,
                      fontWeight: 700,
                      textTransform: "uppercase",
                      fontFamily: "Open Sans"
                    }}
                  >
                    Night Mode
                  </div>
                }
                uncheckedHandleIcon={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%"
                    }}
                  >
                    <img src={ImgSun} />
                  </div>
                }
                checkedHandleIcon={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <img src={ImgMoon} />
                  </div>
                }
                className="react-switch"
                id="small-radius-switch"
              />
            </label>
            <ProfileMenu />
          </div>
          <div className="mobile-search">
            <Search />
            <div className="mobile-bottom-wrapper">
              <div className='map-mode' onClick={() => history.push(TALETREE_MAP)}>
                <img className='normal-state' src={MapMode} alt='' />
                <img className='hover-state' src={MapModeHover} alt='' />
              </div>
              <label htmlFor="small-radius-switch">
                <Switch
                  checked={checked}
                  onChange={handleChange}
                  handleDiameter={28}
                  offColor="#08f"
                  onColor="#101B2F"
                  offborder="2px solid #CCDCF3"
                  // onBorder="2px solid #CCDCF3"
                  height={50}
                  width={120}
                  borderRadius={150}
                  activeBoxShadow="0px 0px 1px 2px #fffc35"
                  uncheckedIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 9,
                        color: "#fff",
                        paddingRight: 2,
                        paddingLeft: 1,
                        fontWeight: 700,
                        textTransform: "uppercase",
                        fontFamily: "Open Sans"
                      }}
                    >
                      Day Mode
                    </div>
                  }
                  checkedIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 9,
                        color: "#fff",
                        paddingRight: 2,
                        paddingLeft: 1,
                        fontWeight: 700,
                        textTransform: "uppercase",
                        fontFamily: "Open Sans"
                      }}
                    >
                      Night Mode
                    </div>
                  }
                  uncheckedHandleIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%"
                      }}
                    >
                      <img src={ImgSun} />
                    </div>
                  }
                  checkedHandleIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <img src={ImgMoon} />
                    </div>
                  }
                  className="react-switch"
                  id="small-radius-switch"
                />
              </label>
            </div>
          </div>
        </Nav>
      </Navbar.Collapse>
    </TaletreeHeader>
  );
};

export default CampHeader;
