import React from 'react';
import { Card, Image } from 'react-bootstrap';
import YouTube from 'react-youtube';
import getVideoId from 'get-video-id';
import { ChallengeStatus } from '../../../constants/status.codes.constants';
import defaultChallengeImage from '../../../assets/images/default-challenge.svg';
import LiveImage from '../../../assets/images/live.png';
import '../next_challenge/scss/challenge.media.scss';

const ChallengeMedia = (props) => {
  const challenge = props.repo;
  const atList = props.for === 'list';
  const hasVideo = challenge.youtube_url && challenge.youtube_url !== '';
  const { id } = hasVideo ? getVideoId(challenge.youtube_url) : '';
  const badge = (
    <div
      className={`challenge-badge ${challenge.challenge_status !== undefined
        ? challenge.challenge_status !== 'EXPIRED' &&
        challenge.challenge_status.toLowerCase()
        : ''
        }`}
    >
      {challenge.challenge_status === 'SELECTED' ? (
        <span>{`${challenge.days_left + 1} days left`}</span>
      ) : (
        challenge.challenge_status !== 'EXPIRED' && (
          <span>{ChallengeStatus[challenge.challenge_status]}</span>
        )
      )}
    </div>
  );
  const video = (
    <div className="challenge-video-container">
      <div className='ribbon-box'>
        {challenge.special_camp?.id ?
          <div className='blue-ribbon'>
            {new Date(challenge.end_date_time) > Date.now() &&
              <Image className=" liveimage" src={LiveImage} />
            }
            <p className='ribbon-text'>Special Challenge</p>
          </div>
          :
          challenge.is_event ?
            <div className='red-ribbon'>
              <p className='ribbon-text' style={{ top: '28px' }}>Event</p>
            </div>
            :
            <div className='green-ribbon'>
              <p className='ribbon-text'>Challenge</p>
            </div>
        }
      </div>
      <YouTube videoId={id} className={`youtube-player ${atList ? '' : ''}`} />
      {badge}
    </div>
  );
  const image = (
    <div className="challenge-image-container">
      <div className='ribbon-box'>
        {challenge.special_camp?.id ?
          <div className='blue-ribbon'>
            {new Date(challenge.end_date_time) > Date.now() &&
              <Image className=" liveimage" src={LiveImage} />
            }
            <p className='ribbon-text'>Special Challenge</p>
          </div>
          :
          challenge.is_event ?
            <div className='red-ribbon'>
              <p className='ribbon-text' style={{ top: '28px' }}>Event</p>
            </div>
            :
            <div className='green-ribbon'>
              <p className='ribbon-text'>Challenge</p>
            </div>
        }
      </div>
      <Card.Img
        variant="top"
        src={
          challenge.picture_url === ''
            ? defaultChallengeImage
            : challenge.picture_url
        }
        className={`challenge-image ${atList ? '' : ''}`}
        id={challenge.id}
      />
      {badge}
    </div>
  );
  return hasVideo ? video : image;
};
export default ChallengeMedia;
