import { treehouseRepository } from "../../repository/treehouse.repository";

const getNextQuery = (params, list, has_more) => {
  const lastId = list.length > 0 ? list[list.length - 1].id: 0;
  const nextQuery = { ...params };
  nextQuery.starting_after = lastId;
  nextQuery.has_more = has_more;
  return nextQuery;
};

const mergeList = (previousList, newList) => {
  const list = previousList ? [...previousList, ...newList] : newList;
  let idSet = new Set();

  const uniqueList = list.filter((item) => {
    let isUnique = false;
    if (!idSet.has(item.id)) {
      idSet.add(item.id);
      isUnique = true;
    }
    return isUnique;
  });
  return uniqueList;
};

const getAllTreehouses = (params, previousFeedList) => {
    return new Promise((resolve, reject) => {
      treehouseRepository
        .getAllTreehouses(params)
        .then((res) => {
          const list = mergeList(previousFeedList, res.data);
          const query = getNextQuery(params, list, res.has_more);
          const data = {
            list,
            query,
          };
          resolve(data)
        }).catch((err) => {
          reject(err)
        })
    })
  }

  const subscribeTreehouse = (params) => {
    return new Promise((resolve, reject) => {
      treehouseRepository
        .subscribeTreehouse(params)
        .then((res) => {
          resolve(res.data)
        }).catch((err) => {
          reject(err)
        })
    })
  }

  const unSubscribeTreehouse = (params) => {
    return new Promise((resolve, reject) => {
      treehouseRepository
        .unSubscribeTreehouse(params)
        .then((res) => {
          resolve(res.data)
        }).catch((err) => {
          reject(err)
        })
    })
  }

  const getSubscribedTreehouses = (params) => {
    return new Promise((resolve, reject) => {
      treehouseRepository
        .getSubscribedTreehouses(params)
        .then((res) => {
          resolve(res.data)
        }).catch((err) => {
          reject(err)
        })
    })
  }

  const getSubscribedCampMasters = (id) => {
    return new Promise((resolve, reject) => {
      treehouseRepository
        .getSubscribedCampMasters(id)
        .then((res) => {
          resolve(res.data)
        }).catch((err) => {
          reject(err)
        })
    })
  }

  const getParmanentTreehouses = (params) => {
    return new Promise((resolve, reject) => {
      treehouseRepository
        .getParmanentTreehouses(params)
        .then((res) => {
          resolve(res.data)
        }).catch((err) => {
          reject(err)
        })
    })
  }

  
  export const treehouseViewModel = {
    getAllTreehouses,
    subscribeTreehouse,
    getSubscribedTreehouses,
    unSubscribeTreehouse,
    getParmanentTreehouses,
    getSubscribedCampMasters
  };