import React, { useCallback, useEffect, useRef, useState } from "react";
import { getOrientation } from 'get-orientation/browser'
import { getRotatedImage } from './canvasUtils';
import { Cropper, FixedCropper, ImageRestriction } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css'
import { Button, Modal } from "react-bootstrap";
import Navigation from "./Navigation";
import { getAbsoluteZoom, getZoomFactor } from 'advanced-cropper/extensions/absolute-zoom';
import { BsArrowLeft } from "react-icons/bs";
import Rotate from '../../../assets/images/rotate.svg';
import './ImageEditor.scss';
import { CgEditFlipH } from '@react-icons/all-files/cg/CgEditFlipH';

const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
}

function readFile(file) {
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}

const ImageCropRotate = ({ imageCropPopup, image, setPicture, closeModal, heading, aspectRatio }) => {
    const [imageSrc, setImageSrc] = React.useState(null)
    const [absoluteZoom, setAbsoluteZoom] = useState(null);
    const [state, setState] = useState(null);
    const [settings, setSettings] = useState(null);
    const cropperRef = useRef();

    const onFileChange = async (e) => {
        if (image) {
            const file = image
            let imageDataUrl = await readFile(file)

            try {
                // apply rotation if needed
                const orientation = await getOrientation(file)
                const rotation = ORIENTATION_TO_ANGLE[orientation]
                if (rotation) {
                    imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
                }
            } catch (e) {
                console.warn('failed to detect the orientation')
            }
            setImageSrc(imageDataUrl)
        }
    }

    useEffect(() => {
        onFileChange();
    }, [image])

    const onChange = (cropper) => {
        // const cropper = cropperRef.current
        if (cropper) {
            const state = cropper.getState();
            setState(state)
            const settings = cropper.getSettings();
            setSettings(settings)
            const absoluteZoom = getAbsoluteZoom(state, settings);
            setAbsoluteZoom(absoluteZoom)
        }
    };

    const flip = (horizontal, vertical) => {
        if (cropperRef.current) {
            cropperRef.current?.flipImage(horizontal, vertical);
        }
    };
    const rotate = (angle) => {
        cropperRef.current?.rotateImage(angle);
    };

    const handleComplete = useCallback(async () => {
        try {
            const cropper = cropperRef.current;
            if (cropper) {
                const result = cropper.getCanvas()
                const croppedImage = await new Promise((resolve, reject) => {
                    result.toBlob((file) => {
                        resolve(file)
                    }, 'image/jpeg')
                })
                const myFile = new File([croppedImage], 'image.jpeg', {
                    type: 'image/jpeg',
                });
                setPicture(myFile)
                closeModal();
            }
        } catch (error) {
            console.log(error)
        }

    })

    // useEffect(() => {

    // }, [imageSrc])

    const onZoom = (value, transitions) => {
        const cropper = cropperRef.current
        cropper.zoomImage(getZoomFactor(state, settings, value), {
            transitions: !!transitions,
        });
    };

    return (
        <Modal show={imageCropPopup} className="mobile-v-popup auth-popup">
            <Modal.Header className="box3-haeder no-flex align-items-center" >
                <div className="row align-items-center">
                    <div className=" col-md-3 mobile-none add-pointer" onClick={closeModal}>
                        <BsArrowLeft />
                    </div>
                    <div className="col-md-6">
                        <h4 className='modal-main-heading text-center'>{heading}</h4>
                    </div>
                    <div className=" col-md-3 text-right crop-modal-btn">
                        <Button
                            className="gred-btn"
                            onClick={handleComplete}
                            variant="contained"
                            color="primary"
                        >
                            Apply
                        </Button>
                    </div>
                </div>

            </Modal.Header>

            <Modal.Body>
                <div className={'image-editor'}>
                    <div className="image-editor__cropper">
                        {aspectRatio ?
                            <FixedCropper
                                ref={cropperRef}
                                src={imageSrc}
                                onChange={onChange}
                                className={'cropper'}
                                stencilProps={{
                                    aspectRatio: aspectRatio && aspectRatio,
                                    resizable: aspectRatio ? false : true,
                                    handlers: aspectRatio ? false : true,
                                }}
                                stencilSize={{
                                    width: 600,
                                    height: 500
                                }}
                                imageRestriction={ImageRestriction.stencil}
                            />
                            :
                            <Cropper
                                ref={cropperRef}
                                src={imageSrc}
                                onChange={onChange}
                                className={'cropper'}
                            />
                        }
                    </div>
                </div>
                <div className='row bottom-strip-zoom'>
                    <div className='col-md-2 add-pointer' onClick={() => flip(true, false)}>
                        <span className="stipname"> Flip</span> <CgEditFlipH />
                    </div>

                    <div className='col-md-8'>
                        <Navigation className="custom-wrapper__navigation" zoom={absoluteZoom} onZoom={onZoom} />
                    </div>
                    <div className='col-md-2 add-pointer' onClick={() => rotate(90)}>
                        <span className="stipname">  Rotate</span> <img src={Rotate} style={{ height: '15px', marginLeft: '2px' }} />
                    </div>

                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ImageCropRotate;
