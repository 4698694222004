import React, { useEffect, useState } from 'react';
import { Row, Col, Image, Button, Carousel } from 'react-bootstrap';
import ZoomModal from './ZoomModal';
import { useAuth } from '../../auth/Auth';
import {
  capitalizeFirstLetter,
  getCampStatus,
  getMyLocalTime,
  getSpecialCountTime,
  getUpcomingTime,
  isCampMember,
} from '../../../utils/camp.utils';
import ZoomButton from './ZoomButton';
import Avatar from '../../../assets/images/my_room_active.svg';
import defaultProfile from '../../../assets/profile/default-profile-guardian.svg';
import verified from '../../../assets/icon/Verified-badge.svg';
import greenTick from '../../../assets/icon/green_tick.svg';
import goldTick from '../../../assets/icon/gold_tick.svg';
import silverTick from '../../../assets/icon/silver_tick.svg';
import './css/camp.schedule.scss';
import Countdown, { zeroPad, formatTimeDelta } from 'react-countdown';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { specialCampViewModel } from '../special_camp/special.camp.viewmodel';
import ZoomButtonSpecialCamp from './ZoomButtonSpecialCamp';
import { CAPTAIN_ROUTE, TREEHOUSE_ROUTE } from '../../../constants/routes.constants';

const SpecialCampComp = ({ camp }) => {

  const getSpecialCampSchedule = () => {
    const convertedTime = getSpecialCountTime(camp.start_date_time, camp.timezone)
    return convertedTime
  }

  const countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // setIsCompleted(true)
      return (
        <div className="join-button px-3">
          <ZoomButtonSpecialCamp title="Join Camp" link={camp?.zoom_link} />
        </div>
      );
    } else {
      return (
        <h5 className='f-600'>
          <div className="countdown">
            <div className="counts-days">
              <span id="days">{days}</span><br />
              <span>Days</span>
            </div>
            <div className="counts-days">
              <span id="hrs">{zeroPad(hours)}</span><br />
              <span>hrs</span>
            </div>
            <div className="counts-days">
              <span id="mins">{zeroPad(minutes)}</span><br />
              <span>mins</span>
            </div>
            <div className="counts-days">
              <span id="secs">{zeroPad(seconds)}</span><br />
              <span>secs</span>
            </div>
          </div>
        </h5>
      );
    }
  };

  const handleSpecialCampClick = () => {
    if (specialCamp?.challenge?.treehouse_user?.id) {
      let id = specialCamp.challenge.treehouse_user.slug ? specialCamp.challenge.treehouse_user?.slug : specialCamp.challenge.treehouse_user?.id
      history.push({
        pathname: `${TREEHOUSE_ROUTE}/${id}`,
        state: {
          type: specialCamp.challenge.treehouse_user?.slug ? 'slug' : 'id'
        }
      })
    }
  }

  return (
    <>
      <div className="camp-schedule-title p-3">{"Upcoming Camp"}</div>
      <div className='inner-camp-box-my-room width-box'>
        <div className='width-box camp-captain-profile-box'>
          <ul className='d-flex kid-up-box '>
            <li className='kid-up-name'>
              <div className="camp-captain-profile-box-img">
                <Image src={
                  camp?.challenge?.treehouse_user?.picture ? camp.challenge.treehouse_user.picture : camp?.picture_url ? camp?.picture_url : camp?.created_by?.picture_url ? camp?.created_by?.picture_url : defaultProfile
                }
                  alt="" className='camp_img' width="50" />
                {camp?.challenge?.treehouse_user?.type_of_organisation === "non profit" ?
                  <Image src={silverTick} alt="" className='green-tick' />
                  : camp?.challenge?.treehouse_user?.type_of_organisation === "profit" ?
                    <Image src={goldTick} alt="" className='green-tick' />
                    : <Image src={verified} alt="" className='green-tick' style={{ top: '-7px' }} />
                }
              </div>
              {camp?.name}
            </li>
          </ul>
          <p>Meet the expert:&nbsp;
            {/* <NavLink to="#"> */}
            {camp?.challenge ?
              <a href="javascript:void(0);">
                {camp?.challenge ? camp?.challenge?.treehouse_user?.name : camp?.created_by?.first_name + camp?.created_by?.last_name}</a>
              : camp?.created_by?.first_name + ' ' + camp?.created_by?.last_name}
            {/* </NavLink> */}
          </p>
          <Countdown
            date={Date.now() + Number(getSpecialCampSchedule())}
            renderer={countdownRenderer}
          // overtime={true}
          // onComplete={handleComplete}
          />
        </div>
      </div>
    </>
  )
}

// import { treehouseViewModel } from './treehouse.view.modal';
import { treehouseViewModel } from '../treehouse/treehouse.view.modal';
import AddSchoolModal from '../dashboard/AddSchoolModal';
const GuardianCamps = ({ dependent }) => {
  const history = useHistory();
  const [treehouse, setTreehouse] = useState();
  const [showZoomModal, setShowZoomModal] = useState(false);
  const handleCloseZoomModal = () => setShowZoomModal(false);
  const handleShowZoomModal = () => setShowZoomModal(true);
  const [specialCamp, setSpecialCamp] = useState([]);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isSpecialCampShow, setIsSpecialCampShow] = useState(false);
  const group = dependent ? dependent.profile?.group : "";
  const campStatus = dependent ? dependent.profile?.camp_status : "";
  const [key, setKey] = useState(0);
  const isActive = isCampMember(campStatus);
  const [showCampJoin, setShowCampJoin] = useState(false);
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);
  const zoomClassName = isActive
    ? 'camp-schedule-zoom-details'
    : 'camp-schedule-zoom-details-disabled';
  const getSchedule = () => {

    const convertedTime = getUpcomingTime(
      group.day,
      group.time,
      group.timezone
    );
    return `${convertedTime}`;

    // return getCampStatus(campStatus) || '';
  };

  const countdownRendererSchool = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // setShowCampJoin(true)
      return (
        <div className="join-button px-3">
          {group?.meet_link ?
            <ZoomButtonSpecialCamp title="Join Camp" link={group?.meet_link} /> :
            group?.zoom_link ?
              <ZoomButtonSpecialCamp title="Join Camp" link={group?.zoom_link} /> :
              <ZoomButton title="Join Camp" creator={dependent} />}
        </div>
      )
    } else {
      // setShowCampJoin(false)
      return (
        <h5 className='f-600'>
          {/* <span>
            {days} Days:{zeroPad(hours)} Hrs:{zeroPad(minutes)} Mins:{zeroPad(seconds)} Secs
          </span> */}
          <div className="countdown">
            <div className="counts-days">
              <span id="days">{days}</span><br />
              <span>Days</span>
            </div>
            <div className="counts-days">
              <span id="hrs">{zeroPad(hours)}</span><br />
              <span>hrs</span>
            </div>
            <div className="counts-days">
              <span id="mins">{zeroPad(minutes)}</span><br />
              <span>mins</span>
            </div>
            <div className="counts-days">
              <span id="secs">{zeroPad(seconds)}</span><br />
              <span>secs</span>
            </div>
          </div>
        </h5>
      );
    }
  };

  const getSpecialCamps = () => {
    if (dependent?.profile?.is_activated) {
      if (dependent?.special_camp?.special_camp_id) {
        specialCampViewModel.getDependentSpecialCamp(dependent?.special_camp?.special_camp_id).then((res) => {
          if (res.id === dependent?.special_camp?.special_camp_id && dependent?.special_camp?.attend === "YES") {
            setSpecialCamp([res])
          } else {
            setSpecialCamp([])
          }
          if (res && dependent.school_details) {
            if (Date.now() + getSchedule() < Date.now() + getSpecialCountTime(res.start_date_time, res.timezone)) {
              setIsSpecialCampShow(true);
            }
          }
        }).catch((err) => { setSpecialCamp([]) })
      } else {
        setSpecialCamp([])
      }
    } else {
      setSpecialCamp([])
    }
  }

  const handleCampClick = (e) => {
    e.preventDefault();
    if (group?.id) {
      history.push(`${CAPTAIN_ROUTE}/${group?.id}`)
    } else {
      return
    }
  }

  const getTreehouseDetails = (tid) => {
    treehouseViewModel.getTeachersDetails(tid).then((res) => {
      setTreehouse(res);
    }).catch((err) => {
      console.log(err)
    })
  }

  useEffect(() => {
    setIndex(0)
    getSpecialCamps()
  }, [dependent])

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="camp-schedule-container creator-camp-box justify-content-center text-center">
      <AddSchoolModal show={show} setShow={setShow} dependent={dependent} />
      <Carousel activeIndex={index} onSelect={handleSelect} className="home-feed-caousel ofday">
        {group?.id ?
          <Carousel.Item>
            <div className="camp-schedule-title p-3">{dependent.school_details?.school_name ? dependent.school_details?.school_name : "Upcoming Camp"}</div>
            <div className='inner-camp-box-my-room width-box'>
              <div className='width-box camp-captain-profile-box'>
                <ul className='d-flex kid-up-box '>
                  <li className='kid-up-name'>
                    <div className="camp-captain-profile-box-img">
                      <Image src={dependent.school_details?.picture ? dependent.school_details?.picture : Avatar} alt="" className='camp_img' width="50" />
                      <Image src={greenTick} alt="" className='green-tick' />
                    </div>
                    Tribe: {group.name}
                  </li>
                </ul>
                <p>Your camp captain:&nbsp;
                  {group.captain_details ? group.captain_details?.first_name + ' ' + group.captain_details?.last_name : group.name}</p>
                <Countdown
                  // key={key}
                  date={Date.now() + Number(getSchedule())}
                  renderer={countdownRendererSchool}
                // overtime={true}
                // onComplete={handleComplete}
                />
              </div>
              {showCampJoin ?
                !group.meet_link && group.zoom_link &&
                <>
                  <Row
                    className={`p-3 justify-content-center camp-schedule-zoom-details`}
                  >
                    <Col className="col-auto">
                      <div className="camp-schedule-details">
                        Check Meeting Id & Password &gt;
                      </div>
                    </Col>
                  </Row>
                  <ZoomModal showModal={showZoomModal} handleClose={handleCloseZoomModal} />
                </>
                : ''
              }
            </div>
          </Carousel.Item>
          :
          <Carousel.Item>
            <button onClick={() => setShow(true)} className="connected-btn">You have no camp connected </button>
          </Carousel.Item>
        }
        {dependent?.profile?.is_activated && specialCamp.length > 0 &&
          specialCamp.map((item, key) => (
            <Carousel.Item>
              <SpecialCampComp camp={item} key={key} />
            </Carousel.Item>
          ))
        }
      </Carousel>

    </div>
  );
};

export default GuardianCamps;