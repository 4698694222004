import React, { useState } from 'react';
import { Button, Form, Image } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import ErrorLabel from '../../common/ErrorLabel';
import { kidSignUpErrors } from '../../../constants/error.constants';
import { useAuth } from '../../auth/Auth';
import { settingsViewModel } from './settings.view.model';
import FormInput from '../../common/FormInput';
import FormLabel from '../../common/FormLabel';
import FormPasswordInput from '../../common/FormPasswordInput';
import LoadButton from '../../common/LoadButton';

const EditChildPassword = ({ dependent, onProfileSave }) => {
  const { guardian, fetchAPI } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const defaultValues = { password: '', confirm_password: '' };
  const {
    control,
    register,
    handleSubmit,
    setError,
    errors,
    formState,
    watch,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
  });
  const onSubmit = (formData) => {
    setIsLoading(true);
    fetchAPI(
      settingsViewModel.updateDependentPassword,
      dependent.id,
      formData.password
    )
      .then(() => {
        reset(defaultValues);
        onProfileSave();
      })
      .catch((error) => {
        console.log(error);
        displayNestedFormErrorsFromApi(error, setError, formData);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div id="username" className="mb-5 tab-width col-xl-4 col-lg-4 col-md-4 col-sm-12">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="create-children-account-header">
          <h4>Username</h4>
        </Form.Group>
        <Form.Group controlId={dependent.username}>
          <FormLabel>Kid's Tale Tree ID</FormLabel>
          <FormInput disabled value={dependent.username} />
          <Form.Label className="pl-1 create-children-account-sublabel">
            ID cannot be changed
          </Form.Label>
        </Form.Group>
        {/* <Form.Group className="create-children-account-header">
          Password
        </Form.Group> */}
        <Form.Group>
          <FormLabel>Password</FormLabel>
          <FormPasswordInput
            name="password"
            ref={register({
              required: kidSignUpErrors.password.required,
              minLength: {
                value: 5,
                message: kidSignUpErrors.password.minLength,
              },
            })}
            placeholder="New Password"
            data-testid="password"
          />
          <ErrorLabel
            errors={errors}
            name="password"
            className="pl-1 small text-danger"
            as="label"
          />
        </Form.Group>
        <Form.Group controlId="confirm_password">
          <FormLabel className="pl-1 small signup-label">
            Confirm Password
          </FormLabel>
          <FormPasswordInput
            name="confirm_password"
            ref={register({
              validate: (value) =>
                value === watch('password') ||
                kidSignUpErrors.password.mismatch,
            })}
            placeholder="Confirm Password"
            data-testid="confirm_password"
          />
          <ErrorLabel
            errors={errors}
            name="confirm_password"
            className="pl-1 small text-danger"
            as="label"
          />
        </Form.Group>
        <Form.Group className="button-wrapper">
          <LoadButton
            className="rounded-pill text-black gred-btn"
            isDisabled={!formState.isDirty || isLoading}
            isLoading={isLoading}
            text="Save Changes"
          />
        </Form.Group>
      </Form>
    </div>
  );
};

export default EditChildPassword;
