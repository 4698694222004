import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import SettingsTitle from './SettingsTitle';
import { useAuth } from '../../auth/Auth';
import FormLabel from '../../common/FormLabel';
import FormInput from '../../common/FormInput';
import ErrorLabel from '../../common/ErrorLabel';
import RoundButton from '../../common/RoundButton';
import { guardianSignUpErrors } from '../../../constants/error.constants';
import { GUARDIAN_LOGIN_ROUTE } from '../../../constants/routes.constants';
import ChangePasswordModal from './ChangePasswordModal';
import { settingsViewModel } from './settings.view.model';
import isEmail from 'validator/lib/isEmail';
import { displayFormErrorsFromApi } from '../../api/api.display.error';
import './css/guardian.account.scss';

function GuardianAccount() {
  const { guardian, fetchAPI, logout } = useAuth();
  const [showPasswordModal, setShowPassWordModal] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    setError,
    reset,
    errors,
    formState,
  } = useForm({
    mode: 'onChange',
    defaultValues: guardian,
  });
  const onEmailChange = (formData) => {
    fetchAPI(
      settingsViewModel.updateGuardianEmail,
      guardian.id,
      formData,
      formState.dirtyFields
    )
      .then(() => {
        logout(GUARDIAN_LOGIN_ROUTE);
      })
      .catch((error) => {
        displayFormErrorsFromApi(error, setError);
      });
  };
  const handleCloseShowPasswordModal = () => {
    setShowPassWordModal(false);
  };
  return (
    <div>
      <Container className="guardian-account-container">
        <ChangePasswordModal
          showPasswordModal={showPasswordModal}
          handleClose={handleCloseShowPasswordModal}
        />
        <SettingsTitle title="Account details" />
        <Row className="mt-3">
          <Col className="col-md-8 col-lg-6">
            <Form onSubmit={handleSubmit(onEmailChange)}>
              <div className="align-self-center guardian-account-property">
                <FormLabel>E-mail: {guardian.email}</FormLabel>
                <br />
                {guardian?.phone_number && <FormLabel>Phone number: +{guardian.phone_number}</FormLabel>}
              </div>
              {/* <Row className="col-12 mb-3 align-self-center">
                <FormInput
                  name="email"
                  className="email"
                  ref={register({
                    required: guardianSignUpErrors.email.required,
                    validate: (input) =>
                      isEmail(input) || guardianSignUpErrors.email.invalid,
                  })}
                  data-testid="email"
                  disabled={true}
                />
                <ErrorLabel errors={errors} name="username" />
              </Row> */}
              {/* <Row>
                <Col className="col-3" />
                <Col className="guardian-account-sublabel">
                  After changing your email you'll be prompted to sign in again
                  with your new address.
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Button
                  className="mt-3"
                  variant="outline-grey"
                  type="submit"
                  disabled={!formState.isDirty}
                >
                  Update Email
                </Button>
              </Row> */}
              {/* <Row className="col-12 align-self-center">
                <FormLabel>
                  Password<span>*</span>
                </FormLabel>
              </Row>
              <Row className="col-12 mb-3 align-self-center">
                <FormInput
                  disabled
                  type="password"
                  value="xxxxxxx"
                  name="password"
                  data-testid="password"
                />
                <ErrorLabel errors={errors} name="password" />
              </Row> */}
              <div className="mb-3">
                <RoundButton
                  type="button"
                  label="Change password"
                  onClick={() => setShowPassWordModal(true)}
                  className="mt-3 gred-btn change-password-button"
                />
              </div>
              <div className="guardian-account-sublabel fw-normal">
              After changing your password you'll be prompted to sign in again with your new password.
              </div>
            </Form>
          </Col>
        </Row>
      </Container>

    </div>
  );
}

export default GuardianAccount;
