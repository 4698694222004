import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, ProgressBar, Form } from 'react-bootstrap';
import { PARTNER_CHECK_EMAIL } from '../../../constants/routes.constants';
import { bg } from '../../../constants/component.constants';
import { partnerSignUpErrors } from '../../../constants/error.constants';
import OnboardingWrapper from './OnboardingWrapper';
import { passwordStrengthValidator } from '../../../utils/form.utils';
import { changeBodyClassName } from '../../../utils/utils';
import Select from 'react-select';
import 'react-datetime/css/react-datetime.css';
import LetGo from '../../../assets/images/lletsgo.svg';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import FormPasswordInput from '../../common/FormPasswordInput';
import FormInput from '../../common/FormInput';
import isEmail from 'validator/lib/isEmail';
import { partnerViewModel } from '../school/partner.view.model';
import { displayFormErrorsFromApi } from '../../api/api.display.error';
import LoadButton from '../../common/LoadButton';


const partnerRegistration = ({ location }) => {
  const _NOT_SELECTED = '';
  const history = useHistory();
  const [type, setType] = useState();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const { register, watch, handleSubmit, setError, errors, getValues } = useForm({
    mode: 'onBlur',
  });

  const options = [
    { value: _NOT_SELECTED, label: 'Select...' },
    { value: 'profit', label: 'Profit' },
    { value: 'non profit', label: 'Non Profit' },
  ];

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 48,
      color: '#321b6d',
      fontWeight: '400',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#321b6d',
    }),
    menu: (provided) => ({
      ...provided,
    }),
  };

  const onSubmit = handleSubmit((e) => {
    const params = getValues();
    // console.log(params)
    setIsLoading(true);
    let data = {
      email: params['email'],
      password: params['password'],
      name: params['name'],
      organizationType: params['organizationType'],
      first_name: params['first_name'],
      last_name: params['last_name'],
    }

    partnerViewModel
      .createPartner(data)
      .then((res) => {
        clearUser()
        history.replace({
          pathname: PARTNER_CHECK_EMAIL,
          state: { email: data.email, from: "signup" },
        });
      }).catch((err) => {
        // console.log(err);
        if (err.resultCode !== 40500)
          displayFormErrorsFromApi(err, setError);
        setIsDisabled(true);
        setIsLoading(false);
        setShow(false);
      });
  })

  useEffect(() => {
    changeBodyClassName([bg.GRADIENT]);
  }, []);

  return (
    <div>
      <OnboardingWrapper className="schoolRegistration step2-wrapper">
        <Row className="content-wrapper">
          <Col className="content">
            <Form onSubmit={onSubmit}>
            <div className="progress-bar-wrapper">
              <ProgressBar now={16} />
            </div>
            <div className="title">
              TaleTree Partners Sign up
            </div>
            <div className="sub title">
              Create an account to post challenges to your
              group or our entire community of kind and creative kids. If they accept your challenge you can meet with them to discover their creative genius through your own branded open studio and gallery.
            </div>

            <div className="leftsgo">
              <img src={LetGo} />
            </div>

            
              <Form.Group className="mb-3" controlId="">
                <Form.Label>Type of organisation<span>*</span></Form.Label>
                <Select
                  options={options}
                  styles={customStyles}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: '6px',
                    colors: {
                      ...theme.colors,
                      primary: '#80e4e1',
                    },
                  })}
                  onChange={(e) => setType(e.value)}
                />
                <input
                  type="hidden"
                  name="organizationType"
                  value={type}
                  ref={register({
                    required:
                      partnerSignUpErrors.organizationType.required,
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="organizationType"
                  className="small text-danger"
                  as="label"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="">
                <Form.Label>Name of organisation<span>*</span></Form.Label>
                <Form.Control
                  name='name'
                  type="text"
                  placeholder="Enter Name"
                  ref={register({
                    required: partnerSignUpErrors.organizationName.required,
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="name"
                  className="small text-danger"
                  as="label"
                />
              </Form.Group>

              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>First name<span>*</span></Form.Label>
                    <FormInput
                      className="signup-input"
                      name="first_name"
                      placeholder="First name"
                      data-testid="first_name"
                      ref={register({
                        required: partnerSignUpErrors.first_name.required,
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="first_name"
                      className="small text-danger"
                      as="label"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Last name<span>*</span></Form.Label>
                    <FormInput
                      className="signup-input"
                      name="last_name"
                      placeholder="Your surname"
                      data-testid="last_name"
                      ref={register({
                        required: partnerSignUpErrors.last_name.required,
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="last_name"
                      className="small text-danger"
                      as="label"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email <span>*</span></Form.Label>
                <FormInput
                  className="signup-input"
                  name="email"
                  placeholder="Your e-mail"
                  data-testid="email"
                  ref={register({
                    required: partnerSignUpErrors.email.required,
                    validate: (input) =>
                      isEmail(input) || partnerSignUpErrors.email.invalid,
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="email"
                  className="small text-danger"
                  as="label"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="">
                <Form.Label>Password<span>*</span></Form.Label>
                <FormPasswordInput
                  name="password"
                  placeholder="At least 8 characters"
                  data-testid="password"
                  ref={register({
                    required: partnerSignUpErrors.password.required,
                    minLength: {
                      value: 8,
                      message: partnerSignUpErrors.password.minLength,
                    },
                    validate: (value) =>
                      passwordStrengthValidator(value) ||
                      partnerSignUpErrors.password.strength,
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="password"
                  className="small text-danger"
                  as="label"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="">
                <Form.Label>Confirm Password<span>*</span></Form.Label>
                <FormPasswordInput
                  name="confirm_password"
                  placeholder="Confirm password"
                  data-testid="confirm_password"
                  ref={register({
                    required: partnerSignUpErrors.password.confirm,
                    validate: (value) =>
                      value === watch('password') ||
                      partnerSignUpErrors.password.mismatch,
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="confirm_password"
                  className="small text-danger"
                  as="label"
                />
              </Form.Group>

              <div className="terms-copy">
                By clicking on Create Account, you agree to TaleTree’s<br /> <a href="#">Terms of Service</a> and the <a href="#">Privacy Policy</a>
              </div>

              <div className="button-wrapper">
                <Form.Group className="container-fluid">
                  <LoadButton
                    className="signup-btn"
                    text="Complete account"
                    //isDisabled={isDisabled}
                    isLoading={isLoading}
                    testid="submit"
                  />
                </Form.Group>
              </div>
            </Form>
          </Col>
        </Row>
      </OnboardingWrapper>
    </div>
  );
};

export default partnerRegistration