import { fetchUtils } from '../utils/fetch.utils';
import { getAPIUrl } from '../utils/config.utils';

const BASE_URL = getAPIUrl();
const BINKIES = 'binkies';

function binkyByCreationId(creation_id) {
  return fetchUtils.post(`${BASE_URL}/${BINKIES}`, {creation_id: creation_id});
}

function deleteBinkyById(id) {
  return fetchUtils.requestDelete(`${BASE_URL}/${BINKIES}/${id}`);
}

export const binkyRepository = {
  binkyByCreationId,
  deleteBinkyById
};
