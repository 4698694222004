import React, { useState, useEffect } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroller";
import ChallengeList from "./ChallengesList";
import { treehouseViewModel } from "./treehouse.view.modal";
import { partnerViewModel } from "../school/partner.view.model";

const ChallengeBottomSection = ({ selectedExpert, partner_id, treehouse }) => {
  const [challengeList, setChallengeList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [upcomingQuery, setUpcomingQuery] = useState({
    limit: 9,
    has_more: true,
    is_expert: true,
    partner_id: treehouse?.id
  });
  const [eventQuery, setEventQuery] = useState({
    limit: 9,
    has_more: true,
  });
  const [loading, setLoading] = useState(false);
  const [eventLoading, setEventLoading] = useState(false);
  const [tabFlag, setTabFlag] = useState("current");

  useEffect(() => {
    const list = []
    const query = {
      limit: 9,
      has_more: true,
      is_expert: true,
      partner_id: treehouse?.id
    }
    setChallengeList(list);
    setUpcomingQuery(query);
    setEventList(list);
    setEventQuery(query);
    getChallenges(list, query);
    getEvents(list, query);
  }, [tabFlag, selectedExpert]);

  const getAPI = () => {
    switch (tabFlag) {
      case "upcoming":
        return partnerViewModel.getUpcomingChallenges;
      case "current":
        return partnerViewModel.getLiveChallenges;
      case "previous":
        return partnerViewModel.getPastChallenges;
      default:
        return null;
    }
  };

  const getEventAPI = () => {
    switch (tabFlag) {
      case "upcoming":
        return partnerViewModel.getUpcomingEvents;
      case "current":
        return partnerViewModel.getLiveEvents;
      case "previous":
        return partnerViewModel.getPastEvents;
      default:
        return null;
    }
  };

  const fetchAPI = async (API, params, upcomingChallengeList) => {
    setLoading(true);
    const type = treehouse?.user_type
    try {
      const res = await API(params, upcomingChallengeList, type);
      setChallengeList(res.newList);
      setUpcomingQuery(res.query);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const eventAPI = async (API, params, upcomingChallengeList) => {
    setEventLoading(true);
    const type = treehouse?.user_type
    const id = treehouse?.id
    try {
      const res = await API(params, upcomingChallengeList, type, id);
      setEventList(res.newList);
      setEventQuery(res.query);
    } catch (err) {
      console.log(err);
    }
    setEventLoading(false);
  };

  const getChallenges = (list, query) => {
    let params = { ...query };
    params.availability = tabFlag;
    if (selectedExpert && selectedExpert !== "All") {
      params.partner_id = selectedExpert[0].id;
    } else {
      // params.partner_id = partner_id;
      if(treehouse?.user_type === "partner") {
        params.is_expert = false
      }
    }
    const API = getAPI();
    if (API) {
      if(!loading) fetchAPI(API, params, list);
    } else {
      setLoading(false);
    }
  };

  const getInfiniteChallenges = () => {
    const upcomingChallengeList = challengeList;
    let params = { ...upcomingQuery };
    params.availability = tabFlag;
    if (selectedExpert && selectedExpert !== "All") {
      params.partner_id = selectedExpert[0].id;
    } else {
      // params.partner_id = partner_id;
      if(treehouse?.user_type === "partner") {
        params.is_expert = false
      }
    }
    const API = getAPI();
    if (API) {
      if(!loading) fetchAPI(API, params, upcomingChallengeList);
      getInfiniteEvents();
    } else {
      setLoading(false);
    }
  }

  const getEvents = (list, query) => {
    let params = { ...query };
    params.availability = tabFlag;
    if (selectedExpert && selectedExpert !== "All") {
      params.id = selectedExpert[0].id;
      params.user_type = 'camp master'
    } else {
      params.id = partner_id
    }
    const API = getEventAPI();
    if (API) {
      if(!eventLoading) eventAPI(API, params, list);
    } else {
      setEventLoading(false);
    }
  }

  const getInfiniteEvents = () => {
    if (eventList.length === 0) {
      const list = eventList;
      let params = { ...eventQuery };
      params.availability = tabFlag;
      if (selectedExpert && selectedExpert !== "All") {
        params.id = selectedExpert[0].id;
        params.user_type = 'camp master'
      } else {
        params.id = partner_id
      }
      const API = getEventAPI();
      if (API && !eventLoading) {
        if(!eventLoading) eventAPI(API, params, list);
      } else {
        setEventLoading(false);
      }
    }
  }

  const loader = (
    <Spinner
      key={0}
      className="myroom-spinner"
      animation="border"
      variant="info"
    />
  );

  return (
    <>
      <Col className="myroom-creation-title">
        <div
          className={`tab tab-left pt-4 pb-2 ${tabFlag === "upcoming" ? "selected" : "unselected"
            }`}
          onClick={() => setTabFlag("upcoming")}
        >
          <div className='public-page-tabs'>Upcoming</div>
        </div>
        <div
          className={`tab tab-left pt-4 pb-2 ${tabFlag === "current" ? "selected" : "unselected"
            }`}
          onClick={() => setTabFlag("current")}
        >
          <div className='public-page-tabs'>Live</div>
        </div>
        <div
          className={`tab tab-right pt-4 pb-2 ${tabFlag === "previous" ? "selected" : "unselected"
            }`}
          onClick={() => setTabFlag("previous")}
        >
          <div className='public-page-tabs'>Past </div>
        </div>
      </Col>
      <Row className="myroom-challenge-container">
        <InfiniteScroll
          pageStart={0}
          loadMore={getInfiniteChallenges}
          hasMore={upcomingQuery.has_more}
          initialLoad={false}
          loader={loader}
          threshold={150}
        >
          <ChallengeList
            challengeList={challengeList}
            eventList={eventList}
            setEventList={setEventList}
            tabFlag={tabFlag}
            selectedExpert={selectedExpert}
            loading={loading}
          />
        </InfiniteScroll>
      </Row>
    </>
  );
};

export default ChallengeBottomSection;
