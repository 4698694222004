import React from 'react';
import { Row, Col } from 'react-bootstrap';
import imageLetsgo from '../../../assets/characters/lets-go-draw-93x140.svg';
import './css/no.creation.scss';

function NoCreation({ children }) {
  return (
    <div className="no-creation-container">
      <Row className="justify-content-md-center ">
        <Col xs={12} md={12} className="justify-content-md-center text-center">
          <img src={imageLetsgo} />
          <br />
          <div className="text">{children}</div>
        </Col>
      </Row>
    </div>
  );
}

export default NoCreation;
