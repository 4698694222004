import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import DashboardHeader from '../../header/DashboardHeader';
import { Row, Container } from 'react-bootstrap';
import SettingsNavHeader from '../dashboard/SettingsNavHeader';
import { useAuth } from '../../auth/Auth';
import RoundButton from '../../common/RoundButton';

import './scss/purchase.complete.scss';
import { GUARDIAN_SETTINGS_ROUTE } from '../../../constants/routes.constants';

const PurchaseComplete = ({ location }) => {
  const { guardian } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (!location.state)
      history.replace({ pathname: `${GUARDIAN_SETTINGS_ROUTE}` });
  }, []);

  return (
    <div className="purchase-special-camp-wrapper">
      <DashboardHeader />
      <Container className="setting-container">
        <SettingsNavHeader
          showLink={false}
          title="Thank you for purchasing our Special Camp"
        />
        <Row className="message">
          <p>
            A confirmation email has been sent to{' '}
            <a href={`mailto:${guardian.email}`}>{guardian.email}</a> with a
            Zoom link. Looking forward to having your kid at the Special Camp.
          </p>
          <p>
            Please reach us at{' '}
            <a href="mailto:contact@taletree.app">contact@taletree.app</a> for
            any questions.
          </p>
        </Row>
        <Row className="mt-2 complete-image">&nbsp;</Row>
        <Row className="mt-3 button-wrapper">
          <RoundButton
            type="button"
            onClick={() =>
              history.replace({
                pathname: `${GUARDIAN_SETTINGS_ROUTE}`,
              })
            }
            label="Go back to Guardian Dashboard"
            className="gred-btn"
          />
        </Row>
      </Container>
    </div>
  );
};

export default PurchaseComplete;
