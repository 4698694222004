import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import Lottie from "react-lottie-player";
import Typed from 'typed.js';
import FormLabel from "../../../common/FormLabel";
import { useAuth } from "../../../auth/Auth";
import { changeTextToVoice, getSoundWaveJson } from "../../../../utils/camp.utils";
import Cartoon from "../../../../assets/images/cartoon.svg";
import Boy from "../../../../assets/images/boy-i-normal.svg";
import Girl from "../../../../assets/images/girl-i-normal.svg";
import BoyActive from "../../../../assets/images/active-boy-i.svg";
import GirlActive from "../../../../assets/images/active-i-girl.svg";
import notVerified from '../../../../assets/icon/Unverified.svg';
import goldTick from '../../../../assets/icon/gold_tick.svg';
import silverTick from '../../../../assets/icon/silver_tick.svg';
import verfiedIcon from '../../../../assets/icon/Verified-badge.svg';
import SignupModal from "./SignupModal";
import { accountViewModel } from "../../account/account.view.model";
import Dad from "../../../../assets/images/dad-i-normal.svg";
import Mom from "../../../../assets/images/mom-i-normal.svg";
import DadActive from "../../../../assets/images/dad-i-active.svg";
import MomActive from "../../../../assets/images/mom-i-active.svg";
import Guardian from "../../../../assets/images/gurdian-i-normal.svg";
import { customEvents } from "../../../../analytics/customEvents";
import ChildRegistration from "./ChildRegistration";

export default function RegisterFirstChild(props) {
  const treehouse = JSON.parse(localStorage.getItem('partner'));
  const _NOT_SELECTED = '';
  const [relationship, setRelationship] = useState(_NOT_SELECTED);
  const { showModal, setShowModal, handleComplete } = props;
  const [showChildUserNameModal, setShowChildUserNameModal] = useState(false)
  const [isPaused, setIsPaused] = useState(false);
  const { refreshUserState } = useAuth();
  const lsGender = JSON.parse(localStorage.getItem('gender'));
  const lsName = localStorage.getItem('firstName');
  const lsBirthdate = localStorage.getItem('birthdate');
  const [original, setOriginal] = useState('');
  const isFromDashboard = location.state?.from === 'dashboard';
  const [kidsName, setKidsName] = useState(lsName !== null ? lsName : '');
  const _BUBBLE_TEXT1 = `Hey, I'm Uni. I don't speak but you can read my thoughts...`;
  const _BUBBLE_TEXT2 = `Wonderful a `;
  const _BUBBLE_TEXT3 = `! what a great name. I love it.`;
  const _BUBBLE_TEXT4 = `What a great birthday, I have the same age that’s funny.`;
  const [text, setText] = useState('');
  const [isPlay, setIsPlay] = useState(false);
  const [gender, setGender] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [childData, setChildData] = useState({});
  const typedRef = useRef(null);

  const maxDate = useMemo(() => {
    let maxYearDate = new Date();
    maxYearDate.setFullYear(maxYearDate.getFullYear() - 5);
    return maxYearDate;
  });

  const [myMonth, setMyMonth] = useState(null);
  const [myYear, setMyYear] = useState(null);
  const [myDay, setMyDay] = useState(null);

  const minDate = myMonth && myYear ? new Date(myYear?.getFullYear(), myMonth?.getMonth(), 1) : new Date()

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (myYear && myMonth && myDay) {
      const myDayDate = myDay && myDay.getDate() ? myDay.getDate() : new Date().getDate();
      setMyDay(new Date(myYear.getFullYear(), myMonth.getMonth(), myDayDate));
    }
  }, [myMonth, myYear, setMyDay]);

  const renderDayContents = (day, date) => {
    return <span>{date.getDate()}</span>;
  };

  const selectedMonth = myMonth && (myMonth?.getUTCMonth() + 1) < 9 ? `0${myMonth?.getUTCMonth() + 1}` : myMonth && myMonth?.getUTCMonth() + 1
  localStorage.setItem('birthdate', `${myYear?.getFullYear()}-${selectedMonth}-${myDay?.toString().substring(8, 10)}`);


  const [birthdate, setBirthdate] = useState(
    // lsBirthdate === null ? '' : moment(new Date(lsBirthdate), 'YYYY/M/D') || ''
    lsBirthdate === null ? '' : lsBirthdate
  );
  const [selectedOption, setSelectedOption] = useState(
    // lsGender !== null ? lsGender : { value: _NOT_SELECTED, label: 'Select...' }
    lsGender !== null ? lsGender : ''
  );
  const options = [
    // { value: _NOT_SELECTED, label: 'Select...' },
    { value: 'male', label: "Boy's" },
    { value: 'female', label: "Girl's" },
  ];
  const customStyles = {
    control: (base) => ({
      ...base,
      width: 184,
      height: 48,
      color: '#321b6d',
      fontWeight: '400',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#321b6d',
    }),
    placeholder: (provided) => ({
      ...provided,
      fontWeight: '400',
      color: 'rgb(204, 204, 204)',
    }),
    menu: (provided, state) => ({
      ...provided,
      width: 184,
    }),
  };

  const manufactureParams = () => {
    const lsFirstName = localStorage.getItem('firstName');
    const lsBirthDate = localStorage.getItem('birthdate');
    const lsGender = JSON.parse(localStorage.getItem('gender')).value;
    //const lsGender = 'male';
    // const lsCountry = JSON.parse(localStorage.getItem('country')).value;
    const lsCountry = 'Andorra';
    // const lsCity = JSON.parse(localStorage.getItem('city')).value;
    const lsCity = 'Canillo';
    // const lsSchool = JSON.parse(localStorage.getItem('school')).value;
    const lsSchool = 'safd';

    const params = {
      first_name: lsFirstName,
      last_name: '',
      //last_name: 'lkjlkjlo',
      signup: 'camp',
      profile: {
        favorites: ['', '', ''],
        taletree_friend: '',
        gender: lsGender,
        birthdate: lsBirthDate.replace(/\//g, '-'),
        //birthdate: "2022-09-15",
        lives_in: lsCountry,
        nearest_city: lsCity,
        school_name: lsSchool,
      },
      // questions: questions,
      questions: [],
      relationship_to_kid: relationship
    };
    return params;
  };

  const handleNextClick = () => {
    let analyticsData = {
      category: "Sign up",
      action: "final step",
      label: 'final step'
    }
    customEvents(analyticsData);
    setIsLoading(true)
    if (!kidsName) {
      setErrors({ ...errors, kidsName: "Please enter your child's name." });
      setIsLoading(false)
      return false;
    }
    else {
      setErrors({ ...errors, kidsName: "" });
    }

    if (!myDay) {
      setErrors({ ...errors, dob: "Please enter your child's birth date." });
      setIsLoading(false)
      return false;
    } else {
      setErrors({ ...errors, dob: "" });
    }

    if (!myMonth) {
      setErrors({ ...errors, dob: "Please enter your child's birth month." });
      setIsLoading(false)
      return false;
    } else {
      setErrors({ ...errors, dob: "" });
    }

    if (!myYear) {
      setErrors({ ...errors, dob: "Please enter your child's birth year." });
      setIsLoading(false)
      return false;
    } else {
      setErrors({ ...errors, dob: "" });
    }

    if (myDay > maxDate) {
      setErrors({ ...errors, dob: "Less then 5 year is not allowed." });
      setIsLoading(false)
      return false;
    }
    else {
      setErrors({ ...errors, dob: "" });
    }
    const lsBirthDate = localStorage.getItem('birthdate');
    let data = {
      category: "Sign up",
      action: "when was your child born",
      label: lsBirthDate.replace(/\//g, '-'),
    }
    customEvents(data);
    const params = manufactureParams();
    setChildData(params)
    setShowModal(false);
    setShowChildUserNameModal(true);

    // accountViewModel
    //   .addKid(params)
    //   .then((res) => {
    //     refreshUserState();
    //     setShowModal(false)
    //     setIsLoading(false)
    //   })
    //   .catch((err) => {
    //     alert(err.message);
    //     setIsLoading(false)
    //   })
  };

  useEffect(() => {
    if (location.state && location.state.from === 'dashboard') {
      setOriginal(location.state.from);
      return;
    }
    setText(_BUBBLE_TEXT1)
    const birthdayInput = document.getElementsByClassName('form-control')[0];
  }, []);

  const handleGenderChange = (selection) => {
    let data = {
      category: "Sign up",
      action: selection === 'male' ? "boy" : 'girl',
      label: selection
    }
    customEvents(data);
    setSelectedOption(selection);
    setGender(selection)
    localStorage.setItem('gender', JSON.stringify(selection));
  };

  const inputProps = {
    placeholder: 'MM/DD/YYYY',
  };

  const handleNameChange = (e) => {
    const name = e.target.value;
    let data = {
      category: "Sign up",
      action: "name",
      label: name
    }
    customEvents(data);
    setKidsName(name);
    localStorage.setItem('firstName', name);
    if (gender === 'male') {
      setText(_BUBBLE_TEXT2 + 'boy!');
    } else {
      setText(_BUBBLE_TEXT2 + 'girl!');
    }
  };

  useEffect(() => {
    if (text && typedRef.current !== null) {
      const typed = new Typed(typedRef.current, {
        strings: [text],
        typeSpeed: 50,
      });

      return () => {
        // Destroy Typed instance during cleanup to stop animation
        typed.destroy();
      };
    }
  }, [text])

  //To disable Raw Input
  const handleDateChangeRaw = (e) => {
    e.preventDefault()
  }

  const handleVoiceClick = () => {
    setIsPlay(!isPlay)
    const element = document.getElementById('voice-text')
    changeTextToVoice(element.textContent, isPlay, setIsPlay, isPaused, setIsPaused);
  }

  const handleClose = () => {
    props.setSignupProcess(false);
    setShowModal(false)
  }

  const handleRelationshipChange = (selection) => {
    let analyticsData = {
      category: "Sign up",
      action: selection === 'Dad' ? "dad" : selection === 'Mom' ? 'mom' : 'legal guardian',
      label: selection === 'Dad' ? "dad" : selection === 'Mom' ? 'mom' : 'legal guardian'
    }
    customEvents(analyticsData);
    setRelationship(selection);
  };

  const CustomInputYear = forwardRef(({ value, onClick }, ref) => (
    <button className="custom-input date-picker-custom-btn" onClick={onClick} ref={ref}>
      {value || 'Select Year'}
    </button>
  ));

  const CustomInputMonth = forwardRef(({ value, onClick }, ref) => (
    <button className="custom-input date-picker-custom-btn" onClick={onClick} ref={ref}>
      {value || 'Select Month'}
    </button>
  ));

  const CustomInputDate = forwardRef(({ value, onClick }, ref) => (
    <button className="custom-input date-picker-custom-btn" onClick={onClick} ref={ref}>
      {value || 'Select Date'}
    </button>
  ));

  return (
    <>
      <SignupModal className='auth-popup right-curved-div close-position' showModal={showModal} handleClose={handleClose}>
        <div className='row bg-blue'>
          <div className="col-md-6 col-lg-5 p-4 bg-blue border-left overflow-hidden">
            <div className="voice-buble buuble-bounce" id="SpeechBubble">
              <p id='voice-text'>
                <span ref={typedRef}>
                  {text}
                </span>
              </p>
            </div>
            <div className="text-left">
              {/* <p className="text-white voice-btn" onClick={handleVoiceClick}><BsVolumeUpFill /> Voice on</p> */}
              <div className="" style={{marginTop: '9rem'}}>
                {/* <Lottie
                  loop
                  animationData={getSoundWaveJson()}
                  play={isPlay}
                  resizemode="cover"
                  className='animate-svg'
                /> */}
              </div>
            </div>
            <div className="cartoon-img">
              <img className="w-100" src={Cartoon} />
              <div className="verify-symbol-div" >
                {treehouse && treehouse.id &&
                  <span className="verify-symbol-span">
                    <img className="verify-main-icon" src={treehouse.picture_url} />
                    {treehouse ?
                      treehouse.user_type === "camp master" ?
                        <img className="verify-symbol-icon" src={verfiedIcon} />
                        : treehouse.type_of_organisation === "non profit" ?
                          <img className="verify-symbol-icon" src={silverTick} />
                          : <img className="verify-symbol-icon" src={goldTick} />
                      : <img className="verify-symbol-icon" src={notVerified} />
                    }
                  </span>
                }
              </div>

            </div>
          </div>
          <div className="col-md-6 col-lg-7 bg-white toparrow border-right right-curve topinmius right-bottom-curve auth-section white-right-arrow">
            <p className="auth-entry-text">Enroll your child...</p>
            <p className="">At TaleTree, we believe that every child is a creative genius and needs freedom to find themselves and express their creativity however they want to.</p>
            <div>
              <Form.Row className="auth-form">
                <div className="form-group">

                  <p>Relationship to child<span className="text-danger">*</span></p>
                  <div className="flex gender-col">
                    <div className={`gender-img dad ${relationship === 'Dad' ? ' active' : ''}`} id='dad' onClick={() => handleRelationshipChange('Dad')}>
                      <img className="normal-img" src={Dad} />
                      <img className="active-img" src={DadActive} />
                      <h3 className="gender-title">Dad</h3>
                    </div>
                    <div className={`gender-img mom ${relationship === 'Mom' ? ' active' : ''}`} id='mom' onClick={() => handleRelationshipChange('Mom')}>
                      <img className="normal-img" src={Mom} />
                      <img className="active-img" src={MomActive} />
                      <h3 className="gender-title">Mom</h3>
                    </div>
                    <div className={`gender-img gurdian ${relationship === 'Legal guardian' ? ' active' : ''}`} id='gurdian' onClick={() => handleRelationshipChange('Legal guardian')}>
                      <img className="normal-img" src={Guardian} />
                      <img className="active-img" src={Guardian} />
                      <h3 className="gender-title">Legal guardian</h3>
                    </div>
                  </div>

                </div>
                {relationship &&
                  <div>
                    <p>My Little</p>
                    <div className="flex gender-col">
                      <div className={`gender-img boy ${selectedOption === 'male' ? 'active' : ''}`} onClick={() => handleGenderChange('male')} id='boy'>
                        <img className="normal-img" src={Boy} />
                        <img className="active-img" src={BoyActive} />
                        <h3 className="gender-title">Boy's</h3>
                      </div>
                      <div className={`gender-img girl ${selectedOption === 'female' ? 'active' : ''}`} onClick={() => handleGenderChange('female')} id='girl'>
                        <img className="normal-img" src={Girl} />
                        <img className="active-img" src={GirlActive} />
                        <h3 className="gender-title">Girl's</h3>
                      </div>
                    </div>
                    <p className="help-div">*You can add additional children later</p>
                  </div>
                }
                {selectedOption && relationship &&
                  <>
                    <Form.Group className="container-fluid">
                      <FormLabel>
                        Name is
                      </FormLabel>
                      <input
                        name="Name"
                        value={kidsName}
                        onChange={handleNameChange}
                        className="input-modal"
                      />
                      <Row>
                        <Col><label className="small text-danger">{errors.kidsName}</label></Col>
                      </Row>
                    </Form.Group>
                    {kidsName &&
                      <>
                        <div className="input-wrapper custom-date cd-modal child-register">
                          <p className='label'>  When was your child born?</p>
                          <Row>
                            <Col className="left">
                              <DatePicker
                                showMonthYearPicker
                                dateFormat="MMM"
                                renderCustomHeader={({ date }) => <div></div>}
                                selected={myMonth}
                                className="name-field"
                                onChange={(date) => {
                                  setMyMonth(date)
                                  setText(kidsName + _BUBBLE_TEXT3)
                                }}
                                onChangeRaw={handleDateChangeRaw}
                                customInput={<CustomInputMonth />}
                              />
                              <h6>
                                Month
                              </h6>

                            </Col>
                            <Col className="center">
                              <DatePicker
                                dateFormat="dd"
                                renderCustomHeader={({ date }) => <div></div>}
                                selected={myDay}
                                className="name-field"
                                popperPlacement="top-center"
                                renderDayContents={renderDayContents}
                                onChange={(date) => setMyDay(date)}
                                onChangeRaw={handleDateChangeRaw}
                                customInput={<CustomInputDate />}
                              /> <h6>
                                Day
                              </h6>
                            </Col>
                            <Col className="end">
                              <DatePicker
                                selected={myYear}
                                className="name-field year-width"
                                popperPlacement="top-end"
                                onChange={(date) => {
                                  setMyYear(date)
                                  setText(_BUBBLE_TEXT4)
                                }}
                                showYearPicker
                                dateFormat="yyyy"
                                maxDate={maxDate}
                                onChangeRaw={handleDateChangeRaw}
                                customInput={<CustomInputYear />}
                              />
                              <h6>
                                Year
                              </h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col><label className="small text-danger">{errors.dob}</label></Col>
                          </Row>
                        </div>

                        <div className="text-center submit-btn" >
                          <Button
                            className="gred-btn"
                            onClick={handleNextClick}
                          >
                            <Spinner
                              as="span"
                              className={`${isLoading ? 'spin' : 'd-none'}`}
                              animation="border"
                              size='sm'
                              role="status"
                              aria-hidden="true"
                            />
                            {!isLoading && 'Final Step'}
                          </Button>
                        </div>
                      </>
                    }
                  </>
                }
              </Form.Row>
            </div>
          </div>
        </div>
      </SignupModal>
      <ChildRegistration showModal={showChildUserNameModal} setShowModal={setShowChildUserNameModal} childData={childData} />
    </>
  )
}