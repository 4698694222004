import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { GUARDIAN_SETTINGS_ROUTE } from '../../../constants/routes.constants';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import backIcon from '../../../assets/icon/back-icon.svg';

import './css/settings.nav.header.scss';

const SettingsNavHeader = ({ title, showLink = true, gobackHandler }) => {
  const history = useHistory();
  const handleClick = () => {
    if (gobackHandler) {
      gobackHandler(false);
    } else {
      // console.log(gobackHandler ? 'true' : 'false');
      history.goBack();
      history.replace({
        pathname: GUARDIAN_SETTINGS_ROUTE,
        state: { refreshState: true },
      });
    }
  };
  return (
    <Container className="mt-5 settings-header-container" fluid="md">
      <Row className="align-items-center contents-wrapper">
        <Col className="col-1 go-back-column">
          <Button
            variant="transparent"
            className={`shadow-none go-back-to-dashboard ${
              showLink ? 'show' : 'hide'
            }`}
            onClick={handleClick}
          >
            <img src={backIcon} alt="go back to dashboard" />
          </Button>
        </Col>
        <Col
          className={`settings-nav-header-title ${
            showLink ? 'col-10' : 'col-12'
          } text-center`}
        >
          {title}
        </Col>
      </Row>
    </Container>
  );
};

export default SettingsNavHeader;
