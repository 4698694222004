import React, { useEffect, useState } from 'react';
import SignupStep7 from './SignupStep7';
import { useAuth } from '../../../auth/Auth';
import SignupStep6 from './SignupStep6';
import CampMemberModal from './CampMemberModal';

export default function ChildRegistration({ showModal, setShowModal, childData }) {
    const [showModal8, setShowModal8] = useState(false);
    const [showStep6, setShowStep6] = useState(false);
    const [showCampMemberModal, setShowCampMemberModal] = useState(false);
    const [campDetails, setCampDetails] = useState({})
    const [newChild, setNewChild] = useState({})
    const { guardian } = useAuth();

    useEffect(() => {
        if (guardian && !guardian.is_verified) {
            setShowModal(false);
            setShowStep6(true);
        }
    }, [])

    return (
        <>
            {showModal && <SignupStep7 showModal={showModal} setShowModal={setShowModal} childData={childData} setCampDetails={setCampDetails} setShowCampMemberModal={setShowCampMemberModal} setNewChild={setNewChild} />}
            <SignupStep6 showModal={showStep6} setShowModal={setShowStep6} />
            {showCampMemberModal && <CampMemberModal showModal={showCampMemberModal} childData={childData} newChild={newChild} campDetails={campDetails} />}
        </>
    )
}