import React, { useState, useRef, useEffect } from 'react';
import { Modal, Image, Spinner, Form } from 'react-bootstrap';
import customizeBannerUpload from '../../../assets/images/upload-ic.svg';
import customizeBannerDelete from '../../../assets/icon/icon-close.svg';
import { imageUtils } from '../../../utils/image.utils';
import EventEmitter from '../../../utils/events';
import { useAuth } from '../../auth/Auth';
import closeIcon from '../../../assets/icon/icon-darker-close.svg';
import profileImg from '../../../assets/profile/default-profile-guardian.png';
import svg from '../../../assets/images/Avatar.svg';
import { useForm } from 'react-hook-form';
import RoundButton from '../../common/RoundButton';
import { schoolViewModel } from '../school/school.view.model';
import { partnerViewModel } from '../school/partner.view.model';
import greyBanner from '../../../assets/images/grey-banner.png';
import ImageCropModal from '../../common/image-crop-rotate/ImageCropModal';
import Blue_banner from '../../../assets/images/Top_banner.png';

function PersonalizeProfileModal({ customizePopup, setCustomizePopup, isEdit }) {
  const { fetchAPI, setUser, school, partner } = useAuth();
  const user = partner;
  const defaultBannerUrl = user && user.banner_url ? user.banner_url : greyBanner;
  const defaultProfileUrl = user && user.picture_url ? user.picture_url : profileImg;
  const [showSpinner, setShowSpinner] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [isImageEdit, setIsImageEdit] = useState(false);
  const [profilePicture, setProfilePicture] = useState(defaultProfileUrl);
  const [bannerPicture, setBannerPicture] = useState(defaultBannerUrl);
  const [profilePicBinary, setProfilePicBinary] = useState();
  const [bannerPicBinary, setBannerPicBinary] = useState();
  const [description, setDescription] = useState(partner.description);
  const [duplicateError, setDuplicateError] = useState();
  const [imageModule, setImageModule] = useState('');
  const [imageCropPopup, setImageCropPopup] = useState(false);
  const [imageForCrop, setImageForCrop] = useState(null);
  const userImage = useRef();
  const bannerImage = useRef();
  const { register, handleSubmit, setError, errors, getValues } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    setProfilePicture(defaultProfileUrl);
  }, [defaultProfileUrl]);

  useEffect(() => {
    setBannerPicture(defaultBannerUrl);
  }, [defaultBannerUrl])

  useEffect(() => {
    setProfilePicture(defaultProfileUrl);
  }, [defaultProfileUrl]);

  useEffect(() => {
    setBannerPicture(defaultBannerUrl);
  }, [defaultBannerUrl])

  const closeModal = () => {
    setCustomizePopup(false)
    setDescription(user.description)
    setDuplicateError(null)
    setBannerPicture(defaultBannerUrl)
    setProfilePicture(defaultProfileUrl)
    setProfilePicBinary(null);
    setBannerPicBinary(null);
    EventEmitter.dispatch('partner_profile_pic',
      {
        preview: user?.picture_url ? user?.picture_url :
          svg
      })
    EventEmitter.dispatch('partner_banner_pic',
      { preview: user?.banner_url ? user?.banner_url : Blue_banner })
  }

  const uploadProfilePic = () => {
    setShowSpinner(true);
    let API = partner?.user_type === "camp master" ? partnerViewModel.updateCampMasterProfilePic : partnerViewModel.updatePartnerProfilePic
    let image = profilePicBinary
    if (profilePicBinary) {
      fetchAPI(
        API,
        user.id,
        image,
        user
      )
        .then((response) => {
          setUser(response)
          setShowSpinner(false);
          setProfilePicBinary(null);
        })
        .catch((error) => {
        })
        .finally((response) => {
          setShowSpinner(false);
        });
    }
  }

  const uploadBannerPic = () => {
    setShowSpinner(true);
    let API = partner?.user_type === "camp master" ? partnerViewModel.updateCampMasterBannerPic : partnerViewModel.updatePartnerBannerPic
    let image = bannerPicBinary
    if (bannerPicBinary) {
      fetchAPI(
        API,
        user.id,
        image,
        user
      )
        .then((response) => {
          setUser(response)
          setShowSpinner(false);
          setBannerPicBinary(null);
        })
        .catch((error) => {
        })
        .finally(() => {
          setShowSpinner(false);
        });
    }
  }

  const handleProfileImageSelection = (e) => {
    const files = e.target.files;
    setIsImageEdit(true)
    if (files.length) {
      setImageForCrop(files[0])
      setImageModule('profile')
      setImageCropPopup(true)
    }
  };

  const handleBannerImageSelection = (e) => {
    const files = e.target.files;
    setIsImageEdit(true)
    if (files.length) {
      setImageForCrop(files[0])
      setImageModule('banner')
      setImageCropPopup(true)
    }
  };

  const onCropComplete = (croppedImage) => {
    const imagePromise = imageUtils.getImagePromise(croppedImage);
    imagePromise
      .then((image) => {
        if (imageModule === 'banner') {
          setBannerPicture(image.preview);
          EventEmitter.dispatch('partner_banner_pic', { preview: image.preview })
          setBannerPicBinary(image)
        } else if (imageModule === 'profile') {
          setProfilePicture(image.preview);
          EventEmitter.dispatch('partner_profile_pic', { preview: image.preview })
          setProfilePicBinary(image);
        }
        setImageForCrop(null);
        setImageModule('');
        setShowSpinner(false);
      });
  }

  const updateUserDescription = () => {
    let body = {
      description: description,
    }
    let API = partner?.user_type === "camp master" ? partnerViewModel.updateCampMasterDescription : partnerViewModel.updatePartnerDescription
    API(user.id, body).then((res) => {
      setUser(res);
      setCustomizePopup(false);
      setDuplicateError(null);
      setIsDisable(false);
      setShowSpinner(false);
      setIsImageEdit(false);
    }).catch((err) => {
      console.log(err)
      setIsDisable(false);
      setShowSpinner(false);
      if (err.serverResponse.result_code === 400000) {
        if (err.serverResponse.error_info[0] === "value too long for type character varying(500)\n") {
          setDuplicateError("Content is too long")
        } else {
          setDuplicateError("Hash tag is already taken")
        }
      }
    })
  }

  const onSubmit = handleSubmit(async (data) => {
    await Promise.all([
      setIsDisable(true),
      setShowSpinner(true),
      updateUserDescription(),
      uploadBannerPic(),
      uploadProfilePic(),
      // const params = getValues()
    ])
  })

  const handleDeleteBanner = () => {
    setIsImageEdit(true);
    let API = user.user_type === "school" ? schoolViewModel.deleteSchoolBanner : partnerViewModel.deletePartnerBanner
    API(user.id).then((res) => {
      setBannerPicture("")
      EventEmitter.dispatch('partner_banner_pic', { preview: "" })
    })
  }

  const handleBioChange = (e) => {
    setDuplicateError(null)
    setDescription(e.target.value)
  }

  return (
    imageCropPopup && imageForCrop ?
      <ImageCropModal
        imageCropPopup={imageCropPopup}
        setImageCropPopup={setImageCropPopup}
        image={imageForCrop}
        setPicture={onCropComplete}
        heading={`Edit your ${imageModule} image`}
      />
      :
      <Modal show={customizePopup} onHide={closeModal} className="account-varification lets-customize partner-side">
        <Modal.Header>
          <Modal.Title>
            {isEdit ?
              <h4 className='mt-20'>Edit profile</h4>
              :
              <h4 className='mt-20'>Personalize your Profile</h4>
            }
            <div className="close-button" data-dismiss="modal" onClick={closeModal}>
              <img src={closeIcon} alt="Close" />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='customize-banner'>
            <Image src={bannerPicture} alt="" />
            <span className='customize-banner-delete' onClick={handleDeleteBanner}><Image src={customizeBannerDelete} alt="" /></span>
            <span className='customize-banner-upload' onClick={() => bannerImage.current.click()}><Image src={customizeBannerUpload} alt="" /></span>
            <input
              className="d-none"
              accept="image/png, image/jpeg, image/jpg, .heic, .HEIC"
              id='banner_picture'
              name='banner_picture'
              type="file"
              ref={bannerImage}
              onChange={(e) => handleBannerImageSelection(e)}
            />
          </div>
          <div className='modal-content-inner'>
            <div className='customize-user-img'>
              <Image src={profilePicture} alt="" />
              <span className='customize-banner-upload' onClick={() => userImage.current.click()}><Image src={customizeBannerUpload} alt="" /></span>
              <input
                className="d-none"
                accept="image/png, image/jpeg, image/jpg, .heic, .HEIC"
                id='profile_picture'
                name='profile_picture'
                type="file"
                ref={userImage}
                onChange={(e) => handleProfileImageSelection(e)}
              />
            </div>
            <Form className='partner_personalize' onSubmit={onSubmit}>
              <Form.Row>
                <Form.Group className="container-fluid">
                  <div className="label-container">
                    <Form.Label>Edit your bio</Form.Label>
                  </div>
                  <Form.Control
                    name="description"
                    as="textarea"
                    rows="3"
                    value={description}
                    onChange={handleBioChange}
                  />
                  {duplicateError ? (
                    <p style={{ color: 'red' }} className='pt-2'>
                      {duplicateError}
                    </p>
                  ) : ''}
                </Form.Group>
              </Form.Row>
              <div className='text-center mt-20'>
                <Spinner
                  className={`spinner ${showSpinner ? '' : 'd-none'}`}
                  animation="border"
                  variant="info"
                />
                {user.user_type === "school" ?
                  <p className='text-mute'>All creations will be able to be discovered publically via this #tag</p>
                  : ""}
                {isEdit ?
                  <div className='partner-modal-button'>
                    <RoundButton
                      type="button"
                      label="Cancel"
                      variant="outline-secondary"
                      className="font-weight-semibold"
                      onClick={closeModal}
                    />
                    <RoundButton
                      type="submit"
                      label="Save changes"
                      variant="success"
                      disabled={!description || isDisable || description === partner.description && !isImageEdit}
                      className="font-weight-semibold gred-btn"
                    />
                  </div>
                  :
                  <button type='submit' disabled={!description} className='gred-btn load-button-container signup-btn rounded-pill mb-4 font-weight-bold btn btn-primary'>
                    {user.user_type === "partner" ? "Finish" : isEdit ? "Save" : "Next"}
                  </button>
                }
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
  )
}
export default PersonalizeProfileModal;