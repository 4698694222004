import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { BsCheckLg } from "react-icons/bs";
import Carousal1 from "../../../../assets/images/space.svg";
import Carousal2 from "../../../../assets/images/emerald-forest.svg";
import Carousal3 from "../../../../assets/images/love.svg";
import { bg, theme } from "../../../../constants/component.constants";
import { addMyRoomClassName } from "../../../../utils/utils";
import SpaceBanner from '../../../../assets/images/Banner-Space.png';
import ForestBanner from '../../../../assets/images/Banner-Forest.png';
import LoveBanner from '../../../../assets/images/Banner-Love.png';
import EventEmitter from "../../../../utils/events";
import defaultBannerImg from '../../../../assets/images/Top_banner.png';

const modalCarousal = ({
  isFullScreen,
  setBgImg,
  setBannerPicture,
  setBannerPicForm,
  setRoomImageStyle,
  setSelectedTabClass,
  selectedThemeId,
  setSelectedThemeId,
  setUnselectedTabClass,
  setIsDisable,
  setNameTextColor,
  setInterestTickColor
}) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 464 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };
  const sliderImageUrl = [
    //First image url
    {
      url: Carousal1,
      Content: "Space",
      id: "space"
    },
    //Second image url
    {
      url: Carousal2,
      Content: "Emerald Forest",
      id: "forest"
    },

    {
      url: Carousal3,
      Content: "Love",
      id: "love"
    },
  ];

  const handleBannerEvent = (image) => {
    EventEmitter.dispatch('banner_pic', { preview: image })
  }

  const handleClick = (id) => {
    let tempId = selectedThemeId;
    if (id) {
      tempId = id
      setSelectedThemeId(id);
    }
    let className;
    let body = document.body;
    if (isFullScreen) {
      if (tempId === 'space') {
        className = theme.SPACE
        setBannerPicture(SpaceBanner);
        setBgImg('');
        body.removeAttribute('style')
        setSelectedTabClass('selected-light')
        setUnselectedTabClass('unselected-light')
        handleBannerEvent('')
        setNameTextColor('text-light')
        setInterestTickColor('interest-tickbtn-outline-dark')
        EventEmitter.dispatch('blue_banner', { preview: SpaceBanner })
      }
      if (tempId === 'forest') {
        className = theme.FOREST
        setBannerPicture(ForestBanner);
        setBgImg('');
        body.removeAttribute('style')
        setSelectedTabClass('selected-dark')
        setUnselectedTabClass('unselected')
        handleBannerEvent('')
        setNameTextColor('text-dark')
        setInterestTickColor('interest-tickbtn-outline-light')
        EventEmitter.dispatch('blue_banner', { preview: ForestBanner })
      }
      if (tempId === 'love') {
        className = theme.LOVE
        setBannerPicture(LoveBanner);
        setBgImg('');
        body.removeAttribute('style')
        setSelectedTabClass('selected-dark')
        setUnselectedTabClass('unselected')
        handleBannerEvent('')
        setNameTextColor('text-dark')
        setInterestTickColor('interest-tickbtn-outline-light')
        EventEmitter.dispatch('blue_banner', { preview: LoveBanner })
      }
      addMyRoomClassName([className]);
      setRoomImageStyle({
        background: 'center'
      })
    } else {
      if (tempId === 'space') {
        setBgImg(SpaceBanner);
        setBannerPicture(SpaceBanner);
        setBannerPicForm(SpaceBanner);
        setRoomImageStyle({
          backgroundImage: `url${SpaceBanner}`
        })
        handleBannerEvent(SpaceBanner)
      }
      if (tempId === 'forest') {
        setBgImg(ForestBanner);
        setBannerPicture(ForestBanner);
        setBannerPicForm(ForestBanner);
        setRoomImageStyle({
          backgroundImage: `url${ForestBanner}`
        })
        handleBannerEvent(ForestBanner)
      }
      if (tempId === 'love') {
        setBgImg(LoveBanner);
        setBannerPicture(LoveBanner);
        setBannerPicForm(LoveBanner);
        setRoomImageStyle({
          backgroundImage: `url${LoveBanner}`
        })
        handleBannerEvent(LoveBanner)
      }
      addMyRoomClassName([bg.WHITE]);
      setSelectedTabClass('selected-dark')
      setUnselectedTabClass('unselected')
      setNameTextColor('text-dark')
    }
    // setBgImg(true);
    setIsDisable(false);
  }

  useEffect(() => {
    handleClick()
  }, [isFullScreen])

  useEffect(() => {
    handleClick()
  }, [])

  return (
    <div className="parent">
      <Carousel
        responsive={responsive}
        autoPlay={false}
        renderDotsOutside={true}
        swipeable={true}
        draggable={true}
        infinite={true}
        partialVisible={false}
        dotListClass="custom-dot-list-style"
      >
        {sliderImageUrl.map((ele, index) => {
          return (
            <div className={`slider add-pointer ${selectedThemeId === ele.id && 'active'}`} key={index} onClick={() => handleClick(ele.id)}>
              <img width="152" src={ele.url} alt="image" />
              <h3 className="slide-head">{ele.Content}</h3>
              <BsCheckLg className="tick-div" />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};
export default modalCarousal;