import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../auth/Auth';
import {
  SIGNUP_COMPLETE_ROUTE,
  PAYMENT_ROUTE,
} from '../../../../constants/routes.constants';
import CreateChildrenForm from '../../../common/form/CreateChildForm';
import AccountHeader from '../AccountHeader';
import { isStrNullOrEmpty } from '../../../../utils/utils';
import { isPendingPayment } from '../../../../utils/camp.utils';

function CreateChildren() {
  const history = useHistory();
  const onChildCreate = (dependents) => {
    const campUsers = dependents.filter((dependent) => {
      return isPendingPayment(dependent.profile.camp_status);
    });
    // console.log(campUsers);
    let route = '';
    let state = '';
    if (campUsers.length == 0) {
      route = SIGNUP_COMPLETE_ROUTE;
    } else {
      route = PAYMENT_ROUTE;
      state = { dependents: campUsers };
    }
    history.replace({
      pathname: route,
      state: state,
    });
  };
  const subTitle = (
    <span>
      Create a Kid's account for each child to access the
      <br />
      TaleTree App. This information is kept private
    </span>
  );
  return (
    <div
      data-testid="create_guardian"
      className="add-children-container signup-container"
    >
      <AccountHeader
        title="Creative Adventures are a Step Away"
        subTitle={subTitle}
      />
      <CreateChildrenForm onChildCreate={onChildCreate} />
    </div>
  );
}

export default CreateChildren;
