import React, { useEffect, useState } from 'react';
import { Image, Navbar, Nav, Dropdown, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import {
  HOME_FEED,
  MY_ROOM_ROUTE,
  NEXT_CHALLENGE_ROUTE,
  GUARDIAN_SETTINGS_ROUTE,
  LOGIN_ROUTE,
  CHALLENGE_ROUTE,
  SHARE_CREATION_ROUTE_BANNER,
  SPECIAL_CHALLENGE_ROUTE,
  EVENT_ROUTE,
  NOTIFICATION_ROUTE,
  TALETREE_MAP,
  KID_LOGIN_ROUTE,
} from '../../constants/routes.constants';
import { useAuth } from '../auth/Auth';
import logo from '../../assets/logo/logo-53x53.svg';
import TaletreeHeader from './TaletreeHeader';
import ProfileMenu from './ProfileMenu';
import './css/camp.header.scss';
import letsgoProfile from '../../assets/profile/default-profile-letsgo.svg';
import { useHistory } from 'react-router-dom';
import { GUEST_LOGIN_ROUTE } from '../../constants/routes.constants';
import myRoom from '../../assets/images/my_room.svg';
import myRoomActive from '../../assets/images/my_room_active.svg';
import HomeActive from '../../assets/images/home_active.svg';
import Home from '../../assets/images/home.svg';
import Challenges from '../../assets/images/challenge.svg';
import ChallengesActive from '../../assets/images/challenges_active.svg';
import userAvtr from '../../assets/images/uni.svg';
import { changeBodyClassName } from '../../utils/utils';
import { bg } from '../../constants/component.constants';
import Switch from 'react-switch';
import ImgMoon from '../../assets/icon/moon.svg';
import ImgSun from '../../assets/icon/sun.svg';
import HomeDark from '../../assets/icon/home-dark.svg';
import HomeActiveDark from '../../assets/icon/home-active-dark.svg';
import myRoomDark from '../../assets/icon/room-dark.svg';
import myRoomActiveDark from '../../assets/icon/room-active-dark.svg';
import ChallengesDark from '../../assets/icon/challenge-dark.svg';
import ChallengesActiveDark from '../../assets/icon/challenge-active-dark.svg';
import BackToDash from '../../assets/icon/back-to-dash.png';
import BackToDashDark from '../../assets/icon/back-to-dash-dark.png';
import MapMode from '../../assets/images/map-rocket.svg';
import MapModeHover from '../../assets/images/map-rocket-hover.svg';
import OptimizeSignup from '../views/onboarding/signup_popups/OptimizeSignup';


const CampHeader = () => {
  const { logout, dependent, guardian } = useAuth();
  const history = useHistory();
  const mode = localStorage.getItem('mode');
  const [checked, setChecked] = useState(mode === 'light' ? false : true);
  const [showModal, setShowModal] = useState(false);

  const gaurdianSignUp = () => {
    localStorage.setItem('kids_login_location', window.location.href)
    history.push(GUEST_LOGIN_ROUTE)
  }

  const handleChange = val => {
    setChecked(val)
    if (val) {
      changeBodyClassName([bg.DARK])
      localStorage.setItem('mode', 'dark')
    } else {
      changeBodyClassName([bg.CHALLENGES])
      localStorage.setItem('mode', 'light')
    }
  }

  useEffect(() => {
    handleChange(mode === 'light' ? false : true)
  }, [])

  const handleGuestProfileClick = () => {
    setShowModal(true);
  }

  return (
    <TaletreeHeader className="camp-header kid">
      <div className='navbar-brand-wrapper'>
        <LinkContainer to={HOME_FEED}>
          <Navbar.Brand>
            <Image src={logo} className="logo" />
          </Navbar.Brand>
        </LinkContainer>
        <div className='headerUserNotification' >
          <Image src={userAvtr} className="User Avtar" />
          {guardian ? ''
            // <span className='userNotification'>Hey {guardian !== null && guardian.first_name}, you are now in guest mode, <span onClick={() => history.push(GUARDIAN_SETTINGS_ROUTE)}><u>click here to exit.</u></span></span>
            :
            <span className='userNotification'><span className='remove-pointer guest-text'>Hello there, you are now in guest mode,</span> <span onClick={() => history.push(KID_LOGIN_ROUTE)}><u>click here to exit.</u></span></span>
          }
          {guardian ? ''
            // <span className='noti-header'>Hey {guardian !== null && guardian.first_name}, <span onClick={() => history.push(GUARDIAN_SETTINGS_ROUTE)}><u>click here to exit.</u></span></span>
            :
            <span className='noti-header'>Hello there, <span onClick={() => history.push(KID_LOGIN_ROUTE)}><u>click here to exit.</u></span></span>
          }
        </div>
      </div>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse
        id="responsive-navbar-nav"
        className="justify-content-end"
      >
        <Nav className="ml-auto align-items-md-center navbar-sub navbar-custom">
          <LinkContainer to={HOME_FEED}>
            <Nav.Link className={`px-3 camp-header-link ${history?.location?.pathname === HOME_FEED ? 'header-active' : ''}`}>
              {history?.location?.pathname === HOME_FEED ?
                <Image
                  src={mode === 'dark' ? HomeActiveDark : HomeActive}
                  alt=""
                />
                :
                <Image
                  src={mode === 'dark' ? HomeDark : Home}
                  alt=""
                />
              }
              <span className='mob-menu'>Home Feed</span>
            </Nav.Link>
          </LinkContainer>
          <LinkContainer to={NEXT_CHALLENGE_ROUTE}>
            <Nav.Link className={`px-3 camp-header-link ${history.location.pathname.slice(0, 11) === NEXT_CHALLENGE_ROUTE ||
              history.location.pathname.slice(0, 10) === CHALLENGE_ROUTE ?
              "header-active" : ''
              }`}>
              {history.location.pathname.slice(0, 11) === NEXT_CHALLENGE_ROUTE ||
                history.location.pathname.slice(0, 10) === CHALLENGE_ROUTE ||
                history.location.pathname.slice(0, 18) === SPECIAL_CHALLENGE_ROUTE ||
                history.location.pathname.slice(0, 6) === EVENT_ROUTE ?
                <Image src={mode === 'dark' ? ChallengesActiveDark : ChallengesActive} alt="" />
                : <Image src={mode === 'dark' ? ChallengesDark : Challenges} alt="" />}
              <span className='mob-menu'>Challenges</span>
            </Nav.Link>
          </LinkContainer>
          {!guardian && !dependent ?
            // <LinkContainer to={LOGIN_ROUTE}>
            <Nav.Link className="px-3 camp-header-link" onClick={handleGuestProfileClick}>
              {history.location.pathname.slice(0, 11) !== NEXT_CHALLENGE_ROUTE &&
                history.location.pathname.slice(0, 10) !== CHALLENGE_ROUTE &&
                history.location.pathname.slice(0, 18) !== SPECIAL_CHALLENGE_ROUTE &&
                history.location.pathname.slice(0, 6) !== EVENT_ROUTE &&
                history.location.pathname !== HOME_FEED ?
                <Image src={mode === 'dark' ? myRoomActiveDark : myRoomActive} alt="" />
                : <Image src={mode === 'dark' ? myRoomDark : myRoom} alt="" />}
              <span className='mob-menu'>My Room</span>
            </Nav.Link>
            // </LinkContainer>
            :
            <LinkContainer to={localStorage.getItem('set_dependent') ? localStorage.getItem('set_dependent') : `/${localStorage.getItem('selected_dependent')}`}>
              <Nav.Link className={`px-3 camp-header-link ${history.location.pathname.slice(0, 11) !== NEXT_CHALLENGE_ROUTE &&
                history.location.pathname !== HOME_FEED &&
                history.location.pathname.slice(0, 10) !== CHALLENGE_ROUTE &&
                history.location.pathname.slice(0, 18) !== SPECIAL_CHALLENGE_ROUTE &&
                history.location.pathname.slice(0, 6) !== EVENT_ROUTE &&
                history.location.pathname.slice(0, 9) !== SHARE_CREATION_ROUTE_BANNER &&
                history.location.pathname !== NOTIFICATION_ROUTE ?
                'header-active' : ''
                }`}>
                {history.location.pathname.slice(0, 11) !== NEXT_CHALLENGE_ROUTE &&
                  history.location.pathname !== HOME_FEED &&
                  history.location.pathname.slice(0, 10) !== CHALLENGE_ROUTE &&
                  history.location.pathname.slice(0, 18) !== SPECIAL_CHALLENGE_ROUTE &&
                  history.location.pathname.slice(0, 6) !== EVENT_ROUTE &&
                  history.location.pathname.slice(0, 9) !== SHARE_CREATION_ROUTE_BANNER &&
                  history.location.pathname !== NOTIFICATION_ROUTE ?
                  <Image src={myRoomActive} alt="" />
                  : <Image src={myRoom} alt="" />}
                <span className='mob-menu'>My Room</span>
              </Nav.Link>
            </LinkContainer>
          }
          {guardian &&
            <LinkContainer to={GUARDIAN_SETTINGS_ROUTE}>
              <Nav.Link className={`px-3 camp-header-link`}>
                <Image className='mob-menu' style={{marginLeft: '-5px', maxWidth: '30px'}} src={mode === 'dark' ? BackToDash : BackToDashDark} alt="" />
                <span className='mob-menu'>Back to dashboard</span>
              </Nav.Link>
            </LinkContainer>
          }
          <Dropdown className="profile-menu-header-container">
            <div className='map-mode' onClick={() => history.push(TALETREE_MAP)}>
              <img className='normal-state' src={MapMode} alt='' />
              <img className='hover-state' src={MapModeHover} alt='' />
            </div>
            <label htmlFor="small-radius-switch">
              <Switch
                checked={checked}
                onChange={handleChange}
                handleDiameter={28}
                offColor="#08f"
                onColor="#101B2F"
                offborder="2px solid #CCDCF3"
                // onBorder="2px solid #CCDCF3"
                height={50}
                width={120}
                borderRadius={150}
                activeBoxShadow="0px 0px 1px 2px #fffc35"
                uncheckedIcon={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      fontSize: 9,
                      color: "#fff",
                      paddingRight: 2,
                      paddingLeft: 1,
                      fontWeight: 700,
                      textTransform: "uppercase",
                      fontFamily: "Open Sans"
                    }}
                  >
                    Day Mode
                  </div>
                }
                checkedIcon={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      fontSize: 9,
                      color: "#fff",
                      paddingRight: 2,
                      paddingLeft: 1,
                      fontWeight: 700,
                      textTransform: "uppercase",
                      fontFamily: "Open Sans"
                    }}
                  >
                    Night Mode
                  </div>
                }
                uncheckedHandleIcon={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%"
                    }}
                  >
                    <img src={ImgSun} />
                  </div>
                }
                checkedHandleIcon={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <img src={ImgMoon} />
                  </div>
                }
                className="react-switch"
                id="small-radius-switch"
              />
            </label>
            {guardian ? <ProfileMenu /> :
              <Dropdown.Toggle
                className="rounded-pill avatar-button"
                id="dropdown-basic"
              >
                <div className="avatar-wrapper">
                  <div className="simple-type"
                    onClick={gaurdianSignUp}>
                    <Image
                      className="rounded-circle"
                      src={letsgoProfile}
                    />
                    Guest
                  </div>
                </div>
              </Dropdown.Toggle>
            }
            {/* <Dropdown.Menu className="profile-menu-header dropdown-menu-right">
              <Row className="align-items-center profile-menu-item">
                <Col className="profile-menu-action-text">
                  <RoundButton
                    label="Log out"
                    type="button"
                    className="logout-button"
                    onClick={() => logout()}
                  />
                </Col>
              </Row>
            </Dropdown.Menu> */}
          </Dropdown>
          <div className="mobile-search">
            {/* <Search /> */}
            <div className="mobile-bottom-wrapper">
              <div className='map-mode' onClick={() => history.push(TALETREE_MAP)}>
                <img className='normal-state' src={MapMode} alt='' />
                <img className='hover-state' src={MapModeHover} alt='' />
              </div>
              <label htmlFor="small-radius-switch">
                <Switch
                  checked={checked}
                  onChange={handleChange}
                  handleDiameter={28}
                  offColor="#08f"
                  onColor="#101B2F"
                  offborder="2px solid #CCDCF3"
                  // onBorder="2px solid #CCDCF3"
                  height={50}
                  width={120}
                  borderRadius={150}
                  activeBoxShadow="0px 0px 1px 2px #fffc35"
                  uncheckedIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 9,
                        color: "#fff",
                        paddingRight: 2,
                        paddingLeft: 1,
                        fontWeight: 700,
                        textTransform: "uppercase",
                        fontFamily: "Open Sans"
                      }}
                    >
                      Day Mode
                    </div>
                  }
                  checkedIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 9,
                        color: "#fff",
                        paddingRight: 2,
                        paddingLeft: 1,
                        fontWeight: 700,
                        textTransform: "uppercase",
                        fontFamily: "Open Sans"
                      }}
                    >
                      Night Mode
                    </div>
                  }
                  uncheckedHandleIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%"
                      }}
                    >
                      <img src={ImgSun} />
                    </div>
                  }
                  checkedHandleIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <img src={ImgMoon} />
                    </div>
                  }
                  className="react-switch"
                  id="small-radius-switch"
                />
              </label>
            </div>
          </div>
        </Nav>
      </Navbar.Collapse>
      <OptimizeSignup showModal={showModal} setShowModal={setShowModal} />
    </TaletreeHeader>
  );
};

export default CampHeader;
