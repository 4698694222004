import { fetchUtils } from '../utils/fetch.utils';
import { getAPIUrl } from '../utils/config.utils';

const BASE_URL = getAPIUrl();
const CHALLENGES = 'challenges';

function getChallenges(filter, params) {
  const queryString = fetchUtils.buildQuery(params);
  // return fetchUtils.get(`${BASE_URL}/${CHALLENGES}?${queryString}`);
  return fetchUtils.get(
    `${BASE_URL}/${CHALLENGES}/list/${filter}?${queryString}`
  );
}

const getPrivateChallenges = (filter, params) => {
  const queryString = fetchUtils.buildQuery(params);
  return fetchUtils.get(
    `${BASE_URL}/${CHALLENGES}/private-list/${filter}?${queryString}`
  );
};

function getChallengeByID(id) {
  return fetchUtils.get(`${BASE_URL}/${CHALLENGES}/${id}`);
}

const getDependentChallenges = (challengeStatus, params) => {
  const queryString = fetchUtils.buildQuery(params);
  return fetchUtils.get(
    `${BASE_URL}/${CHALLENGES}/dependent-challenges/${challengeStatus}?${queryString}`
  );
};

const getPrivateChallengeByID = (id, params) => {
  const queryString = fetchUtils.buildQuery(params);
  return fetchUtils.get(`${BASE_URL}/${CHALLENGES}/dependent-challenge/${id}?${queryString}`);
};

const challengeAccept = (challenge_id, is_accept) => {
  return fetchUtils.post(`${BASE_URL}/${CHALLENGES}/challenge-accept`, {
    challenge_id,
    is_accept,
  });
};

const getEvents = (params) => {
  const queryString = fetchUtils.buildQuery(params);
  return fetchUtils.get(
    `${BASE_URL}/special_camps/events/list?${queryString}`
  );
};

function getEventByID(id) {
  return fetchUtils.get(`${BASE_URL}/special_camps/${id}`);
}

export const challengeRepository = {
  getChallenges,
  getPrivateChallenges,
  getDependentChallenges,
  getChallengeByID,
  getPrivateChallengeByID,
  challengeAccept,
  getEvents,
  getEventByID
};
