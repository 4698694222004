import { creationRepository } from '../../../repository/creation.repository';
import { challengeRepository } from '../../../repository/challenge.repository';
import { tagRepository } from '../../../repository/tag.repository';
import { partnerRepository } from '../../../repository/partner.repository';

const deleteCreation = (params) => {
  return new Promise((resolve, reject) => {
    creationRepository
      .deleteCreationById(params.id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getTags = (params) => {
  return new Promise((resolve, reject) => {
    tagRepository
      .getTags(params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};
const getBranch = () => {
  return new Promise((resolve, reject) => {
    tagRepository
      .getBranch()
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};
const addBranch = (params) => {
  return new Promise((resolve, reject) => {
    tagRepository
      .addBranch(params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};
const getPartners = (params) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .getPartners(params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};
const addRemovePartner = (params) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .addRemovePartner(params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

function getChallengeDetails(id) {
  return new Promise((resolve, reject) => {
    challengeRepository
      .getChallengeByID(id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => reject(err));
  });
}

const getPrivateChallengeDetails = (id, query) => {
  return new Promise((resolve, reject) => {
    challengeRepository
      .getPrivateChallengeByID(id, query)
      .then((res) => {
        resolve(convertChallengeAttributes(res.data));
      })
      .catch((err) => reject(err));
  });
};

const challengeAccept = (id, accept) => {
  return new Promise((resolve, reject) => {
    challengeRepository
      .challengeAccept(id, accept)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => reject(err));
  });
};

function getNextQuery(params, list, hash_more) {
  const lastId = list.length > 0 ? list[list.length - 1].id : 0;
  const nextQuery = { ...params };
  nextQuery.starting_after = lastId;
  nextQuery.has_more = hash_more;
  return nextQuery;
}

/**
 * Merge and remove duplicate items
 * @param {object} previousList
 * @param {object} newList
 */
function mergeList(previousList, newList) {
  const list = previousList ? [...previousList, ...newList] : newList;
  let idSet = new Set();
  const uniqueList = list.filter((item) => {
    let isUnique = false;
    if (!idSet.has(item.id)) {
      idSet.add(item.id);
      isUnique = true;
    }
    return isUnique;
  });
  return uniqueList;
}

const convertChallengeAttributes = (challenge) => {
  var _data = {};
  _data = challenge.challenge;
  // console.log(challenge);
  _data.challenge_status = challenge.challenge_status;
  _data.days_left = challenge.days_left;
  // if (challenge.days_left) _data.days_left = challenge.days_left;
  if (challenge.time_left) _data.time_left = challenge.time_left;
  _data.submission_date = challenge.submission_date;
  _data.all_completed_dependents = challenge.all_completed_dependents;
  _data.all_accepted_dependents = challenge.all_accepted_dependents;
  // console.log(_data);
  return _data;
};

// /**
//  * Merge and remove duplicate creations
//  * @param {object} previousCreationList
//  * @param {object} newCreationList
//  */
// function mergeCreationList(previousCreationList, newCreationList) {
//   const newList = previousCreationList
//     ? [...previousCreationList, ...newCreationList]
//     : newCreationList;
//   let creationIdSet = new Set();
//   const uniqueList = newList.filter((creation) => {
//     let isUnique = false;
//     if (!creationIdSet.has(creation.id)) {
//       creationIdSet.add(creation.id);
//       isUnique = true;
//     }

//     return isUnique;
//   });
//   return uniqueList;
// }

function getChallenges(filter, params, previousChallengeList) {
  return new Promise((resolve, reject) => {
    challengeRepository
      .getChallenges(filter, params)
      .then((response) => {
        const challengeList = mergeList(previousChallengeList, response.data);
        const nextQuery = getNextQuery(
          params,
          challengeList,
          response.has_more
        );
        const data = {
          challengeList: challengeList,
          query: nextQuery,
        };
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getPrivateChallenges = (filter, params, previousChallengeList) => {
  return new Promise((resolve, reject) => {
    challengeRepository
      .getPrivateChallenges(filter, params)
      .then((response) => {
        const data = response.data.map((challenge) =>
          convertChallengeAttributes(challenge)
        );
        const challengeList = mergeList(previousChallengeList, data);
        const query = getNextQuery(params, challengeList, response.has_more);
        const res = {
          challengeList,
          query,
        };
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getMyRoomChallenges = (filter, params, previousChallengeList) => {
  return new Promise((resolve, reject) => {
    challengeRepository
      .getDependentChallenges(filter, params)
      .then((res) => {
        const data = res.data.map((challenge) =>
          convertChallengeAttributes(challenge)
        );
        const challengeList =
          data.length !== 0 ? mergeList(previousChallengeList, data) : data;
        const nextQuery = getNextQuery(params, challengeList, res.has_more);
        resolve({
          challengeList: challengeList,
          query: nextQuery,
        });
      })
      .catch((err) => reject(err));
  });
};

function getCreations(params, previousCreationList) {
  return new Promise((resolve, reject) => {
    creationRepository
      .getCreations(params)
      .then((response) => {
        const creationList = mergeList(previousCreationList, response.data);
        const nextQuery = getNextQuery(params, creationList, response.has_more);
        const data = {
          creationList: creationList,
          query: nextQuery,
        };
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getEvents = (params, previousEventList) => {
  return new Promise((resolve, reject) => {
    challengeRepository
      .getEvents(params)
      .then((response) => {
        // const data = response.data.map((challenge) =>
        //   convertChallengeAttributes(challenge)
        // );
        const data = response.data;
        const eventList = mergeList(previousEventList, data);
        const query = getNextQuery(params, eventList, response.has_more);
        const res = {
          eventList,
          query,
        };
        resolve(res);
      })
      .catch((error) => {
        console.log(error)
        reject(error);
      });
  });
};

function getEventDetails(id) {
  return new Promise((resolve, reject) => {
    challengeRepository
      .getEventByID(id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => reject(err));
  });
}

export const nextChallengeViewModel = {
  getBranch,
  addBranch,
  getTags,
  deleteCreation,
  getChallengeDetails,
  getPrivateChallengeDetails,
  challengeAccept,
  getChallenges,
  getMyRoomChallenges,
  getPrivateChallenges,
  getCreations,
  getPartners,
  addRemovePartner,
  getEvents,
  getEventDetails
};
