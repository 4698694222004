import React from 'react';
import { Modal } from 'react-bootstrap';
import ImageInput from "../../../assets/images/image-input.svg";
import CartoonImage from "../../../assets/images/space.svg";

function NewCustomizeRoomModal({ newCustomizePopup, setNewCustomizePopup, setCustomizePopup, handleHistoryStateChange, setChooseCustomizeType }) {
    const closeModal = () => {
        handleHistoryStateChange();
        setNewCustomizePopup(false);
    }

    const handleChoose = (type) => {
        setChooseCustomizeType(type);
        setCustomizePopup(true);
        closeModal();
    }

    return (
        <Modal className="auth-popup " show={newCustomizePopup} onHide={closeModal} centered>
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body className=' text-center'>
                <h4 className="modal-h5"> Amazing! Select what you would like to do</h4>
                <div className='row p-5'>
                    <div className="col-md-5 ">
                        <div className="App add-pointer" onClick={() => handleChoose('own')}>
                            {/* <input type="file" /> */}
                            <img src={ImageInput} />
                            <h6 className="input-head mt-4">Upload your own design</h6>
                        </div>

                    </div>
                    <div className="col-md-2  d-flex justify-content-center align-items-center">
                        <p>Or</p>
                    </div>
                    <div className="col-md-5 ">
                        <div className="App add-pointer" onClick={() => handleChoose('theme')}>
                            {/* <input type="file" /> */}
                            <img src={CartoonImage} />
                            <h6 className="input-head mt-4">Choose a theme</h6>
                        </div>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default NewCustomizeRoomModal