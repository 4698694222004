import React, { useState } from 'react';
import { Row, Button, Col, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import ErrorLabel from '../../common/ErrorLabel';
import { ERROR_REQUIRE_MSG } from '../../../constants/error.constants';
import { getDefaultProfileImage } from '../../../utils/camp.utils';
import { useAuth, getGuardianDependents } from '../../auth/Auth';
import { settingsViewModel } from './settings.view.model';
import FormInput from '../../common/FormInput';
import FormLabel from '../../common/FormLabel';
import AlertMessage from '../../common/AlertMessage';
import EditAvatar from '../../common/EditAvatar';
import LoadButton from '../../common/LoadButton';
import { isObjEmpty } from '../../../utils/utils';

function EditChildBasic({ dependent, onProfileSave }) {
  const { guardian, fetchAPI } = useAuth();
  const imageUrl = dependent.profile.picture_url
    ? dependent.profile.picture_url
    : getDefaultProfileImage(dependent.profile.taletree_friend);
  const [profilePicture, setProfilePicture] = useState(imageUrl);
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const def = { ...dependent };
  const {
    control,
    register,
    handleSubmit,
    setError,
    reset,
    errors,
    formState,
  } = useForm({
    mode: 'onChange',
    defaultValues: def,
    shouldUnregister: false,
  });
  const onSubmit = (formData) => {
    // console.log(formData);
    setIsLoading(true);
    fetchAPI(
      settingsViewModel.updateDependentUser,
      dependent.id,
      dependent.username,
      formData,
      profilePicture,
      formState.dirtyFields
    )
      .then(() => {
        const newValues = { ...formData };
        newValues.profile_picture = '';
        reset(newValues);
        onProfileSave();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLoading(false));
  };
  const handleProfileImageSelection = (index, image) => {
    setProfilePicture(image);
  };
  return (
    <div id="basic" className="mb-5  tab-width col-xl-4 col-lg-4 col-md-4 col-sm-12">
      <AlertMessage
        handleClose={() => setShowAlert(false)}
        show={showAlert}
        title={'Changes has been saved.'}
      />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="justify-content-center text-center mb-4">
          <Col>
            <EditAvatar
              ref={register}
              currentProfileImage={profilePicture}
              onProfileImageChange={handleProfileImageSelection}
              taletreeFriend={dependent.profile.taletree_friend}
              name="profile_picture"
              errors={errors}
            />
          </Col>
        </Row>
        <Form.Group>
          <h4>Basic</h4>
        </Form.Group>
        <Form.Group>
          <FormLabel>First Name</FormLabel>
          <FormInput
            name="first_name"
            ref={register({
              required: ERROR_REQUIRE_MSG,
            })}
            placeholder="First Name"
            data-testid="first_name"
          />
          <ErrorLabel errors={errors} name="first_name" />
        </Form.Group>
        <Form.Group controlId="last_name">
          <FormLabel className="pl-1 small signup-label">Last Name</FormLabel>
          <FormInput
            name="last_name"
            ref={register({
              required: ERROR_REQUIRE_MSG,
            })}
            placeholder="Last Name"
            data-testid="last_name"
          />
          <ErrorLabel errors={errors} name="last_name" as="label" />
        </Form.Group>
        <Form.Group>
          <FormLabel>Birthdate</FormLabel>
          <FormInput
            name="profile.birthdate"
            ref={register({
              required: ERROR_REQUIRE_MSG,
            })}
            type="date"
            max={new Date().toISOString().slice(0, 10)}
            data-testid="profile.birthdate"
          />
          <ErrorLabel errors={errors} name="profile.birthdate" />
        </Form.Group>
        <Form.Group>
          <FormLabel>Gender</FormLabel>
          <FormInput
            as="select"
            name="profile.gender"
            ref={register}
            data-testid="profile.gender"
          >
            <option data-testid="gender-option" key="male">
              male
            </option>
            <option data-testid="gender-option" key="female">
              female
            </option>
          </FormInput>
        </Form.Group>
        <Form.Group className="button-wrapper">
          <LoadButton
            className="rounded-pill gred-btn"
            isDisabled={isLoading || isObjEmpty(formState.dirtyFields)}
            isLoading={isLoading}
            text="Save Changes"
          />
        </Form.Group>
      </Form>
    </div>
  );
}

export default EditChildBasic;
