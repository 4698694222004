// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/Guardian-bg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "body{padding-bottom:50px;color:#321b6d}body .emphasize{font-weight:bold;color:#32c1bd}html{height:100%;overflow:auto}.form-control-focus,.form-control:focus{color:#495057;background-color:#fff;border-color:#321b6d;outline:0;box-shadow:unset}.form-control::-webkit-input-placeholder{color:#afafaf}.form-control:-moz-placeholder{color:#afafaf}.form-control::-moz-placeholder{color:#afafaf}.form-control:-ms-input-placeholder{color:#afafaf}.form-control::-ms-input-placeholder{color:#afafaf}.guardian-setting-wrapper{min-width:768px;max-width:100%;width:100%}.guardian-setting-wrapper .setting-container{padding-bottom:2rem;max-width:100%;padding:0 0;background-size:cover;background-repeat:no-repeat;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:bottom}.guardian-setting-wrapper .setting-container .divider{justify-content:center}.guardian-setting-wrapper .setting-container .guardian-settings-overview-title{color:#303030;font-family:\"Nunito\",sans-serif;font-size:1.8rem;font-weight:800;text-align:center}@media only screen and (max-width: 1224px){.guardian-setting-wrapper{min-width:768px}}@media only screen and (max-width: 768px){.guardian-setting-wrapper{min-width:375px}}", ""]);
// Exports
module.exports = exports;
