import React from 'react';
import { Row, Col } from 'react-bootstrap';

import Modal from '../../../components/common/Modal';
import { ComponentSize } from '../../../constants/component.constants';


function ZoomModal({ showModal, handleClose }) {
  return (
    <>
      <Modal
        show={showModal}
        handleClose={handleClose}
        title="Meeting ID & Password"
        size={ComponentSize.SMALL}
      >
        <Row className="pt-3">
          <Col className="col-8 offset-2">
            <Row className="justify-content-center text-center mb-5">
              <Col>
                <span>Meeting ID: 283 826 4133</span>
              </Col>
            </Row>
            <Row className="justify-content-center text-center mb-5">
              <Col>
                <span>Passcode: 155286</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default ZoomModal;
