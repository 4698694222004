import React, { useEffect, useState } from 'react';
import { Modal, Image } from 'react-bootstrap';
import challengesSelectedCustomize from '../../../assets/images/visit-child-popup.svg';
import { getUserOnboardingChallengeChar } from '../../../utils/camp.utils';
import { useAuth } from '../../auth/Auth';

function WelcomeRoomModal ({userData, setCustomizePopup, handleHistoryStateChange}) {
    const { dependent } = useAuth();
    const [showPopup, setShowPopup] = useState(true);
    const [showSkipOrCustomize, setShowSkipOrCustomize] = useState(
      dependent && !dependent.profile.is_activated ? true : false
    );
    const closeModal = () => {
      handleHistoryStateChange();
        setShowPopup(false);
      }
      useEffect(() => {
        setTimeout(() => {
        setShowSkipOrCustomize(true)
      }, [2000])
      })
    
      const showCustomize = () => {
        setShowPopup(false)
        setCustomizePopup(true)
      }
    return (
        <>
        <Modal show={showPopup} className="account-varification my-room-modal">
        <Modal.Header>
            <Modal.Title>
              <div className='wonderful_image'>
                <Image src={getUserOnboardingChallengeChar(dependent)} alt="" />
              </div>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {!showSkipOrCustomize ? (
              <>
            <b className="mt-4 mb-4">Wonderful! You have selected 3 challenges.</b>
            <br />
            <b className="mt-4 mb-4">Now you can see your choosen challenges in your room.</b>
            <br />
            </>
            ) : (
              <>
              <div className='welcome-to-room'>
                <h4 className='mb-40 mt-20'><span className='theme-color'>Hey, {dependent?.username}! </span> Welcome to your room.</h4>
                <p>Other kids can visit your room too! Be proud of your room... :)</p>
                <div className='customize-room-modal-action'>
                  <button className='load-button-container float-left rounded-pill mb-4 font-weight-semibold btn btn-light' onClick={closeModal}>Skip</button> 
                  <button style={{maxWidth: '230px'}} className='gred-btn load-button-container signup-btn rounded-pill float-right mb-4 font-weight-bold btn btn-primary' onClick={showCustomize}>Customize your room</button>
                </div>
              </div>
              </>
            )}
        </Modal.Body>
    </Modal>
        </>
    )
}
export default WelcomeRoomModal;