export const StatusCodes = Object.freeze({
  SUCCESS: 200,
  ERROR: 500,
});

export const ChallengeStatus = Object.freeze({
  UNSELECTED: '',
  SELECTED: 'Selected',
  SUBMITTED: 'Completed',
  EXPIRED: 'Expired',
});
