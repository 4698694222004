import React from 'react';
import { Image, Button } from 'react-bootstrap';
import { useAuth } from '../../auth/Auth';
import zoomIcon from '../../../assets/icon/icon-zoom.svg';
import { isCampMember } from '../../../utils/camp.utils';
// import './css/camp.schedule.scss';

function ZoomButton({ title, creator }) {
  const { dependent } = useAuth();
  const campStatus = creator ? creator.profile.camp_status : dependent ? dependent.profile.camp_status : "";
  const isActive = isCampMember(campStatus);
  const disableClass = isActive ? '' : 'camp-schedule-disabled';
  return (
    <>
      <a
        className={`camp-schedule-zoom-anchor ${disableClass}`}
        href="https://us04web.zoom.us/j/7952205101"
        target="_blank"
      >
        <Button
          block
          disabled={!isActive}
          variant="success"
          className="camp-schedule-join px-3 py-3 gred-btn"
        >
          <Image className="pr-3" src={zoomIcon} />
          {title}
        </Button>
      </a>
    </>
  );
}

export default ZoomButton;
