import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import RoundButton from '../../common/RoundButton';
import { Row, Col, ProgressBar } from 'react-bootstrap';
import { GUARDIAN_SETTINGS_ROUTE, ON_BOARDING, SIGN_UP_ROUTE } from '../../../constants/routes.constants';
import { bg } from '../../../constants/component.constants';
import { kidSignUpErrors } from '../../../constants/error.constants';
import moment from 'moment';
// import Datetime from 'react-datetime';
import OnboardingWrapper from './OnboardingWrapper';
import { isKid } from '../../../utils/form.utils';
import { changeBodyClassName } from '../../../utils/utils';
import Select from 'react-select';
import 'react-datetime/css/react-datetime.css';
import './scss/step2.scss';
import DatePicker from "react-datepicker";
import { accountViewModel } from '../account/account.view.model';
import { useAuth } from '../../auth/Auth';

const Step2 = ({ location }) => {
  const history = useHistory();
  const {refreshUserState} = useAuth();
  const lsGender = JSON.parse(localStorage.getItem('gender'));
  const lsName = localStorage.getItem('firstName');
  const lsBirthdate = localStorage.getItem('birthdate');
  const [original, setOriginal] = useState('');
  const [errShow, setErrShow] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const isFromDashboard = location.state?.from === 'dashboard';
  const [kidsName, setKidsName] = useState(lsName !== null ? lsName : '');

  const currentDate = new Date();

  const maxDate = useMemo(() => {
    let maxYearDate = new Date();
    maxYearDate.setFullYear(maxYearDate.getFullYear() - 5);
    return maxYearDate;
  }); 
  
  const [myMonth, setMyMonth] = useState(currentDate);
  const [myYear, setMyYear] = useState(maxDate);
  const [myDay, setMyDay] = useState(currentDate);

  const minDate = myMonth && myYear?new Date(myYear?.getFullYear(), myMonth?.getMonth(), 1): new Date()


  const [errors, setErrors] = useState({});

  useEffect(() => {
    if(myYear && myMonth && myDay){
      const myDayDate = myDay && myDay.getDate()?myDay.getDate():new Date().getDate();
      // console.log("myDayDate", myDayDate);
      setMyDay(new Date(myYear.getFullYear(), myMonth.getMonth(), myDayDate));
    }
  }, [myMonth, myYear, setMyDay]);

  const renderDayContents = (day, date) => {
    return <span>{date.getDate()}</span>;
  };

  const selectedMonth = myMonth && (myMonth?.getUTCMonth() + 1) < 9 ? `0${myMonth?.getUTCMonth() + 1}` : myMonth&&myMonth?.getUTCMonth() + 1
  localStorage.setItem('birthdate', `${myYear?.getFullYear()}-${selectedMonth}-${myDay?.toString().substring(8, 10)}`);


  const [birthdate, setBirthdate] = useState(
    // lsBirthdate === null ? '' : moment(new Date(lsBirthdate), 'YYYY/M/D') || ''
    lsBirthdate === null ? '' : lsBirthdate
  );
  const [selectedOption, setSelectedOption] = useState(
    // lsGender !== null ? lsGender : { value: _NOT_SELECTED, label: 'Select...' }
    lsGender !== null ? lsGender : ''
  );
  const options = [
    // { value: _NOT_SELECTED, label: 'Select...' },
    { value: 'male', label: "Boy's" },
    { value: 'female', label: "Girl's" },
  ];
  const customStyles = {
    control: (base) => ({
      ...base,
      width: 184,
      height: 48,
      color: '#321b6d',
      fontWeight: '400',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#321b6d',
    }),
    placeholder: (provided) => ({
      ...provided,
      fontWeight: '400',
      color: 'rgb(204, 204, 204)',
    }),
    menu: (provided, state) => ({
      ...provided,
      width: 184,
    }),
  };

  const manufactureParams = () => {
    const lsFirstName = localStorage.getItem('firstName');
    const lsBirthDate = localStorage.getItem('birthdate');
    const lsGender = JSON.parse(localStorage.getItem('gender')).value;
    //const lsGender = 'male';
    // const lsCountry = JSON.parse(localStorage.getItem('country')).value;
    const lsCountry = 'Andorra';
    // const lsCity = JSON.parse(localStorage.getItem('city')).value;
    const lsCity = 'Canillo';
    // const lsSchool = JSON.parse(localStorage.getItem('school')).value;
    const lsSchool = 'safd';

    const params = {
        first_name: lsFirstName,
        last_name: '',
        //last_name: 'lkjlkjlo',
        signup: 'camp',
        profile: {
          favorites: ['', '', ''],
          taletree_friend: '',
          gender: lsGender,
          birthdate: lsBirthDate.replace(/\//g, '-'),
          //birthdate: "2022-09-15",
          lives_in: lsCountry,
          nearest_city: lsCity,
          school_name: lsSchool,
        },
        // questions: questions,
        questions:[]
    };
    return params;
  };

  const handleNextClick = () => {
    if(!kidsName){
      setErrors({...errors, kidsName: "Please enter your child's name."});
      return false;
    }
    else{
      setErrors({...errors, kidsName: ""});
    }

    if(myDay > maxDate){
      setErrors({...errors, dob: "Less then 5 year is not allowed."});
      return false;
    }
    else{
      setErrors({...errors, dob: ""});
    }

    let destination = {};
    if (isFromDashboard) {
      destination = {
        pathname: `${GUARDIAN_SETTINGS_ROUTE}`,
      };
      const params = manufactureParams();
      accountViewModel
        .addKid(params)
        .then((res) => {
          refreshUserState();
          localStorage.clear();
        })
        .catch((err) => {
          alert(err.message);
        });
      history.push(destination);
    } else {
      destination = {
        pathname: `${SIGN_UP_ROUTE}`,
        state: {
          ...location.state,
          from: 'step7',
        },
      };
      history.push(destination);
    }
  };
  useEffect(() => {
    changeBodyClassName([bg.GRADIENT]);
    // console.log(localStorage)
    if (location.state && location.state.from === 'dashboard') {
      setOriginal(location.state.from);
      return;
    }

    // if user directly access this step go back to step1
    // if (!location.state || location.state.from !== 'step1')
    //   history.replace({ pathname: `/` });

    //
    const birthdayInput = document.getElementsByClassName('form-control')[0];
    // birthdayInput.value = birthdate;
    // const checkValue = (str, max) => {
    //   if (str.charAt(0) !== '0' || str === '00') {
    //     const num = parseInt(str);
    //     if (isNaN(num) || num <= 0 || num > max) num = 1;
    //     str =
    //       num > parseInt(max.toString().charAt(0)) &&
    //       num.toString().length === 1
    //         ? '0' + num
    //         : num.toString();
    //   return str;
    // };

    // birthdayInput.addEventListener('keydown', (e) => {
    //   let input = e.target.value;
    //   if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
    //   }
    //   // console.log(e);
    //   let values = input.split('/').map((v) => {
    //     return v.replace(/\D/g, '');
    //   });
    //   if (values[0]) values[0] = checkValue(values[0], 12);
    //   if (values[1]) values[1] = checkValue(values[1], 31);
    //   const output = values.map((v, i) => {
    //     return v.length === 2 && i < 2 ? v + '/' : v;
    //   });
    //   e.target.value = output.join('').substr(0, 14);
    // });

    // birthdayInput.addEventListener('keydown', (e) => {
    //   const key = e.keyCode || e.charCode;
    //   const str = e.target.value;
    //   console.log(key);
    //   if (key === 8) str.substring(0, str.length - 1);
    // });
  }, []);

  const handleNext = () => {
    if (isKid(birthdate)) {
      localStorage.setItem(
        'birthdate',
        moment(new Date(birthdate)).format('YYYY-MM-DD')
      );
      history.push({
        pathname: `${ON_BOARDING}/step3`,
        state: { from: 'step2', original },
      });
    } else {
      // console.log("You're not kid anymore");
      setErrShow(true);
      setErrMessage(kidSignUpErrors.birthdate.age);
    }
  };

  const handleChange = (e) => {
    // console.log(e.target);
    const d = e.target.value;
    if (d.length === 0) return;
    if (moment(d, 'MM/DD/YYYY', true).isValid()) {
      setErrShow(false);
      if (d.length > 0)
        setBirthdate(d.indexOf('/') >= 0 ? d : d.format('MM/DD/YYYY'));
      else setBirthdate(d.format('MM/DD/YYYY'));
    } else {
      setBirthdate(d);
      // setErrShow(true);
      // setErrMessage('Please input correct date. format is MM/DD/YYYY');
    }
  };

  const handleGenderChange = (selection) => {
    setSelectedOption(selection);
    localStorage.setItem('gender', JSON.stringify(selection));
  };

  const inputProps = {
    placeholder: 'MM/DD/YYYY',
  };

  const handleNameChange = (e) => {
    const name = e.target.value;
    setKidsName(name);
    localStorage.setItem('firstName', name);
  };

  //To disable Raw Input
  const handleDateChangeRaw = (e) => {
    e.preventDefault()
  }

  return (
    <OnboardingWrapper className="step2-wrapper">
      <Row className="content-wrapper">
        <Col className="content">
          <div className="progress-bar-wrapper">
            <ProgressBar now={16} />
          </div>
          <div className="title">
            Thanks for letting us get to know you and your child better.
          </div>
          <div className="sub title">
            At TaleTree, we believe that every child is a creative genius and
            needs freedom to find themselves and express their creativity
            however they want to.
          </div>
          <div className="input-wrapper">
            <Row>
              <Col className="left">
                <Row>
                  <Col>
                    My little
                    <Select
                      defaultValue={selectedOption}
                      onChange={handleGenderChange}
                      className="basic-single"
                      classNamePrefix="select"
                      options={options}
                      styles={customStyles}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: '6px',
                        colors: {
                          ...theme.colors,
                          primary: '#80e4e1',
                        },
                      })}
                    />
                  </Col>
                </Row>
              </Col>
              
              <Col className="right">
                <Row>
                  <Col>
                    Name is
                    <input
                      type="text"
                      className="name-field"
                      value={kidsName}
                      placeholder="Your child's name"
                      onChange={handleNameChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col><label className="small text-danger">{errors.kidsName}</label></Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="sub title font-set">
            *You can add additional children later
          </div>
          {/* <div className="title sub">When was your child born?</div> */}
          <div className={`error-message-wrapper ${errShow ? 'show' : 'hide'}`}>
            {errMessage}
          </div>
          <div className="input-wrapper custom-date child-register">
            When was your child born?
            <Row>
              <Col className="left">
                {/* <input
                    type="integer"
                    className="name-field"
                    placeholder="MM"
                  /> */}
                <DatePicker
                  showMonthYearPicker
                  dateFormat="MMM"
                  renderCustomHeader={({ date }) => <div></div>}
                  selected={myMonth}
                  className="name-field"
                  onChange={(date) => setMyMonth(date)}
                  onChangeRaw={handleDateChangeRaw}
                />
                <h6>
                  Month
                </h6>

              </Col>
              <Col className="center">

                {/* <input
                    type="integer"
                    className="name-field"
                    placeholder="DD"
                  /> */}

                <DatePicker
                  dateFormat="dd"
                  renderCustomHeader={({ date }) => <div></div>}
                  selected={myDay}
                  className="name-field"
                  renderDayContents={renderDayContents}
                  onChange={(date) => setMyDay(date)}
                  onChangeRaw={handleDateChangeRaw}
                /> <h6>
                  Day
                </h6>
              </Col>
              <Col className="end">
                {/* <input
                  type="integer"
                  className="name-field"
                  placeholder="YYYY"
                /> */}
                <DatePicker
                  selected={myYear}
                  className="name-field year-width"
                  onChange={(date) => setMyYear(date)}
                  showYearPicker
                  dateFormat="yyyy"
                  maxDate={maxDate}
                  onChangeRaw={handleDateChangeRaw}
                />
                <h6>
                  Year
                </h6>
              </Col>
            </Row>
            <Row>
              <Col><label className="small text-danger">{errors.dob}</label></Col>
            </Row>
          </div>
          <div className="button-wrapper">
            <RoundButton
              type="button"
              label="Final step"
              onClick={handleNextClick}
            />
          </div>
        </Col>
      </Row>
    </OnboardingWrapper>
  );
};

export default Step2;
