import { fetchUtils } from '../utils/fetch.utils';
import { getAPIUrl } from '../utils/config.utils';

const BASE_URL = getAPIUrl();

function getGroups(pageSize) {
//   const query = fetchUtils.buildQuery(params);
//hardcoded for now
  return fetchUtils.get(`${BASE_URL}/groups?limit=100`);
}

export const groupRepository = {
    getGroups,
};
