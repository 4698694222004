import React, { useState, useEffect } from 'react';
import checkActive from '../../../assets/images/check-active.svg';
import checkActiveSeedlings from '../../../assets/images/check-icon-seedlings.svg';
import checkActiveVillagers from '../../../assets/images/check-icon-villagers.svg';
import checkimage from '../../../assets/images/check-svg.svg';
// import LeftImage from '../../../assets/images/left-plan-img.svg';
// import RightImage from '../../../assets/images/right-plan-img.svg';

import LeftImage from '../../../assets/images/left-Letsgo22.svg';
import RightImage from '../../../assets/images/right-Cody11.svg';
import backIcon from '../../../assets/icon/back-icon.svg';

import './plan.css';
import PlansSlider from './PlansSlider';
import LearnMorePopup from './LearnMorePopup';
import { stripeViewModel } from '../payment/stripe.view.model';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import { getPrice } from '../../../utils/camp.utils';
import { Row } from 'react-bootstrap';
import { PaymentContext } from '../payment/PaymentSubscription';
import PaymentMethod from '../payment/PaymentMethod';
import { GUARDIAN_SETTINGS_ROUTE } from '../../../constants/routes.constants';
import { HiOutlineChevronLeft } from '@react-icons/all-files/hi/HiOutlineChevronLeft';

const defaultPromotionInfo = {
  code: '',
  id: '',
  coupon: {
    id: '',
    amount_off: 0,
    duration: '',
    name: '',
    times_redeemed: 0,
    valid: false,
    metadata: { description: '' },
  },
};

const NewPaymentPlan = ({ dependent, type }) => {
  const _SEEDLINGS = 'Seedlings';
  const _ROOTS = 'Roots';
  const _BRANCHES = 'Branches';
  const _UNVERIFIED = 'price';
  const _SEEDLINGS_V2 = 'Seedlings V2';
  const _VILLAGERS = 'Villagers';
  const _EMERALITES = 'Emeralites';
  const currentSubClassName = 'disabled';

  const _SEEDLINGS_DESCRIPTION = `You will be charged $0.00 for the next 6 months, and then `; // %s %s from 99/99/9999.`;
  const _ROOTS_DESCRIPTION = `You will be charged $0.00 for the first month, and then `; // %s %s from 99/99/9999.`;
  const _BRANCHES_DESCRIPTION = `You will be charged $0.00 for 7 days trial, and then `; // %s %s from 99/99/9999.`;
  const _SEEDLINGS_V2_DESCRIPTION = '';
  const _EMERALITES_DESCRIPTION = '';
  const _VILLAGERS_DESCRIPTION = '';
  const _PLAN_DESCRIPTION = {
    [_SEEDLINGS]: _SEEDLINGS_DESCRIPTION,
    [_ROOTS]: _ROOTS_DESCRIPTION,
    [_BRANCHES]: _BRANCHES_DESCRIPTION,
    [_EMERALITES]: _EMERALITES_DESCRIPTION,
    [_SEEDLINGS_V2]: _SEEDLINGS_V2_DESCRIPTION,
    [_VILLAGERS]: _VILLAGERS_DESCRIPTION
  };

  const [showModal, setShowModal] = useState(false);
  const [subscribeButtonClassName, setSubscribeButtonClassName] = useState('emeralites-bg');
  const [selectedPlan, setSelectedPlan] = useState('');
  const [selectedLearnMorePlan, setSelectedLearnMorePlan] = useState("emeralites");
  const history = useHistory();
  const [jobType, setJobType] = useState(type);
  const [prices, setPrices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isReviewLoading, setIsReviewLoading] = useState(true);
  const [currentSubscription, setCurrentSubscription] = useState({});
  const [subsPlanName, setSubsPlanName] = useState(_EMERALITES);
  const [isDisabled, setIsDisabled] = useState(dependent[0]?.profile?.is_activated ? true : false);
  const [isCurrentSubscription, setIsCurrentSubscription] = useState(false);
  const [isUpgrade, setIsUpgrade] = useState(true);
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);
  const [index, setIndex] = useState(11);
  const [interval, setInterval] = useState(false); // false: monthly, true: yearly
  const [buttonClassName, setButtonClassName] = useState('default-button');
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalApplePayAmount, setTotalApplePayAmount] = useState(0);
  const [promotionInfo, setPromotionInfo] = useState(defaultPromotionInfo);
  const [disablePromotion, setDisablePromotion] = useState(false);
  const [targetDependent, setTargetDependent] = useState(dependent);
  const [promotionSummaryShow, setPromotionSummaryShow] = useState('hide');
  const [paymentDescription, setPaymentDescription] = useState('');
  const [seedlingsUnverifiedSelected, setSeedlingsUnverifiedSelected] = useState({
    month: 'selected',
    year: '',
  });
  const [seedlingsSelected, setSeedlingsSelected] = useState({
    month: 'selected',
    year: '',
  });
  const [rootsSelected, setRootsSelected] = useState({ month: '', year: '' });
  const [branchesSelected, setBranchesSelected] = useState({
    month: '',
    year: '',
  });
  const [planNickname, setPlanNickname] = useState(
    <div>
      <div className="seedlings-plan">Seedlings Plan!</div>
      <div className="plan-description"></div>
    </div>
  );
  const [emeralitesSelected, setEmeralitesSelected] = useState({ month: '', year: '' });
  const [seedlingsV2Selected, setSeedlingsV2Selected] = useState({ month: '', year: '' });
  const [villagersSelected, setVillagersSelected] = useState({ month: '', year: '' });
  const { fetchAPI } = useAuth();

  const handleLearnMoreClick = (plan) => {
    setShowModal(true);
    setSelectedLearnMorePlan(plan)
  }

  const getPrices = () => {
    setIsLoading(true);
    let tempPrices = [];
    fetchAPI(stripeViewModel.getPrices) // get recurring plans
      // .then((res) => setPrice(res))
      .then((res) => {
        // console.log(res)
        tempPrices =
          res.map((price) => {
            // setIsLoading(false);
            return {
              id: price.id,
              nickname: price.nickname,
              description: price.metadata.description,
              recurringInterval: price.recurring.interval,
              unitAmount: getPrice(price.unit_amount_decimal),
              amount: price.unit_amount,
              trialPeriod: price.recurring.trial_period_days,
              app: price.metadata.App,
              weekly_creative_camp: price.metadata['Weekly Creative Camp'],
              monthly_special_camp: price.metadata['Monthly Special Camp'],
              guardian_dashboard: price.metadata['Guardian Dashboard'],
              trial: price.metadata['trial includes'],
            };
          })
        fetchAPI(stripeViewModel.getPrices, 'one_time').then((response) => {
          let oneTimePrice = response.filter((data) => data.nickname === "Emeralites")
          oneTimePrice = {
            id: oneTimePrice[0].id,
            nickname: oneTimePrice[0].nickname,
            description: oneTimePrice[0].metadata.description || '',
            recurringInterval: "free",
            unitAmount: getPrice(oneTimePrice[0].unit_amount_decimal),
            amount: oneTimePrice[0].unit_amount,
            trialPeriod: "",
            // app: oneTimePrice[0].metadata.App,
            // weekly_creative_camp: oneTimePrice[0].metadata['Weekly Creative Camp'],
            // monthly_special_camp: oneTimePrice[0].metadata['Monthly Special Camp'],
            // guardian_dashboard: oneTimePrice[0].metadata['Guardian Dashboard'],
            // trial: oneTimePrice[0].metadata['trial includes'],
          }
          let unverifiedPrice = {
            recurringInterval: "free",
            nickname: _UNVERIFIED,
            description: "Unverified"
          }
          tempPrices.push(oneTimePrice)
          tempPrices.push(unverifiedPrice)
          // setPrices(prices => [...prices, oneTimePrice])
          setPrices(tempPrices)
        })
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const _PLAN_INDEX = (plan, interval) => {
    let ind = prices.findIndex(obj => {
      return obj.recurringInterval === interval && obj.nickname === plan
    })
    return ind;
  }

  const getStartChargeDate = (index) => {
    let date = new Date();
    date.setDate(date.getDate() + prices[index]?.trialPeriod);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  useEffect(() => {
    getPrices();
  }, [])

  useEffect(() => {
    if (prices.length > 0) {
      const isEdit = jobType === 'edit';
      let __interval = subsPlanName === _SEEDLINGS || subsPlanName === _EMERALITES ? 'free' : 'month';
      let subscription = { nickname: _EMERALITES, interval: __interval };
      let planName = subscription.nickname;
      let __buttonClassName = `${subscription}-button`;
      if (isEdit) {
        // subscription = !dependent[0].subscriptions ? dependent[0]?.profile?.one_time_plan === _EMERALITES ? { nickname: _EMERALITES, interval: "free" } : { nickname: _SEEDLINGS, interval: "free" } : !dependent[0].subscriptions?.subscription_id ? dependent[0]?.profile?.one_time_plan === _EMERALITES ? { nickname: _EMERALITES, interval: "free" } : { nickname: _SEEDLINGS, interval: "free" } : dependent[0].subscriptions;
        // __interval = !dependent[0].subscriptions ? "free" : !dependent[0].subscriptions?.subscription_id ? "free" : dependent[0].subscriptions.interval;
        // planName = !dependent[0].subscriptions ? dependent[0]?.profile?.one_time_plan === _EMERALITES ? _EMERALITES : _SEEDLINGS : !dependent[0].subscriptions?.subscription_id ? _SEEDLINGS : dependent[0].subscriptions.nickname;
        // __buttonClassName = 'default-button';

        subscription = !dependent[0].subscriptions ? { nickname: _EMERALITES, interval: "free" } : !dependent[0].subscriptions?.subscription_id ? { nickname: _EMERALITES, interval: "free" } : dependent[0].subscriptions?.nickname === 'Roots' ? { nickname: _SEEDLINGS_V2, interval: "month" } : dependent[0].subscriptions?.nickname === 'Branches' ? { nickname: _VILLAGERS, interval: "month" } : dependent[0].subscriptions;
        __interval = !dependent[0].subscriptions ? "free" : !dependent[0].subscriptions?.subscription_id ? "free" : dependent[0].subscriptions.interval;
        planName = !dependent[0].subscriptions ? _EMERALITES : !dependent[0].subscriptions?.subscription_id ? _EMERALITES : dependent[0].subscriptions?.nickname === 'Roots' ? _SEEDLINGS_V2 : dependent[0].subscriptions?.nickname === 'Branches' ? _VILLAGERS : dependent[0].subscriptions.nickname;
        __buttonClassName = 'default-button';
        setCurrentSubscription(subscription);
        if (dependent[0].profile.is_activated && dependent[0].subscriptions?.nickname) {
          if (dependent[0].subscriptions?.nickname === _ROOTS || dependent[0].subscriptions?.nickname === _BRANCHES) {
            setSubsPlanName(_SEEDLINGS_V2)
            setSubscribeButtonClassName('seedlings-text-box');
          } else {
            if (dependent[0].subscriptions?.nickname === _BRANCHES) {
              setSubsPlanName(_VILLAGERS)
              setSubscribeButtonClassName('branches-text-box');
            } else {
              setSubsPlanName(dependent[0].subscriptions.nickname)
            }
          }
        } else {
          setSubsPlanName(_EMERALITES)
        }
      }
      setSelectedPlan({
        ...prices[_PLAN_INDEX(subscription.nickname, __interval)],
      });
      setIndex(_PLAN_INDEX(subscription.nickname, __interval));
      const description = _PLAN_DESCRIPTION[subscription.nickname];
      setInterval(__interval);
      setButtonClassName(__buttonClassName);

      if (prices.length > 0) {
        switch (subscription.nickname) {
          case _SEEDLINGS:
            planName = _SEEDLINGS;
            setSeedlingsV2Selected({
              ...seedlingsV2Selected,
              [__interval]: '',
            });
            setEmeralitesSelected({
              ...emeralitesSelected,
              [__interval]: '',
            });
            setVillagersSelected({
              ...villagersSelected,
              [__interval]: '',
            });
            setSeedlingsSelected({
              ...seedlingsSelected,
              // [__interval]: isEdit ? currentSubClassName : 'selected',
              [__interval]: 'selected',
            });
            setRootsSelected({
              ...rootsSelected,
              [__interval]: '',
            });
            setBranchesSelected({
              ...branchesSelected,
              [__interval]: '',
            });
            // setSelectedPlan({ ...prices[_PLAN_INDEX[_SEEDLINGS + __interval]] });
            setSubscribeButtonClassName('emeralites-bg');
            break;
          case _ROOTS:
            planName = _ROOTS;
            setSeedlingsV2Selected({
              ...seedlingsV2Selected,
              [__interval]: '',
            });
            setEmeralitesSelected({
              ...emeralitesSelected,
              [__interval]: '',
            });
            setVillagersSelected({
              ...villagersSelected,
              [__interval]: '',
            });
            setSeedlingsSelected({
              ...seedlingsSelected,
              [__interval]: '',
            });
            setRootsSelected({
              ...rootsSelected,
              // [__interval]: currentSubClassName,
              [__interval]: 'selected',
            });
            setBranchesSelected({
              ...branchesSelected,
              [__interval]: '',
            });
            // setSelectedPlan({ ...prices[_PLAN_INDEX[_ROOTS + __interval]] });
            setSubscribeButtonClassName('emeralites-bg');
            break;
          case _BRANCHES:
            planName = _BRANCHES;
            setSeedlingsV2Selected({
              ...seedlingsV2Selected,
              [__interval]: '',
            });
            setEmeralitesSelected({
              ...emeralitesSelected,
              [__interval]: '',
            });
            setVillagersSelected({
              ...villagersSelected,
              [__interval]: '',
            });
            setSeedlingsSelected({
              ...seedlingsSelected,
              [__interval]: '',
            });
            setRootsSelected({
              ...rootsSelected,
              [__interval]: '',
            });
            setBranchesSelected({
              ...branchesSelected,
              [__interval]: 'selected',
            });
            // setSelectedPlan({ ...prices[_PLAN_INDEX[_BRANCHES + __interval]] });
            setSubscribeButtonClassName('emeralites-bg');
            break;
          case _SEEDLINGS_V2:
            planName = _SEEDLINGS_V2;
            setSeedlingsV2Selected({
              ...seedlingsV2Selected,
              [__interval]: 'selected',
            });
            setEmeralitesSelected({
              ...emeralitesSelected,
              [__interval]: '',
            });
            setVillagersSelected({
              ...villagersSelected,
              [__interval]: '',
            });
            setSeedlingsSelected({
              ...seedlingsSelected,
              [__interval]: '',
            });
            setRootsSelected({
              ...rootsSelected,
              [__interval]: '',
            });
            setBranchesSelected({
              ...branchesSelected,
              [__interval]: '',
            });
            setSubscribeButtonClassName('seedlings-text-box');
            break;
          case _EMERALITES:
            planName = _EMERALITES;
            setSeedlingsV2Selected({
              ...seedlingsV2Selected,
              [__interval]: '',
            });
            setEmeralitesSelected({
              ...emeralitesSelected,
              [__interval]: 'selected',
            });
            setVillagersSelected({
              ...villagersSelected,
              [__interval]: '',
            });
            setSeedlingsSelected({
              ...seedlingsSelected,
              [__interval]: '',
            });
            setRootsSelected({
              ...rootsSelected,
              [__interval]: '',
            });
            setBranchesSelected({
              ...branchesSelected,
              [__interval]: '',
            });
            setSubscribeButtonClassName('emeralites-bg');
            break;
          case _VILLAGERS:
            planName = _VILLAGERS;
            setSeedlingsV2Selected({
              ...seedlingsV2Selected,
              [__interval]: '',
            });
            setEmeralitesSelected({
              ...emeralitesSelected,
              [__interval]: '',
            });
            setVillagersSelected({
              ...villagersSelected,
              [__interval]: 'selected',
            });
            setSeedlingsSelected({
              ...seedlingsSelected,
              [__interval]: '',
            });
            setRootsSelected({
              ...rootsSelected,
              [__interval]: '',
            });
            setBranchesSelected({
              ...branchesSelected,
              [__interval]: '',
            });
            setSubscribeButtonClassName('branches-text-box');
            break;
        }
        if (planName !== _UNVERIFIED) {
          setPlanNickname(
            <div>
              <div className={`${planName}-plan`}>{planName} Plan!</div>
              <div className="plan-description">
                {prices[_PLAN_INDEX(planName, __interval)].description}
                {/* {prices[prices.findIndex(obj => {
              return obj.recurringInterval === __interval && obj.nickname === planName
            })].description} */}
              </div>
            </div>
          );

          setPaymentDescription(
            description +
            `${prices[_PLAN_INDEX(planName, __interval)].unitAmount} ${prices[_PLAN_INDEX(planName, __interval)].recurringInterval
            } from ${getStartChargeDate(_PLAN_INDEX(planName, __interval))}`
          );
        }
        setIsDisabled(isEdit);
        setIsReviewLoading(false);
      }
    }
  }, [prices]);

  useEffect(() => {
    if (prices.length > 0 && index !== 11) {
      // console.log(prices)
      setSelectedPlan({ ...prices[index] });
      setIsLoading(false);
      // console.log("prices[index]", index)
      setIsCurrentSubscription(
        prices[index]?.recurringInterval === currentSubscription.interval
      );
      setPlanNickname(
        <div>
          <div className={`${prices[index]?.nickname}-plan`}>
            {prices[index]?.nickname} Plan!
          </div>
          <div className="plan-description">{prices[index]?.description}</div>
        </div>
      );
      if (
        prices[index]?.nickname === currentSubscription.nickname &&
        prices[index]?.recurringInterval === currentSubscription.interval
      ) {
        setButtonClassName('default-button');
        setIsDisabled(true);
      } else {
        setButtonClassName(`${prices[index]?.nickname}-button`);
        if (subsPlanName === _UNVERIFIED) {
          setIsDisabled(true);
        } else {
          // setIsDisabled(false);
        }
      }
      // const str =
      //   index <= 1
      //     ? _SEEDLINGS_DESCRIPTION
      //     : index <= 3
      //       ? _ROOTS_DESCRIPTION
      //       : _BRANCHES_DESCRIPTION;
      const str =
        subsPlanName === _SEEDLINGS
          ? _SEEDLINGS_DESCRIPTION
          : subsPlanName === _ROOTS
            ? _ROOTS_DESCRIPTION
            : _BRANCHES_DESCRIPTION;
      setPaymentDescription(
        str +
        `${prices[index]?.unitAmount || ''} ${prices[index]?.recurringInterval + 'ly' || ''
        } from ${getStartChargeDate(index)}`
      );
      setTotalAmount(prices[index]?.unitAmount);
      setTotalApplePayAmount(prices[index]?.amount);
    }
  }, [index]);

  const setPlan = (subscription) => {
    // const attr = interval ? 'year' : 'month';
    const attr = 'month';
    let selected = [
      seedlingsUnverifiedSelected[attr],
      seedlingsSelected[attr],
      rootsSelected[attr],
      branchesSelected[attr],
      emeralitesSelected[attr],
      seedlingsV2Selected[attr],
      villagersSelected[attr],
    ];

    switch (subscription) {
      case _UNVERIFIED:
        selected = [
          selected[0] === currentSubClassName ? selected[0] : 'selected',
          selected[1] === currentSubClassName ? selected[1] : '',
          selected[2] === currentSubClassName ? selected[2] : '',
          selected[3] === currentSubClassName ? selected[3] : '',
          selected[3] === currentSubClassName ? selected[4] : '',
          selected[3] === currentSubClassName ? selected[5] : '',
          selected[3] === currentSubClassName ? selected[6] : ''
        ];
        break;
      case _SEEDLINGS:
        selected = [
          selected[0] === currentSubClassName ? selected[0] : '',
          selected[1] === currentSubClassName ? selected[1] : 'selected',
          selected[2] === currentSubClassName ? selected[2] : '',
          selected[3] === currentSubClassName ? selected[3] : '',
          selected[3] === currentSubClassName ? selected[4] : '',
          selected[3] === currentSubClassName ? selected[5] : '',
          selected[3] === currentSubClassName ? selected[6] : ''
        ];
        break;
      case _ROOTS:
        selected = [
          selected[0] === currentSubClassName ? selected[0] : '',
          selected[1] === currentSubClassName ? selected[1] : '',
          selected[2] === currentSubClassName ? selected[2] : 'selected',
          selected[3] === currentSubClassName ? selected[3] : '',
          selected[3] === currentSubClassName ? selected[4] : '',
          selected[3] === currentSubClassName ? selected[5] : '',
          selected[3] === currentSubClassName ? selected[6] : ''
        ];
        break;
      case _BRANCHES:
        selected = [
          selected[0] === currentSubClassName ? selected[0] : '',
          selected[1] === currentSubClassName ? selected[1] : '',
          selected[2] === currentSubClassName ? selected[2] : '',
          selected[3] === currentSubClassName ? selected[3] : 'selected',
          selected[3] === currentSubClassName ? selected[4] : '',
          selected[3] === currentSubClassName ? selected[5] : '',
          selected[3] === currentSubClassName ? selected[6] : ''
        ];
        break;
      case _EMERALITES:
        selected = [
          selected[0] === currentSubClassName ? selected[0] : '',
          selected[1] === currentSubClassName ? selected[1] : '',
          selected[2] === currentSubClassName ? selected[2] : '',
          selected[3] === currentSubClassName ? selected[3] : '',
          selected[3] === currentSubClassName ? selected[4] : 'selected',
          selected[3] === currentSubClassName ? selected[5] : '',
          selected[3] === currentSubClassName ? selected[6] : ''
        ];
        break;
      case _SEEDLINGS_V2:
        selected = [
          selected[0] === currentSubClassName ? selected[0] : '',
          selected[1] === currentSubClassName ? selected[1] : '',
          selected[2] === currentSubClassName ? selected[2] : '',
          selected[3] === currentSubClassName ? selected[3] : '',
          selected[3] === currentSubClassName ? selected[4] : '',
          selected[3] === currentSubClassName ? selected[5] : 'selected',
          selected[3] === currentSubClassName ? selected[6] : ''
        ];
        break;
      case _VILLAGERS:
        selected = [
          selected[0] === currentSubClassName ? selected[0] : '',
          selected[1] === currentSubClassName ? selected[1] : '',
          selected[2] === currentSubClassName ? selected[2] : '',
          selected[3] === currentSubClassName ? selected[3] : '',
          selected[3] === currentSubClassName ? selected[4] : '',
          selected[3] === currentSubClassName ? selected[5] : '',
          selected[3] === currentSubClassName ? selected[6] : 'selected',
        ];
        break;
    }
    setSeedlingsUnverifiedSelected({
      ...seedlingsUnverifiedSelected,
      [attr]: selected[0],
    })
    setSeedlingsSelected({
      ...seedlingsSelected,
      [attr]: selected[1],
    });
    setRootsSelected({
      ...rootsSelected,
      [attr]: selected[2],
    });
    setBranchesSelected({
      ...branchesSelected,
      [attr]: selected[3],
    });
  };

  const handleEmeralitesClick = (e) => {
    // if (dependent[0] && dependent[0]?.profile?.is_activated) return
    if (subsPlanName === _EMERALITES) {
      setIsDisabled(true)
    } else {
      if (dependent[0] && dependent[0]?.profile?.is_activated) {
        setIsDisabled(true)
      } else {
        setIsDisabled(false)
      }
    }
    setIndex(prices.findIndex(obj => {
      return obj.nickname === _EMERALITES && obj.recurringInterval === "free"
    }));
    setPlan(_EMERALITES);
    setSubsPlanName(_EMERALITES);
    setSubscribeButtonClassName('emeralites-bg');
  };

  const handleSeedlingsV2Click = (e) => {
    if (subsPlanName === _SEEDLINGS_V2) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
    setIndex(prices.findIndex(obj => {
      return obj.nickname === _SEEDLINGS_V2 && obj.recurringInterval === "month"
    }));
    setPlan(_SEEDLINGS_V2);
    setSubsPlanName(_SEEDLINGS_V2);
    setSubscribeButtonClassName('seedlings-text-box');
  };

  const handleVillagersClick = (e) => {
    if (subsPlanName === _VILLAGERS) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
    setIndex(prices.findIndex(obj => {
      return obj.nickname === _VILLAGERS && obj.recurringInterval === "month"
    }));
    setPlan(_VILLAGERS);
    setSubsPlanName(_VILLAGERS);
    setSubscribeButtonClassName('branches-text-box');
  };

  const showAmount = (price, plan, interval) => {
    return price.nickname === plan && price.recurringInterval === interval
      ? price.unitAmount
      : '';
  };

  const isSubscription = () => {
    if (currentSubscription.nickname === _SEEDLINGS_V2
      || currentSubscription.nickname === _VILLAGERS) {
      if (subsPlanName === _EMERALITES) {
        return true
      } else {
        if (currentSubscription.nickname === _SEEDLINGS_V2 && subsPlanName === _SEEDLINGS_V2) {
          return true
        }
        if (currentSubscription.nickname === _VILLAGERS && subsPlanName === _VILLAGERS) {
          return true
        } else {
          return false
        }
      }
    } else {
      return false
    }
  }

  const getUpgradeStatus = () => {
    if (currentSubscription.nickname === _ROOTS) {
      if (selectedPlan.nickname === _SEEDLINGS_V2) {
        return false
      }
      if (selectedPlan.nickname === _VILLAGERS) {
        return true
      }
    }
    if (currentSubscription.nickname === _BRANCHES) {
      if (selectedPlan.nickname === _SEEDLINGS_V2) {
        return false
      }
      if (selectedPlan.nickname === _VILLAGERS) {
        return true
      }
    }
    if (currentSubscription.nickname === _SEEDLINGS_V2) {
      if (selectedPlan.nickname === _VILLAGERS) {
        return true
      }
    }
    if (currentSubscription.nickname === _VILLAGERS) {
      if (selectedPlan.nickname === _SEEDLINGS_V2) {
        return false
      }
    }
    if (currentSubscription.nickname === _EMERALITES) {
      return false
    }
    if (currentSubscription.nickname === _SEEDLINGS) {
      return false
    }
  }

  const handleGotoPaymentClick = () => {
    if (Object.keys(selectedPlan).length === 0)
      setSelectedPlan({
        ...prices[0],
      });
    if (isEdit && currentSubscription.nickname !== _SEEDLINGS && currentSubscription.nickname !== _EMERALITES) {
      setIsReviewLoading(true);
      // get subscription id
      stripeViewModel
        .getSubscriptionId(dependent[0].id)
        .then((res) => {
          const subscriptionId = res;
          const _currentIndex =
            _PLAN_INDEX(
              currentSubscription.nickname, currentSubscription.interval
            );
          const _nextIndex =
            _PLAN_INDEX(
              selectedPlan.nickname,
              selectedPlan.recurringInterval
            );

          // setIsUpgrade(_currentIndex < _nextIndex);
          setIsUpgrade(getUpgradeStatus());
          setCurrentSubscription({
            ...currentSubscription,
            subscription_id: subscriptionId,
          });
          // get prorated amount if it is upgrade
          if (_currentIndex < _nextIndex) {
            const params = {
              subscription_id: subscriptionId,
              price_id: selectedPlan.id,
            };
            stripeViewModel
              .previewProratedInvoice(params)
              .then((res) => {
                setTotalAmount(getPrice(res.amount_remaining));
                setTotalApplePayAmount(res.amount_remaining);
              })
              .catch((err) => {
                console.error(err);
                setIsReviewLoading(false);
              });
          }
          setIsReviewLoading(false);
          setShowPaymentMethod(true);
        })
        .catch((err) => {
          console.error(err);
          setIsReviewLoading(false);
        });
    } else {
      setShowPaymentMethod(true);
    }
    window.scrollTo(0, 0);
  };

  const isEdit = jobType === 'edit';

  const handleGobackClick = () => {
    const currentState = history.location.state || {};
    // Define the new state, keeping existing state values and updating 'from'
    const newState = { ...currentState, from: 'payment' };
    history.replace({ pathname: GUARDIAN_SETTINGS_ROUTE, state: newState });
  }

  return (
    <>
      {isLoading ?
        <div className="onboarding-wrapper payment-container payment-container-new">
          <div className="onboarding-container container-md">
            <div className="plan-list-container show">
              <Row className={`loading-message ${isLoading ? 'show-flex' : 'hide'}`}>
                Loading Our Plans...
              </Row>
            </div>
          </div>
        </div>
        :
        <>
          <div className="login-wrapper-with-img1 plan-slider" style={{ display: showPaymentMethod ? 'none' : 'block' }}>
            <div className='treehouse-back new-payment-plan-back-btn' role="button" onClick={handleGobackClick}>
              <span className="back-btn" style={{ color: '#fff' }}><HiOutlineChevronLeft /></span>
            </div>
            <div className="bg-second plan-slider-bg">
              <div className="title-subscribe text-center">Let’s select {dependent[0]?.username}’s plan</div>
              <div className="container-plan1">
                <div className="container-plan mobile-none">
                  <div className={`offers emeralites ${subsPlanName === _EMERALITES && 'selected'}`} onClick={handleEmeralitesClick}>
                    <h2>
                      {subsPlanName === _EMERALITES && <img src={checkActive} className={`check-plan`} />}
                      Emeralites
                    </h2>
                    <h3 className="price">Free</h3>
                    <h4 className="price">$1 one time fee</h4>

                    <p><img src={checkimage} className={`check-plan`} />Emerald Forest (Platform) Access</p>
                    <p><img src={checkimage} className={`check-plan`} />Accept challenges up to 20</p>
                    <p><img src={checkimage} className={`check-plan`} />Publish creations up to 20</p>
                    <span onClick={() => handleLearnMoreClick("emeralites")} href="">Learn more</span>
                  </div>
                  <div className={`offers seedlingsv2 ${subsPlanName === _SEEDLINGS_V2 && 'selected'}`} onClick={handleSeedlingsV2Click}>
                    <h2>
                      {subsPlanName === _SEEDLINGS_V2 && <img src={checkActiveSeedlings} className={`check-plan`} />}
                      Seedlings
                    </h2>
                    <h3 className="price">$5 /month</h3>
                    <h4 className="price">All Emeralites feature, plus</h4>
                    <p><img src={checkimage} className={`check-plan`} />Publish unlimited creations</p>
                    <p><img src={checkimage} className={`check-plan`} />Monthly Special Camp</p>
                    <ul>
                      <li>Live session with industry leaders</li>
                    </ul>
                    <span onClick={() => handleLearnMoreClick("seedlings")} href="">Learn more</span>
                  </div>
                  <div className={`offers villagers ${subsPlanName === _VILLAGERS && 'selected'}`} onClick={handleVillagersClick}>
                    <h2>
                      {subsPlanName === _VILLAGERS && <img src={checkActiveVillagers} className={`check-plan`} />}
                      Villagers
                    </h2>
                    <h3 className="price">$100 /month</h3>
                    <h4 className="price">All Seedlings features, plus</h4>
                    <p> <img src={checkimage} className={`check-plan`} />Weekly Creative Camp </p>
                    <ul>
                      <li>Interactive discussion sessions among kids</li>
                    </ul>
                    <span onClick={() => handleLearnMoreClick("villagers")} href="">Learn more</span>
                  </div>
                </div>
                <div className='desktop-none'>
                  <PlansSlider
                    _EMERALITES={_EMERALITES}
                    _SEEDLINGS_V2={_SEEDLINGS_V2}
                    _VILLAGERS={_VILLAGERS}
                    setShowModal={setShowModal}
                    setSelectedPlan={setSelectedPlan}
                    subsPlanName={subsPlanName}
                    handleEmeralitesClick={handleEmeralitesClick}
                    handleSeedlingsV2Click={handleSeedlingsV2Click}
                    handleVillagersClick={handleVillagersClick}
                    setSelectedLearnMorePlan={setSelectedLearnMorePlan}
                    setPlan={setPlan}
                    setSubsPlanName={setSubsPlanName}
                    setIsDisabled={setIsDisabled}
                    setSubscribeButtonClassName={setSubscribeButtonClassName}
                  />
                </div>
              </div>
              <h3 className="subscription-h3">You can cancel subscription at anytime</h3>
              <button disabled={isDisabled || subsPlanName === _UNVERIFIED || isSubscription()}
                className={`subscription-button ${currentSubscription.nickname === _EMERALITES || currentSubscription.nickname === _SEEDLINGS_V2 || currentSubscription.nickname === _VILLAGERS ? currentSubscription.nickname === subsPlanName ? 'current_plan_btn' : subscribeButtonClassName : subscribeButtonClassName}`} onClick={handleGotoPaymentClick}>
                {currentSubscription.nickname === _EMERALITES ||
                  currentSubscription.nickname === _SEEDLINGS_V2 ||
                  currentSubscription.nickname === _VILLAGERS ?
                  currentSubscription.nickname === subsPlanName ? 'Current Plan' : `Subscribe ${subsPlanName === _SEEDLINGS_V2 ? _SEEDLINGS : subsPlanName}` : `Subscribe ${subsPlanName === _SEEDLINGS_V2 ? _SEEDLINGS : subsPlanName}`}
              </button>

              <div className={'login-uni-left'}>
                <img src={LeftImage} alt='Uni' />
              </div>
              <div className={'login-uni-right'}>
                <img src={RightImage} alt='Diamonds' />
              </div>

            </div>
          </div>
          {showModal &&
            <LearnMorePopup
              showModal={showModal}
              setShowModal={setShowModal}
              selectedPlan={selectedLearnMorePlan}
              handleGotoPaymentClick={handleGotoPaymentClick}
              dependent={dependent[0]}
              isSubscription={isSubscription}
            />
          }
          <div
            className={`payment-form-container`}
            style={{ display: showPaymentMethod ? 'block' : 'none' }}
          >
            <PaymentContext.Provider
              value={{
                jobType,
                prices,
                selectedPlan,
                promotionInfo,
                setPromotionInfo,
                promotionSummaryShow,
                setPromotionSummaryShow,
                disablePromotion,
                setDisablePromotion,
                totalAmount,
                setTotalAmount,
                showAmount,
                setShowPaymentMethod,
                dependent,
                paymentDescription,
                defaultPromotionInfo,
                currentSubscription,
                isUpgrade,
                isCurrentSubscription,
                showPaymentMethod,
                totalApplePayAmount,
                setTotalApplePayAmount
              }}
            >
              <PaymentMethod />
            </PaymentContext.Provider>
          </div>
        </>}
    </>
  );
};

export default NewPaymentPlan;