import { creationRepository } from '../../../repository/creation.repository';
import { isStrNullOrEmpty } from '../../../utils/utils';

//todo get creation
function getCreations(params) {}

function getCreationByID() {
  return new Promise((resolve, reject) => {
    accountRepository
      .getDependents()
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
}

function formatCreation(params, mediaList) {
  const formBody = new FormData();
    for (var i = 0; i < mediaList.length; i++) {
      formBody.append('uploads', mediaList[i]);
    }
  if(params.title) {
    formBody.append('title', params.title);
  }
  if(params.description) {
    formBody.append('description', params.description);
  }
  if(params.challenge_id) {
  formBody.append('challenge_id', params.challenge_id);
  }
  formBody.append('user_id', params.user_id);
  if(params.tags && params.tags[0] !== undefined) {
    formBody.append('tags', params.tags)
  }
  if(params.creation_id) {
    formBody.append('creation_id', params.creation_id)
  }
  return formBody;
}

function createCreation(params, mediaList) {
  const formBody = formatCreation(params, mediaList);

  return new Promise((resolve, reject) => {
    creationRepository
      .uploadCreationAndMedia(formBody)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function editCreation(params, mediaList) {
  const formBody = formatCreation(params, mediaList);

  return new Promise((resolve, reject) => {
    creationRepository
      .editCreationAndMedia(params.creation_id, formBody)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function uploadMedia(id, mediaList) {
  const formBody = new FormData();
  for (var i = 0; i < mediaList.length; i++) {
    formBody.append('uploads', mediaList[i]);
  }
  return new Promise((resolve, reject) => {
    creationRepository
      .uploadCreationMedia(id, formBody)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const uploadViewModel = {
  getCreations,
  getCreationByID,
  createCreation,
  editCreation,
  uploadMedia,
};
