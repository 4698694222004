import React, { useEffect, useState } from 'react';
import { Modal, Image } from 'react-bootstrap';
import SuccessImage from "../../../../assets/images/Group 3746.svg";
import { useAuth } from '../../../auth/Auth';
import { useHistory } from 'react-router-dom';

function KidUpgradedModal({ userData, setCustomizePopup, handleHistoryStateChange }) {
    const { guardian } = useAuth();
    const history = useHistory();
    const dependent = guardian.dependents[guardian.dependents.findIndex(obj => obj.username === localStorage.getItem('set_dependent'))];
    const [showPopup, setShowPopup] = useState(true);
    const [planClassName, setPlanClassName] = useState('');

    const handleClose = () => {
        const currentState = history.location.state || {};
        // Define the new state, keeping existing state values and updating 'from'
        const newState = { ...currentState, from: '', isUpgrade: false };
        history.replace({ ...history.location, state: newState });
        setShowPopup(false)
    }

    useEffect(() => {
        switch (dependent?.subscriptions?.nickname) {
            case 'Roots':
                setPlanClassName('roots-text-box')
                break;
            case 'Branches':
                setPlanClassName('branches-text-box')
                break;
            case 'Villagers':
                setPlanClassName('branches-text-box')
                break;
            case 'Seedlings V2':
                setPlanClassName('seedlings-text-box')
                break;
            default:
                break;
        }
    }, [dependent])

    return (
        <>
            <Modal show={showPopup} className="account-varification my-room-modal" onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className='wonderful_image'>
                            <Image src={SuccessImage} alt="" />
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='welcome-to-room'>
                        <h4 className='mb-40 mt-20'>Congratulations.</h4>
                        {history?.location?.state?.isDowngrade ?
                            <p class="upgraded-p">You have downgraded to the <span class={`upgrade-modal-plan-text seedlings-text-box`}>Seedlings</span> plan</p>
                            : <p class="upgraded-p">You have upgraded to the <span class={`upgrade-modal-plan-text ${planClassName}`}>{dependent?.subscriptions?.nickname === 'Seedlings V2' ? 'Seedlings' : dependent?.subscriptions?.nickname}</span> plan</p>
                        }
                        <div className='customize-room-modal-action'>
                            <button style={{ minWidth: '360px', maxWidth: '360px' }} className='gred-btn load-button-container signup-btn rounded-pill float-right mb-4 font-weight-bold btn btn-primary'
                                onClick={handleClose}>
                                Continue
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default KidUpgradedModal;