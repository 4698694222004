import React from 'react'

function SubscribePlan() {
    console.log("111111")
    return (
        <div>
            TEsting

        </div>
    )
}

export default SubscribePlan
