import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import ErrorLabel from '../../common/ErrorLabel';
import ReactDOM from 'react-dom';
import Select, { createFilter } from 'react-select';
import {
  Row,
  Col,
  Container,
  Image,
  Button,
  Form,
  Spinner,
} from 'react-bootstrap';
import ImageDragDrop from './ImageDragDrop';
import { uploadViewModel } from './upload.view.model';
import { imageUtils } from '../../../utils/image.utils';
import { displayFormErrorsFromApi } from '../../api/api.display.error';
import ResponseErrorNotification from '../../api/ResponseErrorNotification';
import { useResponseError } from '../../api/response.error';
import ValidHttpStatusError from '../../api/api.error.valid';
import UploadArea from './UploadArea';
import { nextChallengeViewModel } from '../next_challenge/next.challenge.viewmodel';

import './scss/upload.scss';
import { MY_ROOM_ROUTE } from '../../../constants/routes.constants';
import { editCreationErrors } from '../../../constants/error.constants';
import ImageCropModal from '../../common/image-crop-rotate/ImageCropModal';

const EditUpload = ({
  from,
  data,
  reset,
  challengeId,
  uploadStatus,
  setUploadStatus,
  handleCloseModal,
  handleOnUploadComplete,
}) => {
  const history = useHistory();
  const { addError } = useResponseError();
  const __MAXIMUM_TITLE_CHAR = 40;
  const __MAXIMUN_DESCRIPTION_CHAR = 180;
  const { fetchAPI, dependent } = useAuth();
  const [validated, setValidated] = useState(false);
  const { register, handleSubmit, setError, errors } = useForm({
    mode: 'onBlur',
  });
  const [files, setFiles] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [titleCharCount, setTitleCharCount] = useState(__MAXIMUM_TITLE_CHAR);
  const [titleValidation, setTitleValidation] = useState(false);
  const [descriptionCharCount, setDescriptionCharCount] = useState(
    __MAXIMUN_DESCRIPTION_CHAR
  );
  const [descriptionValidation, setDescriptionValidation] = useState(false);
  const [isImageProcessing, setIsImageProcessing] = useState(false);
  const initialFormData = {
    title: data.title,
    description: data.description,
    user_id: '',
    challenge_id: '',
    creation_id: data.id,
    tags: 1
  };
  const [formData, updateFormData] = useState(initialFormData);
  const [uploading, setUploading] = useState(false);
  const [showSpinner, setShowSpinner] = useState('hide');
  const [showSubmit, setShowSubmit] = useState('show');
  const [showCover, setShowCover] = useState('hide');
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: ['image/jpeg', 'image/jpg', 'image/png', '.heic', '.HEIC'],
    onDrop: (acceptedFiles) => {
      handleDrop(acceptedFiles);
    },
  });
  const [acceptedChallenges, setAcceptedChallenges] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedChallengeId, setSelectedChallengeId] = useState(data.challengeId);
  const [selectedBranchId, setSelectedBranchId] = useState();
  const [imageCropPopup, setImageCropPopup] = useState(false);
  const [imageForCrop, setImageForCrop] = useState(null);
  const [index, setIndex] = useState('');
  const [backendImages, setBackendImages] = useState(data.media.map((image, key) => {
    return {
      preview: image.url,
      from: 'backend'
    }
  }))

  const handleDrop = (acceptedFiles) => {
    const imagePromises = [];
    setIsImageProcessing(true);
    acceptedFiles.forEach((file) => {
      const imagePromise = imageUtils.getImagePromise(file);
      imagePromises.push(imagePromise);
    });
    Promise.all(imagePromises)
      .then((newImages) => {
        const newFiles = [...files, ...newImages];
        ReactDOM.unstable_batchedUpdates(() => {
          setFiles(newFiles);
          setIsImageProcessing(false);
        });
      })
      .catch((error) => {
        setIsImageProcessing(false);
      });
  };

  const handleChange = (e) => {
    setIsDisabled(false)
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const buttonControl = () => {
    var opts = {};
    if (!validated || uploading) {
      opts['disabled'] = 'disabled';
    }
    return opts;
  };
  //remove image resources
  const revokeUris = () => {
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  };

  const themeStyle = (theme) => ({
    ...theme,
    borderRadius: '6px',
    colors: {
      ...theme.colors,
      primary: '#80e4e1',
    },
  });

  const customStyle = {
    control: (base) => ({
      ...base,
      height: 48,
      color: '#321b6d',
      fontWeight: '400',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#321b6d',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'rgb(204, 204, 204)',
    }),
  };

  const onSubmit = (e) => {
    if (selectedChallengeId === undefined) return;
    // setUploading(true);
    setShowCover('show');
    setUploadStatus('done');
  };

  const handleComplete = (e) => {
    setIsDisabled(true);
    const actionAfterUpload = (response) => {
      // revokeUris();
      // setUploading(false);
      // setShowCover('hide');
      // handleOnUploadComplete(response);
      // history.push(MY_ROOM_ROUTE)
      // handleCloseModal();
      window.location.reload()
    };

    setUploading(true);
    var data = formData;
    data.user_id = dependent.id;
    data.tags = 1;
    // console.log(selectedChallengeId);
    if (selectedChallengeId !== 0) {
      data.challenge_id = selectedChallengeId;
    }
    updateFormData(data);

    fetchAPI(uploadViewModel.editCreation, formData, files)
      .then(actionAfterUpload)
      .catch((error) => {
        console.log(error);
        if (error instanceof ValidHttpStatusError) {
          displayFormErrorsFromApi(error, setError);
        } else {
          addError(error.message);
        }
        setUploading(false);
        setShowCover('hide');
      });

    setValidated(true);
  };

  // PRocessing Uploading spinner
  useEffect(() => {
    if (uploading) {
      setShowSpinner('show');
      setShowSubmit('hide');
    } else {
      setShowSpinner('hide');
      setShowSubmit('show');
    }
  }, [uploading]);

  useEffect(() => {
    if (selectedChallengeId === undefined) return;
    setValidated(files.length != 0);
  }, [files, selectedChallengeId]);

  useEffect(() => {
    // setFiles([]);
    updateFormData(initialFormData);
    setValidated(false);
  }, [reset]);

  useEffect(() => {
    if (from === 'myroom') {
      fetchAPI(
        nextChallengeViewModel.getMyRoomChallenges,
        'all',
        { limit: 3, has_more: false, dependent_id: dependent.id },
        []
      )
        .then((data) => {
          let challenges = data.challengeList.map((el) => ({
            value: el.id,
            label: el.title,
            image: el.picture_url,
          }));
          challenges.push({ value: 0, label: 'My own creation', image: '' });
          setAcceptedChallenges(challenges);
        })
        .catch((err) => console.error(err));
    }
  }, []);

  useEffect(() => {
    fetchAPI(nextChallengeViewModel.getBranch).then((data) => {
      setBranches(data)
    }).catch((err) => console.error(err));
  }, [])

  const handleChallengeChange = (selection) => {
    setSelectedChallengeId(selection.value);
  };

  const handleBranchChange = (selection) => {
    setSelectedBranchId(selection.id);
  };

  const countTitleChar = (e) => {
    const { value } = e.target;
    const isOk = value.length <= __MAXIMUM_TITLE_CHAR && value.length != 0;
    const current = __MAXIMUM_TITLE_CHAR - value.length;
    setTitleCharCount(current <= 0 ? 0 : current);
    setTitleValidation(isOk);
  };
  const countDescriptionChar = (e) => {
    const { value } = e.target;
    const isOk =
      value.length <= __MAXIMUN_DESCRIPTION_CHAR && value.length != 0;
    const current = __MAXIMUN_DESCRIPTION_CHAR - value.length;
    setDescriptionCharCount(current <= 0 ? 0 : current);
    setDescriptionValidation(isOk);
  };

  const showImagePreview = () => {
    return files.length ? (
      <ImageDragDrop list={files} setList={setFiles} open={open} handleImageEditClick={handleImageEditClick} />
    ) : (
      <div className="d-flex preview-image-dnd-container">
        <div className="image-dnd-image-container">
          <div className="image-wrapper">
            <img className="image-dnd-image" src={data.media[0].url} />
          </div> </div>
        <UploadArea open={open} />
      </div>
    );
  };

  const handleImageEditClick = (index) => {
    console.log(files[index])
    if (files[index]) {
      setImageForCrop(files[index])
      setImageCropPopup(true)
      setIndex(index)
    }
  }

  const onCropComplete = (croppedImage) => {
    const imagePromise = imageUtils.getImagePromise(croppedImage)
    imagePromise.then((image) => {
      const list = [...files]
      list[index] = image
      setFiles(list)
      setImageForCrop(null)
      setImageCropPopup(true)
      setIndex(null)
    })
  }

  return (
    imageCropPopup && imageForCrop ?
      <ImageCropModal
        imageCropPopup={imageCropPopup}
        setImageCropPopup={setImageCropPopup}
        image={imageForCrop}
        setPicture={onCropComplete}
        heading='Edit your creation'
      />
      :
      <div>
        <Container className="upload-container">
          <Form onSubmit={handleSubmit(handleComplete)}>
            <ResponseErrorNotification />
            <Row className={`${uploadStatus === 'done' && 'hide'}`}>
              <Col className="text-center justify-content-center">
                <div className="image-table" {...getRootProps({})}>
                  {isImageProcessing ? (
                    <div className="image-dnd-container">
                      <Spinner animation="border" role="status" />
                    </div>
                  ) : (
                    showImagePreview()
                  )}
                </div>
              </Col>
            </Row>
            <Row className={`${uploadStatus === 'done' && 'hide'} mt-4`}>
              <Col className="text-center">
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input name="media" {...getInputProps()} />
                  {/* <div className="upload-button" onClick={open}>
                  <img src={uploadPlus} />
                </div> */}
                </div>
              </Col>
            </Row>
            <Form.Row
              className={`${uploadStatus === 'done' && 'hide'
                } challenge-selection`}
            >
              <Form.Group className="container-fluid">
                <div className="label-container">
                  <Form.Label className="upload-label challenge-label">
                    Challenge
                  </Form.Label>
                </div>
                <Select
                  options={acceptedChallenges}
                  value={acceptedChallenges[acceptedChallenges.findIndex(obj => {
                    if (selectedChallengeId) return obj.value === selectedChallengeId
                    else return obj.value === 0
                  })]}
                  styles={customStyle}
                  theme={themeStyle}
                  filterOption={createFilter({ ignoreAccents: false })}
                  onChange={handleChallengeChange}
                  placeholder="Select Challenge"
                  isSearchable={false}
                  formatOptionLabel={(challenge) => {
                    if (challenge.value === 0)
                      return <span>{challenge.label}</span>;
                    else
                      return (
                        <div className="challenge-option">
                          <img src={challenge.image} alt="challenge image" />
                          <span>{challenge.label}</span>
                        </div>
                      );
                  }}
                />
              </Form.Group>
            </Form.Row>
            {selectedChallengeId === 0 ?
              <Form.Row
                className={`${uploadStatus === 'done' && 'hide'
                  } challenge-selection`}
              >
                <Form.Group className="container-fluid">
                  <div className="label-container">
                    <Form.Label className="upload-label challenge-label">
                      Interest Branch
                    </Form.Label>
                  </div>
                  <Select
                    options={branches}
                    styles={customStyle}
                    theme={themeStyle}
                    filterOption={createFilter({ ignoreAccents: false })}
                    onChange={handleBranchChange}
                    placeholder="Select Interest Branch"
                    isSearchable={false}
                    formatOptionLabel={(branch) => {
                      return (
                        <div className="challenge-option">
                          <span>{branch.tag}</span>
                        </div>
                      )
                    }}
                  />
                </Form.Group>
              </Form.Row>
              : ""}
            <Form.Row
              className={`${uploadStatus === 'done' && 'hide'} creation-title`}
            >
              <Form.Group className="container-fluid">
                <div className="label-container">
                  <Form.Label className="upload-label">Title</Form.Label>
                  <div className="empty-space"></div>
                  <div
                    className={
                      titleValidation || titleCharCount === __MAXIMUM_TITLE_CHAR
                        ? 'char-count'
                        : 'warning'
                    }
                  >
                    {titleCharCount}
                  </div>
                </div>

                <Form.Control
                  className="upload-input title"
                  name="title"
                  placeholder="How will you name your precious creation?"
                  data-testid="title"
                  value={formData.title}
                  onKeyUp={(e) => countTitleChar(e)}
                  onChange={handleChange}
                  maxLength={__MAXIMUM_TITLE_CHAR}
                  ref={register({
                    required: editCreationErrors.title.required,
                  })}
                />
                <ErrorLabel
                  errors={errors}
                  name="title"
                  className="pl-1 small text-danger"
                  as="label"
                />
              </Form.Group>
            </Form.Row>
            <Form.Row
              className={`${uploadStatus === 'done' && 'hide'
                } creation-description`}
            >
              <Form.Group className="container-fluid">
                <div className="label-container">
                  <Form.Label className="upload-label">Description</Form.Label>
                  <div className="empty-space"></div>
                  <div
                    className={
                      descriptionValidation ||
                        descriptionCharCount === __MAXIMUN_DESCRIPTION_CHAR
                        ? 'char-count'
                        : 'warning'
                    }
                  >
                    {descriptionCharCount}
                  </div>
                </div>
                <Form.Control
                  className="upload-input"
                  name="description"
                  placeholder="Create your own #hashtag"
                  data-testid="description"
                  value={formData.description}
                  as="textarea"
                  rows="3"
                  onKeyUp={(e) => countDescriptionChar(e)}
                  onChange={handleChange}
                  maxLength={__MAXIMUN_DESCRIPTION_CHAR}
                  ref={register({
                    required: editCreationErrors.description.required,
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="description"
                  className="pl-1 small text-danger"
                  as="label"
                />
              </Form.Group>
            </Form.Row>
            <Row
              className={`${uploadStatus !== 'done' && 'hide'} complete-message`}
            >
              <div className="first-complete-message">
                Great Job!
                <br /> You’ve completed the Challenge!
              </div>
              <div className="second-complete-message">
                Remember, you can join the Challenge as many times as you want by
                clicking ‘Accept Challenge’ button again.
              </div>
            </Row>
            <Row>
              <Col className="text-center">
                <Button
                  className={`rounded-pill upload-submit gred-btn ${uploadStatus === 'done'
                    }`}
                  type="submit"
                  // {...buttonControl()}
                  disabled={isDisabled}
                >
                  <span className={showSubmit}>Submit</span>
                  <Spinner
                    className={`${showSpinner} edit-upload-spinner`}
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
                {/* <Button
                className={`rounded-pill upload-submit ${
                  uploadStatus !== 'done' && 'hide'
                }`}
                type="button"
                onClick={handleComplete}
              >
                <span>Go back</span>
                <Spinner
                  className={showSpinner}
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </Button> */}
              </Col>
            </Row>
          </Form>
        </Container>
        <div className={`submitCover ${showCover}`}></div>
      </div>
  );
};

export default EditUpload;
