import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import RoundButton from '../../common/RoundButton';
import { Row, Col, ProgressBar } from 'react-bootstrap';
import {
  ON_BOARDING,
  SIGN_UP_ROUTE,
  GUARDIAN_SETTINGS_ROUTE,
} from '../../../constants/routes.constants';
import { bg } from '../../../constants/component.constants';
import { changeBodyClassName } from '../../../utils/utils';
import OnboardingWrapper from './OnboardingWrapper';
import QuestionItems from './QuestionItems';
import { useAuth } from '../../auth/Auth';
import { accountViewModel } from '../account/account.view.model';

import './scss/step7.scss';

const Step7 = ({ location }) => {
  const _STEP = 3;
  const history = useHistory();
  const lsFirstName = localStorage.getItem('firstName');
  const { setGuardianDependents, refreshUserState } = useAuth();
  const [items, setItems] = useState([]);
  const [errShow, setErrShow] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [buttonLabel, setButtonLabel] = useState('Next');
  const isFromDashboard = location.state.original === 'dashboard';

  const pickQuestions = (step) => {
    const unsorted = location.state.questions.filter((q) => {
      return q.step === step;
    });
    return unsorted
      .sort((el1, el2) => el1.id - el2.id)
      .map((el) => ({
        id: el.id,
        question: el.question,
        answer: el.answers[0], // 0
        min: el.answers[0],
        max: el.answers[el.answers.length - 1],
      }));
  };

  const manufactureParams = () => {
    const first_name = localStorage.getItem('firstName');
    const birthdate = localStorage.getItem('birthdate');
    const gender = JSON.parse(localStorage.getItem('gender')).value;
    const lives_in = JSON.parse(localStorage.getItem('country')).value;
    const nearest_city = JSON.parse(localStorage.getItem('city')).value;
    const school_name = JSON.parse(localStorage.getItem('school')).value;
    const lsQ1 = JSON.parse(localStorage.getItem('question1Choices')).map(
      (el) => ({
        question: el.id,
        answer: el.answers[0],
      })
    );
    const lsQ2 = JSON.parse(localStorage.getItem('question2Items')).map(
      (el) => ({
        question: el.id,
        answer: el.answer,
      })
    );
    const lsQ3 = JSON.parse(localStorage.getItem('question3Items')).map(
      (el) => ({
        question: el.id,
        answer: el.answer,
      })
    );
    const questions = lsQ1.concat(lsQ2, lsQ3);

    return {
      first_name,
      last_name: '',
      signup: 'camp',
      profile: {
        favorites: ['', '', ''],
        taletree_friend: '',
        gender,
        birthdate,
        lives_in,
        nearest_city,
        school_name,
      },
      questions,
    };
  };

  const handleBackClick = () => {
    history.goBack();
  };
  const handleNextClick = () => {
    let destination = {};
    if (isFromDashboard) {
      destination = {
        pathname: `${GUARDIAN_SETTINGS_ROUTE}`,
      };
      const params = manufactureParams();
      accountViewModel
        .addKid(params)
        .then((res) => {
          refreshUserState();
          localStorage.clear();
        })
        .catch((err) => {
          alert(err.message);
        });
      history.push(destination);
    } else {
      destination = {
        pathname: `${SIGN_UP_ROUTE}`,
        state: {
          ...location.state,
          from: 'step7',
        },
      };
      history.push(destination);
    }
  };

  const formTagAttributes = isFromDashboard
    ? {
        method: 'POST',
        onSubmit: handleNextClick,
      }
    : {};
  const actionButton = isFromDashboard ? (
    <RoundButton type="submit" label={buttonLabel} className="next-button" />
  ) : (
    <RoundButton
      type="button"
      label={buttonLabel}
      className="next-button"
      onClick={handleNextClick}
    />
  );

  useEffect(() => {
    changeBodyClassName([bg.GRADIENT]);
    if (!location.state || location.state.from !== 'step6')
      history.replace({ pathname: `${ON_BOARDING}/step1` });
    setItems(pickQuestions(_STEP));
    if (isFromDashboard) setButtonLabel('Save');
  }, []);

  return (
    <OnboardingWrapper className="step7-wrapper">
      <Row className="content-wrapper">
        <Col className="out-of-content">
          <div className="image-wrapper">&nbsp;</div>
          <div className="text-wrapper">
            “The challenges allow me to think about the things I have never
            imagined.”
            <br />
            <br /> Yoonjae, 8 years old
          </div>
        </Col>
        <Col className="content">
          <form {...formTagAttributes}>
            <div className="progress-bar-wrapper">
              <ProgressBar now={88} />
            </div>
            <div className="title">
              It's amazing how creative children can be, when they are free to
              be themselves!
            </div>
            <div className="thin-border-line"></div>
            <div className="sub title">
              <strong>Online content:</strong> How much does {lsFirstName} enjoy
              the following online activity?
            </div>
            <div
              className={`error-message-wrapper ${errShow ? 'show' : 'hide'}`}
            >
              {errMessage}
            </div>
            <div className="input-wrapper">
              <QuestionItems items={items} setItems={setItems} step={_STEP} />
            </div>
            <div className="button-wrapper">
              <RoundButton
                type="button"
                label="Back"
                className="back-button"
                onClick={handleBackClick}
              />
              {actionButton}
              {/* <RoundButton
                type={isFromDashboard ? 'submit' : 'button'}
                label={buttonLabel}
                className="next-button"
                onClick={handleNextClick}
              /> */}
            </div>
          </form>
        </Col>
      </Row>
    </OnboardingWrapper>
  );
};

export default Step7;
