import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Form } from 'react-bootstrap';
import { properize } from '../../../utils/utils';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../auth/Auth';
import FormInput from '../../common/FormInput';
import FormPasswordInput from '../../common/FormPasswordInput';
import FormLabel from '../../common/FormLabel';
import RoundButton from '../../common/RoundButton';
import AlertMessage from '../../common/AlertMessage';
import LoadButton from '../../common/LoadButton';
import DashboardHeader from '../../header/DashboardHeader';
import SettingsNavHeader from './SettingsNavHeader';
import ErrorLabel from '../../common/ErrorLabel';
import { KID_LOGIN_ROUTE } from '../../../constants/routes.constants';
import { ERROR_REQUIRE_MSG } from '../../../constants/error.constants';
import { kidSignUpErrors } from '../../../constants/error.constants';
import { bg } from '../../../constants/component.constants';
import { changeBodyClassName } from '../../../utils/utils';
import { settingsViewModel } from './settings.view.model';
import OnboardingModal from '../onboarding/OnboardingModal';
import { displayFormErrorsFromApi } from '../../api/api.display.error';

import './css/activate.kid.scss';

const ActivateKid = () => {
  const history = useHistory();
  const location = useLocation();
  const { fetchAPI, guardian, refreshUserState, logout } = useAuth();
  const { dependent } = location.state;
  const def = { ...dependent };
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    setShowModal(false);
    // refreshUserState();
    history.goBack();
  };
  const handleShow = () => {
    setShowModal(true);
  };
  const handleNext = () => logout(KID_LOGIN_ROUTE);
  const {
    register,
    handleSubmit,
    reset,
    setError,
    errors,
    formState,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValue: def,
    shouldUnregister: false,
  });

  const onSubmit = (formData) => {
    const api = settingsViewModel.updateDependentUser;
    setIsLoading(true);
    const originFields = {
      ...formData,
      profile: {
        is_activated: true,
      },
    };
    const dirtyFields = {
      ...formState.dirtyFields,
      profile: {
        is_activated: true,
      },
    };

    fetchAPI(
      api,
      dependent.id,
      dependent.username,
      originFields,
      null,
      dirtyFields
    )
      .then((res) => {
        const newValues = { ...formData };
        newValues.profile_picture = '';
        reset(newValues);
        const newDependents = guardian.dependents.map((el, index) =>
          el.id === dependent.id ? res[0].data : el
        );
        // console.log(newDependents);
        handleShow();
      })
      .catch((err) => {
        displayFormErrorsFromApi(err, setError);
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    changeBodyClassName([bg.PURPLE]);
  }, []);

  return (
    <div className="activate-kid-wrapper">
      <DashboardHeader />
      <Container className="setting-container" fluid="md">
        <SettingsNavHeader
          showLink={false}
          title={`Create ${properize(dependent.first_name)} Account`}
        />
        <div className="subtitle col-xl-4 col-lg-4 col-md-4 col-sm-12">
          <p>Your subscription has been successfully processed.</p>
          <p>
            It’s now time to grab {dependent.first_name} to create a nickname
            and password to log in to TaleTree App. We take our kid’s security
            extremely seriously. Therefore, we advise our kids not to use their
            full name.
          </p>
        </div>
      </Container>
      <Container className="form-wrapper" fluid="md">
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
          <div id="activate" className="">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group>
                {/* <FormLabel>
                Nickname<span>*</span>
              </FormLabel> */}
                <FormInput
                  name="username"
                  ref={register({
                    required: ERROR_REQUIRE_MSG,
                  })}
                  placeholder="Create nickname"
                  data-testid="username"
                />
                <ErrorLabel errors={errors} name="username" />
              </Form.Group>
              <Form.Group>
                {/* <FormLabel>
                Password<span>*</span>
              </FormLabel> */}
                <FormPasswordInput
                  name="password"
                  ref={register({
                    required: kidSignUpErrors.password.required,
                    minLength: {
                      value: 5,
                      message: kidSignUpErrors.password.minLength,
                    },
                  })}
                  placeholder="Enter password"
                  data-testid="password"
                />
                <ErrorLabel
                  errors={errors}
                  name="password"
                  className="pl-1 small text-danger"
                  as="label"
                />
              </Form.Group>
              <Form.Group>
                {/* <FormLabel>
                Confirm password<span>*</span>
              </FormLabel> */}
                <FormPasswordInput
                  name="confirm_password"
                  ref={register({
                    validate: (value) =>
                      value === watch('password') ||
                      kidSignUpErrors.password.mismatch,
                  })}
                  placeholder="Confirm password"
                  data-testid="confirm_password"
                />
                <ErrorLabel
                  errors={errors}
                  name="confirm_password"
                  className="pl-1 small text-danger"
                  as="label"
                />
              </Form.Group>
              <Form.Group className="button-wrapper">
                <LoadButton
                  className="rounded-pill submit-button"
                  isDisabled={!formState.isDirty || isLoading}
                  isLoading={isLoading}
                  text="Submit"
                />
              </Form.Group>
            </Form>
          </div>
        </div>
        <OnboardingModal
          show={showModal}
          onHide={handleClose}
          className="activate-kid-dialog"
        >
          <div className="upper">
            <h1>
              Yay!
              <span className="kids-firstname emphasize">
                {` ${properize(dependent.first_name)}`}
              </span>
              <br />
              &nbsp; all set to go.
            </h1>
          </div>
          <div className="bottom">
            <div className="btn-wrapper">
              <RoundButton
                type="button"
                onClick={() => logout(KID_LOGIN_ROUTE)}
                label="Log In to TaleTree app"
              />
            </div>
            <div className="bottom-message">
              <span onClick={handleClose} className="message">
                Add another child
              </span>
            </div>
          </div>
        </OnboardingModal>
      </Container>
    </div>
  );
};

export default ActivateKid;
