import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import '../../common/scss/tag.selectbox.scss';

const KidSelectbox = (params) => {
  const {
    tagList,
    selectedFilter,
    selectedDependent,
    setSelectedDependent,
    showListWithTag,
  } = params;
  const [showPane, setShowPane] = useState(false);
  const [rotate, setRotate] = useState('');
  const [tagOn, setTagOn] = useState(false);
  const { dependent, fetchAPI } = useAuth();
  const history = useHistory();

//   useEffect(() => {
//     setTagOn(selectedTag !== '');
//     if (selectedTag === '') return;
//     showListWithTag();
//   }, [selectedTag]);

//   useEffect(() => {
//     if (selectedFilter !== 'tag') setSelectedTag('');
//   }, [selectedFilter]);

  const rotateArrow = () => {
    setRotate(rotate === 'rotate-180' ? 'rotate-360' : 'rotate-180');
  };

  const handleSelectboxClick = (e) => {
    setShowPane(!showPane);
    rotateArrow();
  };

  const handleClick = (e) => {
    const tag = e.target.dataset.tag;
    if (selectedDependent.id === Number(tag)) return;
    setSelectedDependent(tagList.find((element) => {
      return element.id === Number(tag)
    }));
    localStorage.setItem('set_dependent', tagList.find((element) => {
      return element.id === Number(tag)
    }).username)
    setShowPane(false);
    rotateArrow();
  };
  const gaurdianSignUp = () =>{
    history.push('/onboarding/step1')
  }

  return (
    <div className="tagSelectbox-container kid-select">
      <div
        className={`filter  tag-selectbox ${tagOn ? 'tag-selected' : ''}`}
        onClick={handleSelectboxClick}
      >
        <div className="selected-tag tag-whats">
          {selectedDependent.username === '' ? "Select Kid" : selectedDependent.username}
        </div>
        <div className={`selectbox-arrow ${rotate}`}></div>
      </div>
      <div className={`tag-list-pane ${showPane ? 'show' : 'hide'}`}>
        {tagList.map((tag, key) => {
          return (
            <div
              className="tag-item"
              onClick={handleClick}
              data-tag={tag.id}
              key={`item-${key}`}
            >
              {tag.username}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default KidSelectbox;
