import { onboardingRepository } from '../../../repository/onboarding.repository';

const getQuestions = () => {
  return new Promise((resolve, reject) => {
    onboardingRepository
      .getQuestions()
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        // console.log(err);
        reject(err);
      });
  });
};

const getCountries = () => {
  return new Promise((resolve, reject) => {
    onboardingRepository
      .getCountries()
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        // console.log(err);
        reject(err);
      });
  });
};

const getCities = (param) => {
  return new Promise((resolve, reject) => {
    onboardingRepository
      .getCities(param)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        // console.log(err);
        reject(err);
      });
  });
};

// have to send county and city. just for now only city
const getSchools = (params) => {
  return new Promise((resolve, reject) => {
    onboardingRepository
      .getSchools(params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        // console.log(err);
        reject(err);
      });
  });
};

const createGuardian = (params) => {
  return new Promise((resolve, reject) => {
    onboardingRepository
      .createGuardian(params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const childRegsitration = (id, params) => {
  return new Promise((resolve, reject) => {
    onboardingRepository
      .childRegsitration(id, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const checkUsername = (username) => {
  return new Promise((resolve, reject) => {
    onboardingRepository
      .checkUsername(username)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const subscribeTreehouse = (params) => {
  return new Promise((resolve, reject) => {
    onboardingRepository
      .subscribeTreehouse(params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const subscribeExpert = (params) => {
  return new Promise((resolve, reject) => {
    onboardingRepository
      .subscribeExpert(params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const loginKakaoUser = (code) => {
  return new Promise((resolve, reject) => {
    onboardingRepository
      .loginKakaoUser(code)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const loginGoogleUser = (access_token) => {
  return new Promise((resolve, reject) => {
    onboardingRepository
      .loginGoogleUser(access_token)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const loginMicrosoftUser = (code) => {
  return new Promise((resolve, reject) => {
    onboardingRepository
      .loginMicrosoftUser(code)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const onboardingViewModel = {
  getQuestions,
  getCountries,
  getCities,
  getSchools,
  createGuardian,
  childRegsitration,
  checkUsername,
  subscribeTreehouse,
  subscribeExpert,
  loginKakaoUser,
  loginGoogleUser,
  loginMicrosoftUser
};
