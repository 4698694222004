import React, { useEffect } from "react";
import Cartoon from "../../../assets/images/left-c-pop.png";
import CartoonMeet from "../../../assets/images/meetpopup.svg";
import { BsArrowRight } from "react-icons/bs";
import { Modal, Button } from 'react-bootstrap';
import { customEvents } from "../../../analytics/customEvents";

export default function EnterEmeraldModal(props) {
    const {
        showEnterModal,
        setShowChooseCharacterModal,
        setShowEnterModal
    } = props;

    const handleClose = () => {
        let analyticsData = {
            category: "Kid Onboarding",
            action: "enter the emerald forest",
            label: 'enter the emerald forest'
        }
        customEvents(analyticsData);
        setShowChooseCharacterModal(true);
        setShowEnterModal(false);
    }

    useEffect(() => {
        let analyticsData = {
            category: "Kid Onboarding",
            action: "kid empty state",
            label: 'kid empty state'
        }
        customEvents(analyticsData);
    }, [])

    return (
        <Modal className="signupPopup gardian mobile-v-popup auth-popup right-curved-div" show={showEnterModal} centered>
            <Modal.Body >
                <div className='row bg-blue border-right-row'>
                    <div className="col-md-6 p-0  border-left buble-relative">
                        <img className="w-100 h-100 object-fit-cover mobile-none" src={Cartoon} />
                        <img className="w-100 h-100 object-fit-cover desktop-none" src={CartoonMeet} />
                    </div>
                    <div className="col-md-6 bg-white border-right right-curve text-center auth-section white-right-arrow toparrow top75 right-bottom-curve">
                        <h4 className="modal-h4 mobile-modal-h4">
                            The TaleTree friends can’t wait to meet you!
                        </h4>
                        <Button className="gred-btn  w-80  my-3" onClick={handleClose}>Enter the Emerald Forest <BsArrowRight /></Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}