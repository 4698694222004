import React from 'react';
import { useAuth } from '../../auth/Auth';
import { Link } from 'react-router-dom';
import { KID_LOGIN_ROUTE } from '../../../constants/routes.constants';

function SignUpCompleteCard({
  className,
  imageUrl,
  name,
  message,
  link,
  linkText,
}) {
  const { logout } = useAuth();

  const handleClick = () => {
    if (link === KID_LOGIN_ROUTE) logout(link);
  };

  const displayLinkContainer = () => {
    return link === KID_LOGIN_ROUTE ? (
      <div className="link-container">
        <div className="link" onClick={() => handleClick()}>
          {linkText}
        </div>
      </div>
    ) : (
      <div className="link-container">
        <Link
          replace
          to={{
            pathname: link,
            state: { refreshState: true },
          }}
        >
          {linkText}
        </Link>
      </div>
    );
  };

  return (
    // <div className="kid-login login-container">
    <div className={className}>
      <div className="image-container">
        <img src={imageUrl} />
      </div>
      <div className="card-box">
        <div className="content-wrapper">
          <div className="card-to">
            <span>{name}</span>
          </div>
          <div className="message">{message}</div>
          {displayLinkContainer()}
        </div>
      </div>
    </div>
  );
}

export default SignUpCompleteCard;
