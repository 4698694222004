// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/upload-confetti.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../assets/images/yellow-hand.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../../assets/images/green-hand.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../../../assets/images/bracelet-hand.svg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../../../assets/images/blue-hand.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, "body{padding-bottom:50px;color:#321b6d}body .emphasize{font-weight:bold;color:#32c1bd}html{height:100%;overflow:auto}.form-control-focus,.form-control:focus{color:#495057;background-color:#fff;border-color:#321b6d;outline:0;box-shadow:unset}.form-control::-webkit-input-placeholder{color:#afafaf}.form-control:-moz-placeholder{color:#afafaf}.form-control::-moz-placeholder{color:#afafaf}.form-control:-ms-input-placeholder{color:#afafaf}.form-control::-ms-input-placeholder{color:#afafaf}.upload-modal .t-modal-header{position:relative;display:flex;justify-content:center;align-items:center;height:3em !important;background:#fff;font-size:1.4rem;font-weight:700;border-top-left-radius:15px;border-top-right-radius:15px}@media only screen and (max-width: 768px){.upload-modal .t-modal-header{font-size:1rem}}.upload-modal.done{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + "),#5d7285;background-repeat:no-repeat,no-repeat,no-repeat;background-position:top 35px center,bottom 0 left 0,bottom 0 right 10px;border-radius:20px;height:460px;min-height:unset}.upload-modal.done .t-modal-header,.upload-modal.done .t-modal-content{background:unset !important}.upload-modal.done .close-button{display:none !important}@media only screen and (max-width: 768px){.upload-modal.done{min-height:600px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + "),#5d7285;background-repeat:no-repeat,no-repeat;background-position:top 300px left 0,top 300px right 0;background-size:50%,40%}}", ""]);
// Exports
module.exports = exports;
