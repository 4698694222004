import { fetchUtils } from '../utils/fetch.utils';
import { getAPIUrl } from '../utils/config.utils';

const BASE_URL = getAPIUrl();
const ONBOARDING = '/onboarding_';
const LOCATION = '/location';

const getQuestions = () => {
  return fetchUtils.newGet(`${BASE_URL}${ONBOARDING}questions`);
};

const getCountries = () => {
  return fetchUtils.newGet(`${BASE_URL}${LOCATION}/countries`);
};

const getCities = (param) => {
  const query = fetchUtils.buildQuery(param);
  return fetchUtils.newGet(`${BASE_URL}${LOCATION}/${query}/cities`);
};

const getSchools = (params) => {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.newGet(`${BASE_URL}${LOCATION}/${query}/schools`);
};

const createGuardian = (params) => {
  return fetchUtils.post(`${BASE_URL}/guardians/signup-guardian`, params);
  // return fetchUtils.post(`${BASE_URL}/guardians/onboarding_guardian`, params);
};

const childRegsitration = (id, params) => {
  return fetchUtils.patch(`${BASE_URL}/dependents/`+id, params);
};

const checkUsername = (username) => {
  return fetchUtils.get(`${BASE_URL}/dependents/validate_username/${username}/`);
};

const subscribeTreehouse = (params) => {
  return fetchUtils.get(`${BASE_URL}/partner/subscribe-partner-on-signup?partner_id=${params.partner_id}&dependent_id=${params.dependent_id}`);
};

const subscribeExpert = (params) => {
  return fetchUtils.get(`${BASE_URL}/camp-master/subscribe-camp-master-on-signup?partner_id=${params.partner_id}&dependent_id=${params.dependent_id}`);
};

const loginKakaoUser = (code) => {
  return fetchUtils.get(`${BASE_URL}/kakao/login/callback/guardian?code=${code}`);
};

const loginGoogleUser = (access_token) => {
  return fetchUtils.get(`${BASE_URL}/google/callback?access_token=${access_token}`);
};

const loginMicrosoftUser = (code) => {
  return fetchUtils.get(`${BASE_URL}/microsoft/callback?code=${code}`);
};

export const onboardingRepository = {
  getQuestions,
  getCountries,
  getCities,
  getSchools,
  createGuardian,
  childRegsitration,
  checkUsername,
  subscribeTreehouse,
  subscribeExpert,
  loginKakaoUser,
  loginGoogleUser,
  loginMicrosoftUser
};
