import React, { useEffect, useState } from "react";
import Cartoon from "../../../../assets/images/cartoon.svg";
import FormLabel from "../../../common/FormLabel"
import { BsCheckLg } from "react-icons/bs";
import { Button, Form } from 'react-bootstrap';
import { onboardingViewModel } from "../onboarding.view.model";
import { useAuth } from "../../../auth/Auth";
import { AiOutlineEyeInvisible } from "@react-icons/all-files/ai/AiOutlineEyeInvisible";
import { AiOutlineEye } from "@react-icons/all-files/ai/AiOutlineEye";
import { changeTextToVoice, getSoundWaveJson } from "../../../../utils/camp.utils";
import Lottie from "react-lottie-player";
import LoadButton from "../../../common/LoadButton";
import { Controller, useForm } from "react-hook-form";
import ErrorLabel from "../../../common/ErrorLabel";
import { guardianSignUpErrors, schoolCodeError } from "../../../../constants/error.constants";
import { passwordStrengthValidator } from "../../../../utils/form.utils";
import SignupModal from "./SignupModal";
import FormInput from "../../../common/FormInput";
import { customEvents } from "../../../../analytics/customEvents";
import { accountViewModel } from "../../account/account.view.model";
import { KID_LOGIN_ROUTE, PAYMENT_ROUTE } from "../../../../constants/routes.constants";
import { settingsViewModel } from "../../dashboard/settings.view.model";
import ValidHttpStatusError from "../../../api/api.error.valid";
import { displayFormErrorsFromApi } from "../../../api/api.display.error";
import { useResponseError } from "../../../api/response.error";
import { HiCheckCircle } from "@react-icons/all-files/hi/HiCheckCircle";
import { BsXCircleFill } from "@react-icons/all-files/bs/BsXCircleFill";
import CheckIcon from "../../../../assets/images/Check-icon1.png";
import { useHistory } from "react-router-dom";

export default function SignupStep7({ childData, setCampDetails, setShowCampMemberModal, setNewChild }) {
    const history = useHistory();
    const { refreshUserState } = useAuth();
    const [nickName, setNickName] = useState("");
    const [password, setpassword] = useState("");
    const [confirmPass, setconfirmPass] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [userNameError, setUserNameError] = useState(false);
    const [confPassError, setConfPassError] = useState(false);
    const [isUsername, setIsUsername] = useState(false);
    const DEFAULT_TEXT = `We take our kids’ security extremely seriously. Therefore, we advise our kids not to use their full name.`
    const [text, setText] = useState(DEFAULT_TEXT);
    const [isPlay, setIsPlay] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [isDisable, setIsDisable] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isCampLoading, setIsCampLoading] = useState(false);
    const [showModal, setShowModal] = useState(true)
    const [showCongratulationsModal, setShowCongratulationsModal] = useState(false)
    const [createdChild, setCreatedChild] = useState({})
    const [uniqueCode, setUniqueCode] = useState("");
    const [isCodeVerified, setIsCodeVerified] = useState();
    const [showCodeInput, setShowCodeInput] = useState(false);
    const { addError, removeError } = useResponseError();
    const { register, handleSubmit, setError, errors, watch, setValue, clearErrors, control, trigger } = useForm({
        mode: 'onBlur',
    });

    const handleShow = async (e) => {
        let analyticsData = {
            category: "Kid Onboarding",
            action: "kid nickname submit",
            label: 'kid nickname submit'
        }
        customEvents(analyticsData);
        setIsLoading(true);

        if (password !== confirmPass) {
            setIsLoading(false)
            return setConfPassError("Password does not match")
        }

        if (password === confirmPass && nickName != '' && password != '' && confirmPass != '') {
            // const params = {
            //     "profile": {
            //         "nick_name": nickName,
            //     },
            //     "username": nickName,
            //     "password": password
            // };

            const params = childData
            params.username = nickName
            params.password = password
            params.profile.nick_name = nickName

            // onboardingViewModel
            //     .childRegsitration(dependent.id, params)
            //     .then(async (res) => {
            //         await refreshUserState();
            //         // history.push(GUARDIAN_SETTINGS_ROUTE);
            //         setShowModal(false);
            //         setNickName("")
            //         setpassword("")
            //         // setShowModal8(true);
            //     }).catch((error) => {
            //         setUserNameError(error.message);
            //     })
            accountViewModel
                .addKid(params)
                .then(async (res) => {
                    setCreatedChild(res.data)
                    setNewChild(res.data)
                    setShowModal(false);
                    setShowCongratulationsModal(true);
                    setNickName("")
                    setpassword("")
                })
                .catch((err) => {
                    setUserNameError(error.message);
                })
        } else {
            setIsLoading(false)
        }
    };

    const checkUsername = (username) => {
        onboardingViewModel.checkUsername(username).then((res) => {
            setUserNameError(false);
            setIsUsername(true);
            setIsDisable(false);
        }).catch((err) => {
            setIsUsername(false);
            setUserNameError(err.serverResponse.error_info)
            setIsDisable(true);
        })
    }

    const setUserName = (e) => {
        let analyticsData = {
            category: "Kid Onboarding",
            action: "nickname",
            label: 'nickname'
        }
        customEvents(analyticsData);
        setNickName(e.target.value);
        if (e.target.value) checkUsername(e.target.value)
    }
    const confirmPassword = (e) => {
        let analyticsData = {
            category: "Kid Onboarding",
            action: "confirm password",
            label: 'confirm password'
        }
        customEvents(analyticsData);
        setConfPassError(false);
        setconfirmPass(e);
        trigger('confirm_password');
    }
    const handelPassword = (e) => {
        let analyticsData = {
            category: "Kid Onboarding",
            action: "password",
            label: 'password'
        }
        customEvents(analyticsData);
        const value = e.target.value;
        setpassword(value);
        setValue('password', value, { shouldValidate: true });
        
        if (passwordStrengthValidator(value)) {
            clearErrors('password');
        }

        if(confirmPass) {
            trigger('confirm_password');
        }
    }
    const handleVoiceClick = () => {
        setIsPlay(!isPlay);
        changeTextToVoice(text, isPlay, setIsPlay, isPaused, setIsPaused);
    }
    const handleClose = () => {
        setShowCongratulationsModal(false)
    }

    useEffect(() => {
        let analyticsData = {
            category: "Kid Onboarding",
            action: "create kid nickname",
            label: 'create kid nickname'
        }
        customEvents(analyticsData);
    }, [])

    const handleEnterClick = () => {
        setShowCodeInput(true)
    }

    const handleSkipClick = () => {
        refreshUserState();
    }

    const handleNoClick = () => {
        refreshUserState();
        history.push({
            pathname: `${PAYMENT_ROUTE}`,
            state: {
                dependent: JSON.stringify(createdChild),
                type: 'activate',
            },
        })
    }

    const handleCode = (e) => {
        checkTreehouse(e.target.value)
        // setUniqueCode(e.target.value)
    }

    const checkTreehouse = (code) => {
        let body = {
            group_code: code
            // camp_code: code
        }
        settingsViewModel.checkTreehouse(code).then((res) => {
            setIsCodeVerified(true);
            // setIsDisabled(false);
            setUniqueCode(code);
        }).catch((err) => {
            // setIsDisabled(true);
            setIsCodeVerified(false);
        })
    }

    const handleSubmitCode = () => {
        setIsCampLoading(true);
        // setIsDisabled(true);
        let params = {
            dependent_id: createdChild?.id,
            group_code: uniqueCode
        }
        settingsViewModel.connectTreehouse(params).then((res) => {
            // setShowCongratulationsModal(false)
            // history.push({
            //     state: {
            //         from: 'verified'
            //     }
            // })
            // refreshUserState();
            setCampDetails(res)
            setShowCampMemberModal(true)
            setShowCongratulationsModal(false)
            setIsCampLoading(false)
        }).catch((err) => {
            console.log(err)
            // setIsDisabled(false)
            setIsCampLoading(false)
            if (err instanceof ValidHttpStatusError) {
                displayFormErrorsFromApi(err, setError);
            } else {
                addError(err.message);
            }
        })
    }

    return (
        <>
            <SignupModal className="gardian auth-popup right-curved-div" showModal={showModal} handleClose={handleClose}>
                <div className='row bg-blue border-40'>
                    <div className="col-md-6 col-lg-5 p-4 bg-blue border-left overflow-hidden">
                        <div className="voice-buble buuble-bounce" id="SpeechBubble" style={{ width: '230px' }}>
                            <p id='voice-text' >
                                {text}
                            </p>
                        </div>
                        <div className="text-left">
                            {/* <p className="text-white voice-btn" onClick={handleVoiceClick}><BsVolumeUpFill /> Voice on</p> */}
                            <div className="" style={{marginTop: '9rem'}}>
                                {/* <Lottie
                                    loop
                                    animationData={getSoundWaveJson()}
                                    play={isPlay}
                                    className='animate-svg'
                                /> */}
                            </div>
                        </div>
                        <div className="cartoon-img">
                            <img className="w-100" src={Cartoon} />
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-7 bg-white border-right right-curve auth-section white-right-arrow toparrow topinmius right-bottom-curve">
                        <p className="auth-entry-text">Create <span className="user-highlight"> {childData?.first_name}’s</span> account</p>
                        <p className="pb-4">It’s now time to grab {childData?.first_name} to create a nickname and password to log into TaleTree App.</p>
                        <div>
                            <Form onSubmit={handleSubmit(() => handleShow())} className="auth-form create-form-input" autoComplete="off">
                                <Form.Row >
                                    <Form.Group className="container-fluid">
                                        <FormLabel>Create nickname<span className="text-danger">*</span></FormLabel>
                                        <FormInput
                                            type="text"
                                            required
                                            className={`input-modal ${isUsername && ' valid-input'}`}
                                            onChange={setUserName}
                                            name="nickname"
                                            value={nickName}
                                            autoComplete="off"
                                            autoSave="off"
                                        />
                                        {isUsername &&
                                            <BsCheckLg className="input-valid-check" />
                                        }
                                        {userNameError ? (
                                            <p style={{ color: 'red' }} className='pt-2'>
                                                {userNameError}
                                            </p>
                                        ) : ''}
                                    </Form.Group>
                                    <Form.Group className="container-fluid">
                                        <FormLabel>Create password<span className="text-danger">*</span></FormLabel>
                                        <Controller
                                            name="password"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                validate: value => passwordStrengthValidator(value) || guardianSignUpErrors.password.strength
                                            }}
                                            render={({ field }) => (
                                                <FormInput
                                                    {...field}
                                                    type={showPassword ? "text" : "password"}
                                                    placeholder="At least 8 characters"
                                                    onChange={(e) => {
                                                        // field.onChange(e);
                                                        handelPassword(e);
                                                    }}
                                                    className="input-modal"
                                                    required
                                                />
                                            )}
                                        />
                                        <span className='show-ic add-pointer' style={{ top: '26px' }} onClick={() => setShowPassword(!showPassword)}>
                                            {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                                        </span>
                                        {errors.password && <ErrorLabel errors={errors} name="password" className="small text-danger" as="label" />}
                                    </Form.Group>
                                    <Form.Group className="container-fluid">
                                        <FormLabel>Confirm password<span className="text-danger">*</span></FormLabel>
                                        <FormInput
                                            required
                                            type={showConfirmPassword ? "text" : "password"}
                                            placeholder="Confirm password"
                                            onChange={(e) => confirmPassword(e.target.value)}
                                            className="input-modal"
                                            name="confirm_password"
                                            ref={register({
                                                validate: (value) =>
                                                    value === watch('password') ||
                                                    guardianSignUpErrors.password.mismatch,
                                            })}
                                        />
                                        <span className='show-ic add-pointer' style={{ top: '26px' }} onClick={() => setShowConfirmPassword(!showConfirmPassword)}>{showConfirmPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}</span>
                                        {confPassError ? (
                                            <p style={{ color: 'red' }} className='pt-2'>
                                                {confPassError}
                                            </p>
                                        ) : ''}
                                        {errors.confirm_password && <ErrorLabel errors={errors} name="confirm_password" className="small text-danger" as="label" />}
                                    </Form.Group>

                                    <div className="row d-block w-100" >
                                        <div className="">
                                            <LoadButton
                                                className="gred-btn new_button_account w-100"
                                                type="submit"
                                                isDisabled={isDisable || errors?.password || errors?.confirm_password}
                                                isLoading={isLoading}
                                                text='Submit'
                                            />
                                        </div>
                                    </div>
                                </Form.Row>
                            </Form>
                        </div>

                    </div>
                </div>
            </SignupModal>
            <SignupModal className="gardian auth-popup right-curved-div" showModal={showCongratulationsModal}>
                <div className='row bg-blue border-40'>
                    <div className="col-md-6 col-lg-5 p-4 bg-blue border-left overflow-hidden">
                        <div className="voice-buble buuble-bounce" id="SpeechBubble" style={{ width: '230px' }}>
                            <p id='voice-text' >
                                {`We love it when kids join a camp. Your kid will be assigned to a tribe that can chat together and meet with their camp's captain weekly.`}
                            </p>
                        </div>
                        <div className="text-left">
                            {/* <p className="text-white voice-btn" onClick={handleVoiceClick}><BsVolumeUpFill /> Voice on</p> */}
                            <div className="" style={{marginTop: '9rem'}}>
                                {/* <Lottie
                                    loop
                                    animationData={getSoundWaveJson()}
                                    play={isPlay}
                                    className='animate-svg'
                                /> */}
                            </div>
                        </div>
                        <div className="cartoon-img">
                            <img className="w-100" src={Cartoon} />
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-7 signup-7 bg-white border-right right-curve auth-section white-right-arrow toparrow topinmius right-bottom-curve">

                        <div className="logo-account-created">
                            <img className="w-100" src={CheckIcon} />
                        </div>

                        <h1 className="congratulations-h1">Congratulations!</h1>
                        <p className="auth-entry-text entry-text-p"> <span className={childData?.first_name ? "bg-text" : ""}><span className="user-highlight">{childData?.first_name}’s</span></span> account has been created!</p>
                        {/* <p className="pb-4">It’s now time to grab {childData?.first_name} to create a nickname and password to log into TaleTree App.</p> */}

                        <p className="pb-4">If you have a code provided by your Camp Captain (or teacher), please enter it here.</p>
                        <div>
                            {/* <div className="upper">
                                <h1>
                                    Yay!
                                    <span className="kids-firstname emphasize">
                                        {` ${properize(childData?.first_name)}`}
                                    </span>
                                    <br />
                                    &nbsp; Account has been created.
                                </h1>
                            </div> */}
                            {showCodeInput &&
                                <Form>
                                    <Form.Row className='mb-0 mt-40 w-100 signup-7'>
                                        <Form.Group className="d-flex justify-content-center w-100">
                                            <FormInput
                                                className="signup-input w-100"
                                                name="classCode"
                                                placeholder="Enter your unique class code"
                                                onChange={handleCode}
                                                data-testid="classCode"
                                                maxLength={40}
                                                ref={register({
                                                    required: schoolCodeError.code.required,
                                                    invalid: schoolCodeError.code.invalid
                                                })}
                                            />
                                            {isCodeVerified ?
                                                <span className='signup-input-check-ic'><HiCheckCircle /></span>
                                                : isCodeVerified === false ?
                                                    <span className='signup-input-cross-ic'><BsXCircleFill /></span>
                                                    : ""}
                                        </Form.Group>
                                        <ErrorLabel errors={errors} name="classCode" as="label" />
                                    </Form.Row>
                                </Form>
                            }
                            {showCodeInput ?
                                <div className="bottom bottom-cong-blue">
                                    <div className="button-wrapper">
                                        <Form.Group className="container-fluid w-100 no-yes-buttons">
                                            <Button
                                                className="load-button-container create-submit float-left new_button_account rounded-pill mb-4 font-weight-bold"
                                                variant="light"
                                                onClick={handleSkipClick}
                                                disabled={isCampLoading}
                                            >
                                                <span>Skip</span>
                                            </Button>
                                            <LoadButton
                                                className="gred-btn new_button_account signup-btn rounded-pill float-right mb-4 font-weight-bold"
                                                text="Enter"
                                                isDisabled={isCampLoading}
                                                isLoading={isCampLoading}
                                                // testid="submit"
                                                onClick={handleSubmitCode}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                :
                                <div className="bottom bottom-created">
                                    <div className="button-wrapper bottom-created1">
                                        <Form.Group className="container-fluid w-100 account-created no-yes-buttons">
                                            <Button
                                                className="load-button-container float-left rounded-pill mb-4 font-weight-bold"
                                                variant="light"
                                                onClick={handleNoClick}
                                            >
                                                <span>No</span>
                                            </Button>
                                            <LoadButton
                                                className="gred-btn signup-btn rounded-pill float-right mb-4 font-weight-bold"
                                                text="Yes"
                                                // isDisabled={isDisabled}
                                                // isLoading={isLoading}
                                                // testid="submit"
                                                onClick={handleEnterClick}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </SignupModal>
        </>
    )
}