import React, { useState, useEffect, useContext, createContext } from 'react';
import { useHistory } from 'react-router';
import { useAuth } from '../../auth/Auth';
import { stripeViewModel } from '../payment/stripe.view.model';
import { getPrice } from '../../../utils/camp.utils';
// Stripe
import {
  useStripe,
  useElements,
  CardNumberElement,
  // CardExpiryElement,
  // CardCvcElement,
} from '@stripe/react-stripe-js';
import { GUARDIAN_SETTINGS_ROUTE } from '../../../constants/routes.constants';
import { stripeRepository } from '../../../repository/stripe.repository';

const paymentContext = createContext();

export const ProvidePayment = ({ children }) => {
  const payment = useProvidePayment();
  return (
    <paymentContext.Provider value={payment}>
      {children}
    </paymentContext.Provider>
  );
};

export const usePayment = () => {
  return useContext(paymentContext);
};

const useProvidePayment = () => {
  const history = useHistory();
  const { guardian, fetchAPI, refreshUserState } = useAuth();
  const defaultPromotionInfo = {
    code: '',
    id: '',
    promo_code_id: 0,
    total_amount: 0,
    discount_percentage: 0,
    discount_amount: 0,
    remaining_amount: 0,
    status: '',
  };
  const [promotionInfo, setPromotionInfo] = useState(defaultPromotionInfo);
  const [promotionSummaryShow, setPromotionSummaryShow] = useState('hide');
  const [price, setPrice] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [specialCamp, setSpecialCamp] = useState({});
  const [paymentMethodInfo, setPaymentMethodInfo] = useState(null);
  const [paymentResult, setPaymentResult] = useState(false);
  const [dependent, setDependent] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const getPrices = () => {
    setIsLoading(true);
    fetchAPI(stripeViewModel.getPrices, 'one_time')
      .then((res) => {
        setPrice(
          res.map((price) => {
            const result = {
              id: price.id,
              amount: price.unit_amount,
              unitAmount: getPrice(price.unit_amount_decimal),
              nickname: price.nickname
            };
            return result;
          })
        );
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  };

  const createPaymentMethod = async () => {
    const cardHolderName = `${guardian.first_name} ${guardian.last_name}`;
    const cardElement = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: cardHolderName,
      },
    });

    if (error) {
      console.error(error);
      setErrorMessage(error.message);
    } else {
      setPaymentMethodInfo(paymentMethod);
      handlePurchase(paymentMethod);
    }
  };

  const handlePurchase = (method) => {
    const req =
      totalAmount === 0
        ? {
            dependent_id: dependent.id,
            special_camp_id: specialCamp.id,
            promo_code_id: promotionInfo.promo_code_id,
          }
        : {
            payment_method_id: method.id,
            amount: totalAmount,
            dependent_id: dependent.id,
            special_camp_id: specialCamp.id,
            promo_code_id: promotionInfo.promo_code_id,
          };
    setIsLoading(true);
    setErrorMessage('');
    // console.log(req);
    if (totalAmount === 0) {
      stripeViewModel
        .paymentComplete(req)
        .then((res) => {
          setPaymentResult('succeeded');
          refreshUserState();
          setIsLoading(false);
          history.replace({
            pathname: `${GUARDIAN_SETTINGS_ROUTE}/special-camp/payment/complete`,
            state: {
              from: 'special-camp-payment',
            },
          });
        })
        .catch((err) => {
          console.error(err);
          if (err.statusCode === 404)
            setErrorMessage(err.serverResponse.error_info);
          else setErrorMessage('');
          // setShowErrorMessage('show')
          setIsLoading(false);
        });
    } else {
      stripeViewModel
        .paymentIntent(req)
        .then(async (res) => {
          const {
            error: stripeError,
            paymentIntent,
          } = await stripe.confirmCardPayment(res.client_secret, {
            payment_method: method.id,
          });
          if (stripeError) {
            // console.log(stripeError.message);
            setPaymentResult(false);
            setErrorMessage(stripeError.message);
          } else {
            setPaymentResult(paymentIntent.status === 'succeeded');
            refreshUserState();
            history.replace({
              pathname: `${GUARDIAN_SETTINGS_ROUTE}/special-camp/payment/complete`,
              state: {
                from: 'special-camp-payment',
              },
            });
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.error(err);
          if (err.statusCode === 404)
            setErrorMessage(err.serverResponse.error_info);
          else setErrorMessage('');
          // setShowErrorMessage('show')
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    getPrices();
  }, []);

  return {
    dependent,
    price,
    totalAmount,
    isLoading,
    specialCamp,
    stripe,
    elements,
    defaultPromotionInfo,
    promotionInfo,
    promotionSummaryShow,
    setPromotionSummaryShow,
    getPrices,
    setSpecialCamp,
    setDependent,
    setIsLoading,
    setPromotionInfo,
    setPaymentMethodInfo,
    setTotalAmount,
    createPaymentMethod,
    handlePurchase,
  };
};
