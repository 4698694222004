import { accountRepository } from '../../../repository/account.repository';
import { treehouseRepository } from '../../../repository/treehouse.repository';
import { getDirtyValues } from '../../../utils/form.utils';

const updateDependentUser = (
  id,
  username,
  originalFormData,
  profilePicture,
  changedFormData
) => {
  const params = getDirtyValues(changedFormData, originalFormData);
  const profileUpdatePromises = [];
  const { profile_picture, ...profile } = params;
  if (Object.keys(profile).length !== 0) {
    profileUpdatePromises.push(
      accountRepository.updateDependentUser(id, profile)
    );
  }
  if (profile_picture) {
    const formBody = new FormData();
    formBody.append('image', profilePicture);
    profileUpdatePromises.push(accountRepository.uploadPhoto(id, formBody));
  }

  return new Promise((resolve, reject) => {
    Promise.all(profileUpdatePromises)
      .then((response) => resolve(response))
      .catch((error) => {
        reject(error);
      });
  });
};

const updateDependentFavorites = (id, originalFormData) => {
  const data = {
    profile: {
      favorites: [
        originalFormData.favorites.color,
        originalFormData.favorites.sport,
        originalFormData.favorites.animal,
      ],
    },
  };
  return new Promise((resolve, reject) => {
    accountRepository
      .updateDependentUser(id, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => reject(error));
  });
};

const updateTaletreeFriend = (id, params) => {
  const data = {
    profile: {
      taletree_friend: params.taletree_friend,
    },
  };
  return new Promise((resolve, reject) => {
    accountRepository
      .updateDependentUser(id, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => reject(error));
  });
};

const updateTaletreeFriendAndFavorites = (id, params) => {
  const data = {
    profile: {
      taletree_friend: params.taletree_friend,
      favorites: [
        params.favorites.color,
        params.favorites.sport,
        params.favorites.animal,
      ],
    },
  };
  return new Promise((resolve, reject) => {
    accountRepository
      .updateDependentUser(id, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => reject(error));
  });
};

const updateDependentPassword = (id, password) => {
  const data = {
    password,
  };
  return new Promise((resolve, reject) => {
    accountRepository
      .updateDependentUser(id, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => reject(error));
  });
};

const updateGuardianPassword = (id, email, formData) => {
  const credentials = {
    username: email,
    password: formData.password,
    remember_me: false,
  };
  const newPassword = {
    password: formData.new_password,
  };
  return new Promise((resolve, reject) => {
    accountRepository
      .guardianLogin(credentials)
      .then(() => {
        return accountRepository.updateGuardianUser(id, newPassword);
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.resultCode === 400001) {
          error.message = 'Invalid password';
        }
        reject(error);
      });
  });
};

const updateGuardianEmail = (id, originalFormData, changedFormData) => {
  const params = getDirtyValues(changedFormData, originalFormData);
  params.username = params.email;
  return new Promise((resolve, reject) => {
    accountRepository
      .updateGuardianUser(id, params)
      .then((result) => {
        // console.log(result);
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const connectTreehouse = (params) => {
  return new Promise((resolve, reject) => {
    treehouseRepository
      .connectTreehouse(params)
      .then((result) => {
        // console.log(result);
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const disConnectTreehouse = (params) => {
  return new Promise((resolve, reject) => {
    treehouseRepository
      .disConnectTreehouse(params)
      .then((result) => {
        // console.log(result);
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const checkTreehouse = (params) => {
  return new Promise((resolve, reject) => {
    treehouseRepository
      .checkTreehouse(params)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const settingsViewModel = {
  updateDependentUser,
  updateGuardianEmail,
  updateGuardianPassword,
  updateDependentFavorites,
  updateTaletreeFriend,
  updateTaletreeFriendAndFavorites,
  updateDependentPassword,
  connectTreehouse,
  disConnectTreehouse,
  checkTreehouse
};
