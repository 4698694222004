import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Container, Row, Col, Image, Spinner } from 'react-bootstrap';
import { addMyRoomClassName } from '../../../utils/utils';
import CampHeader from '../../header/CampHeader';
import CampHeaderGuest from '../../header/CampHeaderGuest';
import Avatar from '../../common/Avatar';
import uploadIcon from '../../../assets/icon/icon-upload.png';
import defaultProfilePic from '../../../assets/profile/default-profile-guardian.svg';
import CreationUpload from '../upload/CreationUpload';
import { FULL_BIG_TYPE } from '../../../constants/avatarType.constants';
import { bg, theme } from '../../../constants/component.constants';
import EditChildProfileModal from './EditChildProfileModal';
import CreationGallery from './CreationGallery';
import { useAuth } from '../../auth/Auth';
import { myRoomViewModel } from './my.room.viewmodel';
import { nextChallengeViewModel } from '../next_challenge/next.challenge.viewmodel';
import InfiniteScroll from 'react-infinite-scroller';
import { useResponseError } from '../../api/response.error';
import { useCreationHolder } from '../../context/creation/creation.holder.context';
import {
  actionTypes,
} from '../../context/creation/creation.reducer';
import TaleTreeFriend from './TaleTreeFriend';
import Favorites from './Favorites';
import MyRoomChallenges from './MyRoomChallenges';
import './css/myroom.scss';
import WelcomeRoomModal from './WelcomeRoomModal';
import CustomizeRoomModal from './CustomizeRoomModal';
import GetVerifiedNowModal from '../next_challenge/getVerifiedNowModal';
import defaultBannerImg from '../../../assets/images/Default_cover_image.jpg';
import EventEmitter from '../../../utils/events';
import { treehouseViewModel } from '../../common/treehouse.view.model';
import { BsCheck } from '@react-icons/all-files/bs/BsCheck';
import RoundButton from '../../common/RoundButton';
import { ADD_MORE_BRANCHES, ADD_MORE_TREEHOUSES, EXPERT_ROUTE, NEXT_CHALLENGE_ROUTE } from '../../../constants/routes.constants';
import verfiedIcon from '../../../assets/icon/Verified-badge.svg';
import unVerfiedIcon from '../../../assets/icon/Unverified.svg';
import NewCustomizeRoomModal from './NewCustomizeRoomModal';
import InterestBranchesModal from './InterestBranchesModal';
import EnterEmeraldModal from './EnterEmeraldModal';
import SpaceBanner from '../../../assets/images/Banner-Space.png';
import ForestBanner from '../../../assets/images/Banner-Forest.png';
import LoveBanner from '../../../assets/images/Banner-Love.png';
import MyRoomCamp from '../camp_schedule/MyRoomCamp';
import TimeToUpgradeModal from '../onboarding/signup_popups/TimeToUpgradeModal';

const MyRoom = () => {
  const location = useLocation();
  const history = useHistory();
  const { dependent, guardian, fetchAPI, setUser } = useAuth();
  const { addError } = useResponseError();
  const [showUpload, setShowUpload] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showGetVerifiedNow, setShowGetVerifiedNow] = useState(false);
  const [challengeCount, setChallengeCount] = useState(0);
  const [creationCount, setCreationCount] = useState(0);
  const [binkyCount, setBinkyCount] = useState(0);
  const [bgImg, setBgImg] = useState();
  const [noRepeatClass, setNoRepeatClass] = useState('no-repeat');
  const [tabFlag, setTabFlag] = useState(
    location.state && location.state.tab ? location.state.tab : 'creation'
  );
  const [taletreeFriend, setTaletreeFriend] = useState(
    dependent && dependent.taletree_character
  );
  const [showChooseCharacterModal, setShowChooseCharacterModal] = useState(
    false
  );
  const [showFavoritesModal, setShowFavoritesModal] = useState(false);
  const [showInterestBranchesModal, setShowInterestBranchesModal] = useState(false);
  const [showUploadButton, setShowUploadButton] = useState(true);
  const [query, setQuery] = useState({
    limit: 15,
    has_more: true,
  });
  const [acceptedChallenges, setAcceptedChallenges] = useState([]);
  const [completedChallenges, setCompletedChallenges] = useState([]);
  const [treehouses, setTreehouses] = useState([]);
  const [branches, setBranches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(dependent?.profile?.banner_is_fullscreen);
  const handleChooseCharacterClose = () => setShowChooseCharacterModal(false);
  const handleChooseCharacterShow = () => setShowChooseCharacterModal(true);
  const handleFavoritesClose = () => {
    if (treehouses && treehouses[0]?.id) {
      history.replace({
        state: {
          from: 'add_challenges',
        },
      });
      setShowFavoritesModal(false);
    } else {
      setShowInterestBranchesModal(true);
      setShowFavoritesModal(false);
    }
  };
  const handleFavoritesShow = () => setShowFavoritesModal(true);
  const handleCloseUpload = () => setShowUpload(false);
  const handleShowUpload = () => {
    if (dependent && !dependent.profile.is_activated) {
      setShowGetVerifiedNow(true)
    } else {
      setShowUpload(true)
    }
  };
  let myRoomImageStyle = {
    backgroundImage: `url(${bgImg})`
  }
  const handleCloseEditProfile = () => setShowEditProfile(false);
  const [customizePopup, setCustomizePopup] = useState(false);
  const [newCustomizePopup, setNewCustomizePopup] = useState(false);
  const [chooseCustomizeType, setChooseCustomizeType] = useState('');
  const [showEnterModal, setShowEnterModal] = useState(showChooseCharacterModal);
  const [roomImageStyle, setRoomImageStyle] = useState({ backgroundImage: bgImg ? dependent.profile?.banner_url : defaultBannerImg });
  const [selectedTabClass, setSelectedTabClass] = useState('selected-dark');
  const [unSelectedTabClass, setUnselectedTabClass] = useState('unselected');
  const [nameTextColor, setNameTextColor] = useState('text-dark');
  const [interestTickColor, setInterestTickColor] = useState('interest-tickbtn-outline-light');
  const [zoomClass, setZoomClass] = useState('');
  const [showTimeToUpgradePopup, setShowTimeToUpgradePopup] = useState(false);
  const [upgradeType, setUpgradeType] = useState('both');
  const handleHistoryStateChange = () => {
    history.push({
      state: ""
    })
  }
  const onEditProfile = () => {
    setShowEditProfile(true)
  };

  const handleOnUploadComplete = (creation) => {
    dispatchCreationHolder({
      type: actionTypes.ADD_CREATION,
      payload: creation,
    });
  };
  const { creationHolder, dispatchCreationHolder } = useCreationHolder();
  const fetchCreations = () => {
    setIsLoading(true);
    const queryParams = {
      dependent_id: dependent.id,
      for_challenges: false,
      limit: 9,
      starting_after: creationHolder.user.starting_after,
    };
    fetchAPI(myRoomViewModel.getCreations, queryParams, creationHolder.user)
      .then((data) => {
        dispatchCreationHolder({
          type: actionTypes.UPDATE_USER_CREATIONS,
          payload: data,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        // addError(error.data.message);
      });
  };

  const fetchInfiniteCreations = () => {
    if (!isLoading) fetchCreations();
  }

  useEffect(() => {
    const params = {
      'dependent_id': dependent.id
    };
    fetchAPI(myRoomViewModel.getBadgeCount, params)
      .then((response) => {
        ReactDOM.unstable_batchedUpdates(() => {
          setChallengeCount(response.dependent_challenge_count);
          setCreationCount(response.dependent_creation_count);
          setBinkyCount(response.binky_count);
        });
      })
      .catch((error) => {
        addError(error.data.message);
      });
  }, [creationHolder.user.creationList]);

  const handleTheme = () => {
    if (dependent) {
      if (dependent.profile?.banner_theme) {
        if (dependent.profile?.banner_is_fullscreen) {
          setBgImg(null);
          let tempId = dependent.profile.banner_theme;
          let className;
          if (tempId === 'space') {
            className = theme.SPACE
            setSelectedTabClass('selected-light')
            setUnselectedTabClass('unselected-light')
            setNameTextColor('text-light')
            setInterestTickColor('interest-tickbtn-outline-dark')
          }
          if (tempId === 'forest') {
            className = theme.FOREST
            setBgImg('');
            setSelectedTabClass('selected-dark')
            setNameTextColor('text-dark')
            setInterestTickColor('interest-tickbtn-outline-light')
          }
          if (tempId === 'love') {
            className = theme.LOVE
            setBgImg('');
            setSelectedTabClass('selected-dark')
            setNameTextColor('text-dark')
            setInterestTickColor('interest-tickbtn-outline-light')
          }
          addMyRoomClassName([className])
        } else {
          setBgImg(getThemeBannerImage(dependent.profile?.banner_theme))
          setNoRepeatClass('no-repeat')
          addMyRoomClassName([bg.WHITE]);
          setSelectedTabClass('selected-dark')
          setUnselectedTabClass('unselected')
          setNameTextColor('text-dark')
        }
      } else {
        if (dependent.profile?.banner_is_fullscreen) {
          let body = document.getElementById('myroom-theme');
          body.style.backgroundImage = `url(${dependent.profile?.banner_url})`
          addMyRoomClassName(['customFullScreen'])
        } else {
          setBgImg(dependent.profile?.banner_url ? dependent.profile?.banner_url : defaultBannerImg)
          if (dependent.profile?.banner_url) setNoRepeatClass('no-repeat')
          addMyRoomClassName([bg.WHITE]);
        }
      }
    }
  }

  const getThemeBannerImage = (id) => {
    switch (id) {
      case 'space':
        return SpaceBanner
        break;
      case 'forest':
        return ForestBanner
        break;
      case 'love':
        return LoveBanner
        break
      default:
        break;
    }
  }

  useEffect(() => {
    handleTheme();
    setTaletreeFriend(dependent.profile.taletree_friend);
    setShowEnterModal(dependent.profile.taletree_friend === '');
    getSubscribedTreehouses();
    getBranchList();
    fetchCreations();
    return () => {
      EventEmitter.unsubscribe('banner_pic', getSelectedUserData);
    }
  }, []);

  useEffect(() => {
    if (tabFlag === 'challenge') {
      fetchAcceptedChallenges();
      fetchCompletedChallenges();
    }
  }, [tabFlag]);

  const setChallenges = (challenges, filter) => {
    if (filter === 'all') setCompletedChallenges(challenges);
    else setAcceptedChallenges(challenges);
  };

  const fetchAcceptedChallenges = () => {
    let params = { ...query, dependent_id: dependent.id };
    fetchAPI(
      nextChallengeViewModel.getMyRoomChallenges,
      'working',
      params,
      acceptedChallenges
    )
      .then((data) => {
        setChallenges(data.challengeList, 'working');
      })
      .catch((err) => console.log(err));
  };

  const fetchCompletedChallenges = () => {
    let params = { ...query, dependent_id: dependent.id };
    fetchAPI(
      nextChallengeViewModel.getMyRoomChallenges,
      'all',
      params,
      completedChallenges
    )
      .then((data) => {
        setChallenges(data.challengeList, 'all');
        setQuery(data.query);
      })
      .catch((err) => console.log(err));
  };
  const handleDeleteCreationById = (id) => {
    dispatchCreationHolder({
      type: actionTypes.DELETE_CREATION_BY_ID,
      payload: id,
    });
  };

  const handleBinky = (creationId, binkyId, binkyCount) => {
    const list = creationHolder.user.creationList;
    const newList = list.map((obj) => {
      return obj.id === creationId
        ? {
          ...obj,
          binky_count: binkyCount,
          binky_id: binkyId,
        }
        : obj;
    });
    dispatchCreationHolder({
      type: actionTypes.UPDATE_USER_CREATIONS,
      payload: {
        ...creationHolder.user,
        creationList: newList,
      },
    });
  };

  const handleComment = (creationId, commentCount) => {
    const list = creationHolder.user.creationList;
    const newList = list.map((obj) => {
      return obj.id === creationId
        ? {
          ...obj,
          comment_count: commentCount,
        }
        : obj;
    });
    dispatchCreationHolder({
      type: actionTypes.UPDATE_USER_CREATIONS,
      payload: {
        ...creationHolder.user,
        creationList: newList,
      },
    });
  };

  const emeraldProps = {
    showEnterModal,
    setShowChooseCharacterModal,
    setShowEnterModal
  }

  const taletreeFriendProps = {
    showChooseCharacterModal,
    taletreeFriend,
    setTaletreeFriend,
    handleChooseCharacterClose,
    handleFavoritesShow,
  };

  const favoritesProps = {
    showFavoritesModal,
    taletreeFriend,
    handleFavoritesClose
  };

  const interestBranchesProps = {
    showInterestBranchesModal,
    setShowInterestBranchesModal,
    taletreeFriend
  }

  const getSelectedUserData = (data) => {
    if (dependent) {
      setBgImg(data.preview)
      setRoomImageStyle({ backgroundImage: data.preview })
    }
  }

  const getSubscribedTreehouses = () => {
    // treehouseViewModel.getSubscribedTreehouses().then((res) => {
    treehouseViewModel.getSubscribedCampMasters(dependent?.id).then((res) => {
      setTreehouses(res)
    }).catch((err) => { });
  }

  const getBranchList = () => {
    fetchAPI(nextChallengeViewModel.getBranch)
      .then((res) => {
        setBranches(res);
      })
      .catch((err) => { });
  };

  const handleBranchClick = (tag) => {
    if (history.location.pathname !== NEXT_CHALLENGE_ROUTE) {
      history.push({
        pathname: NEXT_CHALLENGE_ROUTE,
        state: {
          tag: tag,
          filter: 'tag'
        }
      })
    }
  }

  const setTreehouseForAdd = (data) => {
    if (treehouses.indexOf(treehouses.find(obj => obj.id === data.id)) != -1) {
      fetchAPI(treehouseViewModel.unSubscribeTreehouse, data.id)
        .then((res) => {
          getSubscribedTreehouses();
        })
        .catch((err) => { });
    }
  }

  EventEmitter.subscribe('banner_pic', getSelectedUserData);

  const onTreehouseClick = (data) => {
    let id = data?.slug ? data?.slug : data?.id
    history.push({
      pathname: `${EXPERT_ROUTE}/${id}`,
      state: {
        type: data?.slug ? 'slug' : 'id'
      }
    })
  }

  const [selectedMember, setSelectedMember] = useState({})

  const handleMemberCLick = (member) => {
    if (member?.id === dependent?.id) {
      setSelectedMember({})
    } else {
      setSelectedMember(member)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location?.state?.from === 'map') {
      setTimeout(() => {
        setZoomClass('page-loaded ');
      }, 300)
    }
  }, []);

  return (
    <>
      {/* {dependent && <CompanionPrompt />} */}
      <div className={`myroom-main ${zoomClass}`}>
        {guardian ?
          <CampHeaderGuest /> : <CampHeader />
        }
        <div>
          <CreationUpload
            from="myroom"
            showUpload={showUpload}
            handleClose={handleCloseUpload}
            handleOnUploadComplete={handleOnUploadComplete}
            setShowTimeToUpgradePopup={setShowTimeToUpgradePopup}
          />
          {showTimeToUpgradePopup && <TimeToUpgradeModal showPopup={showTimeToUpgradePopup} setShowPopup={setShowTimeToUpgradePopup} upgradeType='creation' />}
        </div>
        <div>
          <EditChildProfileModal
            showModal={showEditProfile}
            handleClose={handleCloseEditProfile}
            setNewCustomizePopup={setNewCustomizePopup}
            handleTheme={handleTheme}
            setIsFullScreen={setIsFullScreen}
            getThemeBannerImage={getThemeBannerImage}
            setSelectedTabClass={setSelectedTabClass}
            setUnselectedTabClass={setUnselectedTabClass}
            setNameTextColor={setNameTextColor}
            setInterestTickColor={setInterestTickColor}
          />
        </div>
        <div className=" " id='myroom-theme'>
          <div className="myroom-profile-info main-tab">

            <div style={{ backgroundImage: bgImg }}>
              <Container
                className={bgImg ? `myroom-info-container ${noRepeatClass}` : !isFullScreen ? `myroom-info-container ${noRepeatClass}` : "fullscreen-banner"}
                style={myRoomImageStyle}
              >
                <Row className="myroom-info-wrapper">
                  <Col md={6} lg={7} xl={7} className="myroom-collapse">
                    <Avatar
                      type={FULL_BIG_TYPE}
                      handleEditProfile={onEditProfile}
                      creationCount={creationCount}
                      challengeCount={challengeCount}
                      binkyCount={binkyCount}
                    />
                  </Col>
                  <Col
                    md={{ span: 6 }}
                    lg={{ span: 5 }}
                    xl={{ span: 5 }}
                    className="join-camp-wrapper"
                  >
                    <MyRoomCamp />
                  </Col>
                </Row>
              </Container>
            </div>
            <Col className={`myroom-creation-title ${!isFullScreen && 'white-bg'}`}>
              <div
                className={`tab tab-left pt-4 pb-2 ${tabFlag === 'creation' ? selectedTabClass : unSelectedTabClass
                  }`}
                onClick={() => {
                  setTabFlag('creation');
                  setShowUploadButton(true);
                }}
              >
                <div>Creations ({creationCount})</div>
              </div>
              <div
                className={`tab tab-right pt-4 pb-2 ${tabFlag === 'challenge' ? selectedTabClass : unSelectedTabClass
                  }`}
                onClick={() => {
                  setTabFlag('challenge');
                  setShowUploadButton(false);
                }}
              >
                <div>Challenges ({challengeCount})</div>
              </div>
              <div
                className={`tab tab-right pt-4 pb-2 ${tabFlag === 'interest' ? selectedTabClass : unSelectedTabClass
                  }`}
                onClick={() => {
                  setTabFlag('interest');
                  setShowUploadButton(false);
                }}
              >
                <div>Branches ({branches.length})</div>
              </div>
              <div
                className={`tab tab-right pt-4 pb-2 ${tabFlag === 'treehouse' ? selectedTabClass : unSelectedTabClass
                  }`}
                onClick={() => {
                  setTabFlag('treehouse');
                  setShowUploadButton(false);
                }}
              >
                <div>Experts ({treehouses.length})</div>
              </div>
            </Col>
            {tabFlag === 'creation' ? (
              <div className={`myroom-creation-container ${!isFullScreen && 'white-bg'}`}>
                <InfiniteScroll
                  pageStart={0}
                  loadMore={fetchInfiniteCreations}
                  hasMore={creationHolder.user.has_more}
                  useWindow={false}
                  getScrollParent={() => document.getElementById('myroom-theme')}
                  loader={
                    <Spinner
                      key={0}
                      className="myroom-spinner"
                      animation="border"
                      variant="info"
                    />
                  }
                  threshold={150}
                >
                  <CreationGallery
                    creationList={creationHolder.user.creationList}
                    deleteCreationById={handleDeleteCreationById}
                    handleBinky={handleBinky}
                    handleComment={handleComment}
                  />
                </InfiniteScroll>
              </div>
            ) : tabFlag === "challenge" ? (
              <Row className="myroom-challenge-container">
                <div className="section-div section-ipad-0">
                  <h5 className={nameTextColor}>{acceptedChallenges.length} Accepted</h5>
                  <MyRoomChallenges
                    challenges={acceptedChallenges}
                    filter="working"
                  />
                </div>
                <div className="section-div section-ipad-0">
                  <h5 className={nameTextColor}>{completedChallenges.length} Completed</h5>
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={fetchCompletedChallenges}
                    hasMore={query.has_more}
                    loader={
                      <Spinner
                        key={0}
                        className="myroom-spinner"
                        animation="border"
                        variant="info"
                      />
                    }
                    threshold={150}
                  >
                    <MyRoomChallenges
                      challenges={completedChallenges}
                      filter="all"
                    />
                  </InfiniteScroll>
                </div>
              </Row>
            ) : tabFlag === "interest" ? (
              <div className="myroom-challenge-container center-div">
                <div className='section-div section-ipad-0 small-div interst-div'>
                  <div className='mt-5 mb-5'>
                    {branches.map((item, key) => {
                      return (
                        <div className='flex-div mb-3' role='button' key={key} onClick={() => handleBranchClick(item.tag)}>
                          <span className='interst-tag'>#{item.tag}</span>
                          <button className={`btn-outline interst-btn ${interestTickColor}`} ><BsCheck /></button>
                        </div>
                      )
                    })}
                  </div>
                  <RoundButton
                    type="button"
                    label="Add more Branches"
                    className="button small-btn text-white gred-btn"
                    onClick={() => history.push({
                      pathname: ADD_MORE_BRANCHES,
                      state: {
                        from: 'dashboard',
                      }
                    })}
                  />
                </div>
              </div>
            ) : (
              <Container className='section-div small-div'>
                <div className="add-more-treehouses-inner ">
                  <div className="treehouses-list interst-div">
                    <ul className='mt-5 mb-5'>
                      {treehouses.map((item, key) => {
                        return (
                          <li key={key}>
                            <div className="treehouese-logo-name" role='button' >
                              <div className="treehouese-logo-wrapper " onClick={() => onTreehouseClick(item)}>
                                <div className="treehouese-logo">
                                  <Image className="blue_badge" src={item.picture_url ? item.picture_url : defaultProfilePic} />
                                </div>
                                <span className="check-patch verify-patch">
                                  <Image className="green-tick" src={item?.is_verified ? verfiedIcon : unVerfiedIcon} />
                                </span>
                              </div>
                              <h4 className={`treehouese-name ${nameTextColor}`} onClick={() => onTreehouseClick(item)}>{`${item?.first_name} ${item?.last_name}`}</h4>
                            </div>
                            <div className="subsribe-btn" onClick={() => setTreehouseForAdd(item)}>
                              <button className={`btn-outline tree-selected`} style={{ color: '#fff !important' }} ><span className="subscribed"><BsCheck /></span> Subscribed </button>
                            </div>
                          </li>
                        )
                      })}
                      <RoundButton
                        type="button"
                        label="Add more Experts"
                        className="button text-white gred-btn"
                        onClick={() => history.push(ADD_MORE_TREEHOUSES)}
                      />
                    </ul>
                  </div>
                </div>
              </Container>
            )
            }
          </div>
        </div>
        {!guardian &&
          <div className={`myroom-upload ${showUploadButton ? 'show' : 'hide'}`}>
            <Button variant="transparent shadow-none" onClick={handleShowUpload}>
              <Image src={uploadIcon} />
            </Button>
          </div>
        }

        {showChooseCharacterModal && <TaleTreeFriend {...taletreeFriendProps} />}
        <EnterEmeraldModal {...emeraldProps} />
        <Favorites {...favoritesProps} />
        {showInterestBranchesModal && <InterestBranchesModal {...interestBranchesProps} />}
        {history.location.state && history.location.state.from === 'add_challenges' ?
          <WelcomeRoomModal userData={dependent.profile} setCustomizePopup={setNewCustomizePopup} handleHistoryStateChange={handleHistoryStateChange} />
          : ''}

        {customizePopup &&
          <CustomizeRoomModal
            customizePopup={customizePopup}
            setCustomizePopup={setCustomizePopup}
            handleHistoryStateChange={handleHistoryStateChange}
            chooseCustomizeType={chooseCustomizeType}
            setNewCustomizePopup={setNewCustomizePopup}
            setBgImg={setBgImg}
            isFullScreen={isFullScreen}
            setIsFullScreen={setIsFullScreen}
            setRoomImageStyle={setRoomImageStyle}
            setSelectedTabClass={setSelectedTabClass}
            setUnselectedTabClass={setUnselectedTabClass}
            handleTheme={handleTheme}
            setNameTextColor={setNameTextColor}
            setInterestTickColor={setInterestTickColor}
            getThemeBannerImage={getThemeBannerImage}
          />
        }

        {newCustomizePopup &&
          <NewCustomizeRoomModal
            newCustomizePopup={newCustomizePopup}
            setNewCustomizePopup={setNewCustomizePopup}
            setCustomizePopup={setCustomizePopup}
            handleHistoryStateChange={handleHistoryStateChange}
            setChooseCustomizeType={setChooseCustomizeType}
          />
        }
        {/* </>
            } */}
        {/* </div> */}
        {showGetVerifiedNow &&
          <GetVerifiedNowModal show={showGetVerifiedNow} setShow={setShowGetVerifiedNow} />}
        {/* <MyRoomRightSideBar
            isKidsRoom={true}
            setRightCollapsse={setRightCollapsse}
            isNewChallengesButton={true}
            selectedTabClass={selectedTabClass}
            leftCollapsse={leftCollapsse}
          /> */}
      </div>
    </>
  );
};

export default MyRoom;