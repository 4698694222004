import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Badge } from 'react-bootstrap';
import './css/all.schedule.scss';
import { campScheduleViewModel } from './camp.schedule.view.model';
import { useAuth } from '../../auth/Auth';
import ZoomButton from './ZoomButton';

function AllSchedule() {
  const { fetchAPI } = useAuth();
  const [groups, setGroups] = useState([]);
  useEffect(() => {
    fetchAPI(campScheduleViewModel.getGroups)
      .then((response) => setGroups(response))
      .catch((error) => console.log(error));
  }, []);
  return (
    <div className="all-schedule-container">
      {groups.map((group) => (
        <div className="group-day-wrapper" key={group.day}>
          <div className="day">
            <span>{group.day}</span>
          </div>
          {group.groupList.map((list) => (
            <div className="group" key={list.name}>
              <div className="group-wrapper">
                <div className="group-name">
                  <span>{list.name}</span>
                </div>
                <div className="group-age">
                  <Badge className="text-white" variant="light-orange">
                    {list.ageGroup}
                  </Badge>
                </div>
                <div className="group-time">
                  <span>{list.localTime}</span>
                </div>
              </div>
              <div className="zoom-button-wrapper">
                <ZoomButton title="Join Zoom" />
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default AllSchedule;
