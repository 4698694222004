import React, { useEffect, useState } from 'react';
import { Row, Col, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useAuth } from '../../auth/Auth';
import LoadButton from '../../common/LoadButton';
import { stripeViewModel } from './stripe.view.model';
import { showPrice, getPrice } from '../../../utils/camp.utils';
import PaymentMethod from './PaymentMethod';
// import PlanBox from './PlanBox';
// import SubscriptionSummary from './SubscriptionSummary';
// import { isPendingInterview, isCampMember, isCanceled } from '../../../utils/camp.utils'
// import { isStrNullOrEmpty, priceWithDollerSign } from '../../../utils/utils';
import { bg } from '../../../constants/component.constants';
import { changeBodyClassName } from '../../../utils/utils';
import OnboardingWrapper from '../onboarding/OnboardingWrapper';
import SettingsNavHeader from '../dashboard/SettingsNavHeader';
import UnVeryfied from '../../../assets/icon/Unverified.svg'
import Veryfied from '../../../assets/icon/Verified-badge.svg'
import backIcon from '../../../assets/icon/back-icon.svg';

import './scss/payment.scss';
import { GUARDIAN_SETTINGS_ROUTE } from '../../../constants/routes.constants';
import { useHistory } from 'react-router-dom';

export const PaymentContext = React.createContext();

const PaymentSubscription = ({ dependent, type }) => {
  const _SEEDLINGS = 'Seedlings';
  const _ROOTS = 'Roots';
  const _BRANCHES = 'Branches';
  const _UNVERIFIED = 'price';
  // const _PLAN_INDEX = {
  //   [_SEEDLINGS + 'month']: 0,
  //   [_UNVERIFIED + 'month']: 1,
  //   [_ROOTS + 'month']: 2,
  //   [_ROOTS + 'year']: 3,
  //   [_BRANCHES + 'month']: 4,
  //   [_BRANCHES + 'year']: 5,
  //   [_SEEDLINGS + 'free']: 6
  // };
  const currentSubClassName = 'disabled';

  const _SEEDLINGS_DESCRIPTION = `You will be charged $0.00 for the next 6 months, and then `; // %s %s from 99/99/9999.`;
  const _ROOTS_DESCRIPTION = `You will be charged $0.00 for the first month, and then `; // %s %s from 99/99/9999.`;
  const _BRANCHES_DESCRIPTION = `You will be charged $0.00 for 7 days trial, and then `; // %s %s from 99/99/9999.`;
  const _PLAN_DESCRIPTION = {
    [_SEEDLINGS]: _SEEDLINGS_DESCRIPTION,
    [_ROOTS]: _ROOTS_DESCRIPTION,
    [_BRANCHES]: _BRANCHES_DESCRIPTION,
  };

  const { fetchAPI, refreshUserState } = useAuth();
  const defaultPromotionInfo = {
    code: '',
    id: '',
    coupon: {
      id: '',
      amount_off: 0,
      duration: '',
      name: '',
      times_redeemed: 0,
      valid: false,
      metadata: { description: '' },
    },
  };
  const history = useHistory();
  const [jobType, setJobType] = useState(type);
  const [prices, setPrices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isReviewLoading, setIsReviewLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isCurrentSubscription, setIsCurrentSubscription] = useState(false);
  const [isUpgrade, setIsUpgrade] = useState(true);
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [index, setIndex] = useState(11);
  const [interval, setInterval] = useState(false); // false: monthly, true: yearly
  const [showM, setShowM] = useState('show');
  const [showY, setShowY] = useState('hide');
  const [currentSubscription, setCurrentSubscription] = useState({});
  const [subsPlanName, setSubsPlanName] = useState(_UNVERIFIED)
  const [seedlingsUnverifiedSelected, setSeedlingsUnverifiedSelected] = useState({
    month: 'selected',
    year: '',
  });
  const [seedlingsSelected, setSeedlingsSelected] = useState({
    month: 'selected',
    year: '',
  });
  const [rootsSelected, setRootsSelected] = useState({ month: '', year: '' });
  const [branchesSelected, setBranchesSelected] = useState({
    month: '',
    year: '',
  });
  const [planNickname, setPlanNickname] = useState(
    <div>
      <div className="seedlings-plan">Seedlings Plan!</div>
      <div className="plan-description"></div>
    </div>
  );
  const [buttonClassName, setButtonClassName] = useState('default-button');
  const [totalAmount, setTotalAmount] = useState(0);
  const [promotionInfo, setPromotionInfo] = useState(defaultPromotionInfo);
  const [disablePromotion, setDisablePromotion] = useState(false);
  const [targetDependent, setTargetDependent] = useState(dependent);
  const [promotionSummaryShow, setPromotionSummaryShow] = useState('hide');
  const [paymentDescription, setPaymentDescription] = useState('');

  const getPrices = () => {
    setIsLoading(true);
    let tempPrices = [];
    fetchAPI(stripeViewModel.getPrices) // get recurring plans
      // .then((res) => setPrice(res))
      .then((res) => {
        // console.log(res)
        tempPrices =
          res.map((price) => {
            // setIsLoading(false);
            return {
              id: price.id,
              nickname: price.nickname,
              description: price.metadata.description,
              recurringInterval: price.recurring.interval,
              unitAmount: getPrice(price.unit_amount_decimal),
              amount: price.unit_amount,
              trialPeriod: price.recurring.trial_period_days,
              app: price.metadata.App,
              weekly_creative_camp: price.metadata['Weekly Creative Camp'],
              monthly_special_camp: price.metadata['Monthly Special Camp'],
              guardian_dashboard: price.metadata['Guardian Dashboard'],
              trial: price.metadata['trial includes'],
            };
          })
        fetchAPI(stripeViewModel.getPrices, 'one_time').then((response) => {
          setIsLoading(false)
          let oneTimePrice = response.filter((data) => data.nickname === "Seedlings")
          oneTimePrice = {
            id: oneTimePrice[0].id,
            nickname: oneTimePrice[0].nickname,
            description: oneTimePrice[0].metadata.description,
            recurringInterval: "free",
            unitAmount: getPrice(oneTimePrice[0].unit_amount_decimal),
            amount: oneTimePrice[0].unit_amount,
            trialPeriod: "",
            app: oneTimePrice[0].metadata.App,
            weekly_creative_camp: oneTimePrice[0].metadata['Weekly Creative Camp'],
            monthly_special_camp: oneTimePrice[0].metadata['Monthly Special Camp'],
            guardian_dashboard: oneTimePrice[0].metadata['Guardian Dashboard'],
            trial: oneTimePrice[0].metadata['trial includes'],
          }
          let unverifiedPrice = {
            recurringInterval: "month",
            nickname: _UNVERIFIED,
            description: "Unverified"
          }
          tempPrices.push(oneTimePrice)
          tempPrices.push(unverifiedPrice)
          // setPrices(prices => [...prices, oneTimePrice])
          setPrices(tempPrices)
        })
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const _PLAN_INDEX = (plan, interval) => {
    let ind = prices.findIndex(obj => {
      return obj.recurringInterval === interval && obj.nickname === plan
    })
    return ind;
  }

  useEffect(() => {
    changeBodyClassName([bg.PAYMENT]);
    getPrices();
    // console.log(dependent);
  }, []);

  const getStartChargeDate = (index) => {
    let date = new Date();
    date.setDate(date.getDate() + prices[index].trialPeriod);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  useEffect(() => {
    if (prices.length > 0) {
      console.log(prices)
      const isEdit = jobType === 'edit';
      let __interval = subsPlanName === _SEEDLINGS ? 'free' : 'month';
      let subscription = { nickname: _UNVERIFIED, interval: __interval };
      let planName = subscription.nickname;
      let __buttonClassName = `${subscription}-button`;
      if (isEdit) {
        subscription = !dependent[0].subscriptions ? { nickname: _SEEDLINGS, interval: "free" } : !dependent[0].subscriptions?.subscription_id ? { nickname: _SEEDLINGS, interval: "free" } : dependent[0].subscriptions;
        __interval = !dependent[0].subscriptions ? "free" : !dependent[0].subscriptions?.subscription_id ? "free" : dependent[0].subscriptions.interval;
        planName = !dependent[0].subscriptions ? _SEEDLINGS : !dependent[0].subscriptions?.subscription_id ? _SEEDLINGS : dependent[0].subscriptions.nickname;
        __buttonClassName = 'default-button';
        setCurrentSubscription(subscription);
        if (dependent[0].profile.is_activated && dependent[0].subscriptions?.nickname) {
          setSubsPlanName(dependent[0].subscriptions.nickname)
        } else {
          setSubsPlanName(_SEEDLINGS)
        }
      }
      setSelectedPlan({
        ...prices[_PLAN_INDEX(subscription.nickname, __interval)],
      });
      setIndex(_PLAN_INDEX(subscription.nickname, __interval));
      const description = _PLAN_DESCRIPTION[subscription.nickname];
      setInterval(__interval);
      setButtonClassName(__buttonClassName);

      if (prices.length > 0) {
        switch (subscription.nickname) {
          case _SEEDLINGS:
            planName = _SEEDLINGS;
            setSeedlingsSelected({
              ...seedlingsSelected,
              // [__interval]: isEdit ? currentSubClassName : 'selected',
              [__interval]: 'selected',
            });
            setRootsSelected({
              ...rootsSelected,
              [__interval]: '',
            });
            setBranchesSelected({
              ...branchesSelected,
              [__interval]: '',
            });
            // setSelectedPlan({ ...prices[_PLAN_INDEX[_SEEDLINGS + __interval]] });
            break;
          case _ROOTS:
            planName = _ROOTS;
            setSeedlingsSelected({
              ...seedlingsSelected,
              [__interval]: '',
            });
            setRootsSelected({
              ...rootsSelected,
              // [__interval]: currentSubClassName,
              [__interval]: 'selected',
            });
            setBranchesSelected({
              ...branchesSelected,
              [__interval]: '',
            });
            // setSelectedPlan({ ...prices[_PLAN_INDEX[_ROOTS + __interval]] });
            break;
          case _BRANCHES:
            planName = _BRANCHES;
            setSeedlingsSelected({
              ...seedlingsSelected,
              [__interval]: '',
            });
            setRootsSelected({
              ...rootsSelected,
              [__interval]: '',
            });
            setBranchesSelected({
              ...branchesSelected,
              [__interval]: 'selected',
            });
            // setSelectedPlan({ ...prices[_PLAN_INDEX[_BRANCHES + __interval]] });
            break;
        }
        if (planName !== _UNVERIFIED) {
          setPlanNickname(
            <div>
              <div className={`${planName}-plan`}>{planName} Plan!</div>
              <div className="plan-description">
                {prices[_PLAN_INDEX(planName, __interval)].description}
                {/* {prices[prices.findIndex(obj => {
              return obj.recurringInterval === __interval && obj.nickname === planName
            })].description} */}
              </div>
            </div>
          );

          setPaymentDescription(
            description +
            `${prices[_PLAN_INDEX(planName, __interval)].unitAmount} ${prices[_PLAN_INDEX(planName, __interval)].recurringInterval
            } from ${getStartChargeDate(_PLAN_INDEX(planName, __interval))}`
          );
        }
        setIsDisabled(isEdit);
        setIsReviewLoading(false);
      }
    }
  }, [prices]);

  useEffect(() => {
    setPlan(selectedPlan.nickname);
    setShowM(!interval ? 'show' : 'hide');
    setShowY(!interval ? 'hide' : 'show');
    // setIndex(index + (!interval ? -1 : 1));
    setIndex(index);
  }, [interval]);

  useEffect(() => {
    if (prices.length > 0 && index !== 11) {
      // console.log(prices)
      setSelectedPlan({ ...prices[index] });
      setIsLoading(false);
      // console.log("prices[index]", index)
      setIsCurrentSubscription(
        prices[index].recurringInterval === currentSubscription.interval
      );
      setPlanNickname(
        <div>
          <div className={`${prices[index].nickname}-plan`}>
            {prices[index].nickname} Plan!
          </div>
          <div className="plan-description">{prices[index].description}</div>
        </div>
      );
      if (
        prices[index].nickname === currentSubscription.nickname &&
        prices[index].recurringInterval === currentSubscription.interval
      ) {
        setButtonClassName('default-button');
        setIsDisabled(true);
      } else {
        setButtonClassName(`${prices[index].nickname}-button`);
        if (subsPlanName === _UNVERIFIED) {
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
        }
      }
      // const str =
      //   index <= 1
      //     ? _SEEDLINGS_DESCRIPTION
      //     : index <= 3
      //       ? _ROOTS_DESCRIPTION
      //       : _BRANCHES_DESCRIPTION;
      const str =
        subsPlanName === _SEEDLINGS
          ? _SEEDLINGS_DESCRIPTION
          : subsPlanName === _ROOTS
            ? _ROOTS_DESCRIPTION
            : _BRANCHES_DESCRIPTION;
      setPaymentDescription(
        str +
        `${prices[index].unitAmount || ''} ${prices[index].recurringInterval + 'ly' || ''
        } from ${getStartChargeDate(index)}`
      );
      setTotalAmount(getPrice('0'));
    }
  }, [index]);

  const handleIntervalChange = (nextChecked) => {
    setInterval(nextChecked);
  };

  const handleGotoPaymentClick = () => {
    if (Object.keys(selectedPlan).length === 0)
      setSelectedPlan({
        ...prices[0],
      });
    if (isEdit && currentSubscription.nickname !== _SEEDLINGS) {
      setIsReviewLoading(true);
      // get subscription id
      stripeViewModel
        .getSubscriptionId(dependent[0].id)
        .then((res) => {
          const subscriptionId = res;
          const _currentIndex =
            _PLAN_INDEX(
              currentSubscription.nickname, currentSubscription.interval
            );
          const _nextIndex =
            _PLAN_INDEX(
              selectedPlan.nickname,
              selectedPlan.recurringInterval
            );
          setIsUpgrade(_currentIndex < _nextIndex);
          setCurrentSubscription({
            ...currentSubscription,
            subscription_id: subscriptionId,
          });
          // get prorated amount if it is upgrade
          if (_currentIndex < _nextIndex) {
            const params = {
              subscription_id: subscriptionId,
              price_id: selectedPlan.id,
            };
            stripeViewModel
              .previewProratedInvoice(params)
              .then((res) => {
                setTotalAmount(getPrice(res.amount_remaining));
              })
              .catch((err) => {
                console.error(err);
                setIsReviewLoading(false);
              });
          }
          setIsReviewLoading(false);
          setShowPaymentMethod(true);
        })
        .catch((err) => {
          console.error(err);
          setIsReviewLoading(false);
        });
    } else {
      setShowPaymentMethod(true);
    }
    window.scrollTo(0, 0);
  };

  const setPlan = (subscription) => {
    // const attr = interval ? 'year' : 'month';
    const attr = 'month';
    let selected = [
      seedlingsUnverifiedSelected[attr],
      seedlingsSelected[attr],
      rootsSelected[attr],
      branchesSelected[attr],
    ];

    switch (subscription) {
      case _UNVERIFIED:
        selected = [
          selected[0] === currentSubClassName ? selected[0] : 'selected',
          selected[1] === currentSubClassName ? selected[1] : '',
          selected[2] === currentSubClassName ? selected[2] : '',
          selected[3] === currentSubClassName ? selected[3] : '',
        ];
        break;
      case _SEEDLINGS:
        selected = [
          selected[0] === currentSubClassName ? selected[0] : '',
          selected[1] === currentSubClassName ? selected[1] : 'selected',
          selected[2] === currentSubClassName ? selected[2] : '',
          selected[3] === currentSubClassName ? selected[3] : '',
        ];
        break;
      case _ROOTS:
        selected = [
          selected[0] === currentSubClassName ? selected[0] : '',
          selected[1] === currentSubClassName ? selected[1] : '',
          selected[2] === currentSubClassName ? selected[2] : 'selected',
          selected[3] === currentSubClassName ? selected[3] : '',
        ];
        break;
      case _BRANCHES:
        selected = [
          selected[0] === currentSubClassName ? selected[0] : '',
          selected[1] === currentSubClassName ? selected[1] : '',
          selected[2] === currentSubClassName ? selected[2] : '',
          selected[3] === currentSubClassName ? selected[3] : 'selected',
        ];
        break;
    }
    setSeedlingsUnverifiedSelected({
      ...seedlingsUnverifiedSelected,
      [attr]: selected[0],
    })
    setSeedlingsSelected({
      ...seedlingsSelected,
      [attr]: selected[1],
    });
    setRootsSelected({
      ...rootsSelected,
      [attr]: selected[2],
    });
    setBranchesSelected({
      ...branchesSelected,
      [attr]: selected[3],
    });
  };

  const handleSeedlingsClick = (e) => {
    if (dependent[0] && dependent[0].profile.is_activated) return
    // const current = seedlingsUnverifiedSelected[interval ? 'year' : 'month'];
    if (subsPlanName === _UNVERIFIED) return;
    // setIndex(1);
    setPlan(_UNVERIFIED);
    setSubsPlanName(_UNVERIFIED);
    setIsDisabled(true);
  };
  const handleSeedlingsVerifiedClick = (e) => {
    const current = seedlingsSelected[interval ? 'year' : 'month'];
    if (subsPlanName === _SEEDLINGS) return;
    setIndex(prices.findIndex(obj => {
      return obj.recurringInterval === "free"
    }));
    setPlan(_SEEDLINGS);
    setSubsPlanName(_SEEDLINGS);
    setIsDisabled(false);
  };
  const handleRootsClick = (e) => {
    const current = rootsSelected[interval ? 'year' : 'month'];
    if (subsPlanName === _ROOTS) return;
    setIndex(prices.findIndex(obj => {
      return obj.nickname === "Roots" && obj.recurringInterval === "month"
    }));
    setPlan(_ROOTS);
    setSubsPlanName(_ROOTS);
    setIsDisabled(false);
  };
  const handleBranchesClick = (e) => {
    const current = branchesSelected[interval ? 'year' : 'month'];
    if (subsPlanName === _BRANCHES) return;
    setIndex(prices.findIndex(obj => {
      return obj.nickname === "Branches" && obj.recurringInterval === "month"
    }));
    setPlan(_BRANCHES);
    setSubsPlanName(_BRANCHES);
    setIsDisabled(false);
  };

  const showAmount = (price, plan, interval) => {
    return price.nickname === plan && price.recurringInterval === interval
      ? price.unitAmount
      : '';
  };

  const isSubscription = () => {
    if (currentSubscription.nickname === _ROOTS
      || currentSubscription.nickname === _BRANCHES) {
      if (subsPlanName === _SEEDLINGS) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const isEdit = jobType === 'edit';

  return (
    <div>
      {isLoading ?
        <div className="onboarding-wrapper payment-container payment-container-new">
          <div className="onboarding-container container-md">
            <div className="plan-list-container show">
              <Row className={`loading-message ${isLoading ? 'show-flex' : 'hide'}`}>
                Loading Our Plans...
              </Row>
            </div>
          </div>
        </div>
        :
        <>
          <div className="onboarding-wrapper payment-container payment-container-new"
            style={{ display: showPaymentMethod ? 'none' : 'block' }}
          >
            <div className="onboarding-container container-md">
              <div className="plan-list-container show">
                <div className="mt-5 settings-header-container container-md">
                  <div className="align-items-center contents-wrapper row">
                    <div className="col-1 go-back-column col"
                      onClick={() => {
                        refreshUserState()
                        history.push(GUARDIAN_SETTINGS_ROUTE)
                      }}
                    >
                      <button type="button" className="shadow-none go-back-to-dashboard show btn btn-transparent">
                        <img src={backIcon} alt="go back to dashboard" />
                      </button>
                    </div>
                    <div className="settings-nav-header-title col-10 text-center col">Choosing an experience for your kids!</div>
                  </div>
                </div>
                <div className="payment-subtitle  row">
                  <div>TaleTree experiences are divided into <strong>Seedlings, Roots,</strong> and <strong>Branches plans</strong>. The majority of our community are verified <strong>Seedlings which has ZERO subscription fee..</strong> Depending on the plan you choose for your child, they can access some or all of our services.</div>
                </div>
                <div className="loading-message hide row">Loading Our Plans...</div>
                <div className="plan-wrapper show-flex row">
                  <div className="plan plan-lable-col  col">
                    <div className="blur-layer false"></div>
                    <div className="border-layer"></div>
                    <div className="plan-header row">
                      <div className="plan-name"></div>
                    </div>

                    <div className="plan-price">
                      <div className='plan-price-lable'>FREE</div>
                      <div className='plan-action'>Unverified</div>
                    </div>


                    <div className="plan-features row">
                      <div className="col">Choose companion</div>
                      <div className="col">Explore Emerald Forest</div>
                      <div className="col">Personalize room</div>
                      <div className="col">Interact, submit projects, and give Binkys and encouragement</div>
                      <div className="col">Accept challenges from TaleTree Friends</div>
                      <div className="col">Share creations publicly with friends</div>
                      <div className="col">Accept special challenges from partner Treehouses</div>
                      <div className="col">Accept 1 creative workshop per week</div>
                    </div>
                  </div>
                  <div
                    className={'plan seedlings unverified col ' + (subsPlanName === _UNVERIFIED ? 'selected' : "")}
                    onClick={handleSeedlingsClick}
                  >
                    <div className="blur-layer"></div>
                    <div className="border-layer"></div>
                    <div className="plan-header row">
                      <div className="header-badge">
                        <img src={UnVeryfied} alt='' />
                      </div>
                      <div className="plan-name">Seedlings</div>
                    </div>
                    <div className="plan-price">
                      <div className='plan-price-lable'>FREE</div>
                      <div className='plan-action'>Unverified</div>
                    </div>
                    <div className="plan-features row">
                      <div className="included col"></div>
                      <div className="included col"></div>
                      <div className="included col"></div>
                      <div className="not-included col"></div>
                      <div className="not-included col"></div>
                      <div className="not-included col"></div>
                      <div className="not-included col"></div>
                      <div className="not-included col"></div>
                    </div>
                  </div>
                  <div
                    className={"plan seedlings verified current-undefined false col " + (subsPlanName === _SEEDLINGS ? 'selected' : "")}
                    onClick={handleSeedlingsVerifiedClick}
                  >
                    <div className="blur-layer false"></div>
                    <div className="border-layer"></div>
                    <div className="plan-header row">
                      <div className="header-badge">
                        <img src={Veryfied} alt='' />
                      </div>
                      <div className="plan-name">Seedlings</div>
                    </div>
                    <div className="plan-price show">
                      <div className='plan-price-lable'>FREE</div>
                      <div className='plan-action'>Verified</div>
                    </div>
                    <div className="plan-features row">
                      <div className="included col"></div>
                      <div className="included col"> </div>
                      <div className="included col"></div>
                      <div className="included col"> </div>
                      <div className="included col"></div>
                      <div className="included col"> </div>
                      <div className="not-included col"></div>
                      <div className="not-included col"></div>
                    </div>
                  </div>
                  <div
                    className={"plan roots current-undefined  false col " + (subsPlanName === _ROOTS ? 'selected' : "")}
                    onClick={handleRootsClick}
                  >
                    <div className="blur-layer false"></div>
                    <div className="border-layer"></div>
                    <div className="plan-header row">
                      <div className="header-badge">
                        <img src={Veryfied} alt='' />
                      </div>
                      <div className="plan-name">Roots</div>
                    </div>
                    <div className="plan-price show">
                      {(prices || []).map((price, index) => (
                        <div className='plan-price-lable' key={index}>{showAmount(price, 'Roots', 'month')}</div>
                      ))}
                      <div className='plan-action'>Cancel anytime</div>
                    </div>
                    <div className="plan-features row">
                      <div className="included col"></div>
                      <div className="included col"></div>
                      <div className="included col"> </div>
                      <div className="included col"></div>
                      <div className="included col"> </div>
                      <div className="included col"></div>
                      <div className="included col"> </div>
                      <div className="not-included col"></div>
                    </div>
                  </div>
                  <div
                    className={"plan branches current-undefined  false col " + (subsPlanName === _BRANCHES ? 'selected' : "")}
                    onClick={handleBranchesClick}
                  >
                    <div className="blur-layer false"></div>
                    <div className="border-layer"></div>
                    <div className="plan-header row">
                      <div className="header-badge">
                        <img src={Veryfied} alt='' />
                      </div>
                      <div className="plan-name">Branches</div>
                    </div>
                    <div className="plan-price show">
                      {(prices || []).map((price, index) => (
                        <div className='plan-price-lable' key={index}>
                          {showAmount(price, 'Branches', 'month')}
                        </div>
                      ))}
                      <div className='plan-action'>Cancel anytime</div>
                    </div>
                    <div className="plan-features row">
                      <div className="included col"></div>
                      <div className="included col"></div>
                      <div className="included col"></div>
                      <div className="included col"></div>
                      <div className="included col"></div>
                      <div className="included col"></div>
                      <div className="included col"></div>
                      <div className="included col"></div>
                    </div>
                  </div>

                </div>
                <div className='plans-note'>*Get verified for 0.99c pay nothing else forever to gain access to all standard challenge on TaleTree</div>

                <div className="selected-plan show-flex row">
                  <div>
                    <div className="plan-description">While TaleTree is <strong>100% free to all verified kids on the Seedlings plan</strong>, you will need to upgrade to Roots to access challenges set by our resident experts. Perhaps you wish to subscribe to the Branches plan, the one-hour weekly creator studio, run by our TaleTree Captains in the TaleTree Treehouse. You can upgrade to these plans at any time in your guardian dashboard.</div>
                  </div>
                </div>

                <div className="button-wrapper show-flex row">
                  <button disabled={isDisabled || subsPlanName === _UNVERIFIED || isSubscription()} onClick={handleGotoPaymentClick} data-testid="" type="button" className="load-button-container gred-btn Seedlings-button btn btn-primary">
                    <span role="status" aria-hidden="true" className="d-none spinner-border spinner-border-sm"></span>
                    <span className="">{isEdit ? 'CHANGE MY PLAN' : 'Verify and update plan'}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="type1"></div>
          <div className="lensflare"></div>
        </>
      }
      {/* <div
        className={`payment-form-container ${
          showPaymentMethod ? 'show' : 'hide'
        }`}
      > */}
      <div
        className={`payment-form-container`}
        style={{ display: showPaymentMethod ? 'block' : 'none' }}
      >
        <PaymentContext.Provider
          value={{
            jobType,
            prices,
            selectedPlan,
            promotionInfo,
            setPromotionInfo,
            promotionSummaryShow,
            setPromotionSummaryShow,
            disablePromotion,
            setDisablePromotion,
            totalAmount,
            setTotalAmount,
            showAmount,
            setShowPaymentMethod,
            dependent,
            paymentDescription,
            defaultPromotionInfo,
            currentSubscription,
            isUpgrade,
            isCurrentSubscription,
            showPaymentMethod
          }}
        >
          <PaymentMethod />
        </PaymentContext.Provider>
      </div>

    </div>
  );
};

export default PaymentSubscription;
