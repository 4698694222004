import React, { useState } from 'react';
import { Image, Spinner } from 'react-bootstrap';
import ErrorLabel from '../common/ErrorLabel';
import iconEdit from '../../assets/icon/icon-edit.png';
import { imageUtils } from '../../utils/image.utils';
import { getDefaultProfileImage } from '../../utils/camp.utils';

import './scss/edit.avatar.scss';

const EditAvatar = React.forwardRef((props, ref) => {
  const {
    currentProfileImage,
    onProfileImageChange,
    taletreeFriend,
    name,
    errors,
    index,
    setError,
  } = props;
  const defaultImageUrl = currentProfileImage
    ? currentProfileImage
    : getDefaultProfileImage(taletreeFriend);

  const [profilePicture, setProfilePicture] = useState(defaultImageUrl);
  const [showSpinner, setShowSpinner] = useState(false);
  const handleProfileImageSelection = (e) => {
    const files = e.target.files;
    if (files.length) {
      setShowSpinner(true);
      const imagePromise = imageUtils.getImagePromise(files[0]);
      imagePromise
        .then((image) => {
          setProfilePicture(image.preview);
          onProfileImageChange(index, image);
        })
        .catch(() => {
          setError(name, {
            type: 'manual',
            message: 'Unsupported image! Please choose another.',
          });
        })
        .finally(() => {
          setShowSpinner(false);
        });
    }
  };

  return (
    <div className="edit-child-profile-container">
      <div>
        <label htmlFor={name}>
          <Image className="rounded-circle profile-img" src={profilePicture} />
          <Image className="edit-button" src={iconEdit} />
          <Spinner
            className={`spinner ${showSpinner ? '' : 'd-none'}`}
            animation="border"
            variant="info"
          />
        </label>
        <input
          className="d-none"
          accept="image/png, image/jpeg, image/jpg, .heic, .HEIC"
          id={name}
          name={name}
          type="file"
          ref={ref}
          onChange={(e) => handleProfileImageSelection(e)}
        />
      </div>
      <div>
        <ErrorLabel errors={errors} name={name} />
      </div>
    </div>
  );
});
export default EditAvatar;
