import React, { useState, useEffect } from 'react';

const Question1Choices = (props) => {
  const lsQuestion1Choices = JSON.parse(
    localStorage.getItem('question1Choices')
  );
  const { choices, setChoices } = props;
  const base = lsQuestion1Choices !== null ? lsQuestion1Choices : choices;

  const handleClick = (e) => {
    const classname = e.target.parentNode.className;
    const length = classname.trim().length;
    const index = base.findIndex((c) => {
      return c.id == e.target.dataset.id;
    });
    const choice = base.slice(index, index + 1)[0];
    const answers = choice.answers;

    // set answer
    const selectedObj = {
      ...base.slice(index, index + 1)[0],
      answers:
        answers[0].length === 0 ? [answers[1], answers[1]] : ['', answers[1]],
    };

    // change selected choice background image
    if (answers[0].length === 0) {
      e.target.parentNode.className = classname.trim() + ' selected';
    } else {
      e.target.parentNode.className = classname.substring(length - 8, length);
    }

    // modify choices state
    const newObj = [
      ...base.slice(0, index),
      selectedObj,
      ...base.slice(index + 1, choices.length),
    ];
    setChoices(newObj);

    localStorage.setItem('question1Choices', JSON.stringify(newObj));
  };

  useEffect(() => {
    setChoices(base);
  }, []);

  const choiceViews = base.map((choice, key) => {
    const selected = () => {
      const length =
        lsQuestion1Choices === null
          ? choice.answers[0].length
          : lsQuestion1Choices[key].answers[0].length;
      return length > 0 ? 'selected' : '';
    };
    return (
      <div
        key={key}
        className={`choice choice-${choice.answers[1]} ${selected()}`}
      >
        <div
          data-id={choice.id}
          data-answer={choice.answers[1]}
          onClick={handleClick}
        >
          {choice.question}
        </div>
      </div>
    );
  });

  return <div className="choice-wrapper">{choiceViews}</div>;
};

export default Question1Choices;
