import { fetchUtils } from '../utils/fetch.utils';
import { getAPIUrl } from '../utils/config.utils';

const BASE_URL = getAPIUrl();
const SUBSCRIPTIONS = '/subscriptions';

const getPrices = (type) => {
  const uri =
    // productId === 'all'
    `${BASE_URL}/prices/list/${type}`;
  // : `${BASE_URL}/prices/${productId}`;
  return fetchUtils.get(uri);
};

const getCards = () => {
  return fetchUtils.get(`${BASE_URL}/cards?limit=99`);
};

const previewInvoice = (params) => {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.get(`${BASE_URL}/invoices/preview?${query}`);
};

const checkPromoCode = (params) => {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.get(`${BASE_URL}/invoices/check_promocode?${query}`);
};

const previewProratedInvoice = (params) => {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.newGet(`${BASE_URL}/invoices/prorates/preview?${query}`);
};

const subscriptions = (params) => {
  return fetchUtils.post(`${BASE_URL}${SUBSCRIPTIONS}`, params);
};

const changeSubscription = (params) => {
  const newParam = {
    price_id: params.price_id,
  };
  return fetchUtils.patch(
    `${BASE_URL}${SUBSCRIPTIONS}/change/${params.subscription_id}`,
    newParam
  );
};

// for special camp
const paymentIntents = (params) => {
  return fetchUtils.post(`${BASE_URL}/payment_intents`, params);
};

const applePayPaymentIntents = (params) => {
  return fetchUtils.post(`${BASE_URL}/payment_intents/apple-pay-payment-intent`, params);
};

// for seedlings verified
const paymentIntentsSeedlings = (params) => {
  return fetchUtils.post(`${BASE_URL}/payment_intents/seedling_verified`, params);
};

// for special camp
const paymentComplete = (params) => {
  return fetchUtils.post(
    `${BASE_URL}/payment_intents/complete_payment`,
    params
  );
};

// for special camp
const paymentIntentsApplyPromoCode = (params) => {
  return fetchUtils.post(`${BASE_URL}/payment_intents/apply-coupon`, params);
};

const getSubscriptions = () => {
  return fetchUtils.get(`${BASE_URL}${SUBSCRIPTIONS}?limit=99`);
};

const getSubscriptionId = (dependentId) => {
  return fetchUtils.newGet(
    `${BASE_URL}${SUBSCRIPTIONS}/details/${dependentId}`
  );
};

const getCustomerPortalSession = () => {
  return fetchUtils.get(`${BASE_URL}/customers/billing_portal/sessions`);
};

const cancelSubscription = (dependentId) => {
  return fetchUtils.requestDelete(
    `${BASE_URL}/subscriptions/cancel/${dependentId}`
  );
};

const verificationPayment = (params) => {
  return fetchUtils.post(`${BASE_URL}/payment_intents/verification_payment`, params);
};

const createPaymentIntent = (params) => {
  return fetchUtils.post(`${BASE_URL}/payment_intents/create/payment/intent/${params.amount}`);
};

export const stripeRepository = {
  getPrices,
  getCards,
  previewInvoice,
  previewProratedInvoice,
  subscriptions,
  changeSubscription,
  paymentIntents,
  paymentIntentsSeedlings,
  paymentComplete,
  paymentIntentsApplyPromoCode,
  getSubscriptions,
  getSubscriptionId,
  getCustomerPortalSession,
  verificationPayment,
  checkPromoCode,
  createPaymentIntent,
  applePayPaymentIntents
};
