import React, { useState } from 'react';
import Upload from './Upload';
import Modal from '../../common/Modal';
import { ComponentSize } from '../../../constants/component.constants';

import './scss/creation.upload.scss';
import EditUpload from './EditUpload';

const EditCreationUpload = ({
  from, // myroom or challenge
  data,
  challengeId,
  showUpload,
  status = '',
  handleClose,
  handleOnUploadComplete,
}) => {
  const [uploadStatus, setUploadStatus] = useState(status);
  return (
    <div>
      <Modal
        className={`upload-modal ${uploadStatus}`}
        show={showUpload}
        handleClose={handleClose}
        size={ComponentSize.LARGE}
        title=""
      >
        <EditUpload
          from={from}
          data={data}
          reset={showUpload}
          challengeId={challengeId}
          uploadStatus={uploadStatus}
          setUploadStatus={setUploadStatus}
          handleCloseModal={handleClose}
          handleOnUploadComplete={handleOnUploadComplete}
        />
      </Modal>
    </div>
  );
};

export default EditCreationUpload;
