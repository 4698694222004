import React from 'react';
import { Row, Button, Col, Image, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logoCamp from '../../../../assets/logo/logo-camp.svg';
import logoApple from '../../../../assets/icon/icon_apple.svg';
import '../scss/signup.scss';
import './css/signup.complete.camp.css';
import { GUARDIAN_SETTINGS_ROUTE } from '../../../../constants/routes.constants';

function SignUpCompleteCamp() {
  const goToGuardianDashboard = () => {};
  return (
    <div data-testid="signup_complete_camp">
      <Container>
        <Row className="signup-header justify-content-center text-center">
          You are all completed!
        </Row>
        <Row>
          <Col className="col-8 offset-2 col-md-6 offset-md-3 col-xl-6 offset-xl-3 signup-s-header justify-content-center text-center">
            <Row>
              <Col>
                <p>
                  You succesfuly registerd Tale Tree Creative Camp.
                  <br></br>
                  You will shortly receive the email from us to schedule a{' '}
                  <b>30 min video call</b> with kid(s)
                </p>
              </Col>
            </Row>
            <Row className="flex-grow-1 align-content-center mt-5 mb-3">
              <Col className="signup-complete-camp-card px-5 py-3">
                <Row className="signup-complete-camp-text">
                  You can manage your account and kid’s information from
                  Guardian Dashboard
                </Row>
                <Row className="text-center justify-content-center">
                  <Link
                    replace
                    to={{
                      pathname: GUARDIAN_SETTINGS_ROUTE,
                      state: { refreshState: true },
                    }}
                    className="mb-3 text-secondary signup-complete-camp-text"
                  >
                    <b>Go to Guardian Dashboard</b>
                  </Link>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SignUpCompleteCamp;
