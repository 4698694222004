import React from 'react';

import ImageGallery from './ImageGallery';
import NoCreation from './NoCreation';
import './css/image.gallery.scss';

function CreationGallery({ creationList, deleteCreationById, handleBinky, isCreatorRoom, handleComment, creator, from }) {
  const getCreations = () => {
    if (creationList == null) {
      return null;
    }
    if (creationList.length === 0) {
      return (
        <NoCreation>
          <div>Submit your first creation!</div>
        </NoCreation>
      );
    } else {
      return (
        <ImageGallery {...{ creationList, deleteCreationById, handleBinky, isCreatorRoom, handleComment, creator, from }} />
      );
    }
  };

  return <>{getCreations()}</>;
}

export default CreationGallery;
