import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import RoundButton from '../../common/RoundButton';
import { Row, Col, ProgressBar } from 'react-bootstrap';
import { ON_BOARDING } from '../../../constants/routes.constants';
import { bg } from '../../../constants/component.constants';
import { changeBodyClassName } from '../../../utils/utils';
import OnboardingWrapper from './OnboardingWrapper';
import QuestionItems from './QuestionItems';

import './scss/step6.scss';

const Step6 = ({ location }) => {
  const _STEP = 2;
  const history = useHistory();
  const lsFirstName = localStorage.getItem('firstName');
  const lsCity = JSON.parse(localStorage.getItem('city'));
  const [items, setItems] = useState([]);
  const [errShow, setErrShow] = useState(false);
  const [errMessage, setErrMessage] = useState('');

  const pickQuestions = (step) => {
    const unsorted = location.state.questions.filter((q) => {
      return q.step === step;
    });
    return unsorted
      .sort((el1, el2) => el1.id - el2.id)
      .map((el) => ({
        id: el.id,
        question: el.question,
        answer: el.answers[0], // 0
        min: el.answers[0],
        max: el.answers[el.answers.length - 1],
      }));
  };

  const handleBackClick = () => {
    history.goBack();
  };
  const handleNextClick = () => {
    history.push({
      pathname: `${ON_BOARDING}/step7`,
      state: {
        ...location.state,
        from: 'step6',
      },
    });
  };

  useEffect(() => {
    changeBodyClassName([bg.GRADIENT]);
    if (!location.state || location.state.from !== 'step5')
      history.replace({ pathname: `${ON_BOARDING}/step1` });
    setItems(pickQuestions(_STEP));
  }, []);

  return (
    <OnboardingWrapper className="step6-wrapper">
      <Row className="content-wrapper">
        <Col className="out-of-content">
          <div className="image-wrapper">&nbsp;</div>
          <div className="text-wrapper">
            “I love just being myself at the TaleTree Creative Camp. I couldn’t
            imagine myself without my “crazee” TaleTree Thursdays”
            <br />
            <br /> Erica, 11 years old
          </div>
        </Col>
        <Col className="content">
          <div className="progress-bar-wrapper">
            <ProgressBar now={76} />
          </div>
          <div className="title">
            Interest is such a powerful source of motivation and passion. It
            triggers dedication and achievement.
            {/* Congratulations! We've found a camp in&nbsp;
              {lsCity ? lsCity.label : ''} for&nbsp;
              <span className="emphasize">{lsFirstName}</span> to attend! Let's
              see if we can grant you a FREE pass. Please keep going! */}
          </div>
          <div className="thin-border-line" />
          <div className="sub title">
            <strong>Interests:</strong> What does {lsFirstName} like to do?
          </div>
          <div className={`error-message-wrapper ${errShow ? 'show' : 'hide'}`}>
            {errMessage}
          </div>
          <div className="input-wrapper">
            <QuestionItems items={items} setItems={setItems} step={_STEP} />
          </div>
          <div className="button-wrapper">
            <RoundButton
              type="button"
              label="Back"
              className="back-button"
              onClick={handleBackClick}
            />
            <RoundButton
              type="button"
              label="Next"
              className="next-button"
              onClick={handleNextClick}
            />
          </div>
        </Col>
      </Row>
    </OnboardingWrapper>
  );
};

export default Step6;
