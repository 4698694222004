import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, ProgressBar, Container, Form, Button } from 'react-bootstrap';
import 'react-datetime/css/react-datetime.css';
import './scss/step2.scss';
import DashboardHeader from '../../header/DashboardHeader';
import { AiOutlineEye } from "@react-icons/all-files/ai/AiOutlineEye";
import { AiOutlineEyeInvisible } from "@react-icons/all-files/ai/AiOutlineEyeInvisible";
import { onboardingViewModel } from './onboarding.view.model';
import { useAuth } from '../../auth/Auth';
import { GUARDIAN_SETTINGS_ROUTE } from "../../../constants/routes.constants";
import { HiCheckCircle } from '@react-icons/all-files/hi/HiCheckCircle';

const childRegistration = () => {
	const history = useHistory();
	const { refreshUserState } = useAuth();
	const [nickName, setNickName] = useState();
	const [password, setpassword] = useState();
	const [confirmPass, setconfirmPass] = useState();
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [validated, setValidated] = useState(false);
	const [userNameError, setUserNameError] = useState();
	const [confPassError, setConfPassError] = useState();
	const [isUsername, setIsUsername] = useState();

	const handleShow = async (e) => {
		const form = e.currentTarget;
		if (form.checkValidity() === false) {
			e.preventDefault();
			e.stopPropagation();
		}
		e.preventDefault();
		setValidated(true);

		if (password !== confirmPass) {
			setConfPassError("Password does not match")
		}

		if (password === confirmPass && nickName != '' && password != '' && confirmPass != '') {
			const params = {
				"profile": {
					"nick_name": nickName,
				},
				"username": nickName,
				"password": password
			};

			onboardingViewModel
				.childRegsitration(history.location.state.dependent.id, params)
				.then(async (res) => {
					await refreshUserState();
					history.push(GUARDIAN_SETTINGS_ROUTE);
				}).catch((error) => {
					setUserNameError(error.message);
				})
		}


		// history.push({
		// 	pathname: `${DASHBOARD_ROUTE}/settings`,
		// 	state: { from: 'dashboard' },
		// });
	};

	const checkUsername = (username) => {
		onboardingViewModel.checkUsername(username).then((res) => {
			setUserNameError(false);
			setIsUsername(true);
		}).catch((err) => {
			setIsUsername(false);
			setUserNameError(err.serverResponse.error_info)
		})
	}

	const setUserName = (e) => {
		setNickName(e);
		if (e) checkUsername(e)
	}
	const confirmPassword = (e) => {
		setConfPassError(false);
		setconfirmPass(e);
	}
	const handelPassword = (e) => {
		setpassword(e);
	}
	return (
		<div className="childRegistration">
			<DashboardHeader />
			<div className="createZacksAcocunt my-5">
				<Container className="create-zacks-account-container" fluid="md">
					<div className='createZacksAcocunt-inner'>
						<Row>
							<Col className="col-md-5 mx-auto">
								<h1>Create {history.location.state.dependent.first_name}'s Account</h1>
								<p>It's now time to grab {history.location.state.dependent.first_name} to create a nickname and password to log in to TaleTree App. We take our kids security extremely seriously. Therefore, we advise
									our kids not to use their full name</p>
							</Col>
						</Row>
					</div>
				</Container>
			</div>
			<div className="createZacksAcocunt-form">
				<Container className="createZacksAcocunt-form-container" fluid="md">
					<div className='createZacksAcocunt-form-inner'>
						<Row>
							<Col className="col-md-5 mx-auto">
								<Form onSubmit={handleShow}>
									<Form.Group className="mb-3" controlId="nickName">
										<Form.Control
											type="text"
											required
											placeholder="Create nickname"
											onChange={(e) => setUserName(e.target.value)}
										/>
										{isUsername &&
											<span className='child-input-check-ic'><HiCheckCircle /></span>
										}
										{userNameError ? (
											<p style={{ color: 'red' }} className='pt-2'>
												{userNameError}
											</p>
										) : ''}
									</Form.Group>
									<Form.Group className="mb-3" controlId="Password">
										<Form.Control
											type={showPassword ? "text" : "password"}
											placeholder="At least 8 characters"
											onChange={(e) => handelPassword(e.target.value)}
											required
										/>
										<span className='show-ic' onClick={() => setShowPassword(!showPassword)}>{showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}</span>
									</Form.Group>
									<Form.Group className="mb-3" controlId="ConfirmPassword">
										<Form.Control
											type={showConfirmPassword ? "text" : "password"}
											placeholder="Confirm password"
											onChange={(e) => confirmPassword(e.target.value)}
											required
										/>
										<span className='show-ic' onClick={() => setShowConfirmPassword(!showConfirmPassword)}>{showConfirmPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}</span>
										{confPassError ? (
											<p style={{ color: 'red' }} className='pt-2'>
												{confPassError}
											</p>
										) : ''}
									</Form.Group>
									<div className='submit-btn-wrapper'>
										<Button variant="primary" type="submit">
											Submit
										</Button>
									</div>
								</Form>
							</Col>
						</Row>
					</div>
				</Container>
			</div>
		</div>
	);
};

export default childRegistration;
