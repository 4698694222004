import React, { useEffect, useState } from "react";
import Avatar from "../../../../assets/images/Avatar.svg";
import LogoHorizontal from "../../../../assets/images/tt-hori-logo.svg";
import { BsArrowRight } from "react-icons/bs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { KID_LOGIN_ROUTE, LOGIN_ROUTE, ON_BOARDING } from "../../../../constants/routes.constants";
import { useAuth } from "../../../auth/Auth";
import SignupProcess from "./SignupProcess";
import notVerified from '../../../../assets/icon/Unverified.svg';
import goldTick from '../../../../assets/icon/gold_tick.svg';
import silverTick from '../../../../assets/icon/silver_tick.svg';
import LogoMobile from "../../../../assets/images/logo-white-p.svg";
import SignupFooterLinks from "./SignupFooterLinks";
import { Modal } from "react-bootstrap";
import verfiedIcon from '../../../../assets/icon/Verified-badge.svg';

export default function TreehouseSignup(props) {
    const { showModal, setShowModal, treehouse } = props;
    const { guardian } = useAuth();
    const history = useHistory();
    const [signupProcess, setSignupProcess] = useState(false);
    const [isVerified, setIsVerifed] = useState(true);
    const handleSignupClick = () => {
        setShowModal(false);
        setSignupProcess(true);
    }

    useEffect(() => {
        if (guardian && !guardian?.is_verified) {
            setIsVerifed(false);
            setSignupProcess(true);
        }
    }, [])

    const handleClose = () => {
        setShowModal(false);
    }

    const height = {
        maxHeight: '440px !important'
    }

    const onLoginClick = () => {
        localStorage.setItem('kids_login_location', window.location.href)
        history.push(KID_LOGIN_ROUTE)
    }

    return (
        signupProcess ?
            <SignupProcess signupProcess={signupProcess} isVerified={isVerified} setSignupProcess={setSignupProcess} />
            :
            <Modal className='signupPopup signupPopu-treehouse mobile-v-popup' show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className='row step-1-row'>
                        <div className="col-md-6 mobile-up toparrow p-4">
                            <div className="d-flex justify-content-between mobile-none">
                                <div className="logo">
                                    <img src={LogoHorizontal} />
                                </div>
                                <div className="login-btn" onClick={onLoginClick}>
                                    <button style={{ border: '1px solid #321b6d', color: '#321b6d' }} className="btn btn-outline hover-underline">Login</button>
                                </div>
                            </div>
                            <div className="varified-div">
                                <img style={{ height: '140px', width: '140px', borderRadius: '70px' }} src={treehouse?.picture_url ? treehouse?.picture_url : Avatar} />
                                {treehouse?.is_verified ?
                                    treehouse?.user_type === "camp master" ?
                                        <img className="varified-badge" style={{ height: '45px', width: '45px' }} src={verfiedIcon} />
                                        :
                                        <img className="varified-badge" style={{ height: '45px' }} src={
                                            treehouse?.type_of_organisation === "non profit" ? silverTick : goldTick
                                        } />
                                    : <img className="varified-badge" style={{ height: '45px' }} src={notVerified} />
                                }
                            </div>
                            {treehouse?.user_type === 'partner' ?
                                <p className="primary-text"><span>{treehouse?.name_of_organisation}</span> choose the Emerald Forest as a trusted
                                    place to inspire and challenge kids interested in the
                                    Branches their Treehouse can be found on.
                                </p>
                                :
                                <p className="primary-text"><span>{`${treehouse?.first_name} ${treehouse?.last_name}`}</span>, your resident expert at TaleTree! Our experts have chosen the Emerald Forest as the perfect place to connect with kids, where they can explore challenges and enjoy live meet-ups.
                                </p>
                            }

                            <button className="gred-btn mobile-none btn" onClick={handleSignupClick}>Get your kid a FREE verified account today <BsArrowRight /></button>
                            <button className="m-auto step-1btn gred-btn desktop-none align-items-center btn"
                                style={{ justifyContent: 'center' }}
                                onClick={handleSignupClick}>Get your kid a FREE verified account today <BsArrowRight style={{ marginLeft: '5px' }} />
                            </button>
                        </div>
                        <div className="col-md-6 left-side-step-1 bg-p">
                            <div className="d-flex justify-content-between desktop-none">
                                <div className="logo">
                                    <img src={LogoMobile} />
                                </div>
                                <div className="login-btn popup-login-btn" onClick={onLoginClick}>
                                    <button className="btn btn-outline">Login</button>
                                </div>
                            </div>
                            <div className="popup-bg" style={height}>
                                <p>Which TaleTree Friend should be your companion in the Emerald Forest?</p>
                            </div>
                            <SignupFooterLinks />
                        </div>
                    </div>
                    <div className="for-mobile-only">
                        <SignupFooterLinks />
                    </div>
                </Modal.Body>
            </Modal>
    )
}