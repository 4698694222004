import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import KidManagement from './KidManagement';
import GuardianAccount from './GuardianAccount';
import PaymentPlan from './PaymentPlan';
import DeleteAccount from './DeleteAccount';
import DashboardHeader from '../../header/DashboardHeader';
import AlertMessage from '../../common/AlertMessage';
// import KidsAccountCreated from './KidsAccountCreated';
import { bg } from '../../../constants/component.constants';
import { changeBodyClassName } from '../../../utils/utils';

import './css/guardian.settings.overview.scss';
import ChildRegistration from '../onboarding/signup_popups/ChildRegistration';

const GuardianSettings = () => {
  const history = useHistory();
  const [isNewlyChildCreated, setNewlyChildCreated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [dependent, setDependent] = useState({});
  // const onProfileSave = () => {
  //   setShowAlert(true);
  // };
  useEffect(() => {
    changeBodyClassName([bg.WHITE]);

    // if(history && history.location && history.location.state && history.location.state.childCreated){
    //   setNewlyChildCreated(true);
    // }
  }, []);

  const handleShowModal = (dependent) => {
    setDependent(dependent);
    setShowModal(true);
  }

  return (
    <div className="guardian-setting-wrapper guardian-setting" id="guardian_setting">
      <DashboardHeader />
      <Container className="setting-container setting-wrap" fluid="md">
        <Container fluid="md">
          <AlertMessage
            handleClose={() => setShowAlert(false)}
            show={showAlert}
            title={'Changes has been saved.'}
          />
          <KidManagement handleShowModal={handleShowModal} />
        </Container>
      </Container>
      <Container fluid="md">
      </Container>
    </div>
  );
};

export default GuardianSettings;
