import React, { useEffect } from 'react';
import ReactSlider from 'react-slider';

const QuestionItems = ({ items, setItems, step }) => {
  const lsItems = JSON.parse(localStorage.getItem(`question${step}Items`));

  useEffect(() => {
    if (lsItems === null || lsItems.length === 0)
      localStorage.setItem(`question${step}Items`, JSON.stringify(items));
  }, [items]);

  const itemViews = items.map((item, key) => {
    const selectedItem = lsItems.length !== 0 ? lsItems[key] : item;
    const min = parseInt(selectedItem.min, 10);
    const max = parseInt(selectedItem.max, 10);
    const value = parseInt(selectedItem.answer, 10);

    const handleChange = (value) => {
      let newObj = lsItems.length !== 0 ? lsItems : items;
      const index = newObj.findIndex((o) => o.id === item.id);
      newObj[index].answer = String(value);
      setItems(newObj);
      localStorage.setItem(`question${step}Items`, JSON.stringify(newObj));
    };

    return (
      <div key={key} className="slider-container">
        <div className="label">{selectedItem.question}</div>
        <div className="value">{min}</div>
        <div className="slider-wrapper">
          <ReactSlider
            className="onboarding-slider"
            thumbClassName="onboarding-thumb"
            trackClassName="onboarding-track"
            renderThumb={(props, state) => (
              <div {...props}>{state.valueNow}</div>
            )}
            min={min}
            max={max}
            defaultValue={value}
            onChange={handleChange}
          />
        </div>
        <div className="value">{max}</div>
      </div>
    );
  });

  return <div className="item-wrapper">{itemViews}</div>;
};

export default QuestionItems;
