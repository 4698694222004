import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import { useAuth } from '../../auth/Auth';
import Avatar from '../../common/Avatar';
import RoundButton from '../../common/RoundButton';
// import svg from '../../../assets/images/';
import MyBadge from '../../common/MyBadge';
import MyRoomCamp from '../camp_schedule/MyRoomCamp';
import insights from '../../../assets/images/insights-cooming-soon.svg';
import Challenges from '../../../assets/images/flag-Challenges.svg';
import Creations from '../../../assets/images/star-Creations.svg';
import Lock from '../../../assets/images/Lock.svg';

import {
  ON_BOARDING,
  GUARDIAN_SETTINGS_ROUTE,
  MY_ROOM_ROUTE,
  CAPTAIN_ROUTE,
  PAYMENT_ROUTE
} from '../../../constants/routes.constants';
import { NORMAL_TYPE } from '../../../constants/avatarType.constants';
import ChildActionButtons from './ChildActionButtons';
import Notification from './Notification';
import { specialCampViewModel } from '../special_camp/special.camp.viewmodel';
import visitChildPopupIcon from '../../../assets/images/visit-child-popup.svg';
import AccountVerification from '../../common/AccountVerification';
import './css/kid.management.scss';
import KidSelectbox from './KidSelectBox';
import AddChild from '../onboarding/signup_popups/AddChild';
import RegisterFirstChild from '../onboarding/signup_popups/RegisterFirstChild';
import GuardianCamps from '../camp_schedule/GuardianCamps';
import KidUpgradedModal from '../onboarding/signup_popups/KidUpgradedModal';
import TimeToUpgradeModal from '../onboarding/signup_popups/TimeToUpgradeModal';

const KidManagement = ({ handleShowModal }) => {
  const {
    guardian,
    fetchAPI,
    activatedDependents,
    deactivatedDependents,
    getGuardianDependents,
    saveDependentUser
  } = useAuth();
  const history = useHistory();
  const [showActivate, setShowActivate] = useState('hide');
  const [available, setAvailable] = useState(false);
  const [specialCamp, setSpecialCamp] = useState({});
  const [isExistingUser, setIsExistingUser] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showAddChild, setShowAddChild] = useState(false);
  const [showRegisterFirstChildModal, setShowRegisterFirstChildModal] = useState(false);
  const [showTimeToUpgradePopup, setShowTimeToUpgradePopup] = useState(false);
  const [upgradeType, setUpgradeType] = useState('both');
  const [selectedDependent, setSelectedDependent] = useState(localStorage.getItem('set_dependent') ?
    guardian.dependents[guardian.dependents.findIndex(obj => obj.username === localStorage.getItem('set_dependent'))] :
    guardian.dependents[0] ? guardian.dependents[0] : '');
  const accountVerificationRef = useRef();

  const getUpcomingSpecialCamp = (camp_id) => {
    fetchAPI(specialCampViewModel.getUpcomingSpecialCamp, camp_id)
      .then((res) => {
        if (res === '') {
          setAvailable(false);
          setSpecialCamp({});
          return;
        } else {
          setAvailable(true);
          setSpecialCamp(res);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleAddKidClick = (e) => {
    // history.push({
    //   pathname: `${ON_BOARDING}/step2`,
    //   state: { from: 'dashboard' },
    // });
    setShowAddChild(true);
  };
  const handleKidRegistration = (e) => {
    // history.push({
    //   pathname: `${ON_BOARDING}/childRegistration`,
    //   state: {
    //     from: 'dashboard',
    //     dependent: guardian.dependents[0]
    //   },
    // });
    handleShowModal(guardian.dependents[0]);
  };

  const myRoom = () => {
    saveDependentUser(selectedDependent);
    history.push({
      pathname: MY_ROOM_ROUTE
    });
  }

  // const openModelKidRoom = () => {
  //   if (activatedDependents[0]) {
  //     setSelectedDependent(activatedDependents[0]);
  //     saveDependentUser(activatedDependents[0]);
  //   } else {
  //     setSelectedDependent(deactivatedDependents[0]);
  //     saveDependentUser(deactivatedDependents[0]);
  //   }

  //   // setIsOpen(true);
  //   history.push({
  //     pathname: MY_ROOM_ROUTE
  //   });
  // };
  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (selectedDependent?.id) {
      let camp_id = selectedDependent?.school_details?.id ? selectedDependent?.school_details?.id : ""
      getUpcomingSpecialCamp(camp_id);
    }
    if (guardian.dependents.length && guardian.dependents[0].profile
      && !guardian.dependents[0].profile.nick_name && !guardian.dependents[0].profile?.is_activated) {
      handleKidRegistration();
    }
    else if (guardian.dependents.length && location?.state?.from === 'verified') {
      accountVerificationRef.current.handleOpen();
    }
    if (guardian.dependents.length && selectedDependent.profile && !selectedDependent.profile.is_activated) {
      if (history?.location?.state?.from !== 'payment') {
        const currentState = history.location.state || {};
        const newState = { ...currentState, dependent: selectedDependent };
        history.replace({ ...history.location, state: newState });
        accountVerificationRef.current.handleOpen();
      } else {
        setTimeout(() => {
          const currentState = history.location.state || {};
          const newState = { ...currentState, from: '' };
          history.replace({ ...history.location, state: newState });
        }, [1000])
      }
    }
    if (selectedDependent && !selectedDependent?.subscriptions && selectedDependent?.profile?.one_time_plan === 'Emeralites') {
      if (selectedDependent?.dependent_creation_count > 19 || selectedDependent?.dependent_challenge_count > 19) {
        setShowTimeToUpgradePopup(true)
        if (selectedDependent?.dependent_creation_count > 19 && selectedDependent?.dependent_challenge_count > 19) {
          setUpgradeType('both')
        } else {
          if (selectedDependent?.dependent_creation_count > 19) {
            setUpgradeType('creation')
          } else {
            if (selectedDependent?.dependent_challenge_count > 19) {
              setUpgradeType('challenge')
            }
          }
        }
      }
    }
    setIsExistingUser(
      guardian.dependents.length === 1 &&
      guardian.dependents[0].subscriptions === '' &&
      guardian.relationship_to_kid === ''
    );
  }, [selectedDependent]);

  useEffect(() => { }, [specialCamp]);

  useEffect(() => {
    if (!guardian?.dependents.length) setShowRegisterFirstChildModal(true)
  }, [guardian])

  const handleClick = (e) => {
    const dom = e.target;
    const isSeedlings = dom.dataset.isseedlings === 'true';
    const isAttend = dom.dataset.isattend === 'true';
    const isPurchased = Number(dom.dataset.specialcampid) === specialCamp?.id;
    const isFreeForSeedling = dom.dataset.isfreeforseedling === 'true';
    const dependent = getGuardianDependents([
      parseInt(dom.dataset.dependentid, 10),
    ]);
    if (!dependent[0]?.profile?.is_activated) {
      return
    }
    if (!available) {
      return;
    } else if (isSeedlings && isPurchased) {
      // console.log('purchased routine');
      history.push({
        pathname: `${GUARDIAN_SETTINGS_ROUTE}/special-camp`,
        state: { specialCamp, dependent, purchased: 'attendance' },
        // state: { specialCamp, dependent, purchased: 'purchased' },
      });
    } else if (isSeedlings && isFreeForSeedling) {
      history.push({
        pathname: `${GUARDIAN_SETTINGS_ROUTE}/special-camp`,
        state: { specialCamp, dependent, purchased: 'attendance' },
      });
    } else if (isSeedlings && !isPurchased) {
      history.push({
        pathname: `${GUARDIAN_SETTINGS_ROUTE}/special-camp`,
        state: { specialCamp, dependent, purchased: 'purchase' },
      });
    } else if (!isSeedlings) {
      history.push({
        pathname: `${GUARDIAN_SETTINGS_ROUTE}/special-camp`,
        state: { specialCamp, dependent, purchased: 'attendance' },
      });
    }
  };

  const showSpecialCamp = (dependent) => {
    // const isSeedlings = dependent?.subscriptions.nickname === 'Seedlings';
    const isSeedlings = dependent?.profile?.is_activated ? !dependent?.subscriptions ? true : dependent?.subscriptions?.nickname === "Seedlings" ? true : false : false;
    // const isSeedlings = false;
    const isVerified = dependent?.profile?.is_activated;
    const isPurchased = dependent?.special_camp?.purchased;
    const isAttend = dependent?.special_camp?.attend === 'YES';
    const specialCampId = dependent?.special_camp?.special_camp_id
    const isFreeForSeedling = specialCamp?.is_seedling
    const specialCampImage =
      isVerified
        ? isSeedlings
          ? available
            ? isFreeForSeedling
              ? isAttend && specialCampId === specialCamp?.id
                ? 'attend'
                : 'cyan-sc'
              : isAttend && isPurchased
                ? 'attend'
                : isPurchased && specialCamp?.id === dependent?.special_camp?.special_camp_id
                  ? 'cyan-sc'
                  : 'yellow-sc'
            : 'yellow-sc-coming-soon'
          : available
            ? isAttend && specialCampId === specialCamp?.id
              ? 'attend'
              : 'cyan-sc'
            // : isPurchased && specialCamp?.id === dependent?.special_camp?.special_camp_id
            //   ? 'cyan-sc'
            //   : 'yellow-sc-coming-soon'
            : 'cyan-sc-coming-soon'
        : 'yellow-sc-coming-soon';

    return (
      <Col
        className={`col-xl-4 special-camp-column ${specialCampImage}`}
        data-isseedlings={isSeedlings}
        data-isattend={isAttend}
        data-ispurchased={isPurchased}
        data-dependentid={dependent?.id}
        data-specialCampId={specialCampId}
        data-isfreeforseedling={isFreeForSeedling}
        onClick={handleClick}
      ></Col>
    );
  };

  const handleSchoolClick = () => {
    history.push(`${CAPTAIN_ROUTE}/${selectedDependent?.profile?.group?.group_captain?.id}`)
  }

  const handleUpgradeClick = () => {
    history.push({
      pathname: `${PAYMENT_ROUTE}`,
      state: {
        dependent: JSON.stringify(selectedDependent),
        type: selectedDependent?.profile?.is_activated ? 'edit' : 'activate',
      },
    })
  }

  return (
    <Row className="middle-section guardian-row-box">
      <Row className="header-row">
        <Col className="welcome">{`Welcome ${guardian?.first_name}`}</Col>
        <Col className="add-kid">
          {/* <RoundButton
            type="button"
            label="Visit your child's room"
            className="transparent-button d-none d-sm-block visit-btn"
            onClick={openModelKidRoom}
          /> */}

          <KidSelectbox
            tagList={guardian?.dependents}
            selectedFilter=""
            selectedDependent={selectedDependent}
            setSelectedDependent={setSelectedDependent}
            selected=""
            showListWithTag=""
          />

          <RoundButton
            type="button"
            label="Add a kid"
            className="transparent-button d-none d-sm-block"
            onClick={handleAddKidClick}
          />
          {/* <RoundButton
            type="button"
            label="Kid Registration"
            className="transparent-button d-none d-sm-block"
            onClick={handleKidRegistration}
          /> */}

          <RoundButton
            type="button"
            label="+ Kid"
            className="transparent-button d-block d-sm-none"
            onClick={handleAddKidClick}
          />


        </Col>
      </Row>
      <Modal show={isOpen} onHide={closeModal} className="modal-share">
        <Modal.Header closeButton>
          <Modal.Title>
            <div>
              <img className='' src={visitChildPopupIcon} alt='' />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>You can now share your child's creations with friends and family'</h4>
          <Button className='btn-theme font-weight-bold' onClick={myRoom}>Share</Button>
        </Modal.Body>
      </Modal>
      <Row className="kid-box vertical-height activated">
        <Col className="avatar-wrapper ">
          <Avatar
            type={NORMAL_TYPE}
            key={selectedDependent?.username}
            userProfileUrl={selectedDependent?.profile?.picture_url}
            userName={selectedDependent?.username}
            firstName={selectedDependent?.first_name}
            lastName={selectedDependent?.last_name}
            taletreeFriend={selectedDependent?.profile?.taletree_friend}
            subscription={selectedDependent?.subscriptions}
            activated={selectedDependent?.profile?.is_activated}
            id={selectedDependent?.id}
            size={120}
            profile={selectedDependent.profile}
          />
          <Row className="badge-wrapper kid-dashboard-manage">
            <MyBadge
              icon={Creations}
              className='first-badge'
              count={selectedDependent?.dependent_creation_count}
              title={"Creations"}
              isEmeralites={!selectedDependent.subscriptions && selectedDependent.is_verified && selectedDependent?.profile?.one_time_plan === "Emeralites" ? true : false}
            />
            <MyBadge
              icon={Challenges}
              count={selectedDependent?.dependent_challenge_count}
              title={"Challenges"}
              isEmeralites={!selectedDependent.subscriptions && selectedDependent.is_verified && selectedDependent?.profile?.one_time_plan === "Emeralites" ? true : false}
            />
          </Row>

          {/* {selectedDependent?.profile?.group?.name ?
            <p id="tick-p" className='con-scl-p d' onClick={() => handleSchoolClick()}>gdg{selectedDependent?.school_details?.school_name} ({selectedDependent?.profile?.group?.name})</p>
            :
            <p className='no-scl-p'>You have no camp conndfgdfgcted</p>
          } */}
          <div>
          </div>
        </Col>
        {/* <Col>
              <Button onClick={() => handleAddSchool(dependent)}>Add School</Button>
            </Col> */}
        {showSpecialCamp(selectedDependent)}
        <Col className="command-wrapper">
          <ChildActionButtons dependent={selectedDependent} />
        </Col>
        <Row className="myroom-info-wrapper slider-cards">
          <Col
            // md={6} lg={7} xl={7}
            className="myroom-collapse insights-soon">
            <h3 className="insights-h3">Insights</h3>

            <div className='insights-img'>
              <>
                <img className='' src={insights} alt='' />
                <h4>Insights are coming soon!</h4>
              </>
              {/* {selectedDependent?.profile?.group?.name ?
                <>
                  <img className='' src={insights} alt='' />
                  <h4>Insights are coming soon!</h4>
                </>
                :
                <>
                  <img className='' src={Lock} alt='' />
                  <h4>Unlock <span className='insights-span'> parental insights  </span><br />
                    each month on your kids progress.</h4>

                  <button className="subscription-button Upgrade" onClick={handleUpgradeClick}>Upgrade your plan to see insights</button>
                </>
              } */}
            </div>
          </Col>
          <Col
            // md={{ span: 6 }}
            // lg={{ span: 5 }}
            // xl={{ span: 5 }}
            className="join-camp-wrapper upcoming-events"
          >

            <h2>Upcoming Events</h2>
            <GuardianCamps dependent={selectedDependent} />
          </Col>
        </Row>

      </Row>
      {isExistingUser ? (
        <Notification />
      ) : (
        //   dependent={
        //     deactivatedDependents.length === 1 ? deactivatedDependents[0] : {}
        //   }
        // />
        ''
      )}
      <RegisterFirstChild showModal={showRegisterFirstChildModal} setShowModal={setShowRegisterFirstChildModal} />
      <AddChild showModal={showAddChild} setShowModal={setShowAddChild} />
      <AccountVerification id={selectedDependent?.id} firstName={selectedDependent?.first_name} ref={accountVerificationRef} dependent={selectedDependent} />
      {history?.location?.state?.isUpgrade && <KidUpgradedModal />}
      {showTimeToUpgradePopup && <TimeToUpgradeModal showPopup={showTimeToUpgradePopup} setShowPopup={setShowTimeToUpgradePopup} upgradeType={upgradeType} />}
    </Row>
  );
};

export default KidManagement;
