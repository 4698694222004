import React, { useState } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import RoundButton from '../common/RoundButton';
import closeIcon from '../../assets/icon/icon-close-20x20.svg';

import './scss/confirm.message.scss';

const ConfirmMessage = props => {
  const [resultMessage, setResultMessage] = useState('');

  const smallSize = (props.small) ? "small-size" : "";
  
  return (
    <div className={`confirm-message ${props.show}`}>
      <div className="main">
        <div className="wrapper">
          <div className={`cm-header ${smallSize}`}>
            <div className="text">{props.headerTitle}</div>
            <div className={`close ${smallSize}`} onClick={props.handleClose}>
              <Image src={closeIcon} />
            </div>
          </div>
          <div className={`cm-body ${smallSize}`}>
            <div className="message">
              {props.message}
            </div>
            <div className={`result ${props.useButton}`}>{resultMessage}</div>
            <div className={`buttons ${props.useButton}`}>
              <RoundButton
                label={props.cancelLabel}
                type="button"
                onClick={props.handleClose}
                backgroundColor="#FFF"
                className="cancel-btn"
                block={false}
                testId=""
              />
              <RoundButton
                label={props.confirmLabel}
                type="button"
                onClick={props.handleConfirm}
                backgroundColor="#FF6A57"
                className="confirm-btn"
                block={false}
                testId=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmMessage.defaultProps = {
  headerTitle: "",
  message: "",
  small: false,
  useButton: "show",
  cancelLabel: "Cancel",
  confirmLabel: "Confirm",
  handleClose: ()=>{},
  handleConfirm: ()=>{},
  show: "hide",
};

export default ConfirmMessage;