import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import RoundButton from '../../common/RoundButton';
import { Row, Col, ProgressBar } from 'react-bootstrap';
import { ON_BOARDING } from '../../../constants/routes.constants';
import { bg } from '../../../constants/component.constants';
import { changeBodyClassName } from '../../../utils/utils';
import OnboardingWrapper from './OnboardingWrapper';
import { onboardingViewModel } from './onboarding.view.model';
import Question1Choices from './Question1Choices';

import './scss/step3.scss';

const Step3 = ({ location }) => {
  const _STEP = 1;
  const history = useHistory();
  const [age, setAge] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [choices, setChoices] = useState([]);
  const [errShow, setErrShow] = useState(false);
  const [errMessage, setErrMessage] = useState('');

  const getQuestions = () => {
    onboardingViewModel
      .getQuestions()
      .then((data) => {
        setQuestions(data);
        // console.log(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const pickQuestions = (step) => {
    const result = questions.filter((q) => {
      return q.step === step;
    });
    return result;
  };

  const isAnswered = () => {
    const lsChoices = JSON.parse(localStorage.getItem('question1Choices'));
    const target = lsChoices !== 0 ? lsChoices : choices;
    let count = 0;
    target.map((choice) => {
      if (choice.answers[0].length > 0) count++;
    });
    return count !== 0 || false;
  };

  const handleBackClick = () => {
    // localStorage.removeItem('question1Choices');
    history.goBack();
  };
  const handleNextClick = () => {
    if (isAnswered()) {
      history.push({
        pathname: `${ON_BOARDING}/step4`,
        state: {
          ...location.state,
          from: 'step3',
          questions: questions,
        },
      });
    } else {
      setErrMessage('Please select at least one answer');
      setErrShow(true);
      setTimeout(() => {
        setErrShow(false);
      }, 2000);
    }
  };

  const getAge = () => {
    const today = new Date();
    const birthday = new Date(localStorage.getItem('birthdate'));
    let age = today.getFullYear() - birthday.getFullYear();
    const month = today.getMonth() - birthday.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthday.getDate()))
      age--;
    setAge(age < 0 ? 0 : age);
  };

  useEffect(() => {
    changeBodyClassName([bg.GRADIENT]);
    if (!location.state || location.state.from !== 'step2')
      history.replace({ pathname: `${ON_BOARDING}/step1` });
    getQuestions();
    getAge();
  }, []);

  useEffect(() => {
    setChoices(pickQuestions(_STEP));
  }, [questions]);

  return (
    <OnboardingWrapper className="step3-wrapper">
      <Row className="content-wrapper">
        <Col className="content">
          <div className="progress-bar-wrapper">
            <ProgressBar now={32} />
          </div>
          <div className="title">
            Through creativity, we can nurture your {age}-year-old to increased
            health, happiness, and development.
          </div>
          <div className="sub title">Where can we help best?</div>
          <Question1Choices choices={choices} setChoices={setChoices} />
          <div className={`error-message-wrapper ${errShow ? 'show' : 'hide'}`}>
            {errMessage}
          </div>
          <div className="button-wrapper">
            <RoundButton
              type="button"
              label="back"
              className="back-button"
              onClick={handleBackClick}
            />
            <RoundButton
              type="button"
              label="Next"
              className="next-button"
              onClick={handleNextClick}
            />
          </div>
        </Col>
      </Row>
    </OnboardingWrapper>
  );
};

export default Step3;
