import { fetchUtils } from '../utils/fetch.utils';
import { getAPIUrl } from '../utils/config.utils';

const BASE_URL = getAPIUrl();
const SCHOOL = 'school';
const CAMPCAPTAIN = 'camp-captain';
const GROUPS = 'groups';

function createSchool(params) {
  return fetchUtils.post(`${BASE_URL}/${SCHOOL}/onboarding-school`, params);
}

function schoolLogin(credentials) {
  return fetchUtils.post(`${BASE_URL}/login/${SCHOOL}`, credentials);
}

function updateSchoolUser(id, params, user_type) {
  if(user_type === "school") {
    return fetchUtils.patch(`${BASE_URL}/${SCHOOL}/${id}`, params);
  } else {
    return fetchUtils.patch(`${BASE_URL}/${CAMPCAPTAIN}/update/${id}`, params);
  }
  
}

function getBadgeCount(id) {
    return fetchUtils.get(`${BASE_URL}/${SCHOOL}/badge_Count/${id}`);
}

function getPublicCampBadgeCount(id) {
  return fetchUtils.get(`${BASE_URL}/${SCHOOL}/all_badge_Count/${id}`);
}

function getUpcomingChallenges(params) {
    const query = fetchUtils.buildQuery(params);
    // if(selectedTreehouse && selectedTreehouse !== "All" ) return fetchUtils.get(`${BASE_URL}/${SCHOOL}/challenges?teacher_id=${selectedTreehouse[0].id}&availability=upcoming`);
    return fetchUtils.newGet(`${BASE_URL}/${SCHOOL}/challenges?${query}`);
  }
  
  function getLiveChallenges(params) {
    const query = fetchUtils.buildQuery(params);
    // if(selectedTreehouse && selectedTreehouse !== "All") return fetchUtils.get(`${BASE_URL}/${SCHOOL}/challenges?teacher_id=${selectedTreehouse[0].id}&availability=current`);
    return fetchUtils.newGet(`${BASE_URL}/${SCHOOL}/challenges?${query}`);
  }
  
  function getPastChallenges(params) {
    const query = fetchUtils.buildQuery(params);
    // if(selectedTreehouse && selectedTreehouse !== "All") return fetchUtils.get(`${BASE_URL}/${SCHOOL}/challenges?teacher_id=${selectedTreehouse[0].id}&availability=previous`);
    return fetchUtils.newGet(`${BASE_URL}/${SCHOOL}/challenges?${query}`);
  }

  function getPublicCampChallenges(params) {
    const query = fetchUtils.buildQuery(params);
    return fetchUtils.newGet(`${BASE_URL}/${SCHOOL}/public_challenges?${query}`);
  }

  function addChallenge(params) {
    return fetchUtils.post(`${BASE_URL}/${SCHOOL}/add-challenge`, params);
  }

  function addTeacherChallenge(params) {
    return fetchUtils.post(`${BASE_URL}/challenges/add-challenge`, params);
  }

  function addChallengeMedia(id, params) {
    return fetchUtils.postMultiForm(`${BASE_URL}/${SCHOOL}/upload-challenge-image/${id}`, params);
  }

  function addTeacherChallengeMedia(id, params) {
    return fetchUtils.postMultiForm(`${BASE_URL}/challenges/upload-challenge-image/${id}`, params);
  }

  function uploadSchoolPhoto(id, params) {
    return fetchUtils.postMultiForm(
      `${BASE_URL}/${SCHOOL}/${id}/picture`,
      params
    );
  }

  function uploadCampCaptainPicture(id, params) {
    return fetchUtils.postMultiForm(
      `${BASE_URL}/${CAMPCAPTAIN}/${id}/picture`,
      params
    );
  }
  
  function uploadSchoolBanner(id, params) {
    return fetchUtils.postMultiForm(
      `${BASE_URL}/${SCHOOL}/${id}/banner`,
      params
    );
  }

  function uploadCampCaptainBanner(id, params) {
    return fetchUtils.postMultiForm(
      `${BASE_URL}/${CAMPCAPTAIN}/${id}/banner`,
      params
    );
  }

  function updateSchoolDescription(id, params) {
    return fetchUtils.patch(`${BASE_URL}/${SCHOOL}/update-school/${id}`, params);
  }

  function deleteSchoolBanner(id) {
    return fetchUtils.requestDelete(`${BASE_URL}/${SCHOOL}/${id}/banner`);
  }

  function getTimezones() {
    return fetchUtils.get(`${BASE_URL}/${SCHOOL}/timezone`);
  }

  function editChallenge(id, params) {
    return fetchUtils.patch(`${BASE_URL}/challenges/update/${id}`, params);
  }

  function editChallengeMedia(id, params) {
    return fetchUtils.postMultiForm(`${BASE_URL}/${SCHOOL}/upload-challenge-image/${id}`, params);
  }

  function addTeacher(params) {
    return fetchUtils.post(`${BASE_URL}/${SCHOOL}/add-teacher`, params);
  }

  function addTribe(params) {
    return fetchUtils.post(`${BASE_URL}/${GROUPS}/add`, params);
  }

  function getCampMasterTribes(camp_master_id, params) {
    const query = fetchUtils.buildQuery(params);
    return fetchUtils.get(`${BASE_URL}/${GROUPS}/list/${camp_master_id}?${query}`);
  }

  function getCampCaptainTribes(camp_captain_id, params) {
    const query = fetchUtils.buildQuery(params);
    return fetchUtils.get(`${BASE_URL}/${GROUPS}/list-by-camp-captain/${camp_captain_id}?${query}`);
  }

  function uploadTeacherImage(id, params) {
    return fetchUtils.postMultiForm(`${BASE_URL}/${SCHOOL}/teacher/picture/${id}`, params);
  }

  function getTeachers(params) {
    return fetchUtils.get(`${BASE_URL}/${SCHOOL}/school-teacher/list/`);
  }

  function getPublicTribeList(id) {
    return fetchUtils.get(`${BASE_URL}/${SCHOOL}/school-teacher/list/${id}`);
  }

  function getTeacherDetails(id) {
    return fetchUtils.get(`${BASE_URL}/${SCHOOL}/school-teacher/${id}`);
  }

  function editTeacher(id, params) {
    return fetchUtils.patch(`${BASE_URL}/${SCHOOL}/school-teacher/${id}`, params);
  }

  function deleteTeacher(id) {
    return fetchUtils.requestDelete(`${BASE_URL}/${SCHOOL}/school-teacher/${id}`);
  }

  function addCampCaptain(params) {
    return fetchUtils.post(`${BASE_URL}/${CAMPCAPTAIN}/add`, params);
  }

  function addCampCaptainMedia(id, params) {
    return fetchUtils.postMultiForm(`${BASE_URL}/${CAMPCAPTAIN}/${id}/picture`, params);
  }

  function getCampCaptainsList(id, params) {
    const query = fetchUtils.buildQuery(params);
    return fetchUtils.get(`${BASE_URL}/${CAMPCAPTAIN}/list/${id}?${query}`);
  }

  function editCampCaptain(id, params) {
    return fetchUtils.patch(`${BASE_URL}/${CAMPCAPTAIN}/update/${id}`, params);
  }

  function deleteCampCaptain(id) {
    return fetchUtils.requestDelete(`${BASE_URL}/${CAMPCAPTAIN}/delete/${id}`);
  }

  function deleteCampCaptainMedia(id) {
    return fetchUtils.requestDelete(`${BASE_URL}/${CAMPCAPTAIN}/${id}/picture`);
  }

  function editTribe(id, params) {
    return fetchUtils.patch(`${BASE_URL}/${GROUPS}/update/${id}`, params);
  }

  function getCampDependents(params) {
    const query = fetchUtils.buildQuery(params);
    return fetchUtils.get(`${BASE_URL}/${SCHOOL}/dependents-by-group?${query}`);
  }

  function getCampCamptainDependents(params) {
    const query = fetchUtils.buildQuery(params);
    return fetchUtils.get(`${BASE_URL}/${CAMPCAPTAIN}/dependents?${query}`);
  }

  function getCampMasterBadgeCount(params) {
    const query = fetchUtils.buildQuery(params);
    return fetchUtils.get(`${BASE_URL}/${SCHOOL}/badgecount-school?${query}`);
  }

  function getCampCaptainBadgeCount(params) {
    const query = fetchUtils.buildQuery(params);
    return fetchUtils.get(`${BASE_URL}/${CAMPCAPTAIN}/badge-count?${query}`);
  }

  function getDependentLogs(params, id) {
    const query = fetchUtils.buildQuery(params);
    return fetchUtils.get(`${BASE_URL}/dependents/log-entries/${id}?${query}`);
  }

  function getTribeLogs(params, id) {
    const query = fetchUtils.buildQuery(params);
    return fetchUtils.get(`${BASE_URL}/${GROUPS}/log-entries/${id}?${query}`);
  }

  function changeConnectionStatus(status, id) {
    return fetchUtils.patch(`${BASE_URL}/${CAMPCAPTAIN}/dependent-request/${status}/${id}`);
  }

export const schoolRepository = {
    createSchool,
    schoolLogin,
    updateSchoolUser,
    updateSchoolDescription,
    getBadgeCount,
    getUpcomingChallenges,
    getLiveChallenges,
    getPastChallenges,
    addChallenge,
    addChallengeMedia,
    uploadSchoolBanner,
    uploadSchoolPhoto,
    deleteSchoolBanner,
    getTimezones,
    editChallenge,
    editChallengeMedia,
    addTeacher,
    getTeacherDetails,
    getTeachers,
    editTeacher,
    deleteTeacher,
    addTeacherChallenge,
    addTeacherChallengeMedia,
    uploadTeacherImage,
    getPublicCampBadgeCount,
    getPublicTribeList,
    getPublicCampChallenges,
    addCampCaptain,
    addCampCaptainMedia,
    getCampCaptainsList,
    editCampCaptain,
    deleteCampCaptain,
    deleteCampCaptainMedia,
    uploadCampCaptainBanner,
    uploadCampCaptainPicture,
    addTribe,
    getCampCaptainTribes,
    getCampMasterTribes,
    editTribe,
    getCampDependents,
    getCampCamptainDependents,
    getCampMasterBadgeCount,
    getCampCaptainBadgeCount,
    getDependentLogs,
    changeConnectionStatus,
    getTribeLogs
}