import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { HOME_FEED } from '../../../constants/routes.constants';
import { useAuth } from '../../auth/Auth';
import CreatorRoom from './CreatorRoom';
import { myRoomViewModel } from './my.room.viewmodel';
import MyRoom from './MyRoom';
import { useParams } from 'react-router';

const Creator = () => {
  const location = useLocation();
  const history = useHistory();
  const {id} = useParams();
  let username = id
  // let username = location.pathname && location.pathname.replace("/", "");
  const { dependent, guardian, setUser, fetchAPI, isLoading } = useAuth();

  const [showRoom, setShowRoom] = useState(false);
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(false);

  const getUserData = () => {
    setLoading(true);
    myRoomViewModel.getDependentUserByName(username)
      .then((res) => {
        setUserData(res.data)
        setShowRoom(true);
        setLoading(false);
      }).catch((err) => {
        console.log(err)
        // setShowRoom(true)
        history.push(HOME_FEED)
      })
  }

  useEffect(() => {
    if (dependent) {
      if (location.pathname === '/my-room') {
        if (history.location.state && history.location.state.from === "add_challenges") {
          history.replace({
            pathname: `/${dependent.username}`,
            state: {
              from: "add_challenges"
            }
          })
      }else {
        history.replace(`${dependent.username}`)
      }
      } else if (dependent.username !== username) {
        getUserData();
      }
    }
    if (!dependent) {
      localStorage.setItem('selected_dependent', username)
      getUserData();
    } else {
      setShowRoom(true);
    }
  }, [id])

  return (
    showRoom && !isLoading && !loading ?
      !dependent || dependent && dependent.username !== username ?
        userData ? <CreatorRoom creator={userData} /> : ""
        : <MyRoom />
      :
      <Spinner
        key={0}
        className="home-feed-spinner"
        animation="border"
        variant="info"
      />
  )
}

export default Creator;