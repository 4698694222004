import React, { useState } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import ErrorLabel from '../../common/ErrorLabel';
import {
  ERROR_REQUIRE_MSG,
  ERROR_WEAK_PASSWORD,
  ERROR_PASSWORD_MISMATCH,
} from '../../../constants/error.constants';
import { passwordStrengthValidator } from '../../../utils/form.utils';
import { useAuth } from '../../auth/Auth';
import { settingsViewModel } from './settings.view.model';
import FormInput from '../../common/FormInput';
import FormLabel from '../../common/FormLabel';
import Modal from '../../../components/common/Modal';
import { ComponentSize } from '../../../constants/component.constants';
import { GUARDIAN_LOGIN_ROUTE, PARTNER_LOGIN, SCHOOL_LOGIN } from '../../../constants/routes.constants';
import FormPasswordInput from '../../common/FormPasswordInput';
import LoadButton from '../../common/LoadButton';
import { displayFormErrorsFromApi } from '../../api/api.display.error';
import { partnerViewModel } from '../school/partner.view.model';
import { schoolViewModel } from '../school/school.view.model';

function ChangePasswordModal({ showPasswordModal, handleClose }) {
  const { guardian, fetchAPI, school, partner, logout } = useAuth();
  const passwordFields = {
    current_password: '',
    new_password: '',
    confirm_password: '',
  };
  const { register, handleSubmit, watch, setError, errors, reset } = useForm({
    mode: 'onBlur',
    defaultValues: passwordFields,
  });
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = (formData) => {
    setIsLoading(true);
    if(guardian) {
    fetchAPI(
      settingsViewModel.updateGuardianPassword,
      guardian.id,
      guardian.email,
      formData
    )
      .then(() => {
        reset();
        handleClose();
        logout(GUARDIAN_LOGIN_ROUTE);
      })
      .catch((error) => {
        displayFormErrorsFromApi(error, setError);
      })
      .finally(() => setIsLoading(false));
    } else if (school) {
      fetchAPI(
        schoolViewModel.updateSchoolPassword,
        school.id,
        school.email,
        formData,
        school?.user_type
      )
        .then(() => {
          reset();
          handleClose();
          logout(SCHOOL_LOGIN);
        })
        .catch((error) => {
          displayFormErrorsFromApi(error, setError);
        })
        .finally(() => setIsLoading(false));
    } else if (partner) {
      fetchAPI(
        partnerViewModel.updatePartnerPassword,
        partner.id,
        partner.email,
        formData
      )
        .then(() => {
          reset();
          handleClose();
          logout(PARTNER_LOGIN);
        })
        .catch((error) => {
          displayFormErrorsFromApi(error, setError);
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <div id="username">
      <Modal
        show={showPasswordModal}
        handleClose={handleClose}
        title="Change password"
        size={ComponentSize.SMALL}
        className="change-password-modal"
      >
        <Row className="pt-3">
          <Col className="pl-5 pr-5 col-12 col-sm-12 form-wrapper">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormLabel>
                Current password<span style={{color: 'red'}}>*</span>
              </FormLabel>
              <Form.Group>
                <FormInput
                  name="password"
                  ref={register({
                    required: ERROR_REQUIRE_MSG,
                  })}
                  placeholder="Current password"
                  data-testid="password"
                  type="password"
                />
                <ErrorLabel errors={errors} name="password" as="label" />
              </Form.Group>
              <Form.Group>
                <FormLabel>
                  New password<span style={{color: 'red'}}>*</span>
                </FormLabel>
                <FormPasswordInput
                  name="new_password"
                  ref={register({
                    validate: (value) =>
                      passwordStrengthValidator(value) || ERROR_WEAK_PASSWORD,
                  })}
                  placeholder="New password"
                  data-testid="new_password"
                />
                <ErrorLabel errors={errors} name="new_password" as="label" />
              </Form.Group>
              <Form.Group>
                <FormLabel>
                  Confirm password<span style={{color: 'red'}}>*</span>
                </FormLabel>
                <FormPasswordInput
                  name="confirm_password"
                  ref={register({
                    validate: (value) =>
                      value === watch('new_password') ||
                      ERROR_PASSWORD_MISMATCH,
                  })}
                  placeholder="Confirm password"
                  data-testid="confirm_password"
                />
                <ErrorLabel
                  errors={errors}
                  name="confirm_password"
                  as="label"
                />
              </Form.Group>
              <Form.Group>
                <Form.Row className="justify-content-between mt-4 mb-4 change-pass-act">
                  <Button
                    className="rounded-pill close-button"
                    variant="outline-grey"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <LoadButton
                    className="rounded-pill submit-button gred-btn"
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    text="Change password"
                  />
                </Form.Row>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

export default ChangePasswordModal;
