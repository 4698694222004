import React, { useState, useEffect, useRef } from 'react';
import { Form, Row, Col, Button, Image } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import ErrorLabel from '../../common/ErrorLabel';
import { useAuth } from '../../auth/Auth';
import FormInput from '../../common/FormInput';
import FormLabel from '../../common/FormLabel';
import Modal from '../../../components/common/Modal';
import { ComponentSize } from '../../../constants/component.constants';
import { myRoomViewModel } from './my.room.viewmodel';
import { getDefaultProfileImage } from '../../../utils/camp.utils';
import { FAVORITES_CHAR_LIMIT } from '../../../constants/limit.constants';
import { displayErrorFavorites } from '../../api/api.display.error';
import './css/edit.profile.modal.scss';
import { isObjEmpty } from '../../../utils/utils';
import LoadButton from '../../common/LoadButton';
import customizeBannerUpload from '../../../assets/images/upload-ic.svg';
import { imageUtils } from '../../../utils/image.utils';
import EventEmitter from '../../../utils/events';
import { kidProfileErrors } from '../../../constants/error.constants';
import ImageCropModal from '../../common/image-crop-rotate/ImageCropModal';
import defaultBannerImg from '../../../assets/images/Default_cover_image.jpg';
import { FaTrash } from 'react-icons/fa';
import ChallengeModal from '../../common/ChallengeModal';

function EditChildProfileModal({ 
  showModal, 
  handleClose, 
  setNewCustomizePopup, 
  handleTheme, 
  setIsFullScreen, 
  getThemeBannerImage,
  setSelectedTabClass,
  setUnselectedTabClass,
  setNameTextColor,
  setInterestTickColor
}) {
  const { dependent, fetchAPI, setUser } = useAuth();
  const [imageModule, setImageModule] = useState('');
  const [imageCropPopup, setImageCropPopup] = useState(false);
  const [imageForCrop, setImageForCrop] = useState(null);
  //Order: color, sport, animal
  const favorites = {
    color: dependent ? dependent.profile.favorites[0] : "",
    sport: dependent ? dependent.profile.favorites[1] : "",
    animal: dependent ? dependent.profile.favorites[2] : "",
  };
  const defaultImageUrl = dependent.profile.picture_url
    ? dependent.profile.picture_url
    : getDefaultProfileImage(dependent.profile.taletree_friend);

  const defaultBannerUrl = dependent?.profile?.banner_url ? dependent?.profile?.banner_url : defaultBannerImg;

  const [profilePicture, setProfilePicture] = useState(defaultImageUrl);
  const [profilePicForm, setProfilePicForm] = useState();
  const [bannerPicture, setBannerPicture] = useState(defaultBannerUrl);
  const [bannerPicForm, setBannerPicForm] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteType, setDeleteType] = useState('');
  const handleCloseDeleteConfirm = () => setShowDeleteConfirm(false);
  const userImage = useRef();
  const bannerImage = useRef();

  const getSelectedUserData = (data) => {
    if (dependent) {
      setBannerPicture(data.preview)
    }
  }

  const getSelectedUserProfilePic = (data) => {
    setProfilePicture(data.preview);
  };

  const getBlueBanner = (data) => {
    setBannerPicture(data.preview);
  };

  EventEmitter.subscribe('profile_pic', getSelectedUserProfilePic);
  EventEmitter.subscribe('banner_pic', getSelectedUserData);
  EventEmitter.subscribe('blue_banner', getBlueBanner);
  const {
    register,
    handleSubmit,
    setError,
    errors,
    formState,
    reset,
  } = useForm({
    mode: 'onBlur',
    defaultValues: { favorites },
    shouldUnregister: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = (formData) => {
    setIsLoading(true);
    fetchAPI(
      myRoomViewModel.updateDependentProfile,
      dependent.id,
      formData,
      formState.dirtyFields,
      // profilePicture,
      profilePicForm,
      dependent
    )
      .then((response) => {
        setUser(response);
        if (profilePicForm) {
          uploadProfilePic()
        }
        if (bannerPicForm) {
          uploadBannerPic()
        }
        reset({ favorites: formData.favorites });
        onClose();
      })
      .catch((error) => {
        displayErrorFavorites(error, setError, formData);
      })
      .catch((error) => {
        onClose();
      })
      .finally(() => {
        setIsLoading(false);
        setProfilePicForm(false);
        setBannerPicForm(false);
      });
  };

  const uploadProfilePic = () => {
    fetchAPI(
      myRoomViewModel.updateProfilePic,
      dependent.id,
      profilePicForm,
      dependent
    )
      .then((response) => {
        dependent.profile.picture_url = profilePicture;
        setUser(dependent);
      })
  }

  const uploadBannerPic = () => {
    fetchAPI(
      myRoomViewModel.updateBannerPic,
      dependent.id,
      bannerPicForm,
      dependent
    )
      .then((response) => {
        dependent.profile.banner_url = bannerPicture;
        setUser(dependent);
        setIsFullScreen(false);
      })
    const params = {
      banner_is_fullscreen: false,
      banner_theme: ''
    }
    fetchAPI(
      myRoomViewModel.updateTheme,
      dependent.id,
      params,
      dependent
    ).then((response) => {
      setUser(response);
    })
  }

  const handleProfileImageSelection = (e) => {
    const files = e.target.files;
    if (files.length) {
      setImageForCrop(files[0])
      setImageModule('profile')
      setImageCropPopup(true)
    }
    console.log(files)
  };

  const handleBannerImageSelection = (e) => {
    const files = e.target.files;
    if (files.length) {
      // setShowSpinner(true);
      setImageForCrop(files[0])
      setImageModule('banner')
      setImageCropPopup(true)
    }
  };

  const onCropComplete = (croppedImage) => {
    try {
      const imagePromise = imageUtils.getImagePromise(croppedImage);
      console.log(imagePromise)
      imagePromise
        .then((image) => {
          if (imageModule === 'banner') {
            setBannerPicture(image.preview);
            setBannerPicForm(image)
            EventEmitter.dispatch('banner_pic', { preview: image.preview })
          } else if (imageModule === 'profile') {
            setProfilePicture(image.preview);
            setProfilePicForm(image);
            EventEmitter.dispatch('profile_pic', { preview: image.preview });
          }
          setIsDisable(true);
          setImageForCrop(null);
          setImageModule('');
        });
    } catch (error) {
      console.log(error)
    }
  }

  const onCancel = () => {
    reset();
    setProfilePicture(defaultImageUrl);
    // console.log(defaultBannerUrl);
    if (dependent?.profile?.banner_theme !== '') {
      setBannerPicture(getThemeBannerImage(dependent?.profile?.banner_theme))
    } else {
      setBannerPicture(defaultBannerUrl);
    }
    setProfilePicForm(false);
    setBannerPicForm(false);
    // let temp_banner_url = getUserBannerUrl(dependent);
    // let temp_profile_url = getUserProfileUrl(dependent);
    // EventEmitter.dispatch('profile_pic', { preview: defaultImageUrl })
    // EventEmitter.dispatch('banner_pic', { preview: defaultBannerUrl })
    handleTheme()
    setIsLoading(false);
    handleClose();
  }

  const onClose = () => {
    setIsLoading(false);
    handleClose();
  };

  const handleCustomizeClick = () => {
    setNewCustomizePopup(true);
    onClose()
  }

  useEffect(() => {
    if (dependent?.profile?.banner_theme !== '') {
      setBannerPicture(getThemeBannerImage(dependent?.profile?.banner_theme))
    }

    return () => {
      EventEmitter.unsubscribe('profile_pic', getSelectedUserProfilePic);
      EventEmitter.unsubscribe('banner_pic', getSelectedUserData);
    }
  }, [])

  const handleDeleteProfilePic = () => {
    setShowDeleteConfirm(true);
    setDeleteType('profile');
  }

  const handleDeleteBanner = () => {
    setShowDeleteConfirm(true);
    setDeleteType('banner');
  }

  const handleDelete = () => {
    if (deleteType === 'profile') {
      myRoomViewModel.deleteDependentProfilePic(dependent?.id).then((res) => {
        setUser(res.data)
        setProfilePicture(getDefaultProfileImage(dependent?.profile?.taletree_friend));
        EventEmitter.dispatch('profile_pic', { preview: getDefaultProfileImage(dependent?.profile?.taletree_friend) });
      }).catch((err) => {
        console.log(err)
      })
    }
    if (deleteType === 'banner') {
      if (dependent?.profile?.banner_theme) {
        const params = {
          banner_is_fullscreen: false,
          banner_theme: ''
        }
        fetchAPI(
          myRoomViewModel.updateTheme,
          dependent.id,
          params,
          dependent
        ).then((response) => {
          setUser(response);
          setBannerPicture(defaultBannerImg);
          setSelectedTabClass('selected-dark');
          setUnselectedTabClass('unselected');
          setNameTextColor('text-dark');
          setInterestTickColor('interest-tickbtn-outline-light');
        })
      } else {
        const params = {
          banner_is_fullscreen: false,
          banner_theme: ''
        }
        fetchAPI(
          myRoomViewModel.updateTheme,
          dependent.id,
          params,
          dependent
        ).then((response) => {
          myRoomViewModel.deleteDependentBanner(dependent?.id).then((res) => {
            console.log(res)
          }).catch((err) => {
            console.log(err)
          })
        })
      }
    }
    setShowDeleteConfirm(false);
  }

  return (
    imageCropPopup && imageForCrop ?
      <ImageCropModal
        imageCropPopup={imageCropPopup}
        setImageCropPopup={setImageCropPopup}
        image={imageForCrop}
        setPicture={onCropComplete}
        heading={`Edit your ${imageModule} image`}
      />
      :
      <Modal
        show={showModal}
        handleClose={onCancel}
        title="Edit Profile"
        size={ComponentSize.SMALL}
      >
        <ChallengeModal
          handleClose={handleCloseDeleteConfirm}
          handleAction={handleDelete}
          show={showDeleteConfirm}
          type="confirm"
          level="warning"
          button1Label="Delete"
          button2Label="Cancel"
        >
          <div>
            <span>Are you sure you want to delete?</span>
          </div>
        </ChallengeModal>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className='customize-banner'>
            <Image src={bannerPicture} alt="" />
            <span className='customize-banner-upload' onClick={() => bannerImage.current.click()}><Image src={customizeBannerUpload} alt="" /></span>
            <span className='customize-banner-delete' onClick={handleDeleteBanner}>
              <FaTrash color='#fff' style={{height: '20px', marginTop: '-5px'}} onClick={handleDeleteProfilePic} className="edit-button" /></span>
            <input
              className="d-none"
              accept="image/png, image/jpeg, image/jpg, .heic, .HEIC"
              id='banner_picture'
              name='banner_picture'
              type="file"
              ref={bannerImage}
              onChange={(e) => handleBannerImageSelection(e)}
            />
          </div>
          <div className="flex-div justify-content-between padding-x">
            <div className='customize-user-img' style={{ marginLeft: '30px' }}>
              <Image src={profilePicture} alt="" />
              <span className='customize-banner-upload' onClick={() => userImage.current.click()}><Image src={customizeBannerUpload} alt="" /></span>
              <Form.Group>
                <FormInput
                  className="d-none"
                  accept="image/png, image/jpeg, image/jpg, .heic, .HEIC"
                  id='profile_picture'
                  name='profile_picture'
                  type="file"
                  ref={userImage}
                  onChange={(e) => handleProfileImageSelection(e)}
                />
              </Form.Group>
              {dependent?.profile?.picture_url && <FaTrash color='#fff' style={{ height: '20px' }} onClick={handleDeleteProfilePic} className="edit-button" />}
            </div>
            <div className="btn-div pt-3">
              <button className='gred-btn' onClick={handleCustomizeClick}>Customize</button>
            </div>
          </div>
          <Row className="pt-3 edit-form">
            <Col className="col-sm-8 offset-sm-2">
              <Form.Group>
                <FormLabel>Favorite Color</FormLabel>
                <FormInput
                  name="favorites.color"
                  ref={register({
                    required: kidProfileErrors.color.required,
                  })}
                  placeholder="Favorite Color"
                  maxLength={FAVORITES_CHAR_LIMIT}
                  data-testid="favorite_color"
                />
                <ErrorLabel errors={errors} name="favorites.color" />
              </Form.Group>
              <Form.Group controlId="favorite_sport">
                <FormLabel>Favorite Sport</FormLabel>
                <FormInput
                  name="favorites.sport"
                  ref={register({
                    required: kidProfileErrors.sport.required,
                  })}
                  placeholder="Favorite Sport"
                  maxLength={FAVORITES_CHAR_LIMIT}
                  data-testid="favorite_sport"
                />
                <ErrorLabel errors={errors} name="favorites.sport" />
              </Form.Group>
              <Form.Group>
                <FormLabel>Favorite Animal</FormLabel>
                <FormInput
                  name="favorites.animal"
                  placeholder="Favorite Animal"
                  ref={register({
                    required: kidProfileErrors.animal.required,
                  })}
                  maxLength={FAVORITES_CHAR_LIMIT}
                  data-testid="favorite_animal"
                />
                <ErrorLabel
                  errors={errors}
                  name="favorites.animal"
                  as="label"
                />
              </Form.Group>
              <Form.Group>
                <Form.Row className="justify-content-between mx-0 my-5">
                  <Button
                    onClick={onCancel}
                    className="rounded-pill text-black"
                    variant="outline-grey"
                  >
                    Cancel
                  </Button>

                  <LoadButton
                    className="rounded-pill text-black gred-btn"
                    isDisabled={isLoading || isObjEmpty(formState.dirtyFields) && !profilePicForm && !bannerPicForm}
                    isLoading={isLoading}
                    text="Save Changes"
                  />
                </Form.Row>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal>
  );
}

export default EditChildProfileModal;
