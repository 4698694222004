import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import OnboardingModal from '../onboarding/OnboardingModal';
import { useAuth } from '../../auth/Auth';
import { PAYMENT_ROUTE } from '../../../constants/routes.constants';
import RoundButton from '../../common/RoundButton';

import './css/notification.scss';

const Notification = () => {
  const history = useHistory();
  const { guardian } = useAuth();
  const [isShow, setIsShow] = useState(true);
  const handleClick = (e) => {
    // console.log(guardian.dependents);
    history.push({
      pathname: PAYMENT_ROUTE,
      state: {
        id: guardian.dependents[0].id,
        type: 'activate',
      },
    });
  };
  useEffect(() => {
    // setIsShow(guardian.dependents[0].subscriptions === '');
  }, []);

  return (
    <OnboardingModal
      show={isShow}
      showCloseButton={false}
      className="notification-dialog"
    >
      <div className="upper">
        <div className="logo">&nbsp;</div>
        <h1>Important Notice on your account</h1>
        <div className="description">
          <p>
            <b>Dear TaleTree parents,</b>
          </p>
          <p>
            Thanks much for being a loyal member of our community! We're blessed
            to have been working with you and your kids. We feel strongly that
            our methodology of building social, emotional skills and confidence
            through creativity-sharing works and can be the roots of creating
            magic in kids' hearts. We are ready to push our mission forward and
            would like our parent community to be a part of it.
          </p>
          <p>
            At this point, we need to turn our services into paid ones to
            provide you with higher quality services with stronger security and
            additional features. All free services have a business model of
            selling user data and running ads, which means you are actually
            paying those businesses a huge amount of money you can't even track.
            We prioritize the safety of our kids and their wellbeing online and
            we intentionally chose to keep our platform free from ads and
            data-selling. Therefore, our business will need to rely on your
            subscriptions so that we can continue building a healthy,
            trustworthy product for our kids.
          </p>
          <p>
            Please note that we are introducing different service plans:
            <b>Seedlings, Roots, and Branches</b>. All of our app users will be
            on our Seedlings plan, which includes the use of our app and one{' '}
            <b>free trial for our monthly Special Camp worth $25</b>.
          </p>
          <p>
            For a smooth transition, we would like to offer you another 6 months
            of a free trial period. We still need you to save your credit card
            and billing information in the Parent Dashboard as a form of
            identification. This very process will help us strengthen the
            security of the platform. There will be no charge for the next 6
            months and then you will get on to our Seedlings plan for $2.99 per
            month or $19.99 per year. You may cancel any time. We highly
            encourage you to check out the other plans we offer. If you upgrade
            to Roots or Branches yearly plans, you get greater discounts.
          </p>
          <p>Enjoy seeing your kid grow with TaleTree! ❤️</p>
        </div>
        <Row className="btn-wrapper">
          <RoundButton
            className="notification-button"
            type="button"
            onClick={handleClick}
            label="Choose Plan"
          />
        </Row>
      </div>
    </OnboardingModal>
  );
};

export default Notification;
