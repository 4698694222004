import React, { useEffect, useState } from 'react';
import SettingsNavHeader from './SettingsNavHeader';
import DashboardHeader from '../../header/DashboardHeader';
import LoadButton from '../../common/LoadButton';
import './css/join.camp.scss';
import AccountSubtitle from '../account/AccountSubtitle';
import { useAuth } from '../../auth/Auth';
import { priceWithDollerSign } from '../../../utils/utils';
import { Button, Col, Container, FormGroup, Row } from 'react-bootstrap';
import PaymentWrapper from '../payment/PaymentWrapper';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { stripeViewModel } from '../../views/payment/stripe.view.model';
import {
  GUARDIAN_SETTINGS_ROUTE,
  PAYMENT_ROUTE,
} from '../../../constants/routes.constants';
import SettingsTitle from './SettingsTitle';
import joinCampImage from '../../../assets/images/join-camp.svg';
function RegisterCamp() {
  const [page, setPage] = useState(1);
  const [price, setPrice] = useState(undefined);
  const location = useLocation();
  const history = useHistory();
  const { fetchAPI } = useAuth();
  const registerCampPage = () => {
    const dependents =
      location.state && location.state.dependents
        ? location.state.dependents
        : null;
    if (dependents == null) {
      return <Redirect to={GUARDIAN_SETTINGS_ROUTE} />;
    }
    const showPayment = () => {
      history.push({
        pathname: PAYMENT_ROUTE,
        state: { dependents },
      });
    };

    const getPrice = (productId = 'camp') => {
      fetchAPI(stripeViewModel.getPrices, productId)
        .then((res) => setPrice(res))
        .catch((err) => console.log(err));
    };

    useEffect(() => {
      getPrice();
    }, []);

    return (
      <Container>
        <Row>
          <Col className="pt-5 text-center">
            <img src={joinCampImage} />
          </Col>
        </Row>
        <Row className="pt-3">
          <Col className="col-1"></Col>
          <Col className="col-10 text-center join-camp-main-text">
            <span>
              Our camp is a challenge-based 'show-and-tell' program that
              implements our mission to transform the way kids expand their
              creativity outside the traditional classroom while also learning
              and practicing empathy.
            </span>
          </Col>
        </Row>
        <Row className="text-center join-camp-session">
          <Col>
            <span>First session FREE and {priceWithDollerSign(price?.unit_amount || 4000)} / 4 weeks</span>
          </Col>
        </Row>
        <Row className="pt-3">
          <Col className="text-center">
            <Button
              type="button"
              onClick={showPayment}
              testid="submit"
              className="rounded-pill px-3 join-camp-btn"
            >
              Register Now
            </Button>
          </Col>
        </Row>
      </Container>
    );
  };

  const paymentPage = () => {
    return <PaymentWrapper title="test" onPaymentSuccess={() => {}} />;
  };

  return (
    <div>
      <DashboardHeader />
      <SettingsNavHeader title="Join Camp" />
      <div className="join-camp-container">{registerCampPage()}</div>
    </div>
  );
}

export default RegisterCamp;
