import React from 'react';
import cn from 'classnames';
import { isNumber } from 'advanced-cropper';
import './Navigation.scss';
import { AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai';
import Slider from './Slider';

const Navigation = ({ className, zoom, onZoom }) => {
  const onZoomIn = () => {
    if (onZoom && isNumber(zoom)) {
      onZoom(Math.min(1, zoom + 0.25), true);
    }
  };

  const onZoomOut = () => {
    if (onZoom && isNumber(zoom)) {
      onZoom(Math.max(0, zoom - 0.25), true);
    }
  };

  return (
    <div className={cn('absolute-zoom-cropper-navigation', className)}>
      <button className="absolute-zoom-cropper-navigation__button" onClick={onZoomOut}>
        <AiOutlineZoomOut />
      </button>
      <Slider value={zoom} onChange={onZoom} className="absolute-zoom-cropper-navigation__slider" />
      <button className="absolute-zoom-cropper-navigation__button" onClick={onZoomIn}>
        <AiOutlineZoomIn />
      </button>
    </div>
  );
};

export default Navigation;