// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/logo/logo-53x53.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "body{padding-bottom:50px;color:#321b6d}body .emphasize{font-weight:bold;color:#32c1bd}html{height:100%;overflow:auto}.form-control-focus,.form-control:focus{color:#495057;background-color:#fff;border-color:#321b6d;outline:0;box-shadow:unset}.form-control::-webkit-input-placeholder{color:#afafaf}.form-control:-moz-placeholder{color:#afafaf}.form-control::-moz-placeholder{color:#afafaf}.form-control:-ms-input-placeholder{color:#afafaf}.form-control::-ms-input-placeholder{color:#afafaf}.notification-dialog{max-width:760px !important}.notification-dialog .modal-content .modal-body .upper .logo{display:inline-block;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:50% 0;width:53px;height:53px;margin-bottom:10px}.notification-dialog .modal-content .modal-body .upper h1{font-size:1.6rem;margin-bottom:56px}.notification-dialog .modal-content .modal-body .upper .description{font-size:.9rem;text-align:left}.notification-dialog .modal-content .modal-body .upper .btn-wrapper{justify-content:center}.notification-dialog .modal-content .modal-body .upper .btn-wrapper .notification-button{font-weight:bold;width:80%;max-width:390px}@media only screen and (max-width: 768px){.notification-dialog .modal-content .modal-body .upper .logo{background-size:80% 80%}.notification-dialog .modal-content .modal-body .upper h1{font-size:1.1rem;margin-bottom:35px}.notification-dialog .modal-content .modal-body .upper .description{margin:1rem 2rem}}.notification-dialog .modal-content .modal-footer{background-color:#fff}", ""]);
// Exports
module.exports = exports;
