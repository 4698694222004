import { fetchUtils } from '../utils/fetch.utils';
import { getAPIUrl } from '../utils/config.utils';

const BASE_URL = getAPIUrl();
const FEED = 'feed';

function getFeed(params) {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.newGet(`${BASE_URL}/${FEED}?${query}`);
}

function complimentList() {
  return fetchUtils.get(`${BASE_URL}/compliments`);
}

function addCompliment(params) {
  return fetchUtils.post(`${BASE_URL}/comments`, params);
}

function commentList(params) {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.newGet(`${BASE_URL}/comments?${query}`);
}

function getOfTheDay() {
  return fetchUtils.get(`${BASE_URL}/banner`);
}

export const feedRepository = {
  getFeed,
  complimentList,
  addCompliment,
  commentList,
  getOfTheDay
};
