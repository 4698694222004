import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Container } from 'react-bootstrap';
// import SelectProduct from './SelectProduct';
import CreateChildren from './CreateChildren';
import SignUpCompleteCamp from './SignUpCompleteCamp';
import { useAuth } from '../../../auth/Auth';
import {
  CREATE_CHILDREN_ROUTE,
  LOGIN_ROUTE,
} from '../../../../constants/routes.constants';
function FinishSignUp({ match }) {
  const { path } = match;
  const { guardian, isLoading } = useAuth();

  if (!guardian.is_verified || guardian.dependents.length !== 0) {
    return <Redirect to={LOGIN_ROUTE} />;
  }

  return (
    <Container>
      {isLoading ? (
        <div />
      ) : (
        <div>
          <Switch>
            <Route
              exact
              path={`${path}/complete-camp`}
              component={SignUpCompleteCamp}
            />
            <Route
              exact
              path={CREATE_CHILDREN_ROUTE}
              component={CreateChildren}
            />
            <Route render={() => <Redirect to={{ pathname: '/' }} />} />
          </Switch>
        </div>
      )}
    </Container>
  );
}

export default FinishSignUp;
