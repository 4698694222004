// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/icon/challenge-icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "body{padding-bottom:50px;color:#321b6d}body .emphasize{font-weight:bold;color:#32c1bd}html{height:100%;overflow:auto}.form-control-focus,.form-control:focus{color:#495057;background-color:#fff;border-color:#321b6d;outline:0;box-shadow:unset}.form-control::-webkit-input-placeholder{color:#afafaf}.form-control:-moz-placeholder{color:#afafaf}.form-control::-moz-placeholder{color:#afafaf}.form-control:-ms-input-placeholder{color:#afafaf}.form-control::-ms-input-placeholder{color:#afafaf}.challenge-link-container{width:100%;margin-bottom:5px}.challenge-link-container .line{margin:0 1rem;border-top:.5px solid rgba(43,35,65,.1)}.challenge-link-container .link-wrapper{display:flex;justify-content:space-between;padding:.2rem 1rem;margin:.3rem .3rem .2rem .3rem}.challenge-link-container .link-wrapper .title{font-size:.9rem;padding-left:21px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat left center}.challenge-link-container .link-wrapper .icon{margin-top:-2px;margin-right:-8px}.challenge-link-container .link-wrapper:hover{cursor:pointer;border-radius:15px 15px;background:rgba(43,35,65,.1)}", ""]);
// Exports
module.exports = exports;
