import React, { useState, useEffect } from 'react';
import '../../common/scss/tag.selectbox.scss';
import InfiniteScroll from 'react-infinite-scroller';
import { Spinner } from 'react-bootstrap';

const ExpertSelectbox = (params) => {
  const {
    expertsList,
    selectedExpert,
    setSelectedExpert,
    setIsDisabled
  } = params;
  const [showPane, setShowPane] = useState(false);
  const [rotate, setRotate] = useState('');
  const [tagOn, setTagOn] = useState(false);

  const rotateArrow = () => {
    setRotate(rotate === 'rotate-180' ? 'rotate-360' : 'rotate-180');
  };

  const handleSelectboxClick = (e) => {
    setShowPane(!showPane);
    rotateArrow();
  };

  const handleClick = (e) => {
    const tag = e.target.dataset.tag;
    if (selectedExpert && selectedExpert.id === Number(tag)) return;
    setSelectedExpert(expertsList.filter(expert => {
      return expert.id === Number(tag)
    }));
    if (setIsDisabled) {
      setIsDisabled(false);
    }
    setShowPane(false);
    rotateArrow();
  };
  const handleAllClick = () => {
    setIsDisabled(true);
    setShowPane(false);
    rotateArrow();
    if (setSelectedExpert) setSelectedExpert("All")
  }

  return (
    <div className="tagSelectbox-container kids-in-partner">
      <div
        className={`filter tag-selectbox ${tagOn ? 'tag-selected' : ''}`}
        onClick={handleSelectboxClick}
      >
        <div className="selected-tag">
          {!selectedExpert || selectedExpert === "All" ? "Experts" : selectedExpert[0]?.first_name + " " + selectedExpert[0]?.last_name}
        </div>
        <div className={`selectbox-arrow ${rotate}`}></div>
      </div>
      <div className={`tag-list-pane ${showPane ? 'show' : 'hide'}`}>
        
          <div
            className="tag-item"
            onClick={handleAllClick}
            data-tag="All"
          >
            All experts
          </div>

          {expertsList.map((expert, key) => {
            return (
              <div
                className="tag-item expert-dropdown"
                onClick={handleClick}
                data-tag={expert.id}
                key={`item-${key}`}
              >
                <img data-tag={expert.id} src={expert?.picture_url} />  {expert?.first_name + " " + expert?.last_name}
              </div>
            );
          })}
        
      </div>
    </div>
  );
};

export default ExpertSelectbox;