import { treehouseRepository } from "../../../repository/treehouse.repository";

const getNextQuery = (params, list, has_more) => {
  const lastId = list.length > 0 ? list[list.length - 1].id : 0;
  const nextQuery = { ...params };
  nextQuery.starting_after = lastId;
  nextQuery.has_more = has_more;
  return nextQuery;
};

const mergeChallengeList = (previousList, newList) => {
  const list = previousList ? [...previousList, ...newList] : newList;
  let idSet = new Set();

  const uniqueList = list.filter((item) => {
    let isUnique = false;
    if (!idSet.has(item.id)) {
      idSet.add(item.id);
      isUnique = true;
    }
    return isUnique;
  });
  return uniqueList;
};

const getTreehouseDetails = (id) => {
    return new Promise((resolve, reject) => {
      treehouseRepository
        .getTreehouseDetails(id)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const getExpertDetails = (id) => {
    return new Promise((resolve, reject) => {
      treehouseRepository
        .getExpertDetails(id)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const getTeachersDetails = (id) => {
    return new Promise((resolve, reject) => {
      treehouseRepository
        .getTeachersDetails(id)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const getSubscribedTreehouses = (id) => {
    return new Promise((resolve, reject) => {
      treehouseRepository
        .getSubscribedTreehouses(id)
        .then((res) => {
          resolve(res.data)
        }).catch((err) => {
          reject(err)
        })
    })
  }

  const subscribeTreehouse = (params) => {
    return new Promise((resolve, reject) => {
      treehouseRepository
        .subscribeTreehouse(params)
        .then((res) => {
          resolve(res.data)
        }).catch((err) => {
          reject(err)
        })
    })
  }

  const unSubscribeTreehouse = (params) => {
    return new Promise((resolve, reject) => {
      treehouseRepository
        .unSubscribeTreehouse(params)
        .then((res) => {
          resolve(res.data)
        }).catch((err) => {
          reject(err)
        })
    })
  }

  const getUpcomingChallenges = (params, upcomingChallengeList) => {
    return new Promise((resolve, reject) => {
      treehouseRepository
        .getUpcomingChallenges(params)
        .then((res) => {
          const response = res.data;
          const upcomingList = mergeChallengeList(upcomingChallengeList, response);
          const query = getNextQuery(params, upcomingList, res.has_more);
          const data = {
            newList: upcomingList,
            query,
          };
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const getLiveChallenges = (params, liveChallengeList) => {
    return new Promise((resolve, reject) => {
      treehouseRepository
        .getLiveChallenges(params)
        .then((res) => {
          const response = res.data;
          const currentList = mergeChallengeList(liveChallengeList, response);
          const query = getNextQuery(params, currentList, res.has_more);
          const data = {
            newList: currentList,
            query,
          };
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const getPastChallenges = (params, pastChallengeList) => {
    return new Promise((resolve, reject) => {
      treehouseRepository
        .getPastChallenges(params)
        .then((res) => {
          const response = res.data;
          const pastList = mergeChallengeList(pastChallengeList, response);
          const query = getNextQuery(params, pastList, res.has_more);
          const data = {
            newList: pastList,
            query,
          };
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

const getSchoolBadgeCount = (id) => {
  return new Promise((resolve, reject) => {
      treehouseRepository
          .getSchoolBadgeCount(id)
          .then((res) => {
              resolve(res.data);
          })
          .catch((error) => {
              reject(error);
          });
  });
}

const getPartnerBadgeCount = (id) => {
  return new Promise((resolve, reject) => {
      treehouseRepository
          .getPartnerBadgeCount(id)
          .then((res) => {
              resolve(res.data);
          })
          .catch((error) => {
              reject(error);
          });
  });
}

const getExpertBadgeCount = (id) => {
  return new Promise((resolve, reject) => {
      treehouseRepository
          .getExpertBadgeCount(id)
          .then((res) => {
              resolve(res.data);
          })
          .catch((error) => {
              reject(error);
          });
  });
}

const getTreehouseBySlug = (slug) => {
  return new Promise((resolve, reject) => {
      treehouseRepository
          .getTreehouseBySlug(slug)
          .then((res) => {
              resolve(res.data);
          })
          .catch((error) => {
              reject(error);
          });
  });
}

const getTribeDetails = (id) => {
  return new Promise((resolve, reject) => {
    treehouseRepository
      .getTribeDetails(id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const checkTribeName = (group_name) => {
  return new Promise((resolve, reject) => {
    treehouseRepository
      .checkTribeName(group_name)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const treehouseViewModel = {
    getTreehouseDetails,
    getTeachersDetails,
    getSubscribedTreehouses,
    unSubscribeTreehouse,
    subscribeTreehouse,
    getUpcomingChallenges,
    getLiveChallenges,
    getPastChallenges,
    getSchoolBadgeCount,
    getPartnerBadgeCount,
    getTreehouseBySlug,
    getExpertBadgeCount,
    getExpertDetails,
    getTribeDetails,
    checkTribeName
}