import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie-player';
import animationData from './lotties/Binkyjump2021.json';
import { binkyRepository } from '../../repository/binky.repository.js';

import iconBinkyWhite from '../../assets/images/icon-binky-white.svg';
import iconBinkySelected from '../../assets/images/icon-binky-selected.svg';
import binkyCommentIcon from '../../assets/images/comment_ic.svg';
import { HiDotsHorizontal } from '@react-icons/all-files/hi/HiDotsHorizontal';
import { useAuth } from '../auth/Auth';

import './scss/binky.scss';
import { useHistory } from 'react-router-dom';
import ShareModal from '../common/ShareModal';
import GetVerifiedNowModal from '../views/next_challenge/getVerifiedNowModal';
import { HOME_FEED, NEXT_CHALLENGE_ROUTE, SHARE_CREATION_ROUTE_BANNER } from '../../constants/routes.constants';
import OptimizeSignup from '../views/onboarding/signup_popups/OptimizeSignup.js';

function Binky({
  creationId,
  binkyCountProp,
  commentCountProp,
  binkyIdProp,
  feedList,
  handleBinky,
  setShowFullview,
  className = '',
  isFullView,
  isCreatorRoom,
  isShareCreation,
  from
}) {
  const history = useHistory();
  const { dependent, guardian, school } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const goTo = 2;
  const [play, setPlay] = useState(false);
  const [showBinkyAnimation, setShowBinkyAnimation] = useState(false);
  const [binkyId, setBinkyId] = useState(binkyIdProp);
  const [binkyCount, setBinkyCount] = useState(binkyCountProp);
  const [commentCount, setCommentCount] = useState(commentCountProp);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showGetVerifiedModal, setShowGetVerifiedModal] = useState(false);
  const [shareModalProps, setShareModalProps] = useState({
    id: creationId
  });

  const index = feedList
    ? feedList.findIndex((obj) => {
      // console.log(obj);
      if (obj.creation_id !== undefined)
        return obj.creation_id === creationId;
      return obj.id === creationId;
    })
    : 0;
  const binkyIcon = feedList
    ? feedList[index].binky_id
      ? iconBinkySelected
      : iconBinkyWhite
    : iconBinkyWhite;

  const createBinky = () => {
    return new Promise((resolve, reject) => {
      binkyRepository
        .binkyByCreationId(creationId)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const deleteBinky = () => {
    return new Promise((resolve, reject) => {
      binkyRepository
        .deleteBinkyById(binkyId)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const binkyAction = () => {
    if (from === 'school') return
    if (isShareCreation && !dependent) {
      setShowModal(true);
      localStorage.setItem('kids_login_location', window.location.href)
      return
    }
    // if (isShareCreation && dependent) {
    //   return
    // }
    if (!dependent && !school && school?.user_type !== 'school_teacher') {
      setShowModal(true);
      localStorage.setItem('kids_login_location', window.location.href)
      return false;
    }
    if (dependent && !dependent.profile.is_activated) {
      return setShowGetVerifiedModal(true)
    }
    if (binkyId === '' || !binkyId) {
      setShowBinkyAnimation(true);
      setPlay(true);
      createBinky().then((response) => {
        const newBinkyId = response.data.id;
        const newBinkyCount = binkyCount + 1;
        setBinkyId(newBinkyId);
        setBinkyCount(newBinkyCount);
        handleBinky(creationId, newBinkyId, newBinkyCount);
      });
    } else {
      deleteBinky().then(() => {
        const newBinkyCount = binkyCount - 1;
        setBinkyId('');
        setBinkyCount(newBinkyCount);
        handleBinky(creationId, '', newBinkyCount);
      });
    }
  };

  const binkyAnimationLoopComplete = () => {
    setPlay(false);
    setShowBinkyAnimation(false);
  };

  const onCommentClick = () => {
    if (from === 'school') return
    if (!dependent) {
      if (guardian) {
        if (!dependent && !school) {
          setShowModal(true)
        }
        localStorage.setItem('kids_login_location', window.location.href)
      } else {
        if (school && school?.user_type !== 'school') {
          setShowFullview();
        } else {
          if(!dependent && !guardian && !school) {
            setShowModal(true)
          }
        }
      }
    } else {
      if (dependent) {
        setShowFullview();
      }
    }
  }

  return (
    <div className={`binky-container ${className}`}>
      {/* <img src={binkyId === '' ? iconBinkyWhite : iconBinkySelected} onClick={binkyAction} className="binky-icon" /> */}
      <img src={binkyIcon} onClick={binkyAction} className={`binky-icon ${from === 'school' && 'remove-pointer'}`} />
      {feedList ? (
        feedList[index].binky_count > 0 ? (
          <div className={`binky-count ${from === 'school' && 'remove-pointer'}`}>{feedList[index].binky_count}</div>
        ) : null
      ) : null}

      <div className={`binky-comment-wrap flex-div-binky ${isFullView ? " remove-pointer" : ""} ${from === 'school' && 'remove-pointer'}`} onClick={onCommentClick}>
        <img src={binkyCommentIcon} className={`binky-comment-icon`} /> &nbsp;
        {feedList ? (
          feedList[index].comment_count > 0 ? (
            <div className="binky-count">{feedList[index].comment_count}</div>
          ) : null
        ) : isShareCreation ? <div className="binky-count remove-pointer">{commentCountProp}</div> : null}
      </div>

      {showBinkyAnimation ? (
        <Lottie
          className="binky-animation"
          play={play}
          goTo={goTo}
          animationData={animationData}
          onClick={() => setPlay(true)}
          onLoopComplete={binkyAnimationLoopComplete}
        />
      ) : null}
      {showGetVerifiedModal ? <GetVerifiedNowModal show={showGetVerifiedModal} setShow={setShowGetVerifiedModal} /> : ''}
      <OptimizeSignup showModal={showModal} setShowModal={setShowModal} />
      {!isFullView && history.location.pathname.slice(0, 9) !== SHARE_CREATION_ROUTE_BANNER &&
        history.location.pathname.slice(0, 11) !== NEXT_CHALLENGE_ROUTE &&
        history.location.pathname !== HOME_FEED &&
        !dependent ?
        <div className='binky-comment-wrap binky-share-icon' onClick={() => setShowShareModal(true)}>
          {/* <img src={binkyCommentIcon} className="" /> */}
          <span><HiDotsHorizontal /></span>
        </div>
        : dependent && guardian && history.location.pathname.slice(0, 9) !== SHARE_CREATION_ROUTE_BANNER &&
          history.location.pathname.slice(0, 11) !== NEXT_CHALLENGE_ROUTE &&
          history.location.pathname !== HOME_FEED ?
          <div className='binky-comment-wrap binky-share-icon' onClick={() => setShowShareModal(true)}>
            {/* <img src={binkyCommentIcon} className="" /> */}
            <span><HiDotsHorizontal /></span>
          </div> :
          dependent && history.location.pathname.slice(0, 9) !== SHARE_CREATION_ROUTE_BANNER && history.location.pathname !== HOME_FEED ?
            <div className='binky-comment-wrap binky-share-icon' onClick={() => setShowShareModal(true)}>
              {/* <img src={binkyCommentIcon} className="" /> */}
              <span><HiDotsHorizontal /></span>
            </div> : ""}

      <ShareModal
        showShareModal={showShareModal}
        setShowShareModal={setShowShareModal}
        shareModalProps={shareModalProps}
      />
    </div>
  );
}

export default Binky;
