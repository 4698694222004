// common words
const ERROR_REQUIRED_FIELD_PREFIX = 'Please enter your';
const KIDS = `kid's`;
const FIRST_NAME = 'first name';
const LAST_NAME = 'last name';
const PASSWORD = 'password';

const ERROR_INVALID_EMAIL = 'Enter a valid email address';
const ERROR_PROFANITY = 'This contains word(s) we do not allow';

const INSTITUTION_NAME = 'institution name';
// form errors
export const ERROR_REQUIRE_MSG = 'This field is required'; //legacy
export const ERROR_PASSWORD_MISMATCH = 'Passwords do not match'; //legacy
// changepassword modal
export const ERROR_WEAK_PASSWORD =
  'Password must contain at least 1 letter, 1 number and 1 special character';
//legacy loginform
export const ERROR_REQUIRED_PASSWORD = `${ERROR_REQUIRED_FIELD_PREFIX} password`;

export const guardianLoginErrors = {
  email: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} email`,
    invalid: ERROR_INVALID_EMAIL,
  },
  unvalidated: {
    required: 'You have not validated your email yet. Please check your email.',
  },
  other: {
    login: 'Invalid email and/or password',
  },
};

export const kidLoginErrors = {
  email: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} Guardian's email`,
    invalid: ERROR_INVALID_EMAIL,
  },
  username: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} nickname`,
  },
  other: {
    login: 'Invalid username and/or password',
  },
};

export const guardianSignUpErrors = {
  first_name: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} ${FIRST_NAME}`,
  },
  last_name: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} ${LAST_NAME}`,
  },
  phone_number: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} phone number`,
  },
  country: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} country`,
  },
  relationship_to_kid: {
    required: 'Please select your relationship to child',
  },
  email: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} email`,
    invalid: ERROR_INVALID_EMAIL,
    taken: 'This email is already taken',
  },
  password: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} password`,
    minLength: 'Please make sure your password has at least 8 characters',
    strength:
      'Password must contain at least 1 letter, 1 number and 1 special character',
    confirm: 'Please confirm your password',
    mismatch: ERROR_PASSWORD_MISMATCH,
  },
  recaptcha: {
    required: "Confirm you're not a bot",
  },
};

export const kidSignUpErrors = {
  first_name: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} ${KIDS} ${FIRST_NAME}`,
  },
  last_name: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} ${KIDS} ${LAST_NAME}`,
  },
  birthdate: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} ${KIDS} birthdate`,
    age: 'Your kid must be under 16 years old',
  },
  username: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} ${KIDS} username`,
    minLength: 'Must be at least 3 characters',
    maxLength: 'Must be less than 15 characters',
    charType: 'Use only letters and numbers',
    profane: ERROR_PROFANITY,
    taken: 'This username is already taken',
    sameName: 'Username cannot be same as first or last name',
  },
  password: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} ${KIDS} password`,
    minLength: 'Must be at least 5 characters',
    mismatch: ERROR_PASSWORD_MISMATCH,
  },
  favorites: {
    profane: ERROR_PROFANITY,
  },
  camp: {
    required: `Please select 'Yes' or 'No' for TaleTree Creative Camp`,
  },
};

export const uploadErrors = {
  title: {
    profane: ERROR_PROFANITY,
  },
  description: {
    profane: ERROR_PROFANITY,
  },
};

export const partnerSignUpErrors = {
  schoolType: {
    required: "Please select Partner Type",
  },
  organizationType: {
    required: "Please select Type of Organisation",
  },
  name: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} ${INSTITUTION_NAME}`
  },
  organizationName: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} Organisation name`
  },
  first_name: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} ${FIRST_NAME}`,
  },
  last_name: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} ${LAST_NAME}`,
  },
  email: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} email`,
    invalid: ERROR_INVALID_EMAIL,
    taken: 'This email is already taken',
  },
  password: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} password`,
    minLength: 'Please make sure your password has at least 8 characters',
    strength:
      'Password must contain at least 1 letter, 1 number and 1 special character',
    confirm: 'Please confirm your password',
    mismatch: ERROR_PASSWORD_MISMATCH,
  },
  recaptcha: {
    required: "Confirm you're not a bot",
  },
};

export const partnerLoginErrors = {
  email: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} email`,
    invalid: ERROR_INVALID_EMAIL,
  },
  unvalidated: {
    required: 'You have not validated your email yet. Please check your email.',
  },
  username: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} email`,
  },
  other: {
    login: 'Invalid email and/or password',
  },
};

export const addTreehouseErrors = {
  name: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} camp name`,
    exist: `Tribe name is already exits`
  },
  first_name: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} first name`,
  },
  last_name: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} last name`,
  },
  age: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} age group`,
  },
  time: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} time`,
  },
  timezone: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} timezone`,
  },
  day: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} day`,
  },
  camp_captain: {
    required: `Please select your camp captain`,
  },
};

export const addCampCaptainErrors = {
  first_name: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} first name`,
  },
  last_name: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} last name`,
  },
  email: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} email`,
    invalid: ERROR_INVALID_EMAIL,
    exist: `Email already exist`
  },
  password: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} password`,
  }
};

export const addTreehouseChallenge = {
  title: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} title`
  },
  image: {
    required: `Please upload challenge image`
  },
  description: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} description`,
  },
  date: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} date`,
  },
  time: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} time`,
  },
  endDate: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} date`,
  },
  endTime: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} time`,
  },
  hast_tag: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} hash tags`,
  },
  friend: {
    required: `Please choose your TaleTree friend`,
  },
  zoom_link: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} meeting link`,
    invalid: "Enter a valid URL",
  },
};

export const updateSchoolDescriptionError = {
  description: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} description`
  },
  hast_tag: {
    required: `${ERROR_REQUIRED_FIELD_PREFIX} hash tag`,
    taken: "This hash tag is taken"
  },
};

export const schoolCodeError = {
  code: {
    invalid: "Class Code is invalid. Please enter a valid code.",
    required: "Please enter a code."
  }
};

export const youtubeErrors = {
  invalid: "Please enter a valid link"
}

export const kidProfileErrors = {
  color: {
    required: "Please enter your favorite color"
  },
  sport: {
    required: "Please enter your favorite sport"
  },
  animal: {
    required: "Please enter your favorite animal"
  },
}

export const editCreationErrors = {
  title: {
    required: "Please enter title"
  },
  description: {
    required: "Please enter description"
  }
}

export const addExpertErrors = {
  firstName: {
    required: "Please enter expert's name"
  },
  lastName: {
    required: "Please enter expert's name"
  }
}