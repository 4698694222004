import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import SettingsTitle from './SettingsTitle';
import RoundButton from '../../common/RoundButton';
import { GUARDIAN_SETTINGS_ROUTE } from '../../../constants/routes.constants';
import './css/guardian.account.scss';
import { useHistory } from 'react-router-dom';

function GuardianEditProfile() {
  const history = useHistory();

  return (
    <div>
      <Container className="guardian-account-container">
        <SettingsTitle title="Edit Profile" />
        <Row className="mt-2">
          <Col className="col-md-8 col-lg-6">
              <div className="mb-3">
                <RoundButton
                  type="button"
                  label="Edit Profile"
                  onClick={() => history.push(`${GUARDIAN_SETTINGS_ROUTE}/edit-profile`)}
                  className="mt-3 gred-btn change-password-button"
                />
              </div>
          </Col>
        </Row>
      </Container>

    </div>
  );
}

export default GuardianEditProfile;
