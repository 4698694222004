import React, { useState, useRef, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../auth/Auth';
import RoundButton from '../../common/RoundButton';
import FormInput from '../../common/FormInput';
import FormLabel from '../../common/FormLabel';
import { ERROR_REQUIRE_MSG } from '../../../constants/error.constants';
import SettingsNavHeader from '../dashboard/SettingsNavHeader';
import { PaymentContext } from './PaymentSubscription';
import {
  SIGNUP_COMPLETE_ROUTE,
  GUARDIAN_SETTINGS_ROUTE,
  VERIFICATION_PAYMENT_SUCCESS_ROUTE,
} from '../../../constants/routes.constants';
import { priceWithDollerSign } from '../../../utils/utils';
import { capitalizeFirstLetter } from '../../../utils/camp.utils';
import Select from 'react-select';
// Stripe
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { stripeViewModel } from './stripe.view.model';
import LoadButton from '../../common/LoadButton';

import './scss/paymentMethod.scss';
import { accountRepository } from '../../../repository/account.repository';
import ApplePayButton from './ApplePayButton';

const PaymentMethod = () => {
  const history = useHistory();
  const { guardian, setGuardianDependents, refreshUserState } = useAuth();
  const {
    jobType,
    selectedPlan,
    currentSubscription,
    promotionInfo,
    setPromotionInfo,
    promotionSummaryShow,
    setPromotionSummaryShow,
    disablePromotion,
    setDisablePromotion,
    setShowPaymentMethod,
    totalAmount,
    setTotalAmount,
    dependent,
    paymentDescription,
    defaultPromotionInfo,
    isUpgrade,
    showPaymentMethod,
    totalApplePayAmount,
    setTotalApplePayAmount
  } = useContext(PaymentContext);
  const [services, setServices] = useState();
  const stripe = useStripe();
  const elements = useElements();
  const promotionCodeInput = useRef(null);
  const options = {
    style: {
      base: {
        fontSize: '1.4rem',
        color: '#424770',
        letterSpacing: '0.025em',
        height: '51px',
        marginTop: '2px',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
  const seedlings_description = "You will be charged 0.80 for verification and then free for life on this plan"
  const emeralites_description = "Why is a credit card needed for children under 13?"
  const { register, handleSubmit } = useForm();
  const [cardHolderName, setCardHolderName] = useState(
    guardian.first_name + ' ' + guardian.last_name
  );
  const [methodShow, setMethodShow] = useState('show');
  const [editShow, setEditShow] = useState('hide');
  const [reviewShow, setReviewShow] = useState('hide');
  const [paymentMethodInfo, setPaymentMethodInfo] = useState(null);
  // const [showErrorMessage, setShowErrorMessage] = useState('hide')
  const [errorMessage, setErrorMessage] = useState('');
  const [promoErrorMessage, setPromoErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const [upgradeDescription, setUpgradeDescription] = useState(
    'We bill plans and add-ons on the first of the month. You’ll pay a prorated price today and then full price starting on the first day of next month.'
  );
  const [downgradeDescription, setDowngradeDescription] = useState('');
  const isEdit = jobType === 'edit';

  const handleContinue = async () => {
    // e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    setIsLoadingPayment(true);
    if (selectedOption.value == 'new') {
      createNewPaymentMethod();
    } else {
      setPaymentMethodInfo({ id: selectedOption.value });
      handleSubscribe({ id: selectedOption.value });
    }
    setIsLoadingPayment(false);
  };

  const createNewPaymentMethod = async () => {
    const cardElement = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: cardHolderName,
      },
    });
    if (error) {
      console.log(error)
      // console.log('[createPaymentMethod error]', error);
      setErrorMessage(error.message);
    } else {
      // console.log('[PaymentMethod]', paymentMethod);
      setPaymentMethodInfo(paymentMethod);
      handleSubscribe(paymentMethod);
    }
  };

  const activateKid = (temp_dependent) => {
    let profile = {
      is_activated: true,
    }
    accountRepository.updateDependentUser(temp_dependent.id, profile).then((res) => {
      // console.log(res)
    }).catch((err) => console.log(err))
  }

  const handleSubscribe = (method) => {
    setIsLoading(true);
    setErrorMessage('');
    // handleSubscribe();
    if (isEdit && currentSubscription.nickname !== "Seedlings" && currentSubscription.nickname !== "Emeralites") {
      const req = {
        subscription_id: currentSubscription.subscription_id,
        price_id: selectedPlan.id,
      };
      // console.log(currentSubscription);
      stripeViewModel
        .changeSubscription(req)
        .then((res) => {
          // refreshUserState();
          stripeViewModel
            .getDependents({ limit: 99 })
            .then((response) => {
              const temp_dep = response.filter((obj) => obj.id === dependent[0].id)
              localStorage.setItem('set_dependent', temp_dep[0].username)
              refreshUserState()
              history.replace({
                pathname: GUARDIAN_SETTINGS_ROUTE,
                state: { dependent: temp_dep[0], isUpgrade: true, isDowngrade: !isUpgrade },
              });

            })
        })
        .catch((err) => {
          console.log(err);
          if (err.statusCode === 404 || err.statusCode === 400)
            setErrorMessage(err.serverResponse.error_info);
          else setErrorMessage('');
          // setShowErrorMessage('show')
          setIsLoading(false);
        });
    } else {
      let req;
      if (selectedPlan.nickname === "Seedlings" || selectedPlan.nickname === "Emeralites") {
        req = {
          payment_method_id: method.id,
          dependent_id: dependent[0].id,
          amount: selectedPlan.amount
        };
      } else {
        req = {
          payment_method_id: method.id,
          price_id: selectedPlan.id,
          dependent_ids: [dependent[0].id],
          promotion_code: promotionInfo.code,
        };
      }
      let API = selectedPlan.nickname === "Seedlings" || selectedPlan.nickname === "Emeralites" ? stripeViewModel.verificationPayment : stripeViewModel.subscribe;
      // stripeViewModel
      // .subscribe(req)
      // .paymentIntentSeedlings(req)
      API(req)
        .then((res) => {
          // console.log(res);
          stripeViewModel
            .getDependents({ limit: 99 })
            .then((response) => {
              // setGuardianDependents(response);
              // refreshUserState();
              const temp_dep = response.filter((obj) => obj.id === dependent[0].id)
              localStorage.setItem('set_dependent', temp_dep[0].username)
              if (currentSubscription.nickname === "Seedlings" || currentSubscription.nickname === "Emeralites") {
                refreshUserState()
                history.replace({
                  pathname: GUARDIAN_SETTINGS_ROUTE,
                  state: { isCamp: true, dependent: temp_dep[0], isUpgrade: true },
                });
              } else {
                history.replace({
                  pathname: VERIFICATION_PAYMENT_SUCCESS_ROUTE,
                  state: { isCamp: true, dependent: temp_dep[0], from: "verified" },
                });
              }
            })
            .catch((err) => {
              setIsLoading(false);
            });
        })
        .catch((err) => {
          console.log(err);
          if (err.statusCode === 404 || err.statusCode === 400)
            setErrorMessage(err.serverResponse.error_info);
          else setErrorMessage('');
          // setShowErrorMessage('show')
          setIsLoading(false);
        });
      // }
    }
  };

  const newPaymentMethodForm = () => {
    return (
      <div className={`${methodShow}`}>
        {/* <div className="item-title-wrapper">
          <FormLabel className="item-title">Name on Card</FormLabel>
        </div> */}
        <div className="item name-on-card">
          <FormInput
            name="name_on_card"
            className="form-input"
            ref={register({
              required: ERROR_REQUIRE_MSG,
            })}
            placeholder="Name on Card"
            defaultValue={cardHolderName}
            onChange={(e) => {
              setErrorMessage('');
              setCardHolderName(e.target.value);
            }}
            data-testid="name_on_card"
          />
        </div>
        {/* <div className="item-title-wrapper">
          <FormLabel className="item-title">Card Number</FormLabel>
        </div> */}
        <div className="item card-number">
          <CardNumberElement
            options={options}
            className="form-input form-control"
            placeholder="Card Number"
            showIcon={true}
            onReady={() => {
              // console.log("CardNumberElement [ready]");
            }}
            onChange={(event) => {
              // console.log("CardNumberElement [change]", event);
              setErrorMessage('');
            }}
            onBlur={() => {
              // console.log("CardNumberElement [blur]");
            }}
            onFocus={() => {
              // console.log("CardNumberElement [focus]");
            }}
          />
        </div>
        <div className="extra-card-info-wrapper">
          <div className="extra-card-info">
            {/* <div className="item-title-wrapper">
              <FormLabel className="item-title">Expiry Date</FormLabel>
            </div> */}
            <div className="item expiry-date">
              <CardExpiryElement
                options={options}
                className="form-input form-control"
                placeholder="EXP"
                onReady={() => {
                  // console.log("CardNumberElement [ready]");
                }}
                onChange={(event) => {
                  // console.log("CardNumberElement [change]", event);
                  setErrorMessage('');
                }}
                onBlur={() => {
                  // console.log("CardNumberElement [blur]");
                }}
                onFocus={() => {
                  // console.log("CardNumberElement [focus]");
                }}
              />
            </div>
          </div>
          <div className="extra-card-info">
            {/* <div className="item-title-wrapper">
              <FormLabel className="item-title">CVC</FormLabel>
            </div> */}
            <div className="item cvc">
              <CardCvcElement
                options={options}
                className="form-input form-control"
                placeholder="CVC"
                onReady={() => {
                  // console.log("CardNumberElement [ready]");
                }}
                onChange={(event) => {
                  // console.log("CardNumberElement [change]", event);
                  setErrorMessage('');
                }}
                onBlur={() => {
                  // console.log("CardNumberElement [blur]");
                }}
                onFocus={() => {
                  // console.log("CardNumberElement [focus]");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handlePromotionCode = () => {
    if (promotionInfo.code === '') {
      promotionCodeInput.current.focus();
      return;
    }
    stripeViewModel
      .checkPromotionCode({
        price_id: selectedPlan.id,
        // quantity: dependents.length,
        quantity: 1,
        code: promotionInfo.code,
      })
      .then((res) => {
        if (res.duration === 'once' && res.times_redeemed > 1) {
          // console.log('Already used this promotion code');
        } else if (!res.coupon.valid) {
          console.log('reject');
        } else {
          setPromotionInfo((promotionInfo) => ({
            ...res,
            code: promotionInfo.code,
          }));
          setTotalAmount(priceWithDollerSign(res.total));
          setTotalApplePayAmount(res.total);
          setPromotionSummaryShow('show');
          setDisablePromotion(true);
        }
      })
      .catch((err) => {
        console.log(err);
        // setShowErrorMessage('show')
        if (err.statusCode === 404)
          setPromoErrorMessage(err.serverResponse.error_info);
        else if (err.statusCode === 400)
          setPromoErrorMessage(err.serverResponse.error_info);
        else setPromoErrorMessage('');
      });
  };
  const [paymentOptions, setPaymentOptions] = useState([
    { value: 'new', label: 'New Payment Method' },
  ]);
  const [selectedOption, setSelectedOption] = useState();
  const [newPaymentMethod, setNewPaymentMethod] = useState(false);
  const onPaymentMethodChange = (selection) => {
    if (selection.value == 'new') {
      setNewPaymentMethod(true);
    } else {
      setNewPaymentMethod(false);
    }
    setSelectedOption(selection);
  };

  const updatePaymentMethod = (stripePayments) => {
    const newPaymentOptions = [...stripePayments, ...paymentOptions];
    setPaymentOptions(newPaymentOptions);
    onPaymentMethodChange(newPaymentOptions[0]);
  };

  useEffect(() => {
    // console.log(dependent[0]);
    stripeViewModel.getCards().then((res) => {
      updatePaymentMethod(res);
    });
    // if (dependent[0].subscriptions !== '') {
    if (isEdit) {
      setDowngradeDescription(
        `You’ve already paid for ${capitalizeFirstLetter(
          dependent[0].subscriptions.nickname
        )} ${capitalizeFirstLetter(
          dependent[0].subscriptions.interval
        )}ly and will be on that plan until the end of the month. By confirming, your downgrade will take effect on the first day of next month.`
      );
    }
  }, []);

  useEffect(() => {
    setServices(showIncludedServices());
  }, [selectedPlan]);

  const showIncludedServices = () => {
    const services =
      selectedPlan.nickname === 'Branches'
        ? ['App', 'Special Camp', 'Creative Camp']
        : selectedPlan.nickname === 'Roots'
          ? ['App', 'Special Camp']
          : ['App'];
    // const month_1 = selectedPlan.recurringInterval === 'monthly' ? 1 : 12;
    const month_1 = selectedPlan.recurringInterval === 'monthly' ? 1 : 1;
    const month_2 = selectedPlan.recurringInterval === 'month' ? 4 : 50;
    const elements = services.map((el, index) => {
      const number = el === 'Creative Camp' ? month_2 : month_1;
      return (
        <div key={index}>
          {number} x {el}
        </div>
      );
    });
    return <Row className="included-services">{elements}</Row>;
  };

  const handleRemovePromotionCode = () => {
    setPromotionInfo(defaultPromotionInfo);
    resetTotalAmount();
    setPromotionSummaryShow('hide');
    setDisablePromotion(false);
  };

  const resetTotalAmount = () => {
    stripeViewModel
      .checkPromotionCode({
        price_id: selectedPlan.id,
        quantity: 1,
        code: '',
      })
      .then((res) => {
        setTotalAmount(priceWithDollerSign(res.total))
        setTotalApplePayAmount(res.total)
      }).catch((err) => console.log(err));
  };

  return (
    <div className="subscription-summary-wrapper">
      <div className="summary-header-wrapper">
        <SettingsNavHeader
          // title={isEdit ? 'Confirm Changes' : 'Your Special Offer!'}
          title={selectedPlan.nickname === 'Seedlings V2' ? 'Seedlings' : selectedPlan.nickname}
          gobackHandler={setShowPaymentMethod}
        />
      </div>
      <div className="form-wrapper">
        <Form onSubmit={handleSubmit(handleContinue)}>
          <Row className="summary-title">
            {isEdit ? 'Your New Plan' : 'Subscription summary'}
          </Row>
          <Row className="plan-details">
            <Row className="total">
              <div className="label">
                {selectedPlan.nickname === 'Seedlings V2' ? 'Seedlings' : selectedPlan.nickname} {selectedPlan.nickname === "Seedlings" || selectedPlan.nickname === "Emeralites" ? "" : selectedPlan.recurringInterval + 'ly'}
              </div>
              <div className="amount">{selectedPlan.unitAmount}</div>
            </Row>
            {/* {services} */}
            <Row className="included-services"></Row>
            <Row className={`promotion-item ${promotionSummaryShow}`}>
              <Row className="item-row">
                <div className="item-name">{promotionInfo.code}</div>
                <div className="item-amount">
                  {/* {priceWithDollerSign(promotionInfo.coupon.amount_off, '-')} */}
                  {promotionInfo.coupon.percent_off}% off
                </div>
              </Row>
              <Row className="remove-row">
                <div className="product-desc">
                  {promotionInfo.coupon.metadata.description}
                </div>
                <div className="remove" onClick={handleRemovePromotionCode}>
                  Remove
                </div>
              </Row>
            </Row>
            <Row className="pay-now">
              <div className="label">
                {!isEdit
                  ? 'Pay Now'
                  : isUpgrade
                    ? 'Prorated Price Due Today'
                    : 'Pay Now'}
              </div>
              {/* <div className="amount">{selectedPlan.nickname === "Seedlings" ? selectedPlan.unitAmount : totalAmount}</div> */}
              <div className="amount">{selectedPlan.nickname === "Emeralites" ? selectedPlan.unitAmount : totalAmount}</div>
            </Row>
          </Row>
          <div className='pay-button-wrapper'>
            {showPaymentMethod &&
              <ApplePayButton
                label={selectedPlan.nickname}
                payAmount={selectedPlan.nickname === "Emeralites" ? selectedPlan.amount : totalApplePayAmount}
                handleSubscribe={handleSubscribe}
                cardHolderName={cardHolderName}
                dependent={dependent[0]}
                selectedPlan={selectedPlan}
              />}
          </div>
          <Row className="method">
            Credit Card<Row className="payment-method">&nbsp;</Row>
            <div className={`${methodShow}`}>
              <div
                className={`${paymentOptions.length === 1 ? 'hide' : 'payment-method-card'
                  }`}
              >
                <Select
                  onChange={onPaymentMethodChange}
                  options={paymentOptions}
                  value={selectedOption}
                />
              </div>
              {newPaymentMethod && newPaymentMethodForm()}
              <div className="item promotion-wrapper">
                <div className="code">
                  <FormInput
                    name="promotion_code"
                    ref={promotionCodeInput}
                    placeholder="Promo code"
                    data-testid="promotion_code"
                    onChange={(e) => {
                      setPromoErrorMessage('');
                      setPromotionInfo({
                        ...promotionInfo,
                        code: e.target.value,
                      });
                    }}
                    value={promotionInfo.code}
                    disabled={disablePromotion}
                  />
                </div>
                <div className="apply">
                  <Button
                    onClick={handlePromotionCode}
                    disabled={disablePromotion}
                  >
                    Apply
                  </Button>
                </div>
              </div>
              <div className="promo-error-message">{promoErrorMessage}</div>
              <div className={`plan-description `}>
                <div className="description">
                  {!isEdit
                    ? selectedPlan.nickname === "Seedlings"
                      ? seedlings_description
                      : selectedPlan.nickname === "Emeralites"
                        ? emeralites_description
                        : emeralites_description
                    // : paymentDescription
                    : isUpgrade
                      ? upgradeDescription
                      : downgradeDescription}
                </div>
                <div className={`common-description ${isEdit ? 'd-none' : ''}`}>
                  {/* {selectedPlan.nickname === "Seedlings" || selectedPlan.nickname === "Emeralites" ? '' : <> Cancel anytime. <br /> <br /> </> }   */}
                  {/* In the interest of the safety
                  and security of our TaleTree children, we insist that all
                  parents save their billing information on file as a form of
                  identification. <br /> <br /> This verifies you as the parent
                  of your child and ensures we are fully aware of each and
                  everyone of your members. <br /> <br />
                  Thank you for your support. */}
                  In accordance with the Child Online Privacy Act (COPPA),
                  a parent or guardian must provide consent to set up a
                  TaleTree Account for children under age 13.
                  {selectedPlan?.nickname === "Emeralites" &&
                    <>
                      <br /> <br />
                      A one-time fee of ${selectedPlan?.nickname === "Emeralites" ? "1.00" : "0.80"} is charged to verify that a
                      parent or guardian has provided consent.
                      This one-time payment is a commonly used and accepted
                      parental control method required so that parents have
                      the chance to see the payment on their credit card statement,
                      and to alert TaleTree if consent was improperly provided.
                    </>
                  }
                  <br />
                  <br />
                </div>
              </div>
              {/* <div className={`${showErrorMessage} error-message`}> */}
              <div className="error-message">{errorMessage}</div>
              <div className="confirm button-wrapper">
                <LoadButton
                  isLoading={isLoading}
                  className={`submit-btn ${selectedPlan.nickname}-button ${selectedPlan.nickname === 'Emeralites' ? 'gred-btn'
                    : selectedPlan.nickname === 'Seedlings V2' ? 'seedlings-text-box' :
                      selectedPlan.nickname === 'Villagers' ? 'branches-text-box' : ''}`}
                  // onClick={handleContinue}
                  type="submit"
                  isDisabled={isLoading || !stripe}
                  // text={
                  //   jobType === 'edit' ? `Subscribe to the ${selectedPlan.nickname === 'Seedlings V2' ? 'Seedlings' : selectedPlan.nickname} plan` : 'VERIFY YOUR ACCOUNT'
                  // }
                  text={
                    selectedPlan.nickname === 'Emeralites' ? 'VERIFY YOUR ACCOUNT' : `Subscribe to the ${selectedPlan.nickname === 'Seedlings V2' ? 'Seedlings' : selectedPlan.nickname} plan`
                  }
                />
              </div>
              <br /> <br /> &nbsp;
            </div>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default PaymentMethod;
