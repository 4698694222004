import React from 'react';
import { default as BsModal } from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import closeIcon from '../../assets/icon/icon-darker-close.svg';

import './scss/modal.scss';

const Modal = (props) => {
  const showHideClassName = props.show ? 'show' : 'hide';
  const modalSize = 'size-' + props.size;

  return (
    <div>
      <BsModal
        show={props.show}
        onHide={props.handleClose}
        backdropClassName="backdrop"
        dialogClassName="t-modal"
      >
        <ModalBody
          bsPrefix="t-modal-body"
          className={`${modalSize} ${props.className}`}
        >
          <div className="t-modal-header">
            {props.title}
            <div className="close-button">
              <img src={closeIcon} onClick={props.handleClose} />
            </div>
          </div>
          <div className="t-modal-content">{props.children}</div>
        </ModalBody>
      </BsModal>
    </div>
  );
};

Modal.defaultProps = {
  handleClose: () => {},
  show: false,
  title: '',
  size: 'large',
};

export default Modal;
