import { specialCampRepository } from '../../../repository/special.camp.repository';

const getUpcomingSpecialCamp = (id) => {
  return new Promise((resolve, reject) => {
    specialCampRepository
      .getTargetSpecialCamp(id)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const getSchoolUpcomingSpecialCamp = (id) => {
  return new Promise((resolve, reject) => {
    specialCampRepository
      .getSchoolSpecialCamp(id)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const getSpecialCamp = (params) => {
  return new Promise((resolve, reject) => {
    specialCampRepository
      .getSpecialCamp(params)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const attend = (params) => {
  return new Promise((resolve, reject) => {
    specialCampRepository
      .attendSpecialCamp(params)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const getDependentSpecialCamp = (id) => {
  return new Promise((resolve, reject) => {
    specialCampRepository
      .getDependentSpecialCamp(id)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const getGuardianDependentSpecialCamps = (campId, dependentId) => {
  return new Promise((resolve, reject) => {
    specialCampRepository
      .getGuardianDependentSpecialCamps(campId, dependentId)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const specialCampViewModel = {
  getUpcomingSpecialCamp,
  getSpecialCamp,
  attend,
  getDependentSpecialCamp,
  getSchoolUpcomingSpecialCamp,
  getGuardianDependentSpecialCamps
};
