import React from 'react';
import { useLocation } from 'react-router-dom';
import AccountHeader from './AccountHeader';
import {
  DASHBOARD_ROUTE,
  GUARDIAN_LOGIN_ROUTE,
  GUARDIAN_SETTINGS_ROUTE,
  KID_LOGIN_ROUTE,
} from '../../../constants/routes.constants';
import SignUpCompleteCard from './SignUpCardCompleteCard';
import letsGo from '../../../assets/characters/lets_go_65x72.svg';
import mrGogo from '../../../assets/characters/mr_gogo_89x52.svg';
import './scss/signup.complete.scss';
/**
 * Requires a variable to be passed in from Link component, example
 * <Link
    to={{
      pathname: SIGNUP_COMPLETE_ROUTE,
      state: { isCamp: true },
    }}
  >

  Note HomeRoute has a temporary route below for this page. Probably needs to be updated for payment           
  <Route path={SIGNUP_COMPLETE_ROUTE} component={SignUpComplete} />

 */
function SignUpComplete() {
  const location = useLocation();
  const campMessage = (
    <span>
      In a moment, you will receive an email to schedule a brief 30-min
      <br />
      introductory Zoom call. Be on the lookout!
    </span>
  );
  const appMessage = (
    <span>
      Start uploading your creations into 'My Room' and watch your collection
      grow.
      <br />
      We can't wait to see what kinds of creations you have in store!
    </span>
  );
  let message = appMessage;
  if (location.state?.isCamp) {
    message = campMessage;
  }
  const subTitle = (
    <span>
      Thank you for registering
      <br />
      for our {location.state?.isCamp ? `Creative Camp` : `TaleTree App`}
    </span>
  );
  return (
    <div className="complete-container">
      <AccountHeader title="You're ready to go!" subTitle={subTitle} />

      <div className="description-container">{message}</div>
      <div className="card-container">
        <SignUpCompleteCard
          className="ready-to-go-card"
          imageUrl={letsGo}
          name="Kiddos,"
          message="Upload your creations here and watch your collection grow. Enjoy!"
          link={KID_LOGIN_ROUTE}
          linkText="Check out the app"
        />
        <SignUpCompleteCard
          className="ready-to-go-card"
          imageUrl={mrGogo}
          name="Guardian,"
          message="Manage your child's information and your personal account from our Guardian Dashboard."
          link={GUARDIAN_SETTINGS_ROUTE}
          linkText="Guardian Dashboard"
        />
      </div>
    </div>
  );
}

export default SignUpComplete;
