import { fetchUtils } from '../utils/fetch.utils';
import { getAPIUrl } from '../utils/config.utils';

const BASE_URL = getAPIUrl();
const CREATIONS = 'creations';

function getCreations(params) {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.get(`${BASE_URL}/${CREATIONS}?${query}`);
}

function getCreationByID(id) {
  return fetchUtils.get(`${BASE_URL}/${CREATIONS}/${id}`);
}

function deleteCreationById(id) {
  return fetchUtils.requestDelete(`${BASE_URL}/${CREATIONS}/${id}`);
}

function createCreation(params) {
  return fetchUtils.post(`${BASE_URL}/${CREATIONS}`, params);
}

function uploadCreationAndMedia(params) {
  return fetchUtils.postMultiForm(`${BASE_URL}/creations_media`, params);
}

function editCreationAndMedia(id, params) {
  return fetchUtils.postMultiForm(`${BASE_URL}/creations_media/update/${id}`, params);
}

function uploadCreationMedia(id, params) {
  return fetchUtils.postMultiForm(`${BASE_URL}/${CREATIONS}/${id}/media`, params);
}

function getBadgeCount(params) {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.get(`${BASE_URL}/dependents/badge_Count?${query}`);
}

export const creationRepository = {
  getCreations,
  getCreationByID,
  createCreation,
  uploadCreationMedia,
  editCreationAndMedia,
  deleteCreationById,
  uploadCreationAndMedia,
  getBadgeCount
};
