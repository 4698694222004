import React, { useState, useEffect } from 'react';
import Cartoon from "../../../assets/images/cartoon.svg";
import PaymentLine from "../../../assets/images/payment-plan-HR.svg";
import CloseIcon from '../../../assets/images/CLose-icon.svg';

import SeedlingsNasa from '../../../assets/images/seedlings-nasa-logo.png';
import SeedlingsPixar from '../../../assets/images/seedlings-Pixar.png';
import SeedlingsPremier from '../../../assets/images/Premier-crop.png';
import SeedlingsMarvel from '../../../assets/images/seedlings-Marvel.png';
import SeedlingsRoblox from '../../../assets/images/seedlings-Roblox.png';

import GreenLine from '../../../assets/images/green-line.svg';
import PinkLine from '../../../assets/images/pink-line.svg';

import Lottie from "react-lottie-player";
import SignupModal from '../onboarding/signup_popups/SignupModal';
import { Form, Button } from 'react-bootstrap';
import LoadButton from '../../common/LoadButton';
import { getSoundWaveJson } from '../../../utils/camp.utils';

const LearnMorePopup = ({ showModal, setShowModal, selectedPlan, handleGotoPaymentClick, dependent, isSubscription }) => {
    const EMERALITES_TEXT = `We take our kids’ security extremely seriously. Therefore, we advise our kids not to use their full name.`;
    const SEEDLINGS_TEXT = `Meet experts and purpose your passions! Meet new friends and work on great projects.`;
    const [text, setText] = useState('');
    const [isPlay, setIsPlay] = useState(false);
    
    const handleClose = () => {
        setShowModal(false)
    }

    const handleVoiceClick = () => {
        setIsPlay(!isPlay);
        changeTextToVoice(text, isPlay, setIsPlay, isPaused, setIsPaused);
    }

    useEffect(() => {
        speechBubbleText()
    }, [selectedPlan])

    const handleSubscribe = () => {
        setShowModal(false);
        handleGotoPaymentClick();
    }

    const speechBubbleText = () => {
        switch (selectedPlan) {
            case 'emeralites':
                setText(EMERALITES_TEXT)
                break;

            case 'seedlings':
                setText(SEEDLINGS_TEXT)
                break;

            case 'villagers':
                setText(
                    <div>
                        <span>Watch the video to learn more!</span>
                        <iframe width="100%" height="110" src="https://www.youtube.com/embed/BnSvbzNO5s8?si=aJcM3cHiJeG0Cnzs" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>
                )
                break;

            default:
                break;
        }
    }

    const getButtonClass = () => {
        switch (selectedPlan) {
            case 'emeralites':
                return 'gred-btn'

            case 'seedlings':
                return 'seedlings-text-box'

            case 'villagers':
                return 'branches-text-box'

            default:
                return 'gred-btn'
        }
    }

    const planDetails = () => {
        switch (selectedPlan) {
            case 'emeralites':
                return (
                    <>
                        <p className="auth-entry-text"> Emeralites </p>
                        <p className="auth-entry-text highlight-p"> <span className="user-highlight">Free</span>after $1 verification fee</p>
                        <p className="pb-4"> Start now! Verify with a credit card to access key features and benefits.</p>

                        <div className="subscribe-ul">
                            <ol className="plan-modal-ol">
                                <li> <div className="cartoon-img1 payment-plan">
                                    <img className="w-100" src={PaymentLine} />
                                </div>  Enhanced safety for your child
                                </li>

                                <li>
                                    <div className="cartoon-img1 payment-plan">
                                        <img className="w-100" src={PaymentLine} />
                                    </div>
                                    Up to 20 Challenges and projects
                                </li>

                                <li>
                                    <div className="cartoon-img1 payment-plan">
                                        <img className="w-100" src={PaymentLine} />
                                    </div>
                                    Explore creations
                                </li>
                            </ol>
                        </div>
                    </>
                )

            case 'seedlings':
                return (
                    <>
                        <p className="auth-entry-text"> Seedlings </p>
                        <p className="auth-entry-text highlight-p"> <span className="user-highlight">$5</span>/month, per kid</p>
                        <p className="pb-4"> Enjoy unlimited workspace and gain access to a variety of activities. </p>

                        <div className="subscribe-ul seedlings-ul">
                            <ol className="plan-modal-ol Seedlings">

                                <li> <div className="cartoon-img1 payment-plan">
                                    <img className="w-100" src={GreenLine} />
                                </div>
                                    Monthly live sessions with experts
                                </li>

                                <li>
                                    <div className="cartoon-img1 payment-plan">
                                        <img className="w-100" src={GreenLine} />
                                    </div>
                                    Unlimited Challenges and creations
                                </li>

                                <li>
                                    <div className="cartoon-img1 payment-plan">
                                        <img className="w-100" src={GreenLine} />
                                    </div>
                                    Parental Insights
                                </li>

                                <li>
                                    <div className="cartoon-img1 payment-plan">
                                        <img className="w-100" src={GreenLine} />
                                    </div>
                                    Verification included
                                </li>
                            </ol>

                            <ul className='brand-img'>
                                <li> <img className="w-100" src={SeedlingsNasa} /></li>

                                <li> <img className="w-100" src={SeedlingsPixar} /></li>

                                <li> <img className="w-100" src={SeedlingsPremier} /></li>

                                <li> <img className="w-100" src={SeedlingsMarvel} /></li>

                                <li> <img className="w-100" src={SeedlingsRoblox} /></li>
                            </ul>

                        </div>
                    </>
                )

            case 'villagers':
                return (
                    <>
                        <p className="auth-entry-text"> Villagers </p>
                        <p className="auth-entry-text highlight-p"> <span className="user-highlight">$100</span>/month, per kid</p>
                        <p className="pb-2"> Indulge in the full TaleTree experience with
                            unrestricted access to our programs and content. </p>
                        <p className="pb-4">
                            All Seedlings feature, plus
                        </p>

                        <div className="subscribe-ul Seedlings villagers">
                            <ol className="plan-modal-ol">
                                <li> <div className="cartoon-img1 payment-plan">
                                    <img className="w-100" src={PinkLine} />
                                </div>
                                    Weekly Creative Camps
                                </li>

                                <li>
                                    <div className="cartoon-img1 payment-plan">
                                        <img className="w-100" src={PinkLine} />
                                    </div>
                                    Live events and workshops
                                </li>
                            </ol>

                            <ul className='brand-img'>
                                <li> <img className="w-100" src={SeedlingsNasa} /></li>

                                <li> <img className="w-100" src={SeedlingsPixar} /></li>

                                <li> <img className="w-100" src={SeedlingsPremier} /></li>

                                <li> <img className="w-100" src={SeedlingsMarvel} /></li>

                                <li> <img className="w-100" src={SeedlingsRoblox} /></li>
                            </ul>

                        </div>
                    </>
                )

            default:
                break;
        }
    }

    return (
        <SignupModal className="gardian auth-popup right-curved-div" showModal={showModal} handleClose={handleClose}>
            <div className='row bg-blue border-40'>
                <div className="col-md-6 col-lg-5 p-4 bg-blue border-left overflow-hidden">
                    <div className="voice-buble buuble-bounce" id="SpeechBubble" style={{ width: '230px' }}>
                        <p id='voice-text' >
                            {text}
                        </p>
                    </div>
                    <div className="text-left">
                        {/* <p className="text-white voice-btn" onClick={handleVoiceClick}><BsVolumeUpFill /> Voice on</p> */}
                        <div className="" style={{marginTop: '9rem'}}>
                            {/* <Lottie
                                loop
                                animationData={getSoundWaveJson()}
                                play={isPlay}
                                className='animate-svg'
                            /> */}
                        </div>
                    </div>
                    <div className="cartoon-img">
                        <img className="w-100" src={Cartoon} />
                    </div>
                </div>
                <div className="col-md-6 col-lg-7 bg-white border-right right-curve auth-section white-right-arrow toparrow topinmius right-bottom-curve payment-plan-modal-more">
                    {/* <button onClick={() => { handleClose() }}>X</button> */}

                    <img className="close-icon" src={CloseIcon} onClick={() => { handleClose() }} />

                    {planDetails()}

                    <div className="button-wrapper bottom-created1 newpayment-buttons">
                        <Form.Group className="container-fluid w-100 account-created no-yes-buttons">
                            <Button
                                className="load-button-container float-left rounded-pill mb-4 font-weight-bold"
                                variant="light"
                                onClick={() => { handleClose() }}
                            >
                                Back to list
                            </Button>
                            <LoadButton
                                className={`signup-btn rounded-pill float-right mb-4 font-weight-bold ${getButtonClass()}`}
                                text="Subscribe"
                                isDisabled={dependent?.profile?.is_activated && selectedPlan === 'emeralites' || isSubscription()}
                                onClick={handleSubscribe}
                            />
                        </Form.Group>
                    </div>


                </div>
            </div>
        </SignupModal>
    );
};

export default LearnMorePopup;