import { feedRepository } from '../../../repository/feed.repository';

const getNextQuery = (params, list, has_more) => {
  const lastId = list.length > 0 ? list[list.length - 1].creation_id : 0;
  const nextQuery = { ...params };
  nextQuery.starting_after = lastId;
  nextQuery.has_more = has_more;
  return nextQuery;
};

const mergeList = (previousList, newList) => {
  const list = previousList ? [...previousList, ...newList] : newList;
  let idSet = new Set();

  const uniqueList = list.filter((item) => {
    let isUnique = false;
    if (!idSet.has(item.creation_id)) {
      idSet.add(item.creation_id);
      isUnique = true;
    }
    return isUnique;
  });
  return uniqueList;
};

const getFeed = (params, previousFeedList) => {
  return new Promise((resolve, reject) => {
    feedRepository
      .getFeed(params)
      .then((res) => {
        const response = res.data.filter((data) => data.media.length > 0);
        const feedList = mergeList(previousFeedList, response);
        const query = getNextQuery(params, feedList, res.has_more);
        const data = {
          feedList,
          query,
        };
        resolve(data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

const complimentList = () => {
  return new Promise((resolve, reject) => {
    feedRepository
      .complimentList()
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

const addCompliment = (params) => {
  return new Promise((resolve, reject) => {
    feedRepository
      .addCompliment(params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

const commentList = (params) => {
  return new Promise((resolve, reject) => {
    feedRepository
      .commentList(params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

const getOfTheDay = () => {
  return new Promise((resolve, reject) => {
    feedRepository
      .getOfTheDay()
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export const feedViewModel = {
  getFeed,
  complimentList,
  addCompliment,
  commentList,
  getOfTheDay
};
