import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ChallengeCard from '../next_challenge/ChallengeCard';
import { CHALLENGE_ROUTE, EVENT_ROUTE, NEXT_CHALLENGE_ROUTE, SPECIAL_CHALLENGE_ROUTE } from '../../../constants/routes.constants';

import './css/myroom.challenges.scss';
import { useAuth } from '../../auth/Auth';

const MyRoomChallenges = ({ challenges, filter = 'all' }) => {
  const _ALL = 'all';
  const history = useHistory();
  const { school } = useAuth();

  const [challengeCountClassName, setChallengeCountClassName] = useState(
    'empty'
  );

  const handler = (e, isEvent) => {
    const index = challenges.findIndex(
      (x) => x.id === parseInt(e.target.id, 10)
    );
    if (index >= 0) {
      let path;
      let encodeQuery;
      if (isEvent === 'challenge') {
        path = CHALLENGE_ROUTE
        encodeQuery = btoa(`{"id":${e.target.id}, "slug":"challenge"}`)
      }
      if (isEvent === 'special') {
        path = SPECIAL_CHALLENGE_ROUTE
        encodeQuery = btoa(`{"id":${e.target.id}, "slug":"special-challenge"}`)
      }
      if (isEvent === 'event') {
        path = EVENT_ROUTE
        encodeQuery = btoa(`{"id":${e.target.id}, "slug":"event"}`)
      }
      history.push({
        pathname: path,
        search: `?encrypt=${encodeQuery}`,
        state: {
          filter: _ALL,
          selectedTag: '',
          challenge: challenges[index],
          from: 'myroom',
        },
      });
      window.scrollTo(0, 0);
    }
  };

  const gotoChallenges = () => {
    history.push(NEXT_CHALLENGE_ROUTE);
  };

  useEffect(() => {
    setChallengeCountClassName(challenges.length === 0 ? 'empty' : 'has-');
  }, [challenges]);

  return (
    <div
      className={`${filter === _ALL ? 'completed' : 'working'
        }-challenges-container ${challengeCountClassName}${challenges.length !== 0 ? challenges.length : ''
        }`}
    >
      {challenges.map((challenge, key) => {
        return (
          <ChallengeCard
            challenge={challenge}
            type={`challenge-myroom card-${key + 1}`}
            handleClick={handler}
            key={key}
            noAcceptBtn={true}
          />
        );
      })}
      {filter === 'working' && !school ? (
        <div className="add-challenge-box" onClick={gotoChallenges}>
          <div className="text">
            Let’s start working on new Challenges!
            <br />
            You can choose up to three Challenges.
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default MyRoomChallenges;
