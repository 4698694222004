import { creationRepository } from '../../../repository/creation.repository';
import { challengeRepository } from '../../../repository/challenge.repository';
import { accountRepository } from '../../../repository/account.repository';
import { getDirtyValues } from '../../../utils/form.utils';
import { emptyPromise } from '../../../utils/utils';
import { treehouseRepository } from '../../../repository/treehouse.repository';

//todo get creation
function getChallenges(params) {
  return new Promise((resolve, reject) => {
    creationRepository
      .getCreations(params)
      .then((response) => {
        const formatList = response.data.filter(
          (creation) => creation.media.length !== 0
        );
        resolve(formatList);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getNextCreationQuery(params, creationList, hash_more) {
  const lastId =
    creationList.length > 0 ? creationList[creationList.length - 1].id : 0;
  const nextQuery = { ...params, starting_after: lastId };
  return nextQuery;
}
/**
 * Merge and remove duplicate creations
 * @param {object} previousCreationList
 * @param {object} newCreationList
 */
function mergeCreationList(previousCreationList, newCreationList) {
  const newList = previousCreationList
    ? [...previousCreationList, ...newCreationList]
    : newCreationList;
  let creationIdSet = new Set();
  const uniqueList = newList.filter((creation) => {
    let isUnique = false;
    if (!creationIdSet.has(creation.id)) {
      creationIdSet.add(creation.id);
      isUnique = true;
    }

    return isUnique;
  });
  return uniqueList;
}

function getCreations(params, creationData) {
  const previousCreationList = creationData.creationList;
  return new Promise((resolve, reject) => {
    creationRepository
      .getCreations(params)
      .then((response) => {
        const creationList = mergeCreationList(
          previousCreationList,
          response.data
        );
        const nextQueryParams = getNextCreationQuery(params, creationList);
        const data = {
          creationList: creationList,
          starting_after: nextQueryParams.starting_after,
          has_more: response.has_more,
        };
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getCreationById(id) {
  return new Promise((resolve, reject) => {
    creationRepository
      .getCreationByID(id)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function deleteCreation(params) {
  return new Promise((resolve, reject) => {
    creationRepository
      .deleteCreationById(params.id)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getBadgeCount(params) {
  return new Promise((resolve, reject) => {
    creationRepository
      .getBadgeCount(params)
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
}

function updateDependentProfile(
  id,
  originalFormData,
  changedFormData,
  profilePicture,
  dependent
) {
  const profileUpdatePromises = [];
  const params = getDirtyValues(changedFormData, originalFormData);
  if (params.favorites) {
    const data = {
      profile: {
        favorites: [
          originalFormData.favorites.color,
          originalFormData.favorites.sport,
          originalFormData.favorites.animal,
        ],
      },
    };
    profileUpdatePromises.push(accountRepository.updateDependentUser(id, data));
  } else {
    profileUpdatePromises.push(emptyPromise());
  }
  if (profilePicture) {
    const formBody = new FormData();
    formBody.append('image', profilePicture);
    profileUpdatePromises.push(accountRepository.uploadPhoto(id, formBody));
  } else {
    profileUpdatePromises.push(emptyPromise());
  }

  return new Promise((resolve, reject) => {
    Promise.all(profileUpdatePromises)
      .then((response) => {
        let newDependent = { ...dependent };
        if (response[0]) {
          newDependent = { ...response[0].data };
        }
        if (response[1]) {
          newDependent.profile.picture_url = profilePicture.preview;
        }
        resolve(newDependent);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function updateTheme(
  id,
  params,
  dependent
) {
  const profileUpdatePromises = [];
  if (params) {
    const data = {
      profile: {
        banner_is_fullscreen: params.banner_is_fullscreen,
        banner_theme: params.banner_theme
      },
    };
    profileUpdatePromises.push(accountRepository.updateDependentUser(id, data));
  } else {
    profileUpdatePromises.push(emptyPromise());
  }

  return new Promise((resolve, reject) => {
    Promise.all(profileUpdatePromises)
      .then((response) => {
        let newDependent = { ...dependent };
        if (response[0]) {
          newDependent = { ...response[0].data };
        }
        if (response[1]) {
          newDependent.profile.picture_url = profilePicture.preview;
        }
        resolve(newDependent);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function updateProfilePic(
  id,
  profilePicture,
  dependent
) {
  const profileUpdatePromises = [];

  const formBody = new FormData();
  formBody.append('image', profilePicture);
  profileUpdatePromises.push(accountRepository.uploadPhoto(id, formBody));

  return new Promise((resolve, reject) => {
    Promise.all(profileUpdatePromises)
      .then((response) => {
        let newDependent = { ...dependent };
        if (response[0]) {
          newDependent = { ...response[0].data };
        }
        if (response[1]) {
          newDependent.profile.picture_url = profilePicture.preview;
        }
        resolve(newDependent);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function updateBannerPic(
  id,
  bannerPicture,
  dependent
) {
  const bannerUpdatePromises = [];

  const formBody = new FormData();
  formBody.append('image', bannerPicture);
  bannerUpdatePromises.push(accountRepository.uploadBanner(id, formBody));

  return new Promise((resolve, reject) => {
    Promise.all(bannerUpdatePromises)
      .then((response) => {
        let newDependent = { ...dependent };
        if (response[0]) {
          newDependent = { ...response[0].data };
        }
        if (response[1]) {
          newDependent.profile.banner = bannerPicture.preview;
        }
        resolve(newDependent);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getDependentUserByName(username) {
  return new Promise((resolve, reject) => {
    accountRepository
      .getDependentUserByName(username)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getCreatorTreehouses(username) {
  return new Promise((resolve, reject) => {
    treehouseRepository
      .getCreatorTreehouses(username)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getCreatorInterests(username) {
  return new Promise((resolve, reject) => {
    treehouseRepository
      .getCreatorInterests(username)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function deleteDependentBanner(id) {
  return new Promise((resolve, reject) => {
    accountRepository
      .deleteDependentBanner(id)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const mergeList = (previousList, newList) => {
  const list = previousList ? [...previousList, ...newList] : newList;
  return list;
}; 

const getNextQuery = (params, list, has_more) => {
  const lastId = list.length > 0 ? list[list.length - 1].id : 0;
  const nextQuery = { ...params };
  nextQuery.starting_after = lastId;
  nextQuery.has_more = has_more;
  return nextQuery;
};

const getTribeMembers = (params, previousList) => {
  return new Promise((resolve, reject) => {
    accountRepository
      .getTribeMembers(params)
      .then((res) => {
        const List = mergeList(previousList, res.data);
        const query = getNextQuery(params, List, res.has_more);
        const data = {
            List,
            query,
        };
        resolve(data);
      })
      .catch((error) => {
        console.log(error)
        reject(error);
      });
  });
}

const getAllTribeMembers = (params, previousList, id) => {
  return new Promise((resolve, reject) => {
    accountRepository
      .getAllTribeMembers(params, id)
      .then((res) => {
        const List = mergeList(previousList, res.data);
        const query = getNextQuery(params, List, res.has_more);
        const data = {
            List,
            query,
        };
        resolve(data);
      })
      .catch((error) => {
        console.log(error)
        reject(error);
      });
  });
}

function deleteDependentProfilePic(id) {
  return new Promise((resolve, reject) => {
    accountRepository
      .deleteDependentProfilePic(id)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const myRoomViewModel = {
  getCreations,
  getCreationById,
  updateDependentProfile,
  getChallenges,
  getBadgeCount,
  deleteCreation,
  updateProfilePic,
  updateBannerPic,
  getDependentUserByName,
  getCreatorTreehouses,
  getCreatorInterests,
  updateTheme,
  deleteDependentBanner,
  getTribeMembers,
  deleteDependentProfilePic,
  getAllTribeMembers
};
