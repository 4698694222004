import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import RoundButton from '../../common/RoundButton';
import { Row, Col, ProgressBar } from 'react-bootstrap';
import { ON_BOARDING } from '../../../constants/routes.constants';
import { bg } from '../../../constants/component.constants';
import { changeBodyClassName } from '../../../utils/utils';
import OnboardingWrapper from './OnboardingWrapper';

import './scss/step4.scss';

const Step4 = ({ location }) => {
  const _NOT_SELECTED = 'none';
  const history = useHistory();
  const lsGender = JSON.parse(localStorage.getItem('gender'));
  const lsName = localStorage.getItem('firstName');
  const [selectedOption, setSelectedOption] = useState(
    // lsGender !== null ? lsGender : { value: _NOT_SELECTED, label: 'Select...' }
    lsGender !== null ? lsGender : ''
  );
  const [kidsName, setKidsName] = useState(lsName !== null ? lsName : '');
  const [errShow, setErrShow] = useState(false);
  const [errMessage, setErrMessage] = useState('');

  const options = [
    // { value: _NOT_SELECTED, label: 'Select...' },
    { value: 'male', label: "Boy's" },
    { value: 'female', label: "Girl's" },
  ];

  const customStyles = {
    control: (base) => ({
      ...base,
      width: 184,
      height: 48,
      color: '#321b6d',
      fontWeight: '400',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#321b6d',
    }),
    placeholder: (provided) => ({
      ...provided,
      fontWeight: '400',
      color: 'rgb(204, 204, 204)',
    }),
    menu: (provided, state) => ({
      ...provided,
      width: 184,
    }),
  };

  const handleGenderChange = (selection) => {
    setSelectedOption(selection);
    localStorage.setItem('gender', JSON.stringify(selection));
  };

  const handleNameChange = (e) => {
    const name = e.target.value;
    setKidsName(name);
    localStorage.setItem('firstName', name);
  };

  const handleBackClick = () => {
    // localStorage.removeItem('gender');
    // localStorage.removeItem('firstName');
    history.goBack();
  };
  const handleNextClick = () => {
    const isValid = () => {
      return selectedOption.value !== _NOT_SELECTED && kidsName.length !== 0;
    };
    if (isValid()) {
      history.push({
        pathname: `${ON_BOARDING}/step5`,
        state: {
          ...location.state,
          from: 'step4',
        },
      });
    } else {
      setErrMessage("Please select kid's gender and input kid's name");
      setErrShow(true);
      setTimeout(() => {
        setErrShow(false);
      }, 2000);
    }
  };

  useEffect(() => {
    changeBodyClassName([bg.GRADIENT]);
    if (!location.state || location.state.from !== 'step3')
      history.replace({ pathname: `${ON_BOARDING}/step1` });
  }, []);

  return (
    <OnboardingWrapper className="step4-wrapper">
      <Row className="content-wrapper">
        <Col className="out-of-content">
          <div className="image-wrapper">&nbsp;</div>
          <div className="text-wrapper">
            “Amazing how kids can get together and create a virtual community to
            share their projects and inspire each other. <br /> My daughter is
            looking forward to this every time. It’s her most favorite meeting
            and occupation of the week.”
            <br />
            <br /> Sophie's Dad
          </div>
        </Col>
        <Col className="content">
          <div className="progress-bar-wrapper">
            <ProgressBar now={48} />
          </div>
          <div className="title">
            Welcome to TaleTree! We're so happy to have you with us.
          </div>
          <div className="thin-border-line" />
          <div className="sub title">
            TaleTree provides our kids with magical moments that can lead to
            transformation and growth.
            <br />
            <br />
            It’s a place to be themselves! Let's find out how we can impact your
            child in ways you could not even imagine!
          </div>
          <div className={`error-message-wrapper ${errShow ? 'show' : 'hide'}`}>
            {errMessage}
          </div>
          <div className="input-wrapper">
            <Row>
              <Col className="left">
                My little
                <Select
                  defaultValue={selectedOption}
                  onChange={handleGenderChange}
                  className="basic-single"
                  classNamePrefix="select"
                  options={options}
                  styles={customStyles}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: '6px',
                    colors: {
                      ...theme.colors,
                      primary: '#80e4e1',
                    },
                  })}
                />
              </Col>
              <Col className="right">
                Name is
                <input
                  type="text"
                  className="name-field"
                  value={kidsName}
                  placeholder="Type your child's name"
                  onChange={handleNameChange}
                />
              </Col>
            </Row>
          </div>
          <div className="extra-instruction">
            *You can add additional children later
          </div>
          <div className="button-wrapper">
            <RoundButton
              type="button"
              label="back"
              className="back-button"
              onClick={handleBackClick}
            />
            <RoundButton
              type="button"
              label="Next"
              className="next-button"
              onClick={handleNextClick}
            />
          </div>
        </Col>
      </Row>
    </OnboardingWrapper>
  );
};

export default Step4;
