import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Select, { createFilter } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import MenuList from './MenuList';
import { getAge } from '../../../utils/form.utils';
import RoundButton from '../../common/RoundButton';
import { Row, Col, ProgressBar } from 'react-bootstrap';
import { ON_BOARDING } from '../../../constants/routes.constants';
import { bg } from '../../../constants/component.constants';
import { changeBodyClassName } from '../../../utils/utils';
import OnboardingWrapper from './OnboardingWrapper';
import { onboardingViewModel } from './onboarding.view.model';

import './scss/step5.scss';

const Step5 = ({ location }) => {
  const _NOT_SELECTED = 'none';
  const history = useHistory();
  const lsFirstName = localStorage.getItem('firstName');
  const lsCountry = JSON.parse(localStorage.getItem('country'));
  const lsCity = JSON.parse(localStorage.getItem('city'));
  const lsSchool = JSON.parse(localStorage.getItem('school'));
  const lsBirthDate = localStorage.getItem('birthdate');
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(
    lsCountry !== null ? lsCountry : ''
  );
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(
    lsCity !== null ? lsCity : ''
  );
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(
    lsSchool !== null ? lsSchool : ''
  );
  const [errShow, setErrShow] = useState(false);
  const [errMessage, setErrMessage] = useState('');

  const getCountries = () => {
    onboardingViewModel
      .getCountries()
      .then((data) => {
        let newCountries = data.map((el) => ({ value: el, label: el }));
        newCountries.unshift({ value: 'other', label: 'other' });
        setCountries(newCountries);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getCities = (param) => {
    onboardingViewModel
      .getCities(param)
      .then((data) => {
        let newCities = data.map((el) => ({ value: el, label: el }));
        newCities.unshift({ value: 'other', label: 'other' });
        setCities(newCities);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getSchools = (params) => {
    onboardingViewModel.getSchools(params).then((data) => {
      let newSchools = data.map((el) => ({ value: el, label: el }));
      setSchools(newSchools);
    });
  };

  const themeStyle = (theme) => ({
    ...theme,
    borderRadius: '6px',
    colors: {
      ...theme.colors,
      primary: '#80e4e1',
    },
  });

  const customStyle = {
    control: (base) => ({
      ...base,
      height: 48,
      color: '#321b6d',
      fontWeight: '400',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#321b6d',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'rgb(204, 204, 204)',
    }),
  };

  const handleCountryChange = (selection) => {
    getCities(selection.value);
    setSelectedCountry(selection);
    localStorage.setItem('country', JSON.stringify(selection));
  };
  const handleCityChange = (selection) => {
    getSchools(selection.value);
    setSelectedCity(selection);
    localStorage.setItem('city', JSON.stringify(selection));
  };
  const handleSchoolChange = (selection) => {
    setSelectedSchool(selection);
    localStorage.setItem('school', JSON.stringify(selection));
  };
  const handleSchoolInputChange = (inputValue) => {
    if (inputValue.length !== 0) {
      localStorage.setItem(
        'school',
        JSON.stringify({ value: inputValue, label: inputValue })
      );
    }
  };

  const handleBackClick = () => {
    history.goBack();
  };

  const handleNextClick = () => {
    const isValid = () => {
      return (
        selectedCountry.value !== _NOT_SELECTED &&
        selectedCity.value !== _NOT_SELECTED &&
        selectedSchool.value !== _NOT_SELECTED
      );
    };

    if (isValid()) {
      history.push({
        pathname: `${ON_BOARDING}/step6`,
        state: {
          ...location.state,
          from: 'step5',
        },
      });
    } else {
      setErrMessage('Please select country, city, and school');
      setErrShow(true);
      setTimeout(() => {
        setErrShow(false);
      }, 2000);
    }
  };

  useEffect(() => {
    changeBodyClassName([bg.GRADIENT]);
    if (!location.state || location.state.from !== 'step4')
      history.replace({ pathname: `${ON_BOARDING}/step1` });
    getCountries();
  }, []);

  return (
    <OnboardingWrapper className="step5-wrapper">
      <Row className="content-wrapper">
        <Col className="out-of-content">
          <div className="image-wrapper">&nbsp;</div>
          <div className="text-wrapper">
            “TaleTree has been an amazing source of creativity, imagination and
            conversation for my children. At first, I thought it was a typical
            arts class, but it's so much more!” <br />
            <br /> Joshua's Mom
          </div>
        </Col>
        <Col className="content">
          <div className="progress-bar-wrapper">
            <ProgressBar now={64} />
          </div>
          <div className="title absolute">
            <div className="first-line">We are so excited to learn</div>
            <div className="second-line">
              more about
              <span className="kids-firstname emphasize">{` ${lsFirstName}!`}</span>
            </div>
          </div>
          <div className="thin-border-line" />
          <div className="sub title">
            <p>We help your child build quiet confidence through creativity.</p>
            <p>
              Find out how much {lsFirstName} would gain from the TaleTree
              experience! We’re here to help you to build greater roots for your{' '}
              {getAge(lsBirthDate)}-year-old to blossom.
            </p>
            <p>Let's start with basic information!</p>
          </div>
          <div className={`error-message-wrapper ${errShow ? 'show' : 'hide'}`}>
            {errMessage}
          </div>
          <div className="input-wrapper">
            <Row>
              <Col className="left">
                <span>{lsFirstName} lives in</span>
                <Select
                  defaultValue={selectedCountry}
                  options={countries}
                  styles={customStyle}
                  theme={themeStyle}
                  onChange={handleCountryChange}
                  filterOption={createFilter({ ignoreAccents: false })}
                  components={{ MenuList }}
                  placeholder="Choose a country"
                />
              </Col>
              <Col className="right">
                <span>and the nearest city is...</span>
                <Select
                  defaultValue={selectedCity}
                  options={cities}
                  styles={customStyle}
                  theme={themeStyle}
                  onChange={handleCityChange}
                  filterOption={createFilter({ ignoreAccents: false })}
                  components={{ MenuList }}
                  placeholder="Choose a city"
                />
              </Col>
            </Row>
            <Row>
              <Col className="full">
                <span>{lsFirstName} attends</span>
                <CreatableSelect
                  isClearable
                  defaultValue={selectedSchool}
                  options={schools}
                  styles={customStyle}
                  theme={themeStyle}
                  onChange={handleSchoolChange}
                  onInputChange={handleSchoolInputChange}
                  filterOption={createFilter({ ignoreAccents: false })}
                  components={{ MenuList }}
                  placeholder="Select or type your child's school"
                />
              </Col>
            </Row>
          </div>
          <div className="button-wrapper">
            <RoundButton
              type="button"
              label="Back"
              className="back-button"
              onClick={handleBackClick}
            />
            <RoundButton
              type="button"
              label="Next"
              className="next-button"
              onClick={handleNextClick}
            />
          </div>
        </Col>
      </Row>
    </OnboardingWrapper>
  );
};

export default Step5;
