import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './css/settings.title.css';

function SettingsTitle({ title }) {
  return (
    <Row className="settings-title mb-3">
      <Col className="col-md-8 col-lg-6">
        {title}
      </Col>
    </Row>
  );
}

export default SettingsTitle;
