import { stripeRepository } from '../../../repository/stripe.repository';
import { accountRepository } from '../../../repository/account.repository';

const getDependents = (params) => {
  return new Promise((resolve, reject) => {
    accountRepository
      .getDependents(params)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};
const getPrices = (type = 'recurring') => {
  return new Promise((resolve, reject) => {
    stripeRepository
      .getPrices(type)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const getCards = () => {
  return new Promise((resolve, reject) => {
    stripeRepository
      .getCards()
      .then((res) => {
        const data = res.data;
        const cards = [];
        data.forEach((item) => {
          cards.push({
            value: item.id,
            label: `${item.card.brand.toUpperCase()} xxxx-xxxx-xxxx-${
              item.card.last4
            } Ex. ${item.card.exp_month}/${item.card.exp_year
              .toString()
              .slice(-2)}`,
          });
        });
        resolve(cards);
      })
      .catch((err) => reject(err));
  });
};

const getSubscriptionId = (dependentId) => {
  return new Promise((resolve, reject) => {
    stripeRepository
      .getSubscriptionId(dependentId)
      .then((res) => resolve(res.data.id))
      .catch((err) => reject(err));
  });
};

const previewInvoice = (params) => {
  return new Promise((resolve, reject) => {
    stripeRepository
      .previewInvoice(params)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const previewProratedInvoice = (params) => {
  return new Promise((resolve, reject) => {
    stripeRepository
      .previewProratedInvoice(params)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const getCurrentTotalAmount = (params) => {
  return new Promise((resolve, reject) => {
    stripeRepository
      .previewInvoice(params)
      .then((res) => resolve(res.data.total))
      .catch((err) => reject(err));
  });
};

const checkPromotionCode = (params) => {
  return new Promise((resolve, reject) => {
    stripeRepository
      .previewInvoice(params)
      .then((res) => {
        const discount = res.data.discount;
        const result = {
          ...discount,
          total: res.data.total,
        };
        // console.log(result);
        resolve(result);
      })
      .catch((err) => reject(err));
  });
};

const checkPromoCode = (params) => {
  return new Promise((resolve, reject) => {
    stripeRepository
      .checkPromoCode(params)
      .then((res) => {
        const discount = res.data.discount;
        const result = {
          ...discount,
          total: res.data.total,
        };
        // console.log(result);
        resolve(result);
      })
      .catch((err) => reject(err));
  });
};

const getSubscriptions = () => {
  return new Promise((resolve, reject) => {
    stripeRepository
      .getSubscriptions()
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const subscribe = (params) => {
  return new Promise((resolve, reject) => {
    stripeRepository
      .subscriptions(params)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const changeSubscription = (params) => {
  return new Promise((resolve, reject) => {
    stripeRepository
      .changeSubscription(params)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

// for special camp
const paymentIntent = (params) => {
  return new Promise((resolve, reject) => {
    stripeRepository
      .paymentIntents(params)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

// for Seedlings verified
const paymentIntentSeedlings = (params) => {
  return new Promise((resolve, reject) => {
    stripeRepository
      .paymentIntentsSeedlings(params)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

// for special camp(100% free)
const paymentComplete = (params) => {
  return new Promise((resolve, reject) => {
    stripeRepository
      .paymentComplete(params)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const paymentIntentsApplyPromoCode = (params) => {
  return new Promise((resolve, reject) => {
    stripeRepository
      .paymentIntentsApplyPromoCode(params)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const getCustomerPortalSession = () => {
  return new Promise((resolve, reject) => {
    stripeRepository
      .getCustomerPortalSession()
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const cancelSubscription = (params) => {
  return new Promise((resolve, reject) => {
    stripeRepository
      .cancelSubscription(params)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const verificationPayment = (params) => {
  return new Promise((resolve, reject) => {
    stripeRepository
      .verificationPayment(params)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const applePayPaymentIntents = (params) => {
  return new Promise((resolve, reject) => {
    stripeRepository
      .applePayPaymentIntents(params)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const stripeViewModel = {
  getDependents,
  getPrices,
  getCards,
  previewInvoice,
  previewProratedInvoice,
  getCurrentTotalAmount,
  checkPromotionCode,
  subscribe,
  changeSubscription,
  paymentIntent,
  paymentIntentSeedlings,
  paymentComplete,
  paymentIntentsApplyPromoCode,
  getSubscriptions,
  getSubscriptionId,
  getCustomerPortalSession,
  verificationPayment,
  checkPromoCode,
  applePayPaymentIntents
};
