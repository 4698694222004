import React, { useEffect } from 'react';
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { getStripeApiKey } from '../../../utils/config.utils';
import { ProvidePayment } from '../special_camp/Payment';
import {
  DASHBOARD_ROUTE,
  GUARDIAN_ACCOUNT_DETAILS_ROUTE,
  GUARDIAN_LOGIN_ROUTE,
  GUARDIAN_SETTINGS_ROUTE,
  LOGIN_ROUTE,
} from '../../../constants/routes.constants';
import GuardianSettings from './GuardianSettings';
import EditChildProfile from './EditChildProfile';
import ActivateKid from './ActivateKid';
import AddChildren from './AddChildren';
import AddChildComplete from './AddChildComplete';
import RegisterCamp from './RegisterCamp';
import PurchaseSpecialCamp from '../special_camp/PurchaseSpecialCamp';
import PurchaseComplete from '../special_camp/PurchaseComplete';

import './css/dashboard.scss';
import GuardianDetails from './GuardianDetails';
import EditGuardianProfile from './EditGuardianProfile';

const stripePromise = loadStripe(getStripeApiKey());

const Dashboard = () => {
  const { isLoading, refreshUserState } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const refreshState = () => {
    history.replace();
    refreshUserState();
  };
  useEffect(() => {
    //refresh user state if needed
    if (location.state && location.state.refreshState) {
      refreshState();
    }
  }, [location.state]);

  //detect back button and refresh
  useEffect(() => {
    return history.listen((location) => {
      if (history.action === 'POP') {
        refreshState();
      }
    });
  }, []);

  return (
    <div className="dashboard-wrapper">
      {isLoading ? (
        <div />
      ) : (
        <div>
          <Switch>
          <Route
              exact
              path={DASHBOARD_ROUTE}
              component={GuardianSettings}
            />
            <Route
              exact
              path={GUARDIAN_SETTINGS_ROUTE}
              component={GuardianSettings}
            />
            <Route
              exact
              path={GUARDIAN_ACCOUNT_DETAILS_ROUTE}
              component={GuardianDetails}
            />
            <Route
              exact
              path={`${GUARDIAN_SETTINGS_ROUTE}/activate-kid`}
              component={ActivateKid}
            />
            <Route
              exact
              path={`${GUARDIAN_SETTINGS_ROUTE}/add-children`}
              component={AddChildren}
            />
            <Route
              exact
              path={`${GUARDIAN_SETTINGS_ROUTE}/signup-complete`}
              component={AddChildComplete}
            />
            <Route
              exact
              path={`${GUARDIAN_SETTINGS_ROUTE}/register-camp`}
              component={RegisterCamp}
            />
            <Route
              path={`${GUARDIAN_SETTINGS_ROUTE}/edit/:id`}
              component={EditChildProfile}
            />
            <Route
              path={`${GUARDIAN_SETTINGS_ROUTE}/edit-profile`}
              component={EditGuardianProfile}
            />
            <Elements stripe={stripePromise}>
              <ProvidePayment>
                <Route
                  exact
                  path={`${GUARDIAN_SETTINGS_ROUTE}/special-camp/payment/complete`}
                  component={PurchaseComplete}
                />
                <Route
                  exact
                  path={`${GUARDIAN_SETTINGS_ROUTE}/special-camp`}
                  component={PurchaseSpecialCamp}
                />
              </ProvidePayment>
            </Elements>
            <Route render={() => <Redirect to={{ pathname: LOGIN_ROUTE }} />} />
          </Switch>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
