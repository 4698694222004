import { fetchUtils } from '../utils/fetch.utils';
import { getAPIUrl } from '../utils/config.utils';

const BASE_URL = getAPIUrl();
const SC = 'special_camps';

const getTargetSpecialCamp = (id) => {
  return fetchUtils.newGet(`${BASE_URL}/${SC}/records${id ? `?camp_id=${id}` : ''}`);
};

const getSchoolSpecialCamp = (id) => {
  return fetchUtils.newGet(`${BASE_URL}/${SC}/records-camps`);
};

const getSpecialCamp = (params) => {
  const { id } = params;
  return fetchUtils.newGet(`${BASE_URL}/${SC}/${id}`);
};

const getSpecialCamps = (params) => {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.newGet(`${BASE_URL}/${SC}?${query}`);
};

const attendSpecialCamp = (params) => {
  return fetchUtils.post(`${BASE_URL}/${SC}/attendance`, params);
};

const getDependentSpecialCamp = (id) => {
  // return fetchUtils.newGet(`${BASE_URL}/${SC}?dependent_id=${id}&availability=upcoming`);
  return fetchUtils.newGet(`${BASE_URL}/${SC}/${id}`);
};

const getGuardianDependentSpecialCamps = (campId, dependentId) => {
  return fetchUtils.newGet(`${BASE_URL}/${SC}/guardian-records${dependentId ? `?dependent_id=${dependentId}` : ''}`);
};

export const specialCampRepository = {
  getTargetSpecialCamp,
  getSpecialCamp,
  getSpecialCamps,
  attendSpecialCamp,
  getDependentSpecialCamp,
  getSchoolSpecialCamp,
  getGuardianDependentSpecialCamps
};
