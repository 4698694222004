import React from 'react';
import ImageCropRotate from './ImageCropRotate';

const ImageCropModal = ({ 
    imageCropPopup, 
    setImageCropPopup, 
    image, 
    setImage, 
    setPicture, 
    heading,
    aspectRatio
 }) => {
    const closeModal = () => {
        setImageCropPopup(false)
        if(setImage) {
            setImage(null)
        }
    }

    return (
        <ImageCropRotate
            imageCropPopup={imageCropPopup}
            image={image}
            setPicture={setPicture}
            closeModal={closeModal}
            heading={heading}
            aspectRatio={aspectRatio}
        />
    )
}

export default ImageCropModal;