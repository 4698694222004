import { schoolRepository } from "../../../repository/school.repository";

const getNextQuery = (params, list, has_more) => {
  const lastId = list.length > 0 ? list[list.length - 1].id : 0;
  const nextQuery = { ...params };
  nextQuery.starting_after = lastId;
  nextQuery.has_more = has_more;
  return nextQuery;
};

const mergeList = (previousList, newList) => {
  const list = previousList ? [...previousList, ...newList] : newList;
  let newIdSet = new Set();
  const uniqueList = list.filter((item) => {
    let isUnique = false;
    if (!newIdSet.has(item.id)) {
      newIdSet.add(item.id);
      isUnique = true;
    }

    return isUnique;
  });
  return uniqueList;
};

const mergeChallengeList = (previousList, newList) => {
  const list = previousList ? [...previousList, ...newList] : newList;
  let idSet = new Set();

  const uniqueList = list.filter((item) => {
    let isUnique = false;
    if (!idSet.has(item.id)) {
      idSet.add(item.id);
      isUnique = true;
    }
    return isUnique;
  });
  return uniqueList;
};

const createSchool = (params) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .createSchool(params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const schoolLogin = (params) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .schoolLogin(params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getBadgeCount = (id) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .getBadgeCount(id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getPublicCampBadgeCount = (id) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .getPublicCampBadgeCount(id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getUpcomingChallenges = (params, upcomingChallengeList) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .getUpcomingChallenges(params)
        .then((res) => {
          const response = res.data;
          const newList = mergeChallengeList(upcomingChallengeList, response);
          const query = getNextQuery(params, newList, res.has_more);
          const data = {
            newList,
            query,
          };
          resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getLiveChallenges = (params, liveChallengeList) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .getLiveChallenges(params)
      .then((res) => {
        const response = res.data;
        const newList = mergeChallengeList(liveChallengeList, response);
        const query = getNextQuery(params, newList, res.has_more);
        const data = {
          newList,
          query,
        };
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getPublicCampChallenges = (params, challengeList) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .getPublicCampChallenges(params)
      .then((res) => {
        const response = res.data;
        const newList = mergeChallengeList(challengeList, response);
        const query = getNextQuery(params, newList, res.has_more);
        const data = {
          newList,
          query,
        };
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getPastChallenges = (params, pastChallengeList) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .getPastChallenges(params)
      .then((res) => {
        const response = res.data;
        const newList = mergeChallengeList(pastChallengeList, response);
        const query = getNextQuery(params, newList, res.has_more);
        const data = {
          newList,
          query,
        };
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const addChallenge = (params) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .addChallenge(params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const addTeacherChallenge = (params) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .addTeacherChallenge(params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function addChallengeMedia(id, image) {
  const formBody = new FormData();
  formBody.append('image', image)
  return new Promise((resolve, reject) => {
    schoolRepository
      .addChallengeMedia(id, formBody)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function addTeacherChallengeMedia(id, image) {
  const formBody = new FormData();
  formBody.append('image', image)
  return new Promise((resolve, reject) => {
    schoolRepository
      .addTeacherChallengeMedia(id, formBody)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function updateSchoolProfilePic(
  id,
  profilePicture,
  school
) {
  const profileUpdatePromises = [];

  const formBody = new FormData();
  formBody.append('image', profilePicture);
  profileUpdatePromises.push(schoolRepository.uploadSchoolPhoto(id, formBody));

  return new Promise((resolve, reject) => {
    Promise.all(profileUpdatePromises)
      .then((response) => {
        let newSchool = { ...school };
        if (response[0]) {
          newSchool = { ...response[0].data };
        }
        if (response[1]) {
          newSchool.profile.picture_url = profilePicture.preview;
        }
        resolve(newSchool);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function updateCampCaptainProfilePic(
  id,
  profilePicture,
  school
) {
  const profileUpdatePromises = [];

  const formBody = new FormData();
  formBody.append('image', profilePicture);
  profileUpdatePromises.push(schoolRepository.uploadCampCaptainPicture(id, formBody));

  return new Promise((resolve, reject) => {
    Promise.all(profileUpdatePromises)
      .then((response) => {
        let newSchool = { ...school };
        if (response[0]) {
          newSchool = { ...response[0].data };
        }
        if (response[1]) {
          newSchool.profile.picture_url = profilePicture.preview;
        }
        resolve(newSchool);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function updateSchoolBannerPic(
  id,
  bannerPicture,
  school
) {
  const bannerUpdatePromises = [];

  const formBody = new FormData();
  formBody.append('image', bannerPicture);
  bannerUpdatePromises.push(schoolRepository.uploadSchoolBanner(id, formBody));

  return new Promise((resolve, reject) => {
    Promise.all(bannerUpdatePromises)
      .then((response) => {
        let newSchool = { ...school };
        if (response[0]) {
          newSchool = { ...response[0].data };
        }
        if (response[1]) {
          newSchool.profile.banner = bannerPicture.preview;
        }
        resolve(newSchool);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function updateCampCaptainBannerPic(
  id,
  bannerPicture,
  school
) {
  const bannerUpdatePromises = [];

  const formBody = new FormData();
  formBody.append('image', bannerPicture);
  bannerUpdatePromises.push(schoolRepository.uploadCampCaptainBanner(id, formBody));

  return new Promise((resolve, reject) => {
    Promise.all(bannerUpdatePromises)
      .then((response) => {
        let newSchool = { ...school };
        if (response[0]) {
          newSchool = { ...response[0].data };
        }
        if (response[1]) {
          newSchool.profile.banner = bannerPicture.preview;
        }
        resolve(newSchool);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const updateSchoolPassword = (id, email, formData, user_type) => {
  const credentials = {
    username: email,
    password: formData.password,
    remember_me: false,
  };
  const newPassword = {
    password: formData.new_password,
    email: email
  };
  return new Promise((resolve, reject) => {
    schoolRepository
      .schoolLogin(credentials)
      .then(() => {
        return schoolRepository.updateSchoolUser(id, newPassword, user_type);
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.resultCode === 400001) {
          error.message = 'Invalid password';
        }
        reject(error);
      });
  });
};

const updateSchoolDescription = (id, params) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .updateSchoolDescription(id, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const deleteSchoolBanner = (id) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .deleteSchoolBanner(id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getTimezones = () => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .getTimezones()
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const editChallenge = (id, params) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .editChallenge(id, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function editChallengeMedia(id, image) {
  const formBody = new FormData();
  formBody.append('image', image)
  return new Promise((resolve, reject) => {
    schoolRepository
      .editChallengeMedia(id, formBody)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const addTeacher = (params) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .addTeacher(params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const addTribe = (params) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .addTribe(params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const ediTribe = (id, params) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .editTribe(id, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function uploadTeacherImage(id, image) {
  const formBody = new FormData();
  formBody.append('image', image)
  return new Promise((resolve, reject) => {
    schoolRepository
      .uploadTeacherImage(id, formBody)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getTeachers = (params) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .getTeachers(params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getPublicTribeList = (id) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .getPublicTribeList(id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getTeacherDetails = (id) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .getTeacherDetails(id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const editTeacher = (id, params) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .editTeacher(id, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const deleteTeacher = (id, params) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .deleteTeacher(id, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const addCampCaptain = (params) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .addCampCaptain(params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function addCampCaptainMedia(id, image) {
  const formBody = new FormData();
  formBody.append('image', image)
  return new Promise((resolve, reject) => {
    schoolRepository
      .addCampCaptainMedia(id, formBody)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getCampCaptainsList = (id, params) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .getCampCaptainsList(id, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getCampCaptainTribes = (id, params) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .getCampCaptainTribes(id, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getCampMasterTribes = (id, params) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .getCampMasterTribes(id, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const editCampCaptain = (id, params) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .editCampCaptain(id, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const deleteCampCaptain = (id) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .deleteCampCaptain(id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const deleteCampCaptainMedia = (id) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .deleteCampCaptainMedia(id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getCampDependents = (params, previousList, groups) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .getCampDependents(params, groups)
      .then((res) => {
        const List = mergeList(previousList, res.data);
        const query = getNextQuery(params, List, res.has_more);
        const data = {
            List,
            query,
        };
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getCampCaptainDependents = (params, previousList, groups) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .getCampCamptainDependents(params, groups)
      .then((res) => {
        const List = mergeList(previousList, res.data);
        const query = getNextQuery(params, List, res.has_more);
        const data = {
            List,
            query,
        };
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getTribeDependents = (params, previousList, groups) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .getCampDependents(params, groups)
      .then((res) => {
        const List = mergeList(previousList, res.data);
        const query = getNextQuery(params, List, res.has_more);
        const data = {
            List,
            query,
        };
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getCampMasterBadgeCount = (params) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .getCampMasterBadgeCount(params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getCampCaptainBadgeCount = (params) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .getCampCaptainBadgeCount(params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getDependentLogs = (params, id, previousList) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .getDependentLogs(params, id)
      .then((res) => {
        const List = mergeList(previousList, res.data);
        const query = getNextQuery(params, List, res.has_more);
        const data = {
            List,
            query,
        };
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getTribeLogs = (params, id, previousList) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .getTribeLogs(params, id)
      .then((res) => {
        const List = mergeList(previousList, res.data);
        const query = getNextQuery(params, List, res.has_more);
        const data = {
            List,
            query,
        };
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const changeConnectionStatus = (status, id) => {
  return new Promise((resolve, reject) => {
    schoolRepository
      .changeConnectionStatus(status, id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const schoolViewModel = {
  createSchool,
  schoolLogin,
  getBadgeCount,
  getLiveChallenges,
  getUpcomingChallenges,
  getPastChallenges,
  addChallenge,
  addChallengeMedia,
  updateSchoolBannerPic,
  updateSchoolProfilePic,
  updateSchoolPassword,
  updateSchoolDescription,
  deleteSchoolBanner,
  getTimezones,
  editChallenge,
  editChallengeMedia,
  addTeacher,
  getTeachers,
  getTeacherDetails,
  editTeacher,
  addTeacherChallenge,
  addTeacherChallengeMedia,
  deleteTeacher,
  uploadTeacherImage,
  getPublicCampBadgeCount,
  getPublicTribeList,
  getPublicCampChallenges,
  addCampCaptain,
  addCampCaptainMedia,
  getCampCaptainsList,
  editCampCaptain,
  deleteCampCaptain,
  deleteCampCaptainMedia,
  updateCampCaptainBannerPic,
  updateCampCaptainProfilePic,
  addTribe,
  getCampCaptainTribes,
  getCampMasterTribes,
  ediTribe,
  getCampDependents,
  getTribeDependents,
  getCampCaptainDependents,
  getCampMasterBadgeCount,
  getCampCaptainBadgeCount,
  getDependentLogs,
  changeConnectionStatus,
  getTribeLogs
}