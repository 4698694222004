import React from 'react';
import Masonry from 'react-masonry-css';

import './scss/MasonryLayout.scss';

class MasonryLayout extends React.Component {
  render() {
    const breakpointColumnsObj = {
      default: 3,
      1100: 3,
      850: 2,
      500: this.props.minimum,
    };

    return (
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {this.props.children}
      </Masonry>
    );
  }
}

export default MasonryLayout;
