import React from 'react';
import { Carousel } from 'react-bootstrap';
import checkActive from '../../../assets/images/check-active.svg';
import checkimage from '../../../assets/images/check-svg.svg';
import checkActiveSeedlings from '../../../assets/images/check-icon-seedlings.svg';
import checkActiveVillagers from '../../../assets/images/check-icon-villagers.svg';

const PlansSlider = ({
    _EMERALITES,
    _SEEDLINGS_V2,
    _VILLAGERS,
    setShowModal,
    setSelectedPlan,
    subsPlanName,
    handleEmeralitesClick,
    handleSeedlingsV2Click,
    handleVillagersClick,
    setSelectedLearnMorePlan,
    setPlan,
    setSubsPlanName,
    setIsDisabled,
    setSubscribeButtonClassName,
}) => {

    const handleLearnMoreClick = (plan) => {
        setShowModal(true);
        setSelectedLearnMorePlan(plan)
    }

    const handleSlide = (e) => {
        console.log(e)
        switch (e) {
            case 0:
                handleEmeralitesClick()
                break;
            case 1:
                handleSeedlingsV2Click()
                break;
            case 2:
                handleVillagersClick()
                break;
        
            default:
                break;
        }
    }

    return (
        <Carousel
            controls={true}
            interval={null}
            className='home-feed-caousel ofday new-payment-slider'
            onSlide={handleSlide}
        >
            <Carousel.Item>
                <div className={`offers emeralites ${subsPlanName === _EMERALITES && 'selected'}`} onClick={handleEmeralitesClick}>
                    <h2>
                        {subsPlanName === _EMERALITES && <img src={checkActive} className={`check-plan`} />}
                        Emeralites
                    </h2>
                    <h3 className="price">Free</h3>
                    <h4 className="price">$1 one time fee</h4>

                    <p><img src={checkimage} className={`check-plan`} />Emerald Forest (Platform) Access</p>
                    <p><img src={checkimage} className={`check-plan`} />Accept challenges up to 20</p>
                    <p><img src={checkimage} className={`check-plan`} />Publish creations up to 20</p>
                    <span onClick={() => handleLearnMoreClick("emeralites")} href="">Learn more</span>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className={`offers seedlingsv2 ${subsPlanName === _SEEDLINGS_V2 && 'selected'}`} onClick={handleSeedlingsV2Click}>
                    <h2>
                        {subsPlanName === _SEEDLINGS_V2 && <img src={checkActiveSeedlings} className={`check-plan`} />}
                        Seedlings
                    </h2>
                    <h3 className="price">$5 /month</h3>
                    <h4 className="price">All Emeralites feature, plus</h4>
                    <p><img src={checkimage} className={`check-plan`} />Publish unlimited creations</p>
                    <p><img src={checkimage} className={`check-plan`} />Monthly Special Camp</p>
                    <ul>
                        <li>Live session with industry leaders</li>
                    </ul>
                    <span onClick={() => handleLearnMoreClick("seedlings")} href="">Learn more</span>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className={`offers villagers ${subsPlanName === _VILLAGERS && 'selected'}`} onClick={handleVillagersClick}>
                    <h2>
                        {subsPlanName === _VILLAGERS && <img src={checkActiveVillagers} className={`check-plan`} />}
                        Villagers
                    </h2>
                    <h3 className="price">$100 /month</h3>
                    <h4 className="price">All Seedlings features, plus</h4>
                    <p> <img src={checkimage} className={`check-plan`} />Weekly Creative Camp </p>
                    <ul>
                        <li>Interactive discussion sessions among kids</li>
                    </ul>
                    <span onClick={() => handleLearnMoreClick("villagers")} href="">Learn more</span>
                </div>
            </Carousel.Item>
        </Carousel>
    )
}

export default PlansSlider;