import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Badge, Image } from 'react-bootstrap';
import MasonryLayout from '../../common/MasonryLayout';
import Fullview from '../../common/Fullview';
import iconMultipleImages from '../../../assets/icon/icon-multiple-images.svg';
import ConfirmMessage from '../../common/ConfirmMessage';
import dummy from '../../../assets/images/dummy-upload-failed.png';
import { myRoomViewModel } from './my.room.viewmodel';
import AlertMessage from '../../common/AlertMessage';
import Binky from '../../common/Binky';

import './css/image.gallery.scss';
import { useAuth } from '../../auth/Auth';
import { feedViewModel } from '../feed/feed.view.model';
import { getImageUrl } from '../../../utils/imageUrl.utils';
import OptimizeSignup from '../onboarding/signup_popups/OptimizeSignup';



function ImageGallery({ creationList, deleteCreationById, handleBinky, isCreatorRoom, handleComment, creator, from }) {
  const [showFullview, setShowFullview] = useState(false);
  const [fullviewData, setFullviewData] = useState({});
  const handleCloseFullview = () => setShowFullview(false);
  const handleShowFullview = () => setShowFullview(true);
  const [commentList, setCommentList] = useState([]);

  const [showDeleteConfirm, setShowDeleteConfirm] = useState('hide');
  const [deleteCreationId, setDeleteCreationId] = useState(0);
  const handleShowDeleteConfirm = () => setShowDeleteConfirm('show');
  const handleCloseDeleteConfirm = () => setShowDeleteConfirm('hide');

  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const handleCloseAlertMessage = () => setShowAlertMessage(false);
  const handleShowAlertMessage = () => setShowAlertMessage(true);

  const [showModal, setShowModal] = useState(false);
  const {dependent, guardian, school} = useAuth();
  const [imageLoaded, setImageLoaded] = useState([]);

  const getComments = (creation_id) => {
    feedViewModel.commentList({creation_id: creation_id})
    .then((res) => {
      setCommentList([...res.data]);
    })
    .catch();
  }

  const handleDeleteConfirm = () => {
    myRoomViewModel
      .deleteCreation({ id: deleteCreationId })
      .then((res) => {
        setAlertMessage('Item deleted.');
        setShowAlertMessage(true);
        setShowDeleteConfirm('hide');
        setTimeout(() => {
          setShowAlertMessage(false);
          deleteCreationById(deleteCreationId);
        }, 1600);
      })
      .catch((err) => {
        // show something for error
        console.log(err);
      });
  };

  useEffect(() => {
    // console.log(creationList);
  }, []);

  const deleteMessage = (
    <div>
      <span>Do you want to permanently delete item?</span>
    </div>
  );

  const setImageLoadedFun = (id) => {
    const temp = [...imageLoaded]
    temp[id] = true;
    setImageLoaded(temp);
}

  const getCreations = () => {


    const creations = creationList.map((creation) => {
      const data = {
        id: creation.id,
        challengeId: creation.challenge.id,
        user: creation.user,
        media: creation.media,
        title: creation.title,
        description: creation.description,
        created_at: creation.created_at,
        binky_id: creation.binky_id,
        binky_count: creation.binky_count,
        comment_count: creation.comment_count
      };
      const showFullviewHandler = (e) => {
        // if (from === 'school') return
        if(dependent || school?.user_type === 'school_teacher') {
          // e.stopPropagation();
          if(!guardian) {
            setFullviewData(data);
            handleShowFullview();
            getComments(data.id)
          }
        } else {
          if(!dependent && !guardian && !school) {
            setShowModal(true)
          }
          localStorage.setItem('kids_login_location', window.location.href)
        }
        
      };
      const showDeleteConfirmHandler = () => {
        setDeleteCreationId(creation.id);
        handleShowDeleteConfirm();
      };
      const multipleImageIcon =
        creation.media.length > 1 ? (
          <div className="multiple-images-icon-wrapper">
            <img src={iconMultipleImages} className="multiple-images" />
          </div>
        ) : (
          <></>
        );
      const binky = (
        <Binky
          creationId={data.id}
          binkyCountProp={data.binky_count}
          binkyIdProp={data.binky_id}
          feedList={creationList}
          handleBinky={handleBinky}
          className="myroomBinky"
          isFullView={false}
          setShowFullview={showFullviewHandler}
          isCreatorRoom={isCreatorRoom}
          from={from}
        />
      );
      const challengeBadge =
        creation.challenge === '' ? (
          <></>
        ) : (
          <Badge pill className="image-gallery-challenge-badge">
            Challenge
          </Badge>
        );
      const content =
        data.media.length > 0 ? (
          // <picture className={`creation-image ${isCreatorRoom && !dependent ? " remove-pointer" : ""}`}>
          <picture className={`creation-image`}>
            <source srcSet={creation.media[0].s480_url} />
            <Image
              onClick={showFullviewHandler}
              src={getImageUrl(creation.media[0])}
              alt=""
              onLoad={(e) => setImageLoadedFun(creation.id)}
            />
          </picture>
        ) : (
          <img
            className="dummy-creation"
            src={dummy}
            onClick={showDeleteConfirmHandler}
          />
        );

      /**
       * min width is 480px for all views since masonry layout breaks down by the following
       * 1100px / 3 col = 366px  max size 480x3 = 1440px
       * 850 / 2 col = 425px     max size 480x2 = 960px
       * 500 / 1 col = 500px max size 480x1 = 480px (most phones are ~400px)
       */

      return (
        <div className="content-wrapper" key={creation.id} style={{display: imageLoaded[creation.id] ? "block":"none"}}>
          {content}
          {challengeBadge}
          {binky}
          {multipleImageIcon}
        </div>
      );
    });

    return (
      <div className="image-gallery-creation-masonry-layout">
        <Container>
          <Row className="justify-content-md-center">
            <Col className="justify-content-md-center text-center">
              <MasonryLayout minimum="2">{creations}</MasonryLayout>
            </Col>
          </Row>
        </Container>
        <Fullview
          show={showFullview}
          setShowFullview={setShowFullview}
          data={fullviewData}
          deleteCreationById={deleteCreationById}
          feedList={creationList}
          handleClose={handleCloseFullview}
          handleBinky={handleBinky}
          handleComment={handleComment}
          commentList={commentList}
          getComments={getComments}
          isCreatorRoom={isCreatorRoom}
          isFullView={true}
        />
        
        <ConfirmMessage
          handleClose={handleCloseDeleteConfirm}
          handleConfirm={handleDeleteConfirm}
          show={showDeleteConfirm}
          headerTitle="Delete Creation"
          message={deleteMessage}
          small={true}
          confirmLabel="Delete"
        />
        <AlertMessage
          title={alertMessage}
          handleClose={handleCloseAlertMessage}
          show={showAlertMessage}
        />
        {/* <GuestRedirectVerification showModal={showModal} setShowModal={setShowModal} /> */}
        <OptimizeSignup showModal={showModal} setShowModal={setShowModal} creator={creator} />
      </div>
    );
  };

  return <div>{getCreations()}</div>;
}

export default ImageGallery;
