import React, { useEffect, useState, useMemo } from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ReactTimeAgo from 'react-time-ago';
import { useAuth } from '../auth/Auth';
import Avatar from './Avatar';
import ChallengeLink from './ChallengeLink';
import { myRoomViewModel } from '../views/my_room/my.room.viewmodel';
import ChallengeModal from './ChallengeModal';
import AlertMessage from './AlertMessage';
import Binky from './Binky';
import { FULL_SMALL_TYPE, VERY_SMALL_TYPE } from '../../constants/avatarType.constants';
import deleteIconImage from '../../assets/icon/icon-delete.svg';
import editIconImage from '../../assets/icon/edit_ic.svg';
import RoundButton from './RoundButton';

import prev from '../../assets/icon/prev.svg';
import next from '../../assets/icon/next.svg';
import close from '../../assets/icon/icon-darker-close.svg';
import { feedViewModel } from "../views/feed/feed.view.model";
import './scss/Fullview.scss';
import { getCommentUserProfileUrl, getUserProfileUrl } from '../../utils/camp.utils';
import CreationUpload from '../views/upload/CreationUpload';
import EditCreationUpload from '../views/upload/EditCreationUpload';
import { useLocation } from 'react-router-dom';
import GetVerifiedNowModal from '../views/next_challenge/getVerifiedNowModal';

const commentColors = [
  '#ffd29f', '#C0FECF', '#F2F6AB', '#D6D5FD', "#B3E5FE", "#9CF2F1", "#FDE988", "#ffd6fa", "#d6fbff", "#ddd6ff"
]

function Fullview({
  show,
  setShowFullview,
  data,
  deleteCreationById,
  handleClose,
  feedList,
  handleBinky,
  handleComment,
  commentList,
  getComments,
  isFullView,
  isCreatorRoom
}) {
  const { dependent, guardian, fetchAPI } = useAuth();
  const location = useLocation();
  const defaultValue = {
    prevShow: 'hide',
    prevIndex: 0,
    nextShow: 'hide',
    nextIndex: 1,
    currentIndex: 0,
  };
  const [navigation, setNavigation] = useState(defaultValue);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteCreationId, setDeleteCreationId] = useState(0);
  const handleShowDeleteConfirm = () => setShowDeleteConfirm(true);
  const handleCloseDeleteConfirm = () => setShowDeleteConfirm(false);

  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const handleCloseAlertMessage = () => setShowAlertMessage(false);
  const handleShowAlertMessage = () => setShowAlertMessage(true);

  const [selectedCompliment, setSelectedCompliment] = useState({});
  const [compliments, setCompliments] = useState([]);

  const [showEditCreationModal, setShowEditCreationModal] = useState(false);

  const handleEditCreation = () => setShowEditCreationModal(true);
  const handleCloseEditCreation = () => setShowEditCreationModal(false);
  const [showGetVerifiedModal, setShowGetVerifiedModal] = useState(false);
  // console.log(data)
  const handleNavigation = (e) => {
    const idx = parseInt(e.currentTarget.value, 10);
    let prevIndex;
    let prevShow;
    let nextIndex;
    let nextShow;
    if (idx === 0) {
      prevIndex = idx;
      prevShow = 'hide';
    } else {
      prevIndex = idx - 1;
      prevShow = 'show';
    }
    if (idx === data.media.length - 1) {
      nextIndex = idx;
      nextShow = 'hide';
    } else {
      nextIndex = idx + 1;
      nextShow = 'show';
    }
    setNavigation((navigation) => ({
      ...navigation,
      currentIndex: idx,
      prevIndex: prevIndex,
      prevShow: prevShow,
      nextIndex: nextIndex,
      nextShow: nextShow,
    }));
  };

  const showDeleteConfirmHandler = () => {
    setDeleteCreationId(data.id);
    handleShowDeleteConfirm();
  };

  const handleDeleteConfirm = () => {
    myRoomViewModel
      .deleteCreation({ id: deleteCreationId })
      .then((res) => {
        setAlertMessage('Item deleted.');
        setShowAlertMessage(true);
        setShowDeleteConfirm(false);
        setTimeout(() => {
          setShowAlertMessage(false);
          setShowFullview(false);
          deleteCreationById(deleteCreationId);
        }, 1600);
      })
      .catch((err) => {
        // show something for error
        console.log(err);
      });
  };

  const deleteMessage =
    show && data.media.length === 1 ? (
      <div>
        <span>Are you sure you want to delete this creation?</span>
      </div>
    ) : (
      <div>
        <span>
          If you delete, it will delete all images from this creation. Are you
          sure you want to continue?
        </span>
      </div>
    );

  useEffect(() => {
    // console.log(dependent.id);
    // console.log(data && data.user.id);
    if (show) {
      const isOnlyOne = show && data.media.length === 1;
      if (!isOnlyOne && navigation.currentIndex === 0) {
        setNavigation((navigation) => ({
          ...navigation,
          nextShow: 'show',
        }));
      }
    } else {
      setNavigation(defaultValue);
    }
  }, [show]);

  const getCompliments = () => {
    feedViewModel.complimentList()
      .then((res) => {
        // setCompliments([...res.data]);
        // console.log(compliments,'compliments')
        setCompliments(res.data.map((item, i) => {
          const colorIndex = i % 10;
          item.colorIndex = colorIndex;
          return item;
        }));
      })
      .catch();
  }

  const submitCompliment = () => {
    if (dependent && !dependent.profile?.is_activated) {
      return setShowGetVerifiedModal(true)
    }
    const params = {
      "creation_id": data.id,
      "compliment_id": selectedCompliment.id
    };

    setSelectedCompliment({ ...{} });

    feedViewModel.addCompliment(params)
      .then((res) => {
        getComments(data.id);
        const newCommentCount = data.comment_count + 1;
        const creationId = data.id;
        handleComment(creationId, newCommentCount);
      })
      .catch((err) => {
        console.log(err)
      });
  }

  useState(() => {
    getCompliments();
  }, []);

  const displayImages = () => {
    const date = new Date(data.created_at);
    if (show) {
      let userProfileUrl =
        dependent && data.user.user_id === dependent.id
          ? dependent.profile.picture_url
          : data.user.profile.picture_url;

      const creator = {
        userProfileUrl: userProfileUrl,
        userName: data.user.username,
        groupName: data.user.profile.group.name,
        favorites: data.user.profile.favorites,
        firstName: data.user.first_name,
        lastName: data.user.last_name,
        taletreeFriend: data.user.profile.taletree_friend,
      };

      const deleteIcon =
        dependent && dependent.id === data.user.id ? (
          <div className="fv-delete-wrapper">
            <img src={deleteIconImage} onClick={showDeleteConfirmHandler} />
          </div>
        ) : (
          <></>
        );

      return (
        <div>
          <div className="image-wrapper">
            <div className="fv-header">
              <Avatar {...creator} type={FULL_SMALL_TYPE} />
              <div className="last-column">
                <img src={close} className="fv-close" onClick={handleClose} />
                {dependent && dependent.username === creator.userName && !isCreatorRoom ?
                  !guardian ?
                    <div className='edit-delete-wrapper'>
                      <div className="fv-edit-wrapper" onClick={handleEditCreation}>
                        <img src={editIconImage} />
                      </div>
                      {deleteIcon}
                    </div>
                    : ""
                  : ""}
              </div>
            </div>
            <div className="fv-content">
              {data.media.map((image, key) => {
                const totalImageCount = data.media.length;
                const imageShow =
                  key === navigation.currentIndex ? 'show' : 'hide';
                const displayNumbering =
                  totalImageCount > 1 ? (
                    <div className="fv-numbering">
                      {key + 1} / {totalImageCount}
                    </div>
                  ) : (
                    ''
                  );
                const binky =
                  data.binky_count === undefined ? (
                    <></>
                  ) : (
                    <Binky
                      creationId={data.id}
                      binkyCountProp={data.binky_count}
                      binkyIdProp={data.binky_id}
                      commentCountProp={data.comment_count}
                      feedList={feedList}
                      handleBinky={handleBinky}
                      isFullView={true}
                    />
                  );
                return (
                  <div key={key} className={`fv-image-container ${imageShow}`}>
                    <img className={`fv-creation`} src={image.url} />
                    {binky}
                    {displayNumbering}
                    <button
                      className={`prev-button ${navigation.prevShow}`}
                      value={navigation.prevIndex}
                      onClickCapture={handleNavigation}
                    >
                      <img src={prev} value={navigation.prevIndex} />
                    </button>
                    <button
                      className={`next-button ${navigation.nextShow}`}
                      value={navigation.nextIndex}
                      onClickCapture={handleNavigation}
                    >
                      <img src={next} value={navigation.prevIndex} />
                    </button>
                  </div>
                );
              })}
              <div className="feed-title-bar">
                <div className="fv-title">{data.title}</div>
                <div className="fv-created">
                  <ReactTimeAgo date={date} timeStyle="twitter" />
                </div>
              </div>
              <div className="fv-description">{data.description}</div>
              <ChallengeLink
                id={data.challenge_id}
                title={data.challenge_title}
                from="feed"
              />
              <div className='w-100'>
                <h2 className='comment-title text-center'>Comments</h2>
                <div className='w-100'>
                  {commentList && commentList.map((item, key) => {
                    let target = item.user;
                    let kid = {
                      profile: {
                        picture_url: '',
                        taletree_friend:''
                      }
                    }
                    let userProfileUrl
                    if(item?.user?.user_type === 'dependent') {
                      kid.profile.picture_url = target?.picture_url || ''
                      kid.profile.taletree_friend = target?.taletree_friend || ''
                      userProfileUrl = getUserProfileUrl(kid);
                    } else {
                      userProfileUrl = getCommentUserProfileUrl(target);
                    }
                    const creator = {
                      userProfileUrl,
                      userName: item?.user?.user_type === 'dependent' ? item?.user?.username : `${item?.user?.first_name} ${item?.user?.last_name}`,
                      // groupName: item?.dependent?.profile?.group?.name,
                      // favorites: item?.dependent?.profile?.favorites,
                      firstName: item?.user?.first_name,
                      lastName: item?.user?.last_name,
                      userType: item?.user?.user_type
                    };

                    let commentColor = "#ffd29f";
                    compliments.map(itemData => {
                      if (item.text === itemData.text) {
                        commentColor = commentColors[itemData.colorIndex];
                      }
                    });

                    return <div className="avatar-container row" key={key}>
                      <Avatar {...creator} type={VERY_SMALL_TYPE} isFullView={isFullView} />
                      <span className='message-wrapper' style={{ "backgroundColor": commentColor }}>{item.text}</span>
                    </div>
                  })}
                </div>

                <div className='w-100 comment-box'>
                  {/* <input value={selectedCompliment&&selectedCompliment.text?selectedCompliment.text:""} className="w-100" readOnly={true}/> */}
                  <div className='w-100 border border-secondary rounded p-1'>
                    {selectedCompliment && selectedCompliment.text ? (
                      <>
                        <button className='pre-message' style={{ "backgroundColor": commentColors[selectedCompliment.colorIndex], "marginTop": "0" }} >{selectedCompliment.text}</button>
                        <span role='button' className='float-right' onClick={() => setSelectedCompliment({ ...{} })}>X</span>
                      </>) : (
                      <>Select a comment</>
                    )}
                  </div>

                  <div>
                    {compliments.map(item => {
                      return <button key={item.colorIndex} className='pre-message' style={{ "backgroundColor": commentColors[item.colorIndex] }} onClick={() => setSelectedCompliment(item)}>{item.text}</button>
                    })}
                  </div>

                  {selectedCompliment && selectedCompliment.id &&
                    <div className='d-flex justify-content-center'>
                      <RoundButton
                        label='Send'
                        onClick={submitCompliment}
                        className="w-75 my-4 gred-btn"
                      />
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>

        </div>
      );
    }
  };

  return (
    <div>
      <Modal
        className=' msg-modal'
        show={show}
        onHide={handleClose}
        backdropClassName="backdrop"
        dialogClassName="modal-100w"
      >
        <ModalBody className='comment-modal-width' bsPrefix="fv">
          <div>{displayImages()}</div>
        </ModalBody>
      </Modal>
      {/* <ConfirmMessage
        handleClose={handleCloseDeleteConfirm}
        handleConfirm={handleDeleteConfirm}
        show={showDeleteConfirm}
        headerTitle="Delete Creation"
        message={deleteMessage}
        small={true}
        confirmLabel="Delete"
      /> */}
      <ChallengeModal
        handleClose={handleCloseDeleteConfirm}
        handleAction={handleDeleteConfirm}
        show={showDeleteConfirm}
        type="confirm"
        level="warning"
        button1Label="Delete"
        button2Label="Cancel"
      >
        {deleteMessage}
      </ChallengeModal>
      <AlertMessage
        title={alertMessage}
        handleClose={handleCloseAlertMessage}
        show={showAlertMessage}
      />
      <EditCreationUpload
        data={data}
        from="myroom"
        showUpload={showEditCreationModal}
        handleClose={handleCloseEditCreation}
      />
      {showGetVerifiedModal ? <GetVerifiedNowModal show={showGetVerifiedModal} setShow={setShowGetVerifiedModal} /> : ''}
    </div>
  );
}

export default Fullview;
