import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import Cartoon from "../../../../assets/images/cartoon.svg";
import Boy from "../../../../assets/images/boy-i-normal.svg";
import Girl from "../../../../assets/images/girl-i-normal.svg";
import BoyActive from "../../../../assets/images/active-boy-i.svg";
import GirlActive from "../../../../assets/images/active-i-girl.svg";

import FormLabel from "../../../common/FormLabel"
import { Form, Modal, Row, Col, Button } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "../../../auth/Auth";
import { changeTextToVoice, getSoundWaveJson } from "../../../../utils/camp.utils";
import { GUARDIAN_SETTINGS_ROUTE } from "../../../../constants/routes.constants";
import ChildRegistration from "./ChildRegistration";

export default function AddChild(props) {
  const { showModal, setShowModal } = props;
  const [isPaused, setIsPaused] = useState(false);
  const [utterance, setUtterance] = useState(null);
  const history = useHistory();
  const { refreshUserState, guardian } = useAuth();
  const lsGender = JSON.parse(localStorage.getItem('gender'));
  const lsName = localStorage.getItem('firstName');
  const lsBirthdate = localStorage.getItem('birthdate');
  const [original, setOriginal] = useState('');
  const [errShow, setErrShow] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const isFromDashboard = location.state?.from === 'dashboard';
  const [kidsName, setKidsName] = useState(lsName !== null ? lsName : '');
  const _BUBBLE_TEXT1 = `Hey, I'm Uni. I don't speak but you can read my thoughts...`;
  const _BUBBLE_TEXT2 = `Wonderful a `;
  const _BUBBLE_TEXT3 = `! what a great name. I love it.`;
  const _BUBBLE_TEXT4 = `What a great birthday, I have the same age that’s funny.`;
  const [text, setText] = useState(_BUBBLE_TEXT1);
  const [showChildUserNameModal, setShowChildUserNameModal] = useState(false)
  const [childData, setChildData] = useState({});
  const [isPlay, setIsPlay] = useState(false);
  const datePickerRef = useRef(null);

  const currentDate = new Date();

  const maxDate = useMemo(() => {
    let maxYearDate = new Date();
    maxYearDate.setFullYear(maxYearDate.getFullYear() - 5);
    return maxYearDate;
  });

  const [myMonth, setMyMonth] = useState(currentDate);
  const [myYear, setMyYear] = useState(maxDate);
  const [myDay, setMyDay] = useState(currentDate);

  const minDate = myMonth && myYear ? new Date(myYear?.getFullYear(), myMonth?.getMonth(), 1) : new Date()

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (myYear && myMonth && myDay) {
      const myDayDate = myDay && myDay.getDate() ? myDay.getDate() : new Date().getDate();
      // console.log("myDayDate", myDayDate);
      setMyDay(new Date(myYear.getFullYear(), myMonth.getMonth(), myDayDate));
    }
  }, [myMonth, myYear, setMyDay]);

  const renderDayContents = (day, date) => {
    return <span>{date.getDate()}</span>;
  };

  const selectedMonth = myMonth && (myMonth?.getUTCMonth() + 1) < 9 ? `0${myMonth?.getUTCMonth() + 1}` : myMonth && myMonth?.getUTCMonth() + 1
  localStorage.setItem('birthdate', `${myYear?.getFullYear()}-${selectedMonth}-${myDay?.toString().substring(8, 10)}`);


  const [birthdate, setBirthdate] = useState(
    // lsBirthdate === null ? '' : moment(new Date(lsBirthdate), 'YYYY/M/D') || ''
    lsBirthdate === null ? '' : lsBirthdate
  );
  const [selectedOption, setSelectedOption] = useState(
    // lsGender !== null ? lsGender : { value: _NOT_SELECTED, label: 'Select...' }
    lsGender !== null ? lsGender : ''
  );
  const options = [
    // { value: _NOT_SELECTED, label: 'Select...' },
    { value: 'male', label: "Boy's" },
    { value: 'female', label: "Girl's" },
  ];
  const customStyles = {
    control: (base) => ({
      ...base,
      width: 184,
      height: 48,
      color: '#321b6d',
      fontWeight: '400',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#321b6d',
    }),
    placeholder: (provided) => ({
      ...provided,
      fontWeight: '400',
      color: 'rgb(204, 204, 204)',
    }),
    menu: (provided, state) => ({
      ...provided,
      width: 184,
    }),
  };

  const manufactureParams = () => {
    const lsFirstName = localStorage.getItem('firstName');
    const lsBirthDate = localStorage.getItem('birthdate');
    const lsGender = JSON.parse(localStorage.getItem('gender'));
    //const lsGender = 'male';
    // const lsCountry = JSON.parse(localStorage.getItem('country')).value;
    const lsCountry = 'Andorra';
    // const lsCity = JSON.parse(localStorage.getItem('city')).value;
    const lsCity = 'Canillo';
    // const lsSchool = JSON.parse(localStorage.getItem('school')).value;
    const lsSchool = 'safd';

    const params = {
      first_name: lsFirstName,
      last_name: guardian.last_name,
      //last_name: 'lkjlkjlo',
      signup: 'camp',
      profile: {
        favorites: ['', '', ''],
        taletree_friend: '',
        gender: lsGender,
        birthdate: lsBirthDate.replace(/\//g, '-'),
        //birthdate: "2022-09-15",
        lives_in: lsCountry,
        nearest_city: lsCity,
        school_name: lsSchool,
      },
      // questions: questions,
      questions: []
    };
    return params;
  };

  const handleNextClick = () => {
    if (!kidsName) {
      setErrors({ ...errors, kidsName: "Please enter your child's name." });
      return false;
    }
    else {
      setErrors({ ...errors, kidsName: "" });
    }

    if (myDay > maxDate) {
      setErrors({ ...errors, dob: "Less then 5 year is not allowed." });
      return false;
    }
    else {
      setErrors({ ...errors, dob: "" });
    }

    let destination = {};
    // destination = {
    //   pathname: `${GUARDIAN_SETTINGS_ROUTE}`,
    // };
    const params = manufactureParams();
    setChildData(params)
    setShowModal(false);
    setShowChildUserNameModal(true)
    // accountViewModel
    //   .addKid(params)
    //   .then((res) => {
    //     refreshUserState();
    //     localStorage.clear();
    //     handleComplete();
    //   })
    //   .catch((err) => {
    //     alert(err.message);
    //   })
    // history.push(destination);
  };

  const handleComplete = () => {
    setShowModal(false);
    history.push(GUARDIAN_SETTINGS_ROUTE);
  }

  useEffect(() => {
    if (location.state && location.state.from === 'dashboard') {
      setOriginal(location.state.from);
      return;
    }

    const birthdayInput = document.getElementsByClassName('form-control')[0];
  }, []);

  const handleGenderChange = (selection) => {
    setSelectedOption(selection);
    localStorage.setItem('gender', JSON.stringify(selection));
    if (selection === 'male') {
      setText(_BUBBLE_TEXT2 + 'boy!');
    } else {
      setText(_BUBBLE_TEXT2 + 'girl!');
    }
  };

  const inputProps = {
    placeholder: 'MM/DD/YYYY',
  };

  const handleNameChange = (e) => {
    const name = e.target.value;
    setKidsName(name);
    setText(name + _BUBBLE_TEXT3)
    localStorage.setItem('firstName', name);
  };

  //To disable Raw Input
  const handleDateChangeRaw = (e) => {
    e.preventDefault()
  }

  const handleVoiceClick = () => {
    const element = document.getElementById('voice-text')
    changeTextToVoice(element.textContent);
    setIsPlay(!isPlay);
    // const synth = window.speechSynthesis;
    // const u = new SpeechSynthesisUtterance(element.textContent);

    // setUtterance(u);
    // synth.speak(utterance);

    // return () => {
    //   synth.cancel();
    // };
  }

  const handlePlay = () => {
    const synth = window.speechSynthesis;

    if (isPaused) {
      synth.resume();
    }

    synth.speak(utterance);

    setIsPaused(false);
  };

  const handlePause = () => {
    const synth = window.speechSynthesis;

    synth.pause();

    setIsPaused(true);
  };

  const handleStop = () => {
    const synth = window.speechSynthesis;

    synth.cancel();

    setIsPaused(false);
  };

  const handleFocus = (e) => {
    // Prevent the keyboard from opening
    e.target.blur();
    // Manually open the date picker
    datePickerRef.current.setFocus();
  };

  const CustomInputYear = forwardRef(({ value, onClick }, ref) => (
    <button className="custom-input date-picker-custom-btn" onClick={onClick} ref={ref}>
      {value || 'Select Year'}
    </button>
  ));

  const CustomInputMonth = forwardRef(({ value, onClick }, ref) => (
    <button className="custom-input date-picker-custom-btn" onClick={onClick} ref={ref}>
      {value || 'Select Month'}
    </button>
  ));

  const CustomInputDate = forwardRef(({ value, onClick }, ref) => (
    <button className="custom-input date-picker-custom-btn" onClick={onClick} ref={ref}>
      {value || 'Select Date'}
    </button>
  ));

  return (
    <>
      <Modal className="signupPopup auth-popup mobile-v-popup right-curved-div" show={showModal} centered onHide={() => setShowModal(!showModal)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body >

          <div className='row bg-blue'>
            <div className="col-md-6 col-lg-5 p-4 bg-blue border-left overflow-hidden">
              <div className="voice-buble buuble-bounce" id="SpeechBubble" >
                <p id='voice-text' >
                  {text}
                </p>
              </div>
              <div className="text-left">
                {/* <p className="text-white voice-btn" onClick={handleVoiceClick}><BsVolumeUpFill /> Voice on</p> */}
                <p className="" style={{marginTop: '9rem'}}>
                  {/* <Lottie
                    loop
                    animationData={getSoundWaveJson()}
                    play={isPlay}
                    resizeMode="cover"
                    className='animate-svg'
                  /> */}
                </p>
              </div>
              <div className="cartoon-img">
                <img className="w-100" src={Cartoon} />
              </div>
            </div>
            <div className="col-md-6 col-lg-7 bg-white border-right right-curve auth-section white-right-arrow">
              <p className="auth-entry-text">Thanks for letting us get to know you and your child better.</p>
              <p className="">At TaleTree, we believe that every child is a creative genius and needs freedom to find themselves and express their creativity however they want to.</p>
              <div>

                <Form.Row className="auth-form">
                  <div>
                    <p>My Little</p>
                    <div className="flex gender-col">
                      <div className={`gender-img boy ${selectedOption === 'male' ? 'active' : ''}`} onClick={() => handleGenderChange('male')} id='boy'>
                        <img className="normal-img" src={Boy} />
                        <img className="active-img" src={BoyActive} />
                        <h3 className="gender-title">Boy</h3>
                      </div>
                      <div className={`gender-img girl ${selectedOption === 'female' ? 'active' : ''}`} onClick={() => handleGenderChange('female')} id='girl'>
                        <img className="normal-img" src={Girl} />
                        <img className="active-img" src={GirlActive} />
                        <h3 className="gender-title">Girl</h3>
                      </div>
                    </div>

                    <p className="help-div">*You can add additional children later</p>

                  </div>
                  {selectedOption &&
                    <>
                      <Form.Group className="container-fluid">
                        <FormLabel>
                          Name is
                        </FormLabel>
                        <input
                          name="Name"
                          value={kidsName}
                          onChange={handleNameChange}
                          className="input-modal"
                        />
                        <Row>
                          <Col><label className="small text-danger">{errors.kidsName}</label></Col>
                        </Row>
                      </Form.Group>
                      {kidsName &&
                        <>
                          <div className="input-wrapper custom-date cd-modal child-register">
                            <p className='label'>  When was your child born?</p>
                            <Row>
                              <Col className="left">
                                <DatePicker
                                  showMonthYearPicker
                                  dateFormat="MMM"
                                  renderCustomHeader={({ date }) => <div></div>}
                                  selected={myMonth}
                                  className="name-field"
                                  onChange={(date) => setMyMonth(date)}
                                  onChangeRaw={handleDateChangeRaw}
                                  customInput={<CustomInputMonth />}
                                />
                                <h6>
                                  Month
                                </h6>

                              </Col>
                              <Col className="center">
                                <DatePicker
                                  dateFormat="dd"
                                  renderCustomHeader={({ date }) => <div></div>}
                                  selected={myDay}
                                  className="name-field"
                                  popperPlacement="top-center"
                                  renderDayContents={renderDayContents}
                                  onChange={(date) => setMyDay(date)}
                                  onChangeRaw={handleDateChangeRaw}
                                  customInput={<CustomInputDate />}
                                /> <h6>
                                  Day
                                </h6>
                              </Col>
                              <Col className="end">
                              <DatePicker
                                selected={myYear}
                                className="name-field year-width"
                                popperPlacement="top-end"
                                onChange={(date) => setMyYear(date)}
                                showYearPicker
                                dateFormat="yyyy"
                                maxDate={new Date()}
                                onChangeRaw={handleDateChangeRaw}
                                isClearable={false}
                                onFocus={handleFocus}
                                disabledKeyboardNavigation
                                customInput={<CustomInputYear />}
                              />
                                <h6>
                                  Year
                                </h6>
                              </Col>
                            </Row>
                            <Row>
                              <Col><label className="small text-danger">{errors.dob}</label></Col>
                            </Row>
                          </div>

                          <div className="text-center submit-btn" >
                            <Button className="gred-btn new_button_account" onClick={handleNextClick}>Final step</Button>
                          </div>
                        </>
                      }
                    </>
                  }
                </Form.Row>
              </div>

            </div>
          </div>

        </Modal.Body>
      </Modal>
      <ChildRegistration showModal={showChildUserNameModal} setShowModal={setShowChildUserNameModal} childData={childData} />
    </>
  )
}