import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Container, Row, Col, Image } from 'react-bootstrap';
import { agoraChatGroupName, changeBodyClassName } from '../../../utils/utils';
import iconEdit from '../../../assets/icon/icon-edit.png';
import svg from '../../../assets/images/Avatar.svg';
import goldTick from '../../../assets/icon/gold_tick.svg';
import silverTick from '../../../assets/icon/silver_tick.svg';
import starIcon from '../../../assets/icon/icon-my-room-star.svg';
import flagIcon from '../../../assets/icon/icon-my-room-flag.svg';
import { bg } from '../../../constants/component.constants';
import { useAuth } from '../../auth/Auth';
import '../my_room/css/myroom.scss';
import MyBadge from '../../common/MyBadge';
import { PARTNER_ADD_CHALLENGES } from '../../../constants/routes.constants';
import defaultBannerImg from '../../../assets/images/Top_banner.png';
import EventEmitter from '../../../utils/events';
import PartnerHeader from '../school/PartnerHeader';
import { partnerViewModel } from '../school/partner.view.model';
import PersonalizeProfileModal from './PersonalizeProfileModal';
import ExpertSelectbox from './ExpertSelectBox';
import { agoraViewModel } from '../../common/agora.view.model';
import ChallengeBottomSection from './ChallengeBottomSection';
import { treehouseViewModel } from '../treehouse/treehouse.view.modal';
import { LuShare } from 'react-icons/lu';
import ShareModalTreehouse from '../treehouse/ShareModalTreehouse';
import verfiedIcon from '../../../assets/icon/Verified-badge.svg';
import unVerfiedIcon from '../../../assets/icon/Unverified.svg';

const PartnerChallenges = () => {
  const history = useHistory();
  const { partner, fetchAPI } = useAuth();
  const userName = partner?.user_type === "partner" ? partner?.name_of_organisation : `${partner?.first_name} ${partner?.last_name}`;
  const [challengeCount, setChallengeCount] = useState(0);
  const [creationCount, setCreationCount] = useState(0);
  const [subscriptionCount, setSubscriptionCount] = useState(0);
  const [expertsList, setExpertsList] = useState([]);
  const [selectedExpert, setSelectedExpert] = useState();
  const [customizePopup, setCustomizePopup] = useState(false);
  const [isDisabled, setIsDisabled] = useState(partner?.user_type === 'partner' ? true : false);
  const defaultProfileUrl = partner?.picture_url ? partner?.picture_url : svg;
  const defaultBannerUrl = partner?.banner_url ? partner?.banner_url : defaultBannerImg;
  const [profilePic, setProfilePic] = useState(defaultProfileUrl);
  const [bgImg, setBgImg] = useState(defaultBannerUrl);
  const [appToken, setAppToken] = useState();
  const [showShareModal, setShowShareModal] = useState(false);
  const shareModalProps = `${partner?.user_type === 'partner' ? 'treehouse' : 'expert'}/${partner?.slug}`
  const [query, setQuery] = useState({
    limit: 10,
    has_more: true,
  })

  useEffect(() => {
    setProfilePic(defaultProfileUrl);
  }, [defaultProfileUrl]);

  const onAddChallenge = () => {
    if (partner?.user_type === "partner") {
      history.push({
        pathname: PARTNER_ADD_CHALLENGES,
        state: {
          expertId: selectedExpert[0].id,
          expert_agora_group_id: selectedExpert[0]?.profile?.agora_group_id
        }
      })
    }
    else {
      history.push({
        pathname: PARTNER_ADD_CHALLENGES,
        state: {
          expert_agora_group_id: partner?.agora_group_id,
          expert_agora_group_id: partner?.profile?.agora_group_id
        }
      })
    }
  }

  let myRoomImageStyle = {
    backgroundImage: `url(${bgImg})`
  }

  const getBadgeCount = () => {
    let API = partner.user_type === 'partner' ? treehouseViewModel.getPartnerBadgeCount : treehouseViewModel.getExpertBadgeCount;
    API(partner.id).then((res) => {
      setChallengeCount(res.challenge_count)
      setCreationCount(res.creation_count)
      if (res.subscription_count) {
        setSubscriptionCount(res.subscription_count)
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const getExperts = () => {
    if (partner.user_type === "partner") {
      let params = { ...query };
      params.partner_id = partner?.id;
      partnerViewModel.getExperts(params, expertsList).then((res) => {
        setExpertsList(res.newList)
        for (let index = 0; index < res.newList.length; index++) {
          getUser(res.newList[index])
        }
      }).catch((err) => {
        console.log(err);
      })
    }
  }

  const getAppToken = async () => {
    let res = await agoraViewModel.getAppToken()
    setAppToken(res.data.app_token)
  }

  const createChatGroup = async (expert) => {
    const data = {
      groupname: agoraChatGroupName(expert?.username),
      desc: expert?.username,
      public: true,
      owner: String(expert?.id),
    };

    try {
      await agoraViewModel.createGroup(appToken, data).then((res) => {
        if (res?.data?.groupid) {
          let params = {
            agora_group_id: res?.data?.groupid
          }
          partnerViewModel.addExpertAgoraGroupId(expert?.id, params).then((resp) => {
            // console.log(resp)
            getExperts()
          }).catch((err) => {
            console.log(err)
            let params = {
              appToken: appToken,
              id: res?.data?.groupid
            }
            agoraViewModel.deleteGroup(params)
          })
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getUser = async (expert) => {
    let data = {
      username: expert.id,
      appToken: appToken
    }
    await agoraViewModel.getUser(data).then((res) => {
      if (!expert?.profile?.agora_group_id) {
        createChatGroup(expert);
      }
    }).catch((err) => {
      if (err.statusCode === 404) {
        registerUser(expert)
      }
    })
  }

  const registerUser = (expert) => {
    let data = {
      username: expert.id,
      nickname: expert.id,
      password: 'test@123',
      appToken: appToken
    }
    agoraViewModel.registerUser(data).then((res) => {
      createChatGroup(expert)
    })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    changeBodyClassName([bg.WHITE]);
    getBadgeCount();
  }, [partner])

  useEffect(() => {
    getAppToken()
  }, [])

  useEffect(() => {
    if (appToken) getExperts();
  }, [appToken])

  const getUserProfilePic = (image) => {
    setProfilePic(image.preview);
  }

  const getUserBannerPic = (image) => {
    setBgImg(image.preview);
  }

  EventEmitter.subscribe('partner_profile_pic', getUserProfilePic)
  EventEmitter.subscribe('partner_banner_pic', getUserBannerPic)

  useEffect(() => {
    return () => {
      EventEmitter.unsubscribe('partner_profile_pic', getUserProfilePic);
      EventEmitter.unsubscribe('partner_banner_pic', getUserBannerPic);
    }
  }, [])

  return (
    <div className="myroom-main partner_myroom_main partner-wrapper-main">
      <div className="myroom-profile-info">
        <PartnerHeader />
        <div className={bgImg ? "myroom-info-container no-repeat overlay-container container" : "myroom-info-container overlay-container container"} style={myRoomImageStyle}>
          <Container fluid="md">
            <Row className="myroom-info-wrapper">
              <Col md={7} lg={7} xl={8}>
                <div className="myroom-collapse">
                  <div className="avatar-wrapper">
                    <div className="full-big-type">
                      <Row>
                        <div className="avatar-image" >
                          <Image
                            className="rounded-circle profile-image"
                            src={profilePic}
                          />
                          <Image className="edit-button" onClick={() => setCustomizePopup(true)} src={iconEdit} />
                        </div>
                        <div className="user-info">
                          <div className="name-favorites">
                            <div className="user-name">{userName}
                              {partner.is_verified ?
                                partner.user_type === "partner" ?
                                  <Image className="blue_badge remove-pointer" src={partner.type_of_organisation === "non profit" ? silverTick : goldTick} />
                                  : partner?.user_type === "camp master" ?
                                    <Image className="blue_badge remove-pointer" src={partner?.is_verified ? verfiedIcon : unVerfiedIcon} />
                                    : ''
                                : <Image className="blue_badge remove-pointer" src={unVerfiedIcon} />
                              }
                              <span onClick={() => setShowShareModal(true)} style={{ marginLeft: '7px', cursor: 'pointer' }}><LuShare style={{ height: '22px' }} /></span>
                            </div>
                            <div className="favorites">{partner.description}</div>
                          </div>
                        </div>
                      </Row>
                      <Row className="badge-wrapper">
                        <MyBadge
                          icon={flagIcon}
                          count={challengeCount}
                          className="first-badge"
                          title="Challenges published"
                        />
                        <MyBadge
                          icon={starIcon}
                          count={creationCount}
                          title="Creations submitted"
                        />
                        {partner.user_type === 'camp master' &&
                          <MyBadge
                            count={subscriptionCount}
                            title="Kids subscribed"
                          />
                        }
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                md={{ span: 5 }}
                lg={{ span: 5 }}
                xl={{ span: 4 }}
              >
                <div className="join-camp-wrapper">
                  {partner.user_type === "partner" &&
                    <div className="filter-container">
                      <div className="filter-tag" >
                        <ExpertSelectbox
                          expertsList={expertsList}
                          selectedExpert={selectedExpert}
                          setSelectedExpert={setSelectedExpert}
                          setIsDisabled={setIsDisabled}
                        />
                      </div>
                    </div>
                  }
                  <Button
                    variant="outline-light common-round-btn btn btn-block"
                    onClick={onAddChallenge}
                    disabled={!partner || isDisabled}
                  >
                    + Set Challenge
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Container fluid="md">
          <ChallengeBottomSection
            selectedExpert={selectedExpert}
            appToken={appToken}
            getBadgeCount={getBadgeCount}
          />
        </Container>
      </div>
      <ShareModalTreehouse
        showShareModal={showShareModal}
        setShowShareModal={setShowShareModal}
        shareModalProps={shareModalProps}
      />
      <PersonalizeProfileModal customizePopup={customizePopup} setCustomizePopup={setCustomizePopup} isEdit={true} />
    </div>
  );
};

export default PartnerChallenges;