import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import { useAuth } from '../../auth/Auth';
import { settingsViewModel } from '../dashboard/settings.view.model';
import LoadButton from '../../common/LoadButton';
import letsgo from '../../../assets/images/lletsgo.svg';
import cody from '../../../assets/images/cody.svg';
import rushmore from '../../../assets/images/rushmore.svg';
import doma from '../../../assets/images/doma.svg';
import rooty from '../../../assets/images/rooty.svg';
import uni from '../../../assets/images/uni.svg';
import CartoonBottom from "../../../assets/images/cartoon-bottom.svg";

import './css/taletree.friend.scss';
import { BsCheckLg, BsVolumeUpFill } from 'react-icons/bs';
import Lottie from 'react-lottie-player';
import { changeTextToVoice, getSoundWaveJson, getTaletreeFriendJson } from '../../../utils/camp.utils';
import Typed from 'typed.js';
import TaleTreeFriendSlider from './TaleTreeFriendSlider';
import closeIcon from '../../../assets/icon/icon-darker-close.svg';
import { customEvents } from '../../../analytics/customEvents';

const TaleTreeFriend = (props) => {
  const {
    showChooseCharacterModal,
    taletreeFriend,
    setTaletreeFriend,
    handleChooseCharacterClose,
    handleFavoritesShow,
  } = props;
  const LETSGO = 'LETSGO';
  const UNI = 'UNI';
  const RUSHMORE = 'RUSHMORE';
  const CODY = 'CODY';
  const DOMA = 'DOMA';
  const ROOTY = 'ROOTY';
  const _LETSGO_DESCRIPTION = 'I’m Letsgo, the super active bunny! Physically active and always thinking about the next adventure. Creativity, determination, and diligence are my strengths.';
  const _UNI_DESCRIPTION = 'I’m Uni, Uni for Universe. I wear a futuristic space helmet as a being from an unknown planet far from Earth. I’m curious about how things work on Earth, which is so different from home.';
  const _RUSHMORE_DESCRIPTION = 'I’m Rushmore a clumsy house cat. One day, I hope to become a great chef and open a fish restaurant with my dad. I’m bold and audacious in expressing myself through making food.';
  const _CODY_DESCRIPTION = 'I’m Cody a red fox. I always been savvy with gadgets, and started to learn to program when I was only 4! I want to make a greater impact on the world by utilizing my knowledge of technology.';
  const _DOMA_DESCRIPTION = 'I’m Doma a super speedy lizard who zips around on a skateboard. I like to spend time with friends and play team sports, which has always been a talent of mine. I see the best quality in everyone.';
  const _ROOTY_DESCRIPTION = 'I’m Rooty a kind and friendly bear. I have a robotic hand (which detects honey!) and I use a wheelchair to get around! I’m very observant of my surroundings and I like to recycle scrap materials and turn them into art.';
  const _LETSGO_HEADING = 'I’m a charismatic explorer';
  const _UNI_HEADING = 'I’m a visitor from space with big, universal love ';
  const _RUSHMORE_HEADING = 'I’m an ambitious free-spirit';
  const _CODY_HEADING = 'I’m a tech prodigy, influencer';
  const _DOMA_HEADING = 'I’m an athletic, fast learner';
  const _ROOTY_HEADING = 'I’m an ambitious free-spirit';
  const defaultFriend = {
    letsgo: letsgo,
    cody: cody,
    rushmore: rushmore,
    doma: doma,
    rooty: rooty,
    uni: uni,
  };
  const selectedFriendName = {
    ['letsgo']: 'LETSGO',
    ['uni']: 'UNI',
    ['rushmore']: 'RUSHMORE',
    ['cody']: 'CODY',
    ['doma']: 'DOMA',
    ['rooty']: 'ROOTY',
    ['']: 'LETSGO'
  }
  const selectedFriendClass = {
    ['letsgo']: 'letsgo-btn',
    ['uni']: 'uni-btn',
    ['rushmore']: 'rush-btn',
    ['cody']: 'cody-btn',
    ['doma']: 'doma-btn',
    ['rooty']: 'rooty-btn',
    ['']: 'letsgo-btn'
  }
  const selectedFriendHeading = {
    ['letsgo']: _LETSGO_HEADING,
    ['uni']: _UNI_HEADING,
    ['rushmore']: _RUSHMORE_HEADING,
    ['cody']: _CODY_HEADING,
    ['doma']: _DOMA_HEADING,
    ['rooty']: _ROOTY_HEADING,
    ['']: _LETSGO_HEADING
  }
  const selectedFriendDescription = {
    ['letsgo']: _LETSGO_DESCRIPTION,
    ['uni']: _UNI_DESCRIPTION,
    ['rushmore']: _RUSHMORE_DESCRIPTION,
    ['cody']: _CODY_DESCRIPTION,
    ['doma']: _DOMA_DESCRIPTION,
    ['rooty']: _ROOTY_DESCRIPTION,
    ['']: _LETSGO_DESCRIPTION
  }

  const { dependent, fetchAPI, refreshUserState } = useAuth();
  const [current, setCurrent] = useState(defaultFriend);
  const [clicked, setClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPlay, setIsPlay] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const typedRef = useRef(null);

  const hasFavorites = () => {
    return (
      dependent.profile.favorites.filter((fav) => fav.length > 0).length >= 1
    );
  };

  const handleClick = (value) => {
    // const value = e.target.dataset.value;
    const oldValue = taletreeFriend;
    if (value !== oldValue) {
      // setCurrent({
      //   ...defaultFriend,
      //   [value]: chosenFriend[value],
      //   [oldValue]: defaultFriend[oldValue],
      // });
    }
    setTaletreeFriend(value);
    setClicked(true);
  };

  const handleNext = () => {
    let analyticsData = {
      category: "Kid Onboarding",
      action: "adopt tale tree friend",
      label: 'adopt tale tree friend'
    }
    customEvents(analyticsData);
    if (hasFavorites()) {
      setIsLoading(true);
      const params = {
        taletree_friend: taletreeFriend,
      };

      fetchAPI(settingsViewModel.updateTaletreeFriend, dependent.id, params)
        .then((res) => {
          refreshUserState();
          handleChooseCharacterClose();
        })
        .catch((err) => console.error(err))
        .finally(() => setIsLoading(false));
      return;
    }
    handleChooseCharacterClose();
    handleFavoritesShow();
  };

  const handleVoiceClick = () => {
    setIsPlay(!isPlay);
    const element = document.getElementById('voice-text');
    changeTextToVoice(element.textContent, isPlay, setIsPlay, isPaused, setIsPaused);
  }

  useEffect(() => {
    const typed = new Typed(typedRef.current, {
      strings: [selectedFriendDescription[taletreeFriend]],
      typeSpeed: 20,
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, [taletreeFriend])

  return (
    <Modal className="signupPopup gardian mobile-v-popup auth-popup right-curved-div" show={showChooseCharacterModal} centered>
      <Modal.Body >
        <div className='row bg-blue border-right-row'>
          <div className="col-md-6 col-lg-5 p-0 bg-blue border-left buble-relative ">
            <div className="p-4 h-500 overflow-hidden">

              <div className="d-flex justify-content-between">
                <div className="text-left mr-3">
                  {/* <p className="text-white voice-btn" style={{display: 'none'}} onClick={handleVoiceClick}><BsVolumeUpFill /> Voice on</p> */}
                  <div className="" style={{marginTop: '10rem'}}>
                    {/* <img src='' />
                    <Lottie
                      loop={true}
                      animationData={getSoundWaveJson()}
                    // play={isPlay}
                    /> */}
                  </div>
                </div>
                <div className='my-name-is'>
                  <p className="text-white remove-pointer voice-btn pr-0">My name is <span className={`name-btn ${selectedFriendClass[taletreeFriend]}`}>{selectedFriendName[taletreeFriend]}</span></p>
                </div>
              </div>

              <div className="cartoon-img mtop-100 carton-div">
                {/* <img className="w-100 cartoon-top" src={selectedFriendPicture[taletreeFriend]} /> */}
                <Lottie
                  loop={false}
                  play
                  animationData={getTaletreeFriendJson(taletreeFriend)}
                  className="mobile-none cartoon-top"
                />
                <img className="mobile-none cartoon-bottom" src={CartoonBottom} />
                <div className='desktop-none'>
                  <TaleTreeFriendSlider
                    handleClick={handleClick}
                    clicked={clicked}
                    taletreeFriend={taletreeFriend}
                  />
                </div>
              </div>
            </div>
            <div className=" bottom-buble buuble-bounce">
              <p><b>{selectedFriendHeading[taletreeFriend]}</b></p>
              <p className="" id='voice-text'>
                <span ref={typedRef} />
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-7 v-flex bg-white border-right right-curve auth-section white-right-arrow toparrow right-bottom-curve">

            <h4 className="modal-h5">
              Hey, {dependent.first_name}! Our TaleTree Friends can't wait to get to know you!
            </h4>
            <p>Choose the TaleTree Friend you feel most close to! This friend will always be there for you :)</p>

            <div className="row mobile-none">
              <div className="col-md-6 col-lg-4" onClick={() => handleClick("letsgo")}>
                <div className={`letGo cartoon-div ${taletreeFriend === 'letsgo' && ' active'}`}>
                  <img src={current.letsgo}
                    data-value="letsgo"
                  />
                  <BsCheckLg className="tick-div" />
                </div>
              </div>
              <div className="col-md-6 col-lg-4" onClick={() => handleClick("uni")}>
                <div className={`uni cartoon-div ${taletreeFriend === 'uni' && ' active'}`}>
                  <img src={current.uni} data-value="uni" />
                  <BsCheckLg className="tick-div" />
                </div>
              </div>
              <div className="col-md-6 col-lg-4" onClick={() => handleClick("rushmore")}>
                <div className={`rush cartoon-div ${taletreeFriend === 'rushmore' && ' active'}`}>
                  <img
                    src={current.rushmore}
                    data-value="rushmore"
                  />
                  <BsCheckLg className="tick-div" />
                </div>
              </div>
              <div className="col-md-6 col-lg-4" onClick={() => handleClick("cody")}>
                <div className={`cody cartoon-div ${taletreeFriend === 'cody' && ' active'}`}>
                  <img src={current.cody} data-value="cody" />
                  <BsCheckLg className="tick-div" />
                </div>
              </div>
              <div className="col-md-6 col-lg-4" onClick={() => handleClick("rooty")}>
                <div className={`rooty cartoon-div ${taletreeFriend === 'rooty' && ' active'}`}>
                  <img src={current.rooty} data-value="rooty" />
                  <BsCheckLg className="tick-div" />
                </div>
              </div>
              <div className="col-md-6 col-lg-4" onClick={() => handleClick("doma")}>
                <div className={`doma cartoon-div ${taletreeFriend === 'doma' && ' active'}`}>
                  <img src={current.doma} data-value="doma" />
                  <BsCheckLg className="tick-div" />
                </div>
              </div>
            </div>


            <div className="text-center">
              <LoadButton
                type={hasFavorites() ? 'submit' : 'button'}
                onClick={handleNext}
                className="gred-btn w-80 "
                text={`Adopt ${selectedFriendName[taletreeFriend]}`}
                isDisabled={!clicked}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>

      </Modal.Body>
    </Modal>
  );
};

export default TaleTreeFriend;
