import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useAuth } from '../../auth/Auth';
import { useParams } from 'react-router-dom';
import SettingsNavHeader from './SettingsNavHeader';
import EditChildBasic from './EditChildBasic';
import EditChildFavorites from './EditChildFavorites';
import EditChildPassword from './EditChildPassword';
import DashboardHeader from '../../header/DashboardHeader';
import AlertMessage from '../../common/AlertMessage';

import './css/edit.kid.profile.scss';

const EditChildProfile = () => {
  const { getGuardianDependents } = useAuth();
  const { id } = useParams();
  const [dependent, setDependent] = useState(getGuardianDependents([id])[0]);
  const [showAlert, setShowAlert] = useState(false);
  const onProfileSave = () => {
    setShowAlert(true);
  };
  const props = {
    dependent,
    onProfileSave,
  };

  useEffect(() => {
    // console.log(dependent);
  }, []);

  return (
    <div className="edit-kid-profile-wrapper">
      <DashboardHeader />
      <Container className="setting-container save-modal">
        <AlertMessage
          handleClose={() => setShowAlert(false)}
          show={showAlert}
          title={'Changes has been saved.'}
        />
        <SettingsNavHeader title={`Settings for ${dependent.username}`} />
        <Row className="flex-grow-1 align-content-center wrapper">
          <EditChildBasic {...props} />
        </Row>
        <Row className="flex-grow-1 align-content-center wrapper">
          <EditChildFavorites {...props} />
        </Row>
        <Row className="flex-grow-1 align-content-center wrapper">
          <EditChildPassword {...props} />
        </Row>
      </Container>
    </div>
  );
};

export default EditChildProfile;
