import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { BiSearch } from '@react-icons/all-files/bi/BiSearch';
import { useHistory } from 'react-router-dom';
import { CHALLENGE_ROUTE, NEXT_CHALLENGE_ROUTE, SHARE_CREATION_ROUTE_BANNER, SPECIAL_CHALLENGE_ROUTE, TREEHOUSE_ROUTE } from '../../constants/routes.constants';
import { searchRepository } from '../../repository/search.repository';
import { useAuth } from '../auth/Auth';
import OptimizeSignup from '../views/onboarding/signup_popups/OptimizeSignup';

const Search = (props) => {
  const history = useHistory();
  const { dependent, guardian } = useAuth();
  const [searchInput, setSearchInput] = useState("");
  const [searchResultBranches, setSearchResultBranches] = useState();
  const [searchResultChallenges, setSearchResultChallenges] = useState();
  const [searchResultTreehouses, setSearchResultTreehouses] = useState();
  const [searchResultCreations, setSearchResultCreations] = useState();
  const [showModal, setShowModal] = useState(false);
  const isDisable = !dependent && !guardian ? true : false;

  const getSearchResult = () => {
    searchRepository.getSearchResult(searchInput).then((res) => {
      // console.log(res)
      res.data.branches ? setSearchResultBranches(res.data.branches) : setSearchResultBranches("");
      res.data.challenges ? setSearchResultChallenges(res.data.challenges) : setSearchResultChallenges("");
      res.data.treehouse ? setSearchResultTreehouses(res.data.treehouse) : setSearchResultTreehouses("");
      res.data.creations ? setSearchResultCreations(res.data.creations) : setSearchResultCreations("");
    })
  }

  useEffect(() => {
    if (searchInput) {
      getSearchResult()
    }
  }, [searchInput])

  const onInputChange = (e) => {
    if (!e.target.value) {
      setSearchResultBranches("");
      setSearchResultChallenges("");
      setSearchResultTreehouses("");
    }
    setSearchInput(e.target.value)
  }

  const onBranchClick = (tag) => {
    if (!dependent && !guardian) {
      setShowModal(true);
      localStorage.setItem('kids_login_location', window.location.href)
    } else if (history.location.pathname !== NEXT_CHALLENGE_ROUTE) {
      history.push({
        pathname: NEXT_CHALLENGE_ROUTE,
        state: {
          tag: tag,
          filter: 'tag'
        }
      })
    } else {
      props.branchClick(tag)
    }
  }

  const onChallengeClick = (challenge) => {
    if (!dependent && !guardian) {
      setShowModal(true);
      localStorage.setItem('kids_login_location', window.location.href)
    } else {
      let path = CHALLENGE_ROUTE
      let encodeQuery = btoa(`{"id":${challenge.id}, "slug":"challenge"}`)
      if (challenge?.special_camp?.id) {
        path = SPECIAL_CHALLENGE_ROUTE
        encodeQuery = btoa(`{"id":${challenge.id}, "slug":"special-challenge"}`)
      }
      history.push({
        pathname: path,
        search: `?encrypt=${encodeQuery}`
      })
    }
  }

  const onCreationClick = (id) => {
    if (!dependent && !guardian) {
      setShowModal(true);
      localStorage.setItem('kids_login_location', window.location.href)
    } else {
      const encoded = btoa(`{"id":${id}, "slug":"creation"}`)
      history.push({
        pathname: SHARE_CREATION_ROUTE_BANNER,
        search: `?encrypt=${encoded}`
      })
    }
  }

  const onTreehouseClick = (data) => {
    if (!dependent && !guardian) {
      setShowModal(true);
      localStorage.setItem('kids_login_location', window.location.href)
    } else {
      let id = data?.slug ? data?.slug : data?.id
      history.push({
        pathname: `${TREEHOUSE_ROUTE}/${id}`,
        state: {
          type: data?.slug ? 'slug' : 'id'
        }
      })
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
  }

  return (
    <div className='accordian-body'>
      <Form className="search-form hide-on-toggle" onSubmit={handleSubmit}>
        <Form.Group className="">
          <Form.Control type="text"
            value={searchInput}
            onChange={onInputChange}
            // disabled={isDisable}
            placeholder="Search branches, treehouses.." />
          <span className="search_ic"><BiSearch /></span>

          {searchInput ?
            <div className='search-results'>
              {searchResultBranches ?
                <div className='result-branches'>
                  <div className='branches-header'>Branches</div>
                  <div className='result-row'>
                    {searchResultBranches.map((data, key) => {
                      return <div className='result-itm branch-itm' key={key} onClick={() => onBranchClick(data.tag)}>#{data.tag}</div>
                    })}
                  </div>
                </div>
                : ""}

              {searchResultChallenges ?
                <div className='result-challenges'>
                  <div className='challenges-header'>Challenges</div>
                  <div className='result-row'>
                    {searchResultChallenges.map((data, key) => {
                      return <div className='result-itm challenges-itm' key={key} onClick={() => onChallengeClick(data)}>{data.tag}</div>
                    })}
                  </div>
                </div>
                : ""}

              {searchResultCreations ?
                <div className='result-challenges'>
                  <div className='challenges-header'>Creations</div>
                  <div className='result-row'>
                    {searchResultCreations.map((data, key) => {
                      return <div className='result-itm challenges-itm' key={key} onClick={() => onCreationClick(data.id)}>{data.tag}</div>
                    })}
                  </div>
                </div>
                : ""}

              {searchResultTreehouses ?
                <div className='result-treehouses'>
                  <div className='treehouse-header'>Treehouses</div>
                  <div className='result-row'>
                    {searchResultTreehouses.map((data, key) => {
                      return <div className='result-itm treehouses-itm' key={key} onClick={() => onTreehouseClick(data)}>{data.tag}</div>
                    })}
                  </div>
                </div>
                : ""}

              {!searchResultBranches && !searchResultChallenges && !searchResultTreehouses ?
                <div className='result-treehouses'>
                  <div className='result-row'>
                    <div className='result-itm treehouses-itm'>No result found</div>
                  </div>
                </div>
                : ""}
            </div>
            : ""}
        </Form.Group>
      </Form>
      {/* <GuestRedirectVerification showModal={showModal} setShowModal={setShowModal} /> */}
      <OptimizeSignup showModal={showModal} setShowModal={setShowModal} />
    </div>
  )
}

export default Search;