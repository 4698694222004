import React, { useState } from 'react'
import { Modal, Image } from 'react-bootstrap';
import { GUARDIAN_LOGIN_ROUTE } from '../../../constants/routes.constants';
import { useAuth } from '../../auth/Auth';
import notVerified from '../../../assets/icon/Unverified.svg';
import { IoIosCheckmarkCircle } from '@react-icons/all-files/io/IoIosCheckmarkCircle';
import { HiArrowNarrowRight } from '@react-icons/all-files/hi/HiArrowNarrowRight';
import closeIcon from '../../../assets/icon/icon-darker-close.svg';


function GetVerifiedNowModal({show, setShow}) {
    const [showModal, setShowModal] = useState(show)
    const { logout, dependent } = useAuth()
    const name = dependent ? dependent.first_name : '';
    const closeModal = () => {
        setShowModal(false);
        setShow(false);
    }

    return(
        <Modal className='not-veryfied-modal' show={showModal} onHide={closeModal}>
            <Modal.Header>
            <div className="close-button" data-dismiss="modal" onClick={closeModal}>
                <img src={closeIcon} alt="Close" />
            </div>
            </Modal.Header>
            <Modal.Body>
                <Image className='not-veryfied-img-modal mt-5 mb-5' src={notVerified} alt=""/>
                <h4>Hey {name}</h4>
                <p>Ask parent or guardians help! Once verified you will be able to:</p>
                <ul>
                    <li><span className='list-ic-check'><IoIosCheckmarkCircle/></span> Accept challenges from TaleTree partners and schools</li>
                    <li> <span className='list-ic-check'><IoIosCheckmarkCircle/></span>Treehouse chat with new and existing friends based on their passions</li>
                    <li> <span className='list-ic-check'><IoIosCheckmarkCircle/></span>Upload projects to Treehouse galleries</li>
                    <li> <span className='list-ic-check'><IoIosCheckmarkCircle/></span>Like and comment on friend's creations</li>
                </ul>
                <button className='gred-btn common-round-btn mt-40 font-weight-bold btn btn-primary btn-block' onClick={() => logout(GUARDIAN_LOGIN_ROUTE)}>Get Verified now <HiArrowNarrowRight className="float-right"/></button>
            </Modal.Body>
        </Modal>
    )
}

export default GetVerifiedNowModal;