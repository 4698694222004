import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Container, Row, Col, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import starIcon from '../../../assets/icon/icon-my-room-star.svg';
import flagIcon from '../../../assets/icon/icon-my-room-flag.svg';
import { useAuth } from '../../auth/Auth';
import '../my_room/css/myroom.scss';
import MyBadge from '../../common/MyBadge';
import defaultBannerImg from '../../../assets/images/bg_MyRoom.svg';
import notVerified from '../../../assets/icon/Unverified.svg';
import defaultProfilePic from '../../../assets/profile/default-profile-guardian.svg';
import { treehouseViewModel } from './treehouse.view.modal';
import PartnerHeader from '../school/PartnerHeader';
import CampHeader from '../../header/CampHeader';
import CampHeaderGuest from '../../header/CampHeaderGuest';
import { BsCheck } from '@react-icons/all-files/bs/BsCheck';
import { HiOutlineChevronLeft } from '@react-icons/all-files/hi/HiOutlineChevronLeft';
import goldTick from '../../../assets/icon/gold_tick.svg';
import silverTick from '../../../assets/icon/silver_tick.svg';
import TreehouseSignup from '../onboarding/signup_popups/TreehouseSignup';
import ChallengeBottomSection from './ChallengeBottomSection';
import { partnerViewModel } from '../school/partner.view.model';
import ExpertSelectbox from '../partner/ExpertSelectBox';
import { HiDotsHorizontal } from '@react-icons/all-files/hi/HiDotsHorizontal';
import { LuShare } from "react-icons/lu";
import ShareModalTreehouse from './ShareModalTreehouse';
import verfiedIcon from '../../../assets/icon/Verified-badge.svg';

const TreehouseRoom = ({ treehouse, showModal, setShowModal }) => {
    const history = useHistory();
    const { dependent, guardian, school, partner, fetchAPI } = useAuth();
    const userName = treehouse?.user_type === "partner" ? treehouse?.name_of_organisation : `${treehouse?.first_name} ${treehouse?.last_name}`;
    const [challengeCount, setChallengeCount] = useState(0);
    const [creationCount, setCreationCount] = useState(0);
    const [subscriptionCount, setSubscriptionCount] = useState(0);
    const [bgImg, setBgImg] = useState(treehouse.banner_url);
    const [selectedTreehouses, setSelectedTreehouses] = useState([]);

    const [expertsList, setExpertsList] = useState([]);
    const [selectedExpert, setSelectedExpert] = useState();
    const [isDisabled, setIsDisabled] = useState(true);
    const [showShareModal, setShowShareModal] = useState(false);
    const shareModalProps = `${treehouse?.user_type === 'partner' ? 'treehouse' : 'expert'}/${treehouse?.slug}`
    const [query, setQuery] = useState({
        limit: 10,
        has_more: true,
    })

    let myRoomImageStyle = {
        backgroundImage: bgImg ? `url(${bgImg})` : `url(${defaultBannerImg})`
    }

    useEffect(() => {

        let partner_signup = {
            id: treehouse?.id,
            picture_url: treehouse?.picture_url,
            is_verified: treehouse?.is_verified,
            type_of_organisation: treehouse?.type_of_organisation,
            user_type: treehouse?.user_type
        }
        localStorage.setItem('partner', JSON.stringify(partner_signup));

    }, [])

    // useEffect(() => {
    //     if (dependent || guardian || partner || school) {
    //         setShowModal(false)
    //     } else {
    //         setShowModal(true)
    //     }
    // }, [dependent, guardian, partner, school])

    const getBadgeCount = () => {
        let API = treehouse.user_type === 'partner' ? treehouseViewModel.getPartnerBadgeCount : treehouseViewModel.getExpertBadgeCount;
        API(treehouse.id).then((res) => {
            setChallengeCount(res.challenge_count)
            setCreationCount(res.creation_count)
            if (res.subscription_count) {
                setSubscriptionCount(res.subscription_count)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const getSubscribedTreehouses = () => {
        treehouseViewModel.getSubscribedTreehouses(dependent?.id).then((res) => {
            setSelectedTreehouses(res.map(item => item.id))
        }).catch((err) => { });
    }

    const showSelected = (id) => {
        if (selectedTreehouses.indexOf(id) != -1) {
            return true;
        }
        else {
            return false;
        }
    }

    const setTreehouseForAdd = (data) => {
        if (dependent) {
            if (selectedTreehouses.indexOf(data) != -1) {
                setSelectedTreehouses(selectedTreehouses.filter(item => item != data));
                fetchAPI(treehouseViewModel.unSubscribeTreehouse, data)
                    .then((res) => { })
                    .catch((err) => { });
            }
            else {
                setSelectedTreehouses([...selectedTreehouses, data]);
                fetchAPI(treehouseViewModel.subscribeTreehouse, data)
                    .then((res) => { })
                    .catch((err) => { });
            }
        } else {
            setShowModal(true);
            let partner_signup = {
                id: treehouse.id,
                picture_url: treehouse.picture_url,
                is_verified: treehouse.is_verified,
                type_of_organisation: treehouse.type_of_organisation,
                user_type: treehouse.user_type
            }
            localStorage.setItem('partner', JSON.stringify(partner_signup));
        }
    }

    const getExperts = () => {
        let params = { ...query };
        params.partner_id = treehouse?.id;
        partnerViewModel.getExperts(params, expertsList).then((res) => {
            setExpertsList(res.newList)
        })
    }

    useEffect(() => {
        // changeBodyClassName([bg.WHITE]);
        if (treehouse) {
            getBadgeCount();
            getExperts();
        }
    }, [])

    useEffect(() => {
        if (dependent) getSubscribedTreehouses()
    }, [dependent])

    const renderTooltip = (treehouse) => (
        <Tooltip id="button-tooltip" >
            {treehouse.is_verified ?
                treehouse.user_type === "camp master" ?
                    "This account is for KYC verified experts who have been invited to interact with our community." :
                    treehouse.type_of_organisation === "non profit" ?
                        "This account is for KYC verified non profits who have who have been invited to own a public Treehouse"
                        : "This account is for KYC verified businesses who have been invited to own a public Treehouse" : ""
            }
        </Tooltip>
    );

    const handleGobackClick = () => {
        history.goBack()
    }

    return (
        <div className="myroom-main partner_myroom_main partner-wrapper-main">
            {dependent ? <div className='camp-left'><CampHeader /></div> : school || partner ? <PartnerHeader /> : <div className='camp-right-logo'> <CampHeaderGuest /> </div>}
            <TreehouseSignup showModal={showModal} setShowModal={setShowModal} treehouse={treehouse} />
            <div className="myroom-profile-info">
                <div className='treehouse-back kid-back-btn' role="button" onClick={handleGobackClick}>
                    <span className="back-btn" style={{ color: '#fff' }}><HiOutlineChevronLeft /></span>
                </div>
                <div className={bgImg ? "myroom-info-container overlay-container container new-banner-image" : "myroom-info-container overlay-container container"} style={myRoomImageStyle}>
                    <Container fluid="md">
                        <Row className="myroom-info-wrapper">
                            <Col md={7} lg={7} xl={8}>
                                <div className="myroom-collapse">
                                    <div className="avatar-wrapper">
                                        <div className="full-big-type">
                                            <Row>
                                                <div className="avatar-image" >
                                                    <Image
                                                        className="rounded-circle profile-image"
                                                        src={treehouse.picture_url ? treehouse.picture_url : defaultProfilePic}
                                                    />
                                                </div>
                                                <div className="user-info">
                                                    <div className="name-favorites">
                                                        <div className="user-name">{userName}
                                                            <OverlayTrigger
                                                                placement="top"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={renderTooltip(treehouse)}
                                                            >
                                                                {treehouse.is_verified ?
                                                                    treehouse.user_type === "partner" ?
                                                                        treehouse.type_of_organisation === "non profit" ?
                                                                            <Image className="blue_badge" src={silverTick} />
                                                                            : <Image className="blue_badge" src={goldTick} />
                                                                        : treehouse.user_type === "camp master" ?
                                                                            <Image className="blue_badge" src={verfiedIcon} /> : ''
                                                                    : <Image className="blue_badge" src={notVerified} />
                                                                }
                                                            </OverlayTrigger>
                                                            <span onClick={() => setShowShareModal(true)} style={{ marginLeft: '7px', cursor: 'pointer' }}><LuShare style={{ height: '22px' }} /></span>
                                                        </div>

                                                        <div className="favorites">{treehouse?.description}</div>
                                                    </div>
                                                </div>
                                            </Row>
                                            <Row className="badge-wrapper">
                                                <MyBadge
                                                    icon={flagIcon}
                                                    count={challengeCount}
                                                    className="first-badge"
                                                    title="Challenges published"
                                                />
                                                <MyBadge
                                                    icon={starIcon}
                                                    count={creationCount}
                                                    title="Creations submitted"
                                                />
                                                {treehouse?.user_type === "camp master" &&
                                                    <MyBadge
                                                        count={subscriptionCount}
                                                        title="Kids subscribed"
                                                    />
                                                }
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col
                                md={{ span: 5 }}
                                lg={{ span: 5 }}
                                xl={{ span: 4 }}
                            >
                                <div className="join-camp-wrapper">
                                    {treehouse?.user_type === "partner" &&
                                        <div className="filter-container">
                                            <div className="filter-tag" >
                                                <ExpertSelectbox
                                                    expertsList={expertsList}
                                                    selectedExpert={selectedExpert}
                                                    setSelectedExpert={setSelectedExpert}
                                                    setIsDisabled={setIsDisabled}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {treehouse?.user_type === "camp master" && !guardian ?
                                        <Button
                                            variant={`outline-light common-round-btn btn btn-block ${showSelected(treehouse.id) && "tree-subscribed"}`}
                                            onClick={() => setTreehouseForAdd(treehouse.id)}
                                            disabled={partner || school}
                                        >
                                            {dependent && showSelected(treehouse?.id) ? <><span className="subscribed"><BsCheck /></span> Subscribed </>
                                                : "Subscribe"}
                                        </Button>
                                        : ""
                                    }
                                </div>


                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container fluid="md">
                    <ChallengeBottomSection
                        selectedExpert={selectedExpert}
                        partner_id={treehouse.id}
                        treehouse={treehouse}
                    />
                </Container>

                <ShareModalTreehouse
                    showShareModal={showShareModal}
                    setShowShareModal={setShowShareModal}
                    shareModalProps={shareModalProps}
                />
            </div>
        </div>
    );
};

export default TreehouseRoom;