// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/purchased-camp-pass.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "body{padding-bottom:50px;color:#321b6d}body .emphasize{font-weight:bold;color:#32c1bd}html{height:100%;overflow:auto}.form-control-focus,.form-control:focus{color:#495057;background-color:#fff;border-color:#321b6d;outline:0;box-shadow:unset}.form-control::-webkit-input-placeholder{color:#afafaf}.form-control:-moz-placeholder{color:#afafaf}.form-control::-moz-placeholder{color:#afafaf}.form-control:-ms-input-placeholder{color:#afafaf}.form-control::-ms-input-placeholder{color:#afafaf}.purchase-special-camp-wrapper .message{max-width:528px;padding:0 1rem}.purchase-special-camp-wrapper .complete-image{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:center center;width:614px;height:361px}.purchase-special-camp-wrapper .button-wrapper{min-width:unset}.purchase-special-camp-wrapper .button-wrapper .btn{width:528px;color:#321b6d;font-weight:700}@media only screen and (max-width: 768px){.purchase-special-camp-wrapper .complete-image{background-size:100% 100%;width:100%;height:180px}.purchase-special-camp-wrapper .button-wrapper{min-width:100%}.purchase-special-camp-wrapper .button-wrapper .btn{width:100%}}", ""]);
// Exports
module.exports = exports;
