import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Card, Image } from 'react-bootstrap';
import { CAPTAIN_ROUTE, EXPERT_ROUTE, MY_ROOM_ROUTE, TREEHOUSE_ROUTE } from '../../../constants/routes.constants';
import ChallengeMedia from '../next_challenge/ChallengeMedia';
import myRoomGuideCard from '../../../assets/images/my-room-guide-card.svg';
import Avatar from '../../common/Avatar';
import { SMALLEST_TYPE } from '../../../constants/avatarType.constants';
import { getUserProfileUrl } from '../../../utils/camp.utils';
import { useAuth } from '../../auth/Auth';
import letsgoProfile from '../../../assets/profile/default-profile-letsgo.svg';
import taletreeLogo from '../../../assets/logo/logo-camp.svg';
import defaultProfilePic from '../../../assets/profile/default-profile-guardian.svg';
import { nextChallengeViewModel } from './next.challenge.viewmodel';
import EventEmitter from '../../../utils/events';
import ArrowButton from '../../../assets/icon/blue_arrow.svg';
import './scss/challenge.card.scss';
import GetVerifiedNowModal from './getVerifiedNowModal';
import ChallengeModal from '../../common/ChallengeModal';
import ShareModal from '../../common/ShareModal';
import { HiDotsHorizontal } from '@react-icons/all-files/hi/HiDotsHorizontal';
import OptimizeSignup from '../onboarding/signup_popups/OptimizeSignup';

const ChallengeCard = ({ challenge, type = 'challenge-list', handleClick, noAcceptBtn, noClick }) => {

  const [showModal, setShowModal] = useState(false);
  const [challengeStatus, setChallengeStatus] = useState(challenge?.challenge_status);
  const history = useHistory();
  const { dependent, guardian, fetchAPI } = useAuth();
  const isAcceptBtn = useMemo(() => noAcceptBtn ? false : true);
  const [showGetVerifiedModal, setShowGetVerifiedModal] = useState(false);
  const [showExceedMaxChallenge, setShowExceedMaxChallenge] = useState(false);
  const [imageLoaded, setImageLoaded] = useState([]);
  const isEvent = challenge?.is_event ? 'event' : challenge?.special_camp?.id ? 'special' : 'challenge'
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareModalProps, setShareModalProps] = useState({
    id: challenge?.id,
    slug: isEvent === 'special' ? 'special-challenge' : isEvent
  });

  const setImageLoadedFun = (id) => {
    const temp = [...imageLoaded]
    temp[id] = true;
    setImageLoaded(temp);
  }

  const goToMyRoom = () => {
    history.push(MY_ROOM_ROUTE);
    window.scrollTo(0, 0);
  };
  const acceptedInfos = () => {
    const displayKids = challenge.accepted_dependents.flatMap((kid, index) =>
      index >= 3
        ? []
        : {
          userProfileUrl: getUserProfileUrl(kid),
          taletreeFriend: kid.profile.taletree_friend,
          userName: kid.username,
        }
    );
    const dependentCount = challenge.accepted_dependent_count;
    const submissionCount = challenge.submission_count;
    const dependentCountView =
      dependentCount > 0 ? (
        <>
          {displayKids.flatMap((kid, index) => (
            <span key={index}>
              {dependent ? <Avatar {...kid} type={SMALLEST_TYPE} /> : <div className="avatar-wrapper">
                <div className="smallest-type">
                  <Image
                    className="rounded-circle"
                    src={letsgoProfile}
                  />
                </div>
              </div>}

            </span>
          ))}{' '}
          <span className="accepted-count opa" id={challenge.id}>
            {dependentCount}{' '}
            <span
              className={`${isMobile ? 'hide' : 'show'}`}
              id={challenge.id}
            ></span>
          </span>
        </>
      ) : (
        <div></div>
      );
    const submissionCountView =
      submissionCount > 0 ? (
        <div className="submission-count opa" id={challenge.id}>
          {submissionCount} completed
        </div>
      ) : (
        <div></div>
      );
    const dot =
      dependentCount > 0 && submissionCount > 0 ? (
        <span className="dot opa" id={challenge.id}>
          ·
        </span>
      ) : (
        ''
      );
    return dependentCount === 0 && submissionCount === 0 ? (
      <div id={challenge.id}>Be the first!</div>
    ) : (
      <>
        {dependentCountView}
        {dot}
        {submissionCountView}
      </>
    );
  };

  const challengeAccept = (id) => {
    if (dependent && !dependent.profile.is_activated) {
      setShowGetVerifiedModal(true)
    } else {
      if (dependent && !guardian) {
        fetchAPI(nextChallengeViewModel.challengeAccept, id, true)
          .then((res) => {
            setChallengeStatus("SELECTED");
            EventEmitter.dispatch('accept_challenge', {})
          })
          .catch((err) => {
            if (err.serverResponse.result_code === 405007) {
              setShowExceedMaxChallenge(true);
            }
          });
      } else {
        setShowModal(true);
        localStorage.setItem('kids_login_location', window.location.href)
      }
    }
  };

  const handleTreehouseClick = () => {
    if (challenge?.created_by?.user_type === "partner" || challenge?.created_by?.user_type === "camp master") {
      let id = challenge?.treehouse_user?.slug ? challenge?.treehouse_user?.slug : 
      challenge?.created_by?.slug ? challenge?.created_by?.slug : challenge?.treehouse_user?.id
      history.push({
        pathname: `${challenge?.treehouse_user?.type_of_organisation ? TREEHOUSE_ROUTE : EXPERT_ROUTE}/${id}`,
        state: {
          type: challenge?.treehouse_user?.slug || challenge?.created_by?.slug ? 'slug' : 'id'
        }
      })
    } else if (challenge?.created_by?.user_type === "school_teacher") {
      history.push(`${CAPTAIN_ROUTE}/${challenge?.group?.id}`)
    } else if (challenge?.created_by?.user_type === "school") {
      history.push(`${CAPTAIN_ROUTE}/${dependent?.profile?.group?.id}`)
    }
  }

  return type.substring(0, 9) === 'challenge' ? (
    <Card
      className={`challenge-card ${type}`}
      id={challenge.id}
    >
      <div role={noClick ? "" : "button"}>
        <div>
          <ChallengeMedia repo={challenge} for="list" handleClick={handleClick} />
        </div>
        <span className='postedby-img' onClick={() => handleTreehouseClick()}>
          <Image src={
            challenge?.treehouse_user?.picture ? challenge?.treehouse_user?.picture :
            challenge?.created_by?.is_superuser && challenge.created_by.is_superuser === true ?
            taletreeLogo :
            challenge?.created_by?.picture_url ? challenge?.created_by?.picture_url :
            defaultProfilePic
          } />
          {/* {challenge?.treehouse_user?.picture ?
            <Image src={challenge.treehouse_user.picture} />
            : challenge?.created_by?.is_superuser && challenge.created_by.is_superuser === true ? <Image src={taletreeLogo} />
              : <Image src={defaultProfilePic} />} */}
        </span>
      </div>
      <Card.Body className={`custom-title-and-desc ${type}`} id={challenge.id}>
        <div className="feed-title-bar" id={challenge.id}>
          <Card.Title className="challenge-title" id={challenge.id} onClick={(e) => handleClick(e, isEvent)} role={type === 'challenge-detail' ? '' : 'button'}>
            {challenge.title || challenge?.name}
            {/* three dots */}
            {type === 'challenge-detail' &&
              <div className="three-dot" onClick={() => setShowShareModal(true)}>
                <HiDotsHorizontal />
              </div>
            }
          </Card.Title>
          <Card.Text className="challenge-desc" id={challenge.id} onClick={(e) => handleClick(e, isEvent)} role={type === 'challenge-detail' ? '' : 'button'}>
            {challenge.description}
          </Card.Text>
          <div className="additional-infos row-flex" id={challenge.id}>
            <div className="left" id={challenge.id}>
              {challenge.accepted_dependents && acceptedInfos()}
            </div>
            {/* {isAcceptBtn && 
              <div>
                {challengeStatus != "SELECTED" ?(
                  <div className="right" id={challenge.id} role="button" onClick={()=>challengeAccept(challenge.id)}><Image src={acceptArrow} /></div>
                ):(
                  <div className="right btn-accepted-chall"><BsCheckLg /> Accepted</div>
                )
                }
              </div>
            } */}
            <div id={challenge.id} style={{ zIndex: '5' }} onClick={(e) => handleClick(e, isEvent)} role="button">
              <Image src={ArrowButton} alt="" id={challenge.id} />
            </div>
          </div>
        </div>
      </Card.Body>
      <ChallengeModal
        handleClose={() => setShowExceedMaxChallenge(false)}
        show={showExceedMaxChallenge}
        type="warning"
        level="warning"
        button1Label="Got it"
      >
        Oops! You can only choose up to three Challenges at a time. Please
        finish your Challenge before choosing a new one.
      </ChallengeModal>
      {showGetVerifiedModal ? <GetVerifiedNowModal show={showGetVerifiedModal} setShow={setShowGetVerifiedModal} /> : ''}
      <OptimizeSignup showModal={showModal} setShowModal={setShowModal} />
      <ShareModal
        showShareModal={showShareModal}
        setShowShareModal={setShowShareModal}
        shareModalProps={shareModalProps}
      />
    </Card>
    
  ) : (
    <Card className="challenge-card guide-card" role='button' onClick={goToMyRoom}>
      <Card.Img
        variant="top"
        src={myRoomGuideCard}
        className="challenge-iamge"
      />
      <div className="description">
        You can do your own challenge and submit from My Room!
      </div>
      <div className="linkToMyRoom">
        Go to <span className="myroom">My Room</span>
      </div>
    </Card>
  );
};

export default ChallengeCard;
