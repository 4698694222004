import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useAuth } from '../../auth/Auth';
import { useParams } from 'react-router-dom';
import DashboardHeader from '../../header/DashboardHeader';
import AlertMessage from '../../common/AlertMessage';
import { useForm } from 'react-hook-form';
import ErrorLabel from '../../common/ErrorLabel';
import { ERROR_REQUIRE_MSG } from '../../../constants/error.constants';
import FormInput from '../../common/FormInput';
import FormLabel from '../../common/FormLabel';
import EditAvatar from '../../common/EditAvatar';
import LoadButton from '../../common/LoadButton';
import { isObjEmpty } from '../../../utils/utils';

import './css/edit.kid.profile.scss';
import { settingsViewModel } from './settings.view.model';

const EditGuardianProfile = () => {
  const { guardian, fetchAPI, refreshUserState } = useAuth();
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const def = { ...guardian };
  const {
    control,
    register,
    handleSubmit,
    setError,
    reset,
    errors,
    formState,
  } = useForm({
    mode: 'onChange',
    defaultValues: guardian,
    shouldUnregister: false,
  });
  const onSubmit = (formData) => {
    // console.log(formData);
    setIsLoading(true);
    fetchAPI(
      settingsViewModel.updateGuardianEmail,
      guardian.id,
      formData,
      formState.dirtyFields
    )
      .then(() => {
        const newValues = { ...formData };
        reset(newValues);
        setShowAlert(true);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="edit-kid-profile-wrapper">
      <DashboardHeader />
      <Container className="setting-container save-modal">
        <AlertMessage
          handleClose={() => {
            refreshUserState()
            setShowAlert(false)
          }}
          show={showAlert}
          title={'Changes has been saved.'}
        />
        <Row className="flex-grow-1 mt-3 align-content-center wrapper">
          <div id="basic" className="mb-5  tab-width col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group>
                <h4>Basic</h4>
              </Form.Group>
              <Form.Group>
                <FormLabel>First Name</FormLabel>
                <FormInput
                  name="first_name"
                  ref={register({
                    required: ERROR_REQUIRE_MSG,
                  })}
                  placeholder="First Name"
                  data-testid="first_name"
                />
                <ErrorLabel errors={errors} name="first_name" />
              </Form.Group>
              <Form.Group controlId="last_name">
                <FormLabel className="pl-1 small signup-label">Last Name</FormLabel>
                <FormInput
                  name="last_name"
                  ref={register({
                    required: ERROR_REQUIRE_MSG,
                  })}
                  placeholder="Last Name"
                  data-testid="last_name"
                />
                <ErrorLabel errors={errors} name="last_name" as="label" />
              </Form.Group>
              <Form.Group className="button-wrapper">
                <LoadButton
                  className="rounded-pill gred-btn"
                  isDisabled={isLoading || isObjEmpty(formState.dirtyFields)}
                  isLoading={isLoading}
                  text="Save Changes"
                  type='submit'
                />
              </Form.Group>
            </Form>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default EditGuardianProfile;
