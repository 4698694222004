import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { treehouseViewModel } from './treehouse.view.modal';
import TreehouseRoom from './TreehouseRoom';
import { useAuth } from '../../auth/Auth';
import PartnerChallenges from '../partner/Challenges';
import { useLocation, useHistory } from 'react-router-dom';
import { HOME_FEED } from '../../../constants/routes.constants';

const Treehouse = () => {
    const { tid } = useParams();
    const { dependent, guardian, school, partner, fetchAPI, isLoading } = useAuth();
    const [treehouse, setTreehouse] = useState();
    const [isTreehouseLoading, setIsTreehouseLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(true);
    const [treehouseId, setTreehouseId] = useState(null);
    const [expertId, setExpertId] = useState(null);
    const [showPartnerRoom, setShowPartnerRoom] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const location = useLocation();
    const history = useHistory();

    const getTreehouseBySlug = () => {
        treehouseViewModel.getTreehouseBySlug(tid).then((res) => {
            if (res.user_type === "partner") {
                setTreehouseId(res.id);
            } else {
                setExpertId(res.id)
            }
        }).catch((err) => {
            console.log(err)
            setTreehouseId(tid)
            // history.replace(HOME_FEED)
        })
    }

    const getTreehouseDetails = () => {
        treehouseViewModel.getTreehouseDetails(treehouseId).then((res) => {
            setTreehouse(res);
            setIsTreehouseLoading(false);
        }).catch((err) => {
            console.log(err)
            history.replace(HOME_FEED)
        })
    }

    useEffect(() => {
        if (location?.state?.type) {
            if (location?.state?.type === 'slug') {
                getTreehouseBySlug()
            }
            if (location?.state?.type === 'id') {
                setTreehouseId(tid)
            }
        } else {
            getTreehouseBySlug()
        }
    }, [])

    const getExpertDetails = () => {
        treehouseViewModel.getExpertDetails(expertId).then((res) => {
            setTreehouse(res);
            setIsTreehouseLoading(false);
        }).catch((err) => {
            console.log(err)
            history.replace(HOME_FEED)
        })
    }

    useEffect(() => {
        if (treehouseId) getTreehouseDetails();
    }, [treehouseId])

    useEffect(() => {
        if (expertId) getExpertDetails();
    }, [expertId])

    useEffect(() => {
        if (dependent || guardian || partner || school) {
            setShowModal(false)
        } else {
            setShowModal(true)
        }
    }, [dependent, guardian, partner, school])

    return (
        isTreehouseLoading || isLoading ?
            <Spinner />
            :
            <TreehouseRoom treehouse={treehouse} showModal={showModal} setShowModal={setShowModal} />
    )
}

export default Treehouse;