import React, { useState, useRef, useEffect } from 'react';
import { Modal, Image, Spinner, Button } from 'react-bootstrap';
import { myRoomViewModel } from './my.room.viewmodel';
import customizeBannerUpload from '../../../assets/images/upload-ic.svg';
import { ImArrowRight2 } from '@react-icons/all-files/im/ImArrowRight2';
import { imageUtils } from '../../../utils/image.utils';
import { getDefaultProfileImage, getUserProfileUrl } from '../../../utils/camp.utils';
import EventEmitter from '../../../utils/events';
import { useAuth } from '../../auth/Auth';
import ImageCropModal from '../../common/image-crop-rotate/ImageCropModal';
import Carousel from "../onboarding/signup_popups/modalCaraousal";
import { BsArrowLeft } from 'react-icons/bs';
import { addMyRoomClassName } from '../../../utils/utils';
import { bg } from '../../../constants/component.constants';
import defaultBannerImg from '../../../assets/images/Default_cover_image.jpg';

function CustomizeRoomModal({
  customizePopup,
  setCustomizePopup,
  handleHistoryStateChange,
  chooseCustomizeType,
  setNewCustomizePopup,
  setBgImg,
  isFullScreen,
  setIsFullScreen,
  setRoomImageStyle,
  setSelectedTabClass,
  setUnselectedTabClass,
  handleTheme,
  setNameTextColor,
  setInterestTickColor,
  getThemeBannerImage
}) {
  const { dependent, fetchAPI, setUser } = useAuth();
  const [imageModule, setImageModule] = useState('');
  const [imageCropPopup, setImageCropPopup] = useState(false);
  const [imageForCrop, setImageForCrop] = useState(null);
  const initialData = {
    isFullScreen: dependent?.profile?.banner_is_fullscreen,
    banner: dependent.profile?.banner_url ? dependent.profile?.banner_url : defaultBannerImg,
    profile: dependent.profile?.picture_url ? dependent.profile?.picture_url : getUserProfileUrl(dependent),
    theme: dependent.profile?.banner_theme
  }

  const defaultImageUrl = dependent.profile.picture_url
    ? dependent.profile.picture_url
    : getDefaultProfileImage(dependent.profile.taletree_friend);

  const defaultBannerUrl = dependent.profile.banner_url
    ? dependent.profile.banner_url
    : defaultBannerImg;

  const [showSpinner, setShowSpinner] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [profilePicture, setProfilePicture] = useState(defaultImageUrl);
  const [bannerPicture, setBannerPicture] = useState(defaultBannerUrl);
  const [profilePicForm, setProfilePicForm] = useState();
  const [bannerPicForm, setBannerPicForm] = useState(false);
  const [selectedThemeId, setSelectedThemeId] = useState(dependent?.profile?.banner_theme);
  const userImage = useRef();
  const bannerImage = useRef();
  const canvasRef = useRef(null);
  const handleComplete = () => {
    setCustomizePopup(false);
    handleTheme();
    setBannerPicForm(null);
    setBannerPicture(defaultBannerUrl);
    setIsFullScreen(initialData.isFullScreen);
    setIsDisable(false);
    setShowSpinner(false);
    if (dependent?.profile?.banner_theme === '') {
      EventEmitter.dispatch('blue_banner', { preview: defaultBannerUrl })
    } else {
      EventEmitter.dispatch('blue_banner', { preview: getThemeBannerImage(dependent?.profile?.banner_theme) })
    }
  }

  useEffect(() => {
    if (dependent?.profile?.banner_theme !== '') {
      setBannerPicture(getThemeBannerImage(dependent?.profile?.banner_theme))
    }
  }, [])

  const closeModal = () => {
    handleHistoryStateChange();
    handleComplete();
  }

  const handleBack = () => {
    // setBgImg(initialData.banner)
    setNewCustomizePopup(true);
    handleComplete()
  }

  const getThemeImageBinary = async (image) => {
    if (chooseCustomizeType === 'theme') {
      // const res = imageUtils.convertImageToBinary(image)
      return image;
    } else {
      return bannerPicForm;
    }
  };

  const uploadProfilePic = () => {
    if (profilePicForm) {
      fetchAPI(
        myRoomViewModel.updateProfilePic,
        dependent.id,
        profilePicForm,
        dependent
      )
        .then((response) => {
          setUser(response);
        }).finally((response) => {
          setShowSpinner(false);
          // dependent.profile.picture_url = profilePicture;
          setCustomizePopup(false);
        });
    }
  }

  const uploadBannerPic = async () => {
    try {
      let tempBannerImg = ''
      if (bannerPicForm) {
        tempBannerImg = await getThemeImageBinary(bannerPicForm);
      }
      if (chooseCustomizeType === 'theme') {
        fetchAPI(myRoomViewModel.deleteDependentBanner, dependent.id).then((res) => {
          // dependent.profile.banner_url = '';
          setCustomizePopup(false);
        })
      } else {
        const imagePromise = chooseCustomizeType === 'theme' ? '' : imageUtils.getImagePromise(tempBannerImg);
        imagePromise.then((image) => {
          fetchAPI(
            myRoomViewModel.updateBannerPic,
            dependent.id,
            image,
            dependent,
          ).then((response) => {
            setUser(response);
          })
          // dependent.profile.banner_url = bannerPicture;
          setCustomizePopup(false);
        })
      }
      uploadProfilePic()
    } catch (error) {
      // Handle any errors here
      console.log(error)
    } finally {
      setShowSpinner(false);
      // dependent.profile.banner_url = bannerPicture;
    }
  };

  const handleProfileImageSelection = (e) => {
    const files = e.target.files;
    if (files.length) {
      setImageForCrop(files[0])
      setImageModule('profile')
      setImageCropPopup(true)
    }
  };

  const handleBannerImageSelection = (e) => {
    setShowSpinner(true)
    setIsDisable(true)
    const files = e.target.files;
    if (files.length) {
      // setShowSpinner(true);
      setImageForCrop(files[0])
      setImageModule('banner')
      setImageCropPopup(true)
    }
  };

  const onCropComplete = (croppedImage) => {
    const imagePromise = imageUtils.getImagePromise(croppedImage);
    imagePromise
      .then((image) => {
        if (imageModule === 'banner') {
          setBannerPicture(image.preview);
          setBannerPicForm(image)
          EventEmitter.dispatch('banner_pic', { preview: image.preview })
        } else if (imageModule === 'profile') {
          setProfilePicture(image.preview);
          setProfilePicForm(image);
          EventEmitter.dispatch('profile_pic', { preview: image.preview });
        }
        // setIsDisable(true);
        setImageForCrop(null);
        setImageModule('');
        setIsDisable(false);
        setShowSpinner(false);
        if (imageModule === 'banner') {
          handleFullScreen(isFullScreen, image.preview)
        }
      });
  }

  const handleUpdateTheme = () => {
    const params = {
      banner_is_fullscreen: isFullScreen,
      banner_theme: chooseCustomizeType === 'theme' ? selectedThemeId : ''
    }
    fetchAPI(
      myRoomViewModel.updateTheme,
      dependent.id,
      params,
      dependent
    ).then((response) => {
      setUser(response);
      setCustomizePopup(false);
      setShowSpinner(false);
      setIsDisable(false);
    })
  }

  const handleUpload = () => {
    setShowSpinner(true);
    setIsDisable(true);
    handleUpdateTheme()
    uploadBannerPic()
  }

  const handleFullScreen = (fullScreen, image) => {
    if (chooseCustomizeType !== 'theme') {
      let body = document.getElementById('myroom-theme');
      if (fullScreen) {
        body.style.backgroundImage = `url(${image ? image : bannerPicture})`
        addMyRoomClassName(['customFullScreen'])
        setBgImg('');
        setIsFullScreen(true)
      } else {
        body.style.backgroundImage = 'none';
        addMyRoomClassName([bg.WHITE]);
        setIsFullScreen(false);
        EventEmitter.dispatch('banner_pic', { preview: image ? image : bannerPicture })
      }
    } else {
      setIsFullScreen(fullScreen)
    }
  }

  return (
    imageCropPopup && imageForCrop ?
      <ImageCropModal
        imageCropPopup={imageCropPopup}
        setImageCropPopup={setImageCropPopup}
        image={imageForCrop}
        setPicture={onCropComplete}
        heading={`Edit your ${imageModule} image`}
      />
      :
      <Modal className="auth-popup" show={customizePopup} onHide={closeModal} centered>
        <Modal.Header className="box3-haeder align-items-center" closeButton>

          <div className="add-pointer" onClick={handleBack} >
            <BsArrowLeft />
          </div>
          <div className="">
            <h4 className='modal-main-heading text-center'>Let’s customize your Room </h4>
          </div>

        </Modal.Header>
        <Modal.Body className=' '>
          <div className='customize-banner'>
            <img id="theme-banner" className='d-none' src={bannerPicForm ? bannerPicForm : ""} />
            <Image src={bannerPicture} alt="" />
            {chooseCustomizeType !== 'theme' &&
              <span className='customize-banner-upload' onClick={() => bannerImage.current.click()}><Image src={customizeBannerUpload} alt="" /></span>
            }
            <input
              className="d-none"
              accept="image/png, image/jpeg, image/jpg, .heic, .HEIC"
              id='banner_picture'
              name='banner_picture'
              type="file"
              ref={bannerImage}
              onChange={(e) => handleBannerImageSelection(e)}
            />
          </div>
          <div className="flex-div justify-content-between padding-x">
            <div className="customize-user-img">
              <Image src={profilePicture} alt="" />
              <span className='customize-banner-upload' onClick={() => userImage.current.click()}><Image src={customizeBannerUpload} alt="" /></span>

              <input
                className="d-none"
                accept="image/png, image/jpeg, image/jpg, .heic, .HEIC"
                id='profile_picture'
                name='profile_picture'
                type="file"
                ref={userImage}
                onChange={(e) => handleProfileImageSelection(e)}
              />
            </div>
            <div className="btn-div custom-popup pt-3">
              <button className={`mr-3 ${isFullScreen ? 'gred-btn' : 'btn-outline'}`} onClick={() => handleFullScreen(true)}>Full Screen</button>
              <button className={` ${!isFullScreen ? 'gred-btn' : 'btn-outline'}`} onClick={() => handleFullScreen(false)}>Cover Photo</button>
            </div>
          </div>

          <div className="padding-x p-3">
            <div className="slider-div text-center">
              {chooseCustomizeType === 'theme' &&
                <Carousel
                  isFullScreen={isFullScreen}
                  setBgImg={setBgImg}
                  setBannerPicture={setBannerPicture}
                  setBannerPicForm={setBannerPicForm}
                  setRoomImageStyle={setRoomImageStyle}
                  setSelectedTabClass={setSelectedTabClass}
                  selectedThemeId={selectedThemeId}
                  setSelectedThemeId={setSelectedThemeId}
                  setUnselectedTabClass={setUnselectedTabClass}
                  setIsDisable={setIsDisable}
                  setNameTextColor={setNameTextColor}
                  setInterestTickColor={setInterestTickColor}
                />
              }

              <Button className='gred-btn w-80 mt-4 px-5' disabled={isDisable} onClick={handleUpload}>
                {showSpinner ?
                  <Spinner
                    className={'spin'}
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  :
                  <>
                    {chooseCustomizeType === 'theme' ? 'Choose this theme' : 'Discover your room'}
                    <span className='right-arrow-ic'><ImArrowRight2 /></span>
                  </>
                }
              </Button>
            </div>
          </div>

        </Modal.Body>
      </Modal>
  )
}
export default CustomizeRoomModal;