import React from 'react';
import { Modal, Image } from 'react-bootstrap';
import SuccessImage from "../../../../assets/images/Group 3746.svg";

function TimeToUpgradeModal({ showPopup, setShowPopup, upgradeType = 'both' }) {
    const handleClose = () => {
        setShowPopup(false)
    }

    return (
        <>
            <Modal show={showPopup} className="account-varification my-room-modal" onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className='wonderful_image'>
                            <Image src={SuccessImage} alt="" />
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='welcome-to-room'>
                        <h3 className='mb-40 mt-20'>
                            Wow, you reached the maximum number of Challenges or creations
                        </h3>
                        <p class="upgraded-p">It's time to upgrade to the <span class={`upgrade-modal-plan-text seedlings-text-box`}>Seedlings</span> plan</p>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default TimeToUpgradeModal;