import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  PAYMENT_ROUTE,
  GUARDIAN_SETTINGS_ROUTE,
} from '../../../constants/routes.constants';
import { useAuth } from '../../auth/Auth';
import ChallengeModal from '../../common/ChallengeModal';
import RoundButton from '../../common/RoundButton';
import AddSchoolModal from './AddSchoolModal';
import { settingsViewModel } from './settings.view.model';

const ChildActionButtons = ({ dependent }) => {
  const history = useHistory();
  const { refreshUserState } = useAuth();
  const [show, setShow] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const handleCloseDeleteConfirm = () => setShowDeleteConfirm(false);

  const disConnectTreehouse = () => {
    let params = {
      dependent_id: dependent?.id,
      treehouse_id: dependent?.profile?.group.id
    }
    settingsViewModel.disConnectTreehouse(params).then((res) => {
      setShowDeleteConfirm(false);
      refreshUserState();
    }).catch((err) => {
      console.log(err)
    })
  }

  const onPublicRoomClick = () => {
    localStorage.setItem('set_dependent', dependent?.username)
    localStorage.setItem('set_dependent_id', dependent?.id)
    history.push(`/${dependent?.username}`)
  }

  const actionButton = dependent?.profile?.is_activated ? (
    // const actionButton = false ? (

    <RoundButton
      type="button"
      label="Change Plan"
      className="gred-btn change-plan-button button"
      onClick={() =>
        history.push({
          pathname: `${PAYMENT_ROUTE}`,
          state: {
            dependent: JSON.stringify(dependent),
            type: 'edit',
          },
        })
      }
    />

  ) : (
    // Add 'Change plan' button

    <RoundButton
      type="button"
      label="Choose Plan"
      className="gred-btn activate-button"
      onClick={() =>
        history.push({
          pathname: `${PAYMENT_ROUTE}`,
          state: {
            dependent: JSON.stringify(dependent),
            type: 'activate',
        },
        })
      }
    />

  );
  return (
    <div className="button-wrapper">
      <RoundButton
        type="button"
        label="Edit Account"
        className="edit-button button"
        onClick={() =>
          history.push(`${GUARDIAN_SETTINGS_ROUTE}/edit/${dependent?.id}`)
        }
      />
      <RoundButton
            type="button"
            label="View public room"
            className="edit-button button"
            onClick={() =>
              onPublicRoomClick()
            }
          />
      {dependent?.profile?.group?.name ?
          <RoundButton
            type="button"
            label="Disconnect camp"
            className="disc-scl-btn button"
            onClick={() =>
              setShowDeleteConfirm(true)
            }
          />
        :
          <RoundButton
            type="button"
            label="Connect camp"
            className="connect-scl-btn button"
            onClick={() =>
              setShow(true)
            }
          />
      }
      {actionButton}
      <AddSchoolModal show={show} setShow={setShow} dependent={dependent} />
      <ChallengeModal
        handleClose={handleCloseDeleteConfirm}
        handleAction={disConnectTreehouse}
        show={showDeleteConfirm}
        type="confirm"
        level="warning"
        button1Label="Delete"
        button2Label="Cancel"
      >
        <div>
          <span>Are you sure you want to disconnect the camp?</span>
        </div>
      </ChallengeModal>
    </div>
  );
};

export default ChildActionButtons;
