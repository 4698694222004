import React, { Suspense, lazy } from 'react';
import Home from '../views/home/Home';
import { Switch, Route, Redirect } from 'react-router-dom';
// import Account from '../views/account/Account';
const Account = lazy(() => import('../views/account/Account.js'));
const HomeFeed = lazy(() => import('../views/feed/HomeFeed.js'));
const NextChallenges = lazy(() => import('../views/next_challenge/NextChallenges'));
// const Creator = lazy(() => import('../views/my_room/Creator'));
const TaletreeMap = lazy(() => import('../views/map/TaletreeMap.js'));
const PartnerChallenges = lazy(() => import('../views/partner/Challenges'));
const PartnerSignUp = lazy(() => import('../views/school/PartnerSignUp'));
const AddPartnerChallenge = lazy(() => import('../views/partner/AddPartnerChallenge'));
const VerificationPayment = lazy(() => import('../views/dashboard/VerificationPayment'));
const VerificationPaymentSuccess = lazy(() => import('../views/dashboard/VerificationPaymentSuccess'));
const Notification = lazy(() => import('../views/notification/Notification'))
const AddMoreBranches = lazy(() => import('../views/next_challenge/AddMoreBranch'));
const AddMoreTreehoses = lazy(() => import('../views/next_challenge/AddMoreTreehouse'));
const AddChallenges = lazy(() => import('../views/next_challenge/AddChallenges'));
const ShareCreation = lazy(() => import('../views/my_room/ShareCreation'));
const PartnerRoom = lazy(() => import('../views/partner/PartnerRoom'));
const PartnerEditChallenge = lazy(() => import('../views/partner/EditChallenge'));
const SchoolTeacher = lazy(() => import('../views/treehouse/SchoolTeacher'));
const EditExpert = lazy(() => import('../views/partner/EditExpert'));
const AddExpert = lazy(() => import('../views/partner/AddExpert'));
const UpdateEvent = lazy(() => import('../views/partner/UpdateEvent'));
const SingleChallenge = lazy(() => import('../views/next_challenge/SingleChallenge'));
const SingleSpecialChallenge = lazy(() => import('../views/next_challenge/SingleSpecialChallenge'));
const SingleEvent = lazy(() => import('../views/next_challenge/SingleEvent'));
const CampDashboard = lazy(() => import('../views/school/CampDashboard.js'));
import {
  ACCOUNT_ROUTE,
  VERIFY_ROUTE,
  FINISH_SIGNUP_ROUTE,
  DASHBOARD_ROUTE,
  MY_ROOM_ROUTE,
  MY_ROOM,
  NEXT_CHALLENGE_ROUTE,
  RESET_PASSWORD_ROUTE,
  FORGOT_PASSWORD_GUARDIAN_ROUTE,
  FORGOT_PASSWORD_DEPENDENT_ROUTE,
  FORGOT_PASSWORD_EMAIL_ROUTE,
  CAMP_SCHEDULE_ROUTE,
  SIGNUP_COMPLETE_ROUTE,
  PAYMENT_ROUTE,
  HOME_FEED,
  ON_BOARDING,
  KID_OLD_USER_ROUTE,
  UPDATE_NEEDED_EMAIL_ROUTE,
  ADD_MORE_BRANCHES,
  ADD_MORE_TREEHOUSES,
  VERIFICATION_PAYMENT_ROUTE,
  VERIFICATION_PAYMENT_SUCCESS_ROUTE,
  ADD_CHALLENGES_ROUTE,
  NOTIFICATION_ROUTE,
  MY_SCHOOL_ROUTE,
  SCHOOL_KIDS_ROUTE,
  PARTNER_CHALLENGES,
  CREATOR_ROUTE,
  PARTNER_ADD_CHALLENGES,
  PARTNER_ADD_TREEHOUSE,
  PARTNER_ROUTE,
  PARTNER_CHALLENGE_SINGLE,
  MY_PARTNER_ROUTE,
  SCHOOL_ADD_CHALLENGES,
  FORGOT_PASSWORD_SCHOOL_ROUTE,
  FORGOT_PASSWORD_PARTNER_ROUTE,
  PARTNER_EDIT_TREEHOUSE,
  SCHOOL_EDIT_CHALLENGES,
  SCHOOL_CHALLENGES,
  PARTNER_EDIT_CHALLENGES,
  TEACHERS_ROUTE,
  SCHOOL_ROUTE,
  PARTNER_ADD_EXPERT,
  PARTNER_EDIT_EXPERT,
  SITE_DOWN,
  PARTNER_EDIT_EVENT,
  STAGING_LOGIN,
  SHARE_CREATION_ROUTE_BANNER,
  CHALLENGE_ROUTE,
  SPECIAL_CHALLENGE_ROUTE,
  EVENT_ROUTE,
  TREEHOUSE_DETAIL_ROUTE,
  NEW_VERIFIED_SCREEN,
  TALETREE_MAP,
  ON_BOARDING_PARTNER,
  CAPTAIN_DETAIL_ROUTE,
  EXPERT_DETAIL_ROUTE,
  ADD_CAPTAIN,
  EDIT_CAPTAIN,
  CAMP,
  SSO_REDIRECT_SCREEN,
  KAKAO_CALLBACK_SCREEN,
  FORGOT_PASSWORD_ROUTE,
  NEW_PAYMENT_PLAN,
  GOOGLE_CALLBACK_SCREEN,
  MICROSOFT_CALLBACK_SCREEN,
  APPLE_VERIFY
} from '../../constants/routes.constants';
import VerifyAccount from '../views/account/VerifyAccount';
import FinishSignUp from '../views/account/finish_signup/FinishSignUp';
import UpdateNeeded from '../views/account/UpdateNeeded';
import Dashboard from '../views/dashboard/Dashboard';
import GuardianRoute from './GuardianRoute';
// import NextChallenges from '../views/next_challenge/NextChallenges';
// import SingleChallenge from '../views/next_challenge/SingleChallenge';
import DependentRoute from './DependentRoute';
import DependentOrGuestRoute from './DependentOrGuestRoute';
import ResetPassword from '../views/account/ResetPassword';
import ForgotGuardianPassword from '../views/account/ForgotGuardianPassword';
import ForgotPasswordEmail from '../views/account/ForgotPasswordEmail';
import ForgotDependentPassword from '../views/account/ForgotDependentPassword';
import CampSchedule from '../views/camp_schedule/CampSchedule';
import usePageTracking from '../../analytics/usePageTracking';
import payment from '../views/payment/PaymentWrapper';
import SignUpComplete from '../views/account/SignUpComplete';
// import HomeFeed from '../views/feed/HomeFeed';
// import VerificationPayment from '../views/dashboard/VerificationPayment';
// import VerificationPaymentSuccess from '../views/dashboard/VerificationPaymentSuccess';
import Step2 from '../views/onboarding/Step2';
import Step3 from '../views/onboarding/Step3';
import Step4 from '../views/onboarding/Step4';
import Step5 from '../views/onboarding/Step5';
import Step6 from '../views/onboarding/Step6';
import Step7 from '../views/onboarding/Step7';
import partnerRegistration from '../views/onboarding/partnerRegistration';
import UpdateNeededEmail from '../views/account/UpdateNeededEmail';
import childRegistration from '../views/onboarding/childRegistration';
// import Notification from '../views/notification/Notification';
// import Kids from '../views/school/KidsSection';
// import PartnerSignUp from '../views/school/PartnerSignUp';
// import Challenges from '../views/school/Challenges';
import Creator from '../views/my_room/Creator';
// import AddChallenge from '../views/school/AddChallenge';
import PartnerRoutes from '../views/partner/PartnerRoutes';
// import AddPartnerChallenge from '../views/partner/AddPartnerChallenge';
import ForgotSchoolPassword from '../views/account/ForgotSchoolPassword';
import ForgotParentPassword from '../views/account/ForgotParentPassword';
import SchoolRoute from './SchoolRoute';
import PartnerRoute from './PartnerRoute';
// import SchoolRoom from '../views/school/SchoolRoom';
// import PartnerChallenges from '../views/partner/Challenges';
import SchoolRoutes from '../views/school/SchoolRoutes';
import SchoolOrPartnerRoute from './SchoolOrPartnerRoute';
import LoginStaging from '../staging/LoginStaging.jsx';
import VerifiedScreenNew from '../views/account/VerifiedScreenNew';
// import SingleChallenge from '../views/next_challenge/SingleChallenge';
// import SingleSpecialChallenge from '../views/next_challenge/SingleSpecialChallenge';
// import SingleEvent from '../views/next_challenge/SingleEvent';
import Treehouse from '../views/treehouse/Treehouse';
import RedirectScreen from '../views/onboarding/RedirectScreen.js';
import KakaoCallBackScreen from '../views/onboarding/KakaoCallbackScreen.js';
import SubscribePlan from '../views/onboarding/SubscribePlan.jsx';
import ForgotPasswordCommon from '../views/account/ForgotPasswordCommon.js';
import GoogleCallBackScreen from '../views/onboarding/GoogleCallbackScreen.js';
import MicrosoftCallBackScreen from '../views/onboarding/MicrosoftCallbackScreen.js';
// import TaletreeMap from '../views/map/TaletreeMap.js';

function HomeRoute() {
  usePageTracking();
  return (
    <Suspense fallback={<div></div>}>
      <Switch>
        <GuardianRoute exact path={PAYMENT_ROUTE} component={payment} />
        {/* new payment */}
        <Route path={ACCOUNT_ROUTE} component={Account} />
        <Route path={PARTNER_ROUTE} component={PartnerRoutes} />
        <Route path={SCHOOL_ROUTE} component={SchoolRoutes} />
        <Route path={VERIFY_ROUTE} component={VerifyAccount} />
        <Route path={SIGNUP_COMPLETE_ROUTE} component={SignUpComplete} />
        <GuardianRoute
          path={FINISH_SIGNUP_ROUTE}
          component={FinishSignUp}
        />
        <GuardianRoute path={DASHBOARD_ROUTE} component={Dashboard} />
        <SchoolRoute path={CAMP} component={CampDashboard} />
        <GuardianRoute
          exact
          path={VERIFICATION_PAYMENT_ROUTE}
          component={VerificationPayment}
        />
        <GuardianRoute
          exact
          path={VERIFICATION_PAYMENT_SUCCESS_ROUTE}
          component={VerificationPaymentSuccess}
        />
        {/* <DependentGuardianRoute exact path={MY_ROOM_ROUTE} component={MyRoom} /> */}

        <DependentOrGuestRoute exact path={HOME_FEED} component={HomeFeed} />
        <DependentOrGuestRoute exact path={NOTIFICATION_ROUTE} component={Notification} />
        <DependentRoute
          exact
          path={CAMP_SCHEDULE_ROUTE}
          component={CampSchedule}
        />
        <DependentOrGuestRoute
          exact
          path={NEXT_CHALLENGE_ROUTE}
          component={NextChallenges}
        />
        <DependentOrGuestRoute
          exact
          path={CHALLENGE_ROUTE}
          component={SingleChallenge}
        />
        <DependentOrGuestRoute
          exact
          path={SPECIAL_CHALLENGE_ROUTE}
          component={SingleSpecialChallenge}
        />
        <DependentOrGuestRoute
          exact
          path={EVENT_ROUTE}
          component={SingleEvent}
        />
        <Route exact path={KID_OLD_USER_ROUTE} component={UpdateNeeded} />
        <Route
          exact
          path={RESET_PASSWORD_ROUTE}
          component={ResetPassword}
        />
        <Route
          exact
          path={FORGOT_PASSWORD_GUARDIAN_ROUTE}
          component={ForgotGuardianPassword}
        />
        <Route
          exact
          path={FORGOT_PASSWORD_ROUTE}
          component={ForgotPasswordCommon}
        />
        <Route
          exact
          path={FORGOT_PASSWORD_DEPENDENT_ROUTE}
          component={ForgotDependentPassword}
        />
        <Route
          exact
          path={FORGOT_PASSWORD_EMAIL_ROUTE}
          component={ForgotPasswordEmail}
        />
        <Route
          exact
          path={UPDATE_NEEDED_EMAIL_ROUTE}
          component={UpdateNeededEmail}
        />
        <Route
          exact
          path={ADD_MORE_BRANCHES}
          component={AddMoreBranches}
        />
        <Route
          exact
          path={ADD_CHALLENGES_ROUTE}
          component={AddChallenges}
        />
        <DependentRoute
          exact
          path={ADD_MORE_TREEHOUSES}
          component={AddMoreTreehoses}
        />
        {/* Partners Route */}
        <PartnerRoute exact path={MY_PARTNER_ROUTE} component={PartnerRoom} />
        {/* <Route path={`${ON_BOARDING}/schoolRegistration`} component={PartnerSignUp} /> */}
        <Route path={ON_BOARDING_PARTNER} component={PartnerSignUp} />
        <PartnerRoute exact path={PARTNER_CHALLENGES} component={PartnerChallenges} />
        {/* <PartnerRoute exact path={PARTNER_CHALLENGES} component={Treehouse} /> */}
        <PartnerRoute exact path={PARTNER_ADD_CHALLENGES} component={AddPartnerChallenge} />
        <PartnerRoute exact path={PARTNER_EDIT_CHALLENGES} component={PartnerEditChallenge} />
        <PartnerRoute exact path={PARTNER_EDIT_EVENT} component={UpdateEvent} />
        <SchoolOrPartnerRoute exact path={PARTNER_CHALLENGE_SINGLE} component={SingleChallenge} />
        <PartnerRoute exact path={PARTNER_ADD_EXPERT} component={AddExpert} />
        <PartnerRoute exact path={PARTNER_EDIT_EXPERT} component={EditExpert} />
        <Route exact path={TREEHOUSE_DETAIL_ROUTE} component={Treehouse} />
        <Route exact path={EXPERT_DETAIL_ROUTE} component={Treehouse} />
        <DependentRoute exact path={CAPTAIN_DETAIL_ROUTE} component={SchoolTeacher} />
        <Route
          exact
          path={FORGOT_PASSWORD_SCHOOL_ROUTE}
          component={ForgotSchoolPassword}
        />
        <Route
          exact
          path={FORGOT_PASSWORD_PARTNER_ROUTE}
          component={ForgotParentPassword}
        />

        {/* Creator Route */}
        <Route exact path={CREATOR_ROUTE} component={Creator} />

        <Route exact path={SHARE_CREATION_ROUTE_BANNER} component={ShareCreation} />


        {/* <Route path={SIGN_UP_ROUTE} component={SignUp} /> */}
        {/* <Route exact path={`${ON_BOARDING}`} component={Step1} /> */}
        {/* <Route path={`${ON_BOARDING}/step1`} component={Step2} /> */}
        <Route exact path={`${ON_BOARDING}/step2`} component={Step2} />
        <Route exact path={`${ON_BOARDING}/step3`} component={Step3} />
        <Route exact path={`${ON_BOARDING}/step4`} component={Step4} />
        <Route exact path={`${ON_BOARDING}/step5`} component={Step5} />
        <Route exact path={`${ON_BOARDING}/step6`} component={Step6} />
        <Route exact path={`${ON_BOARDING}/step7`} component={Step7} />
        <Route exact path={`${ON_BOARDING}/childRegistration`} component={childRegistration} />
        <Route exact path={`${ON_BOARDING}/partnerRegistration`} component={partnerRegistration} />
        {/* <Route path={`${ON_BOARDING}/schoolRegistration`} component={schoolRegistration} /> */}
        <Route exact path={`/subscribe-plan`} component={SubscribePlan} />

        {/* <Route exact path={SITE_DOWN} component={SiteDown} /> */}
        <Route exact path={STAGING_LOGIN} component={LoginStaging} />
        <Route exact path={NEW_VERIFIED_SCREEN} component={VerifiedScreenNew} />
        <Route path={SSO_REDIRECT_SCREEN} component={RedirectScreen} />
        <Route path={KAKAO_CALLBACK_SCREEN} component={KakaoCallBackScreen} />
        <Route path={GOOGLE_CALLBACK_SCREEN} component={GoogleCallBackScreen} />
        <Route path={MICROSOFT_CALLBACK_SCREEN} component={MicrosoftCallBackScreen} />

        <Route exact path={TALETREE_MAP} component={TaletreeMap} />

        {/* This route must be in last but before "/" route */}
        <Route exact path={MY_ROOM} component={Creator} />
        <Route exact path={MY_ROOM_ROUTE} component={Creator} />
        {/* <Route exact path={APPLE_VERIFY} component={ApplePayVerify} /> */}
        {/*  */}

        <Route exact path="/" component={HomeFeed} />
        <Route render={() => <Redirect to={{ pathname: '/' }} />} />

      </Switch>
    </Suspense>
  );
}
export default HomeRoute;
