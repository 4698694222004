import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import ErrorLabel from '../../common/ErrorLabel';
import { useAuth } from '../../auth/Auth';
import { settingsViewModel } from './settings.view.model';
import { FAVORITES_CHAR_LIMIT } from '../../../constants/limit.constants';
import FormInput from '../../common/FormInput';
import FormLabel from '../../common/FormLabel';
import LoadButton from '../../common/LoadButton';
import { displayErrorFavorites } from '../../api/api.display.error';

function EditChildFavorites({ dependent, onProfileSave }) {
  const { guardian, fetchAPI } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const favorites = {
    color: dependent.profile.favorites[0],
    sport: dependent.profile.favorites[1],
    animal: dependent.profile.favorites[2],
  };
  const {
    control,
    register,
    handleSubmit,
    setError,
    errors,
    formState,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: { favorites },
  });
  const onSubmit = (formData) => {
    setIsLoading(true);
    fetchAPI(settingsViewModel.updateDependentFavorites, dependent.id, formData)
      .then(() => {
        reset(formData);
        onProfileSave();
      })
      .catch((error) => {
        displayErrorFavorites(error, setError, formData);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div id="favorites" className="mb-5 tab-width col-xl-4 col-lg-4 col-md-4 col-sm-12">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="create-children-account-header">
          <h4>Favorites</h4>
        </Form.Group>
        <Form.Group>
          <FormLabel>Favorite Color</FormLabel>
          <FormInput
            name="favorites.color"
            ref={register()}
            placeholder="Favorite Color"
            maxLength={FAVORITES_CHAR_LIMIT}
            data-testid="favorites.color"
          />
          <ErrorLabel errors={errors} name="favorites.color" />
        </Form.Group>
        <Form.Group>
          <FormLabel>Favorite Sport</FormLabel>
          <FormInput
            name="favorites.sport"
            ref={register()}
            placeholder="Favorite Sport"
            maxLength={FAVORITES_CHAR_LIMIT}
            data-testid="favorites.sport"
          />
          <ErrorLabel errors={errors} name="favorites.sport" />
        </Form.Group>
        <Form.Group>
          <FormLabel>Favorite Animal</FormLabel>
          <FormInput
            name="favorites.animal"
            placeholder="Favorite Animal"
            ref={register()}
            maxLength={FAVORITES_CHAR_LIMIT}
            data-testid="favorites.animal"
          />
          <ErrorLabel errors={errors} name="favorites.animal" as="label" />
        </Form.Group>
        <Form.Group className="button-wrapper">
          <LoadButton
            className="rounded-pill text-black gred-btn"
            isDisabled={!formState.isDirty || isLoading}
            isLoading={isLoading}
            text="Save Changes"
          />
        </Form.Group>
      </Form>
    </div>
  );
}

export default EditChildFavorites;
