import React from 'react';
import CampHeader from '../../header/CampHeader';
import AllSchedule from './AllSchedule';
import MySchedule from './MySchedule';
import { useAuth } from '../../auth/Auth';

function CampSchedule() {
  const { dependent } = useAuth();

  return (
    <div className="camp-schedule-main">
      <CampHeader />
      <div className="camp-schedule-wrapper">
        <div className="first-section">
          <div className="camp-schedule-header">
            <h1>{`${dependent.first_name}'s Camp`}</h1>
          </div>
          <div className="my-schedule">
            <MySchedule />
          </div>
        </div>
        <div className="second-section">
          <h2>Some things to remember</h2>
          <div className="first-description">
            <h3>Uploading pictures</h3>
            <p>If you have trouble uploading, please send it to contact@taletree.app 20 mins at the latest before the camp so that one of us can do it. Once we start our call, it's hard to handle uploading</p>
          </div>
          <div className="second-description">
            <h3>Try to be punctual</h3>
            <p> We want to make sure all kids get enough time to share their creations and watch others</p>
          </div>
          <div className="third-description">
            <h3>Please remind your kids of the Zoom etiquette below</h3>
            <ul>
              <li>Show your face throughout the call</li>
              <li>If you are in a noisy environment, mute yourself unless you talk</li>
              <li>Don't draw on the screen while other people show their stuff</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="all-group-container">
        <div className="camp-schedule-header">
          <h1>All Schedules</h1>
        </div>
        <div className="schedule-wrapper">
          <AllSchedule />
        </div>
      </div>
    </div>
  );
}

export default CampSchedule;
