import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import letsgoJson from '../../../assets/json/letsgo.json';
import codyJson from '../../../assets/json/cody.json';
import rootyJson from '../../../assets/json/rooty.json';
import rushmoreJson from '../../../assets/json/rushmore.json';
import uniJson from '../../../assets/json/Uni.json';
import domaJson from '../../../assets/json/doma.json';
import Lottie from 'react-lottie-player';
import CartoonBottom from "../../../assets/images/cartoon-bottom.svg";
import SelectedImg from '../../../assets/icon/selected-friend.png';
import TapToPick from '../../../assets/images/tap_to_pick_me.png';

export default function TaleTreeFriendSlider({ handleClick, clicked, taletreeFriend }) {

    const sliderData = [
        {
            json: letsgoJson,
            value: "letsgo"
        },
        {
            json: uniJson,
            value: "uni"
        },
        {
            json: rushmoreJson,
            value: "rushmore"
        },
        {
            json: codyJson,
            value: "cody"
        },
        {
            json: rootyJson,
            value: "rooty"
        },
        {
            json: domaJson,
            value: "doma"
        },
    ]

    const handleSliderClick = (value) => {
        handleClick(value)
    }

    return (
        <div className="slider-div friend text-center">
            <Carousel
                interval={null}
                wrap={true}
                className='friend-slider'
            >

                {
                    sliderData.map((character, key) => {
                        return (
                            <Carousel.Item key={key}>
                                <div
                                    className='slider'
                                    onClick={() => handleSliderClick(character.value)}
                                >
                                    {taletreeFriend !== character.value && <img className='tap-to-pick' src={TapToPick} alt='Tap to pick me' title='Tap to pick me' /> }
                                    {clicked && taletreeFriend === character.value &&
                                        <img className='friend-selected-img' src={SelectedImg} />}
                                    <Lottie
                                        loop={false}
                                        play
                                        animationData={character.json}
                                        className="cartoon-top"
                                    />
                                    <img className="cartoon-bottom" src={CartoonBottom} />
                                </div>
                            </Carousel.Item>
                        )
                    })
                }
            </Carousel>
        </div>
    )
}