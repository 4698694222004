import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { RiFacebookBoxLine } from '@react-icons/all-files/ri/RiFacebookBoxLine';
import { FiTwitter } from '@react-icons/all-files/fi/FiTwitter';
import { ImWhatsapp } from '@react-icons/all-files/im/ImWhatsapp';
import { SiMailDotRu } from '@react-icons/all-files/si/SiMailDotRu';
import { AiOutlineCopy } from '@react-icons/all-files/ai/AiOutlineCopy';
import { useHistory } from 'react-router-dom';
import { getBaseUrl } from '../../utils/config.utils';
import closeIcon from '../../assets/icon/icon-darker-close.svg';

const ShareModal = ({ showShareModal, setShowShareModal, shareModalProps }) => {

    const BASE_URL = getBaseUrl();
    const history = useHistory();
    const [copiedClass, setCopiedClass] = useState("");
    const [creationId, setCreationId] = useState(
        shareModalProps ? shareModalProps.id : ""
    );
    const slug = shareModalProps?.slug ? shareModalProps?.slug : 'creation'

    useEffect(() => {
        setCreationId(shareModalProps ? shareModalProps.id : "")
    }, [shareModalProps])

    async function copyTextToClipboard() {
        setCopiedClass(copiedClass === "copied" ? "" : "copied");
        console.log(creationId, 'id')
        console.log(typeof(slug), 'slug')
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(`${BASE_URL}/${slug}?encrypt=${btoa(`{"id":${creationId}, "slug":"${slug}"}`)}`);
        } else {
            return document.execCommand('copy', true, `${BASE_URL}/${slug}?encrypt=${btoa(`{"id":${creationId}, "slug":"${slug}"}`)}`);
        }
        
    }

    useEffect(() => {
        setCopiedClass("")
    }, [showShareModal])

    return (
        <>
            <Modal show={showShareModal} onHide={() => setShowShareModal(false)} className='share_modal' centered>
                <Modal.Header >
                    <h5>Share with</h5>
                    <div className="close-button" data-dismiss="modal" onClick={() => setShowShareModal(false)}>
                        <img src={closeIcon} alt="Close" />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="share-modal-inner">
                        <div className="share-icons">
                            <ul>
                                <li>
                                    <div className="facebook">
                                        <a href={`https://www.facebook.com/share.php?u=${BASE_URL}/${slug}?encrypt=${btoa(`{"id":${creationId}, "slug":"${slug}"}`)}`} target='_blank'>
                                            <RiFacebookBoxLine />
                                        </a>
                                    </div>
                                    <h4>Facebook</h4>
                                </li>
                                <li>
                                    <div className="facebook">
                                        <a href={`https://twitter.com/intent/tweet?url=${BASE_URL}/${slug}?encrypt=${btoa(`{"id":${creationId}, "slug":"${slug}"}`)}`} target='_blank'>
                                            <FiTwitter />
                                        </a>
                                    </div>
                                    <h4>Twitter</h4>
                                </li>
                                <li>
                                    <div className="facebook">
                                        <a href={`https://wa.me/?text=${BASE_URL}/${slug}?encrypt=${btoa(`{"id":${creationId}, "slug":"${slug}"}`)}`} data-action="share/whatsapp/share" target='_blank'>
                                            <ImWhatsapp />
                                        </a>

                                    </div>
                                    <h4>Whatsapp</h4>
                                </li>
                                <li>
                                    <div className="facebook">
                                        <a href={`mailto:?body=${BASE_URL}/${slug}?encrypt=${btoa(`{"id":${creationId}, "slug":"${slug}"}`)}`} target='_blank'>
                                            <SiMailDotRu />
                                        </a>
                                    </div>
                                    <h4>E-mail</h4>
                                </li>
                            </ul>
                        </div>
                        <div className="share-link">
                            <h5>Or share with link</h5>
                            <div className={"link-txt " + copiedClass}>
                                {copiedClass === "copied" ? <span>Link copied to clipboard</span> :
                                    <span className='share_link_text'>{BASE_URL}/{slug}?encrypt={btoa(`{"id":${creationId}, "slug":"${slug}"}`)}</span>}
                                <span className="copy-icon" onClick={copyTextToClipboard}>
                                    <AiOutlineCopy />
                                </span>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ShareModal;      