// src/ApplePayButton.js
import React, { useEffect, useState } from 'react';
import { CardNumberElement, Elements, PaymentRequestButtonElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { stripeViewModel } from './stripe.view.model';

const ApplePayButton = ({ label, payAmount, handleSubscribe }) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);

  const applePayFun = async () => {
    const pr = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: label === 'Seedlings V2' ? 'Seedlings' : label,
        amount: payAmount,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    console.log(pr)
    // Check the availability of the Payment Request API.
    pr.canMakePayment().then(result => {
      console.log(result)
      if (result) {
        setPaymentRequest(pr);
      }
    }).catch((err) => {
      console.log(err)
    });

    pr.on('paymentmethod', async (e) => {
      console.log(e.paymentMethod.id)

      e.complete('success');
      console.log('payment', e.paymentMethod.id)
      // console.log('paymentIntent', paymentIntent.status)
      handleSubscribe(e.paymentMethod)
    });
  }

  useEffect(() => {
    if (stripe) {
      applePayFun()
    }
  }, [stripe]);

  return (
    paymentRequest ?
      <PaymentRequestButtonElement options={{ paymentRequest }} />
      : ''
  );
};

export default ApplePayButton