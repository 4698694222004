import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Image, Spinner } from 'react-bootstrap';
import CampHeader from '../../header/CampHeader';
import CampHeaderGuest from '../../header/CampHeaderGuest';
import Avatar from '../../common/Avatar';
import { FULL_BIG_TYPE_CREATOR } from '../../../constants/avatarType.constants';
import CreationGallery from './CreationGallery';
import { useAuth } from '../../auth/Auth';
import { myRoomViewModel } from './my.room.viewmodel';
import { nextChallengeViewModel } from '../next_challenge/next.challenge.viewmodel';
import InfiniteScroll from 'react-infinite-scroller';
import { useResponseError } from '../../api/response.error';
import MyRoomChallenges from './MyRoomChallenges';
import { BsCheck } from '@react-icons/all-files/bs/BsCheck';
import './css/myroom.scss';
import defaultBannerImg from '../../../assets/images/Default_cover_image.jpg';
import defaultProfilePic from '../../../assets/profile/default-profile-guardian.svg';
import RoundButton from '../../common/RoundButton';
import { ADD_MORE_BRANCHES, ADD_MORE_TREEHOUSES, EXPERT_ROUTE, NEXT_CHALLENGE_ROUTE } from '../../../constants/routes.constants';
import PartnerHeader from '../school/PartnerHeader';
import SpaceBanner from '../../../assets/images/Banner-Space.png';
import ForestBanner from '../../../assets/images/Banner-Forest.png';
import LoveBanner from '../../../assets/images/Banner-Love.png';
import { treehouseViewModel } from '../../common/treehouse.view.model';
import verfiedIcon from '../../../assets/icon/Verified-badge.svg';
import unVerfiedIcon from '../../../assets/icon/Unverified.svg';

const CreatorRoom = ({ creator }) => {
  const { dependent, partner, school, fetchAPI } = useAuth();
  const { addError } = useResponseError();
  const history = useHistory();
  const [challengeCount, setChallengeCount] = useState(0);
  const [creationCount, setCreationCount] = useState(0);
  const [binkyCount, setBinkyCount] = useState(0);
  const [bgImg, setBgImg] = useState(creator && creator.profile.banner_url);
  const [tabFlag, setTabFlag] = useState('creation');
  const [creationHasMore, setCreationHasMore] = useState(true);
  const [taletreeFriend, setTaletreeFriend] = useState(
    creator && creator.taletree_character
  );
  const [query, setQuery] = useState({
    limit: 15,
    has_more: true,
  });
  const [startingAfter, setStartingAfter] = useState(0);
  const [acceptedChallenges, setAcceptedChallenges] = useState([]);
  const [completedChallenges, setCompletedChallenges] = useState([]);
  const [creationList, setCreationList] = useState([]);
  const [treehouses, setTreehouses] = useState([]);
  const [branches, setBranches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchCreations = () => {
    setIsLoading(true);
    const queryParams = {
      dependent_id: creator.id,
      for_challenges: false,
      limit: 9,
      starting_after: startingAfter,
    };
    let tempCreationHolder = {
      creationList: creationList
    }
    fetchAPI(myRoomViewModel.getCreations, queryParams, tempCreationHolder)
      .then((data) => {
        setCreationList(data.creationList)
        setStartingAfter(data.starting_after)
        setCreationHasMore(data.has_more);
        setIsLoading(false);
      })
      .catch((error) => {
      });
  };

  const fetchInfiniteCreations = () => {
    if (!isLoading) fetchCreations();
  }

  useEffect(() => {
    const params = {
      'dependent_id': creator.id
    };
    fetchAPI(myRoomViewModel.getBadgeCount, params)
      .then((response) => {
        ReactDOM.unstable_batchedUpdates(() => {
          setChallengeCount(response.dependent_challenge_count);
          setCreationCount(response.dependent_creation_count);
          setBinkyCount(response.binky_count);
        });
      })
      .catch((error) => {
        addError(error.data.message);
      });
  }, []);

  useEffect(() => {
    // changeBodyClassName([bg.WHITE]);
    setTaletreeFriend(creator.profile.taletree_friend);
    getCreatorTreehouses();
    getCreatorInterests();
    fetchCreations();
    if (creator?.profile?.banner_theme !== '') {
      setBgImg(getThemeBannerImage(creator?.profile?.banner_theme))
    }
  }, []);

  const getThemeBannerImage = (id) => {
    switch (id) {
      case 'space':
        return SpaceBanner
        break;
      case 'forest':
        return ForestBanner
        break;
      case 'love':
        return LoveBanner
        break
      default:
        break;
    }
  }

  useEffect(() => {
    if (tabFlag === 'challenge') {
      fetchAcceptedChallenges();
      fetchCompletedChallenges();
    }
  }, [tabFlag]);

  const setChallenges = (challenges, filter) => {
    if (filter === 'all') setCompletedChallenges(challenges);
    else setAcceptedChallenges(challenges);
  };

  const fetchAcceptedChallenges = () => {
    let params = { ...query, dependent_id: creator.id };
    fetchAPI(
      nextChallengeViewModel.getMyRoomChallenges,
      'working',
      params,
      acceptedChallenges
    )
      .then((data) => {
        setChallenges(data.challengeList, 'working');
      })
      .catch((err) => console.log(err));
  };

  const fetchCompletedChallenges = () => {
    let params = { ...query, dependent_id: creator.id };
    fetchAPI(
      nextChallengeViewModel.getMyRoomChallenges,
      'all',
      params,
      completedChallenges
    )
      .then((data) => {
        setChallenges(data.challengeList, 'all');
        setQuery(data.query);
      })
      .catch((err) => console.log(err));
  };
  const handleDeleteCreationById = (id) => { };
  const handleBinky = (creationId, binkyId, binkyCount) => {
    const newList = creationList.map((obj) => {
      return obj.id === creationId
        ? {
          ...obj,
          binky_count: binkyCount,
          binky_id: binkyId,
        }
        : obj;
    });
    setCreationList(newList)
  };

  const handleComment = (creationId, newCommentCount) => {
    const newList = creationList.map((obj) => {
      return obj.id === creationId
        ? {
          ...obj,
          comment_count: newCommentCount,
        }
        : obj;
    });
    setCreationList(newList)
  };

  const getCreatorTreehouses = () => {
    // myRoomViewModel.getCreatorTreehouses(creator.username).then((res) => {
    treehouseViewModel.getSubscribedCampMasters(creator?.id).then((res) => {
      setTreehouses(res)
    }).catch((err) => console.log(err))
  }

  const getCreatorInterests = () => {
    myRoomViewModel.getCreatorInterests(creator.username).then((res) => {
      setBranches(res)
    }).catch((err) => console.log(err))
  }

  let myRoomImageStyle = {
    backgroundImage: bgImg ? `url(${bgImg})` : `url(${defaultBannerImg})`
  }

  const handleBranchClick = (tag) => {
    if(dependent) {
      history.push({
        pathname: NEXT_CHALLENGE_ROUTE,
        state: {
          tag: tag,
          filter: 'tag'
        }
      })
    }
  }

  const onTreehouseClick = (data) => {
    let id = data?.slug ? data?.slug : data?.id
    history.push({
      pathname: `${EXPERT_ROUTE}/${id}`,
      state: {
        type: data?.slug ? 'slug' : 'id'
      }
    })
  }

  return (
    <div className="myroom-main">
      <div className="myroom-profile-info main-tab">
        {dependent ?
          <CampHeader /> : partner || school ? <div className='partner_myroom_main partner-wrapper-main'> <PartnerHeader /> </div> : <CampHeaderGuest />
        }
        <div className="myroom-info-container new-banner-image" style={myRoomImageStyle}>
          <Container fluid="md">
            <Row className="myroom-info-wrapper">
              <Col md={12} lg={12} xl={12}>
                <div className="myroom-collapse">
                  <Avatar
                    type={FULL_BIG_TYPE_CREATOR}
                    creationCount={creationCount}
                    challengeCount={challengeCount}
                    binkyCount={binkyCount}
                    creator={creator}
                  />
                </div>
              </Col>
              {/* <Col
                md={{ span: 5 }}
                lg={{ span: 5 }}
                xl={{ span: 4 }}
              >
                <div className="join-camp-wrapper">

                </div>
              </Col> */}
            </Row>
          </Container>
        </div>
        <Container fluid="md">
          <div className="myroom-creation-title">
            <div
              className={`tab tab-left pt-4 pb-2 ${tabFlag === 'creation' ? 'selected' : 'unselected'
                }`}
              onClick={() => {
                setTabFlag('creation');
              }}
            >
              <div className='public-page-tabs'>Creations ({creationCount})</div>
            </div>
            <div
              className={`tab tab-right pt-4 pb-2 ${tabFlag === 'challenge' ? 'selected' : 'unselected'
                }`}
              onClick={() => {
                setTabFlag('challenge');
                fetchCompletedChallenges();
                fetchAcceptedChallenges();
              }}
            >
              <div className='public-page-tabs'>Challenges ({challengeCount})</div>
            </div>
            <div
              className={`tab tab-right pt-4 pb-2 ${tabFlag === 'interest' ? 'selected' : 'unselected'
                }`}
              onClick={() => {
                setTabFlag('interest');
              }}
            >
              <div className='public-page-tabs'>Interests ({branches.length})</div>
            </div>
            <div
              className={`tab tab-right pt-4 pb-2 ${tabFlag === 'treehouse' ? 'selected' : 'unselected'
                }`}
              onClick={() => {
                setTabFlag('treehouse');
              }}
            >
              <div className='public-page-tabs'>Experts ({treehouses.length})</div>
            </div>
          </div>
          {tabFlag === 'creation' ? (
            <div className="myroom-creation-container">
              <InfiniteScroll
                pageStart={0}
                loadMore={fetchInfiniteCreations}
                hasMore={creationHasMore}
                loader={
                  <Spinner
                    key={0}
                    className="myroom-spinner"
                    animation="border"
                    variant="info"
                  />
                }
                threshold={150}
              >
                <CreationGallery
                  creationList={creationList}
                  deleteCreationById={handleDeleteCreationById}
                  handleBinky={handleBinky}
                  isCreatorRoom={true}
                  handleComment={handleComment}
                  creator={creator}
                />
              </InfiniteScroll>
            </div>
          ) : tabFlag == "challenge" ?
            (
              <Row className="myroom-challenge-container">
                <div className="section-div">
                  <h5>{acceptedChallenges.length} Accepted</h5>
                  <MyRoomChallenges
                    challenges={acceptedChallenges}
                  />
                </div>
                <div className="section-div">
                  <h5>{completedChallenges.length} Completed</h5>
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={fetchCompletedChallenges}
                    hasMore={query.has_more}
                    loader={
                      <Spinner
                        key={0}
                        className="myroom-spinner"
                        animation="border"
                        variant="info"
                      />
                    }
                    threshold={150}
                  >
                    <MyRoomChallenges
                      challenges={completedChallenges}
                      filter="all"
                    />
                  </InfiniteScroll>
                </div>
              </Row>
            ) : tabFlag === "interest" ? (
              <div className="myroom-challenge-container center-div">
                <div className='section-div small-div interst-div'>
                  <h4 className='text-center mt-2 mb-4 public-page-tabs'>Tale Tree is about exploring the interest branches of our Emerald Forest and finding your tribe.</h4>
                  <p className='text-center mt-3 mb-5 public-page-tabs'>See what <span className="creator-text-style">{creator.username}'s</span> interests are</p>
                  <hr />
                  <div className='mt-5 mb-5'>
                    {branches.map((item, key) => {
                      return (
                        <div className='flex-div mb-3' key={key} role='button' onClick={() => handleBranchClick(item.tag)}>
                          <span className='interst-tag'>#{item.tag}</span>
                          <button className="btn-outline interst-btn" ><BsCheck /></button>
                        </div>
                      )
                    })}
                  </div>
                  {dependent &&
                    <RoundButton
                      type="button"
                      label="Find your interests "
                      className=" button gred-btn small-btn text-white"
                      onClick={() => history.push({
                        pathname: ADD_MORE_BRANCHES,
                        state: {
                          from: 'dashboard',
                        }
                      })}
                    />
                  }
                </div>
              </div>
            ) : (
              <Container className='section-div small-div'>
                <div className="add-more-treehouses-inner ">
                  <div className="treehouses-list interst-div">
                    <h4 className='text-center mt-2 mb-4 public-page-tabs'>Tale Tree is about exploring the experts of our Emerald Forest and finding your tribe.</h4>
                    <p className='text-center mt-3 mb-5 public-page-tabs'>See What <span className="creator-text-style">{creator.username}</span> interests are</p>
                    <hr />
                    <ul className='mt-5 mb-5'>
                      {treehouses && treehouses.map((item, key) => {
                        return (
                          <li key={key}>
                            <div className="treehouese-logo-name" role='button' >
                              <div className="treehouese-logo-wrapper " onClick={() => onTreehouseClick(item)}>
                                <div className="treehouese-logo">
                                  <Image className="blue_badge" src={item.picture_url ? item.picture_url : defaultProfilePic} />
                                </div>
                                <span className="check-patch verify-patch">
                                  <Image className="green-tick" src={item?.is_verified ? verfiedIcon : unVerfiedIcon} />
                                </span>
                              </div>
                              <h4 className="treehouese-name" onClick={() => onTreehouseClick(item)}>{`${item?.first_name} ${item?.last_name}`}</h4>
                            </div>
                            <div className="subsribe-btn">
                              <button className="btn-outline tree-selected remove-pointer" ><span className="subscribed"><BsCheck /></span> Subscribed </button>
                            </div>
                          </li>
                        )
                      })}
                      {dependent &&
                        <RoundButton
                          type="button"
                          label="Add more Experts"
                          className=" button gred-btn text-white"
                          onClick={() => history.push(ADD_MORE_TREEHOUSES)}
                        />
                      }
                    </ul>
                  </div>
                </div>
              </Container>
            )
          }

        </Container>
      </div>
    </div>
  );
};

export default CreatorRoom;
