import React from 'react';
import './scss/alert.message.scss';
import { Image } from 'react-bootstrap';
import closeIcon from '../../assets/icon/icon-close-20x20.svg';

const AlertMessage = props => {

  // { handleClose, show, title }
  const showHideClassName = props.show ? "show" : "hide";

  return (
    <div className={`alert-message ${showHideClassName}`}>
      <div className="main">
        <div className="wrapper">
          <div className="am-body">
            <div className="message">
              {props.title}
            </div>
            <div className="close" onClick={props.handleClose}>
              <Image src={closeIcon} />
            </div>
          </div>
        </div>
      </div>
    </div>  
  );
};

AlertMessage.defaultProps = {
  title: "",
  handleClose: ()=>{},
  show: false,
};

export default AlertMessage;
