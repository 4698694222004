import React, { useState } from 'react';
import Upload from './Upload';
import Modal from '../../common/Modal';
import { ComponentSize } from '../../../constants/component.constants';

import './scss/creation.upload.scss';

const CreationUpload = ({
  from, // myroom or challenge
  challengeId,
  showUpload,
  status = '',
  handleClose,
  handleOnUploadComplete,
  setShowTimeToUpgradePopup
}) => {
  const [uploadStatus, setUploadStatus] = useState(status);
  return (
    <div>
      <Modal
        className={`upload-modal ${uploadStatus}`}
        show={showUpload}
        handleClose={handleClose}
        size={ComponentSize.LARGE}
        title=""
      >
        <Upload
          from={from}
          reset={showUpload}
          challengeId={challengeId}
          uploadStatus={uploadStatus}
          setUploadStatus={setUploadStatus}
          handleCloseModal={handleClose}
          handleOnUploadComplete={handleOnUploadComplete}
          setShowTimeToUpgradePopup={setShowTimeToUpgradePopup}
        />
      </Modal>
    </div>
  );
};

export default CreationUpload;
